import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
//import { Link } from 'react-router-dom';
import styles from './Card.module.sass';
//import Icon from '../Icon';
import ReactPlayer from 'react-player';
//import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from "@mui/styles";
//import Button from '@material-ui/core/Button';
//import Button from "@mui/material/Button";
//import Typography from '@material-ui/core/Typography';
//import Typography from "@mui/material/Typography";
import { useHistory } from 'react-router';
import { axiosInstance } from '../../utils/API';
import Swal from 'sweetalert2';
import Image from '../Image';
import { current_datetime } from '../../controller/utils';
import moment from 'moment';
import hasAuctionEnded from '../../utils/auctionEnded';
import { formatPricingNumber } from '../../utils/formatPricingNumber';
import { CRYPTOCURRENCY_CONSTANTS, NAMES_CONSTANTS } from '../../constants';
import { displayPrice } from '../../utils/displayPrice';

const useStyles = makeStyles({
    root: {
          maxWidth: 345
        , overflow: 'hidden'
    }
    , media: {
          height: 140
        , transition: '0.3s'
    }
    , '&:hover': {
        transform: 'scale(1.1)'
    }
});

const CardContainer = ({
      className
    , item
    , type = ''
    , showLabel = false
    , price = '1'
    , resaleHandleClick
    , cancelOption
    , resaleCancelClick
    , productDetail
    , resalePage
    , ownedAssetPage
}) => {
    console.log('CardContainer()');
    console.log('item = ', item);

    //const [visible, setVisible] = useState(false);
    //const classes = useStyles();
    const history = useHistory();
    const exchangeRate = useSelector(state  => state.counter.euroValue);

    /*
    const handleUnlist = (e, id) => {
        let config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        e.stopPropagation();
        axiosInstance.patch(`/asset/un-publish/${id}`, '', config).then(res => {
            Swal.fire('Unlisted', 'item unlisted from marketplace', 'info').then(
                res => window.location.reload()
            );
        });
    };
    */

    const checkForUpcomingBid = bidStartDate => {
        let time = new Date(bidStartDate);
        var now = new Date();
        return time < now ? false : true;
    };

    const checkIfResalePage = () => {
        return resalePage;
    };

    const showBlockchainTransaction = (txHash) => {
        window.open(`https://mumbai.polygonscan.com/tx/${txHash}`, '_blank');
    }

    return (
        <>
        {
            item
            ? (
                <div
                    className = { styles.card }
                    onClick = { () => {
                        if (type === 'view') {
                            if (checkIfResalePage()) {
                                history.push('/item/' + productDetail?.id + `?tx=${item.txHash}`);
                            } else {
                                history.push('/item/view/' + productDetail?.listedAssetId, {
                                    fromPrevious: true,
                                    fractions: productDetail?.fractionCount
                                });
                            }
                        } else {
                            history.push('/item/' + productDetail?.id);
                        }
                        // history.push(type === "view" ? checkIfResalePage() ? "/item/view/" + productDetail?.id : "/item/view/" + productDetail?.listedAssetId : "/item/" + productDetail?.id)
                    }}
                    //onClick={() => history.push(type !== "view" && "/item/" + item?.id)}
                    >

                    {/* we need to take the outer saletype Key */}
                    {
                        productDetail?.saleType === 'auction'
                        && (
                            <div className = { styles.indicator }>
                            {
                                !resalePage && type !== 'view'
                                ? (
                                    <>
                                    <Image
                                        className={styles.hammer}
                                        src='/images/hammer.png'
                                        srcDark='/images/hammer.png'
                                        alt='auction logo.jpg'
                                        />
                                    </>
                                ) : (
                                    <>
                                    <p> &nbsp;&nbsp; </p>
                                    {/* //just to leave an empty space for UI */}
                                    </>
                                )
                            }

                            {/*  !resalePage && type !== "view" this code means auction expired and its view from marketplace and not owned asset or resale asset */}
                            {
                                   item?.auctions
                                && hasAuctionEnded(item?.auctions[0]?.endingDate)
                                && item?.saleType === 'auction'
                                && !resalePage
                                && type !== 'view' ? (
                                    <p style={{ color: 'black', fontSize: 12 }}>
                                        <b>Auction Expired</b>
                                    </p>
                                ) : (
                                    <>
                                    {
                                           item?.auctions
                                        && checkForUpcomingBid(item?.auctions[0]?.startDate)
                                        && item?.saleType === 'auction'
                                        && !resalePage
                                        && type !== 'view'
                                        ? (
                                            <>
                                            <p style={{ color: 'black', fontSize: 12 }}>
                                                <b>Auction not yet started </b>
                                                {/* {moment(item?.auctions?.endingDate).utc().format("MM/DD/YYYY h:mm:ss UTC")} */}
                                            </p>
                                            </>
                                        ) : (
                                            !resalePage
                                            && type !== 'view'
                                            && item?.saleType === 'auction'
                                            && (
                                                <p style={{ color: 'black', fontSize: 12 }}>
                                                    <b>Auction on </b>
                                                    {
                                                        item?.auctions
                                                        && moment(item?.auctions[0]?.startDate).format('DD/MM/YYYY hh:mm A')
                                                    }
                                                </p>
                                            )
                                        )
                                    }
                                    </>
                                )
                            }
                            </div>
                        )
                    }

                    {
                           productDetail.saleType === 'fixed'
                        && !resalePage
                        && type !== 'view'
                        && (
                            <div className={styles.indicator}>
                                {/* //just to leave an empty space for UI */}
                                <p> &nbsp;&nbsp; </p>
                            </div>
                        )
                    }

                    {/*<div className={styles.preview}>*/}

                        {
                            item.mediaType === 'audio'
                            && (
                                <div
                                    className='player-wrapper'
                                    style={{ position: 'relative', paddingTop: 0 }}>
                                    <div>
                                        <img
                                            src={item?.audioThumbnail}
                                            style={{
                                                width: '100%',
                                                height: 180,
                                                // objectFit: "cover"
                                                objectFit: 'scale-down'
                                            }}
                                            alt='thumbnail.png'
                                            />
                                        <audio
                                            controls
                                            controlsList='nodownload'
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                height: 35,
                                                width: '100%'
                                            }}>
                                            <source src={item?.mediaPreviewUrl} type='audio/mpeg' />
                                        </audio>
                                    </div>
                                </div>
                            )
                        }

                        {
                            item.mediaType === 'video'
                            && (
                                <div
                                    className='player-wrapper'
                                    // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                                    style={{ position: 'relative', paddingTop: '56%' }}>
                                    <ReactPlayer
                                        url={item?.mediaPreviewUrl}
                                        width='100%'
                                        height='100%'
                                        controls={true}
                                        light={false}
                                        style={{ position: 'absolute', top: '0', left: '0' }}
                                        config = {{
                                            file: {
                                                attributes: {
                                                    disablePictureInPicture: true,
                                                    controlsList: 'nodownload'
                                                }
                                            }
                                        }}
                                        />
                                </div>
                            )
                        }

                        {
                            (
                                   item?.mediaType === 'image'
                                || item?.mediaType === '3D Model'
                            )
                            ? (

                                    <img
                                        className = { `item ${styles.thumbnailItem}` }
                                        src = { item?.thumbnailUrl || item?.mediaPreviewUrl }
                                        alt='thumbnail.png'
                                        />

                            ) : (
                                <div style={{aspectRatio: '1'}}></div>
                            )
                        }

                        {/* <div className={styles.control}> */}

                        {/*
                        <div
                            className = { cn(
                                  { "status-green": item.category === "green" }
                                , styles.category
                            )}
                            >
                            { item.categoryText }
                        </div>
                        */}

                        {/*
                        <button
                            className={cn(styles.favorite, { [styles.active]: visible })}
                            onClick={() => setVisible(!visible)}
                            >
                                <Icon name="heart" size="20" />
                        </button>
                        */}

                        {/*
                        <Link
                            className={styles.link}
                            to={
                                type === "view"
                                  ? "/item/view/" + item?.id
                                  : "/item/" + item?.id
                            }
                            >
                            <button className={cn("button-small", styles.button)}>
                                <span>{type === "view" ? "View" : "Place Order"}</span>
                                <Icon name="scatter-up" size="16" />
                            </button>
                        </Link>
                        */}

                        {/* </div> */}

                    {/*</div>*/}

                    <div
                        className={styles.itemInfor}
                        style = {{
                              padding: '15px'
                            , margin: '0px'
                        }}>

                        {/*
                        <div
                            className=''
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                        */}

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    columnGap: '5px',
                                    color: '#1d1d1d',
                                    marginBottom: '4px'
                                }}>
                                <div><img src='\images\icons\avatar-icon.png ' style={{ height: '26px' }} /></div>
                                <div style = {{ fontWeigh: 700 }}>{ item?.creatorName }</div>
                            </div>

                            {/* Like Button/Icon
                            <div>
                                <img src="/images/love.svg" />
                            </div>
                            */}

                        {/*
                        </div>
                        */}

                        <div className={ "watch-info-box"} style={{minHeight: '24px'}}>
                            <div>
                                <div className={ "watch-title" } >{ item?.name || 'Unknown' }</div>
                            </div>
                            {/*
                            <div>
                                <div className={ "watch-subtitle" }>Altiplano</div>
                            </div>
                            */}
                        </div>

                        <div className={'price-box'}
                            style = {{
                                  display: "flex"
                                , flexWrap: "wrap"
                                , justifyContent: "space-between"
                                , maxWidth: "220px"
                            }}
                            >

                            <div>
                                <div className = { 'table' }>
                                    <div className = { 'row' }>
                                        <div style = {{ whiteSpace: 'nowrap' }}>Price USD</div>
                                    </div>
                                    <div className = { 'row' }>
                                        <div className={`price price-dollars ${styles.price}}`}>
                                            US${displayPrice(item, exchangeRate)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className = { 'table' }>
                                    <div className = { 'row' }>
                                        <div style = {{ whiteSpace: 'nowrap' }}>Price Crypto</div>
                                    </div>
                                    <div className = { 'row' }>
                                        <div
                                            className={cn('price', 'price-crypto')}
                                            style = {{ whiteSpace: 'nowrap' }}
                                            >
                                            {(
                                                item?.regularPrice
                                            ).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)}{' '}
                                            MATIC
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    {/*
                    </div>

                    <div
                        className={styles.link}
                        //to={
                        //   type === "view" ? "/item/view/" + item?.id : "/item/" + item?.id
                        //}
                        >
                    */}

                        <div
                            //className = {styles.body}
                            style = {{ marginTop: "10px" }}
                            >

                            {
                                   type !== 'view'
                                && item.isFractional
                                //item.isFractional
                                && (
                                    <div className={styles.fractions}>
                                        <span>Fractions : {productDetail?.availableFractions}</span>
                                        {/*
                                        <p>
                                            {
                                                productDetail?.isResell === true
                                                ? '$' + productDetail?.resalePrice?.toFixed(4)
                                                : '$' + item.regularPrice?.toFixed(4)
                                            }
                                        </p>
                                        */}
                                    </div>
                                )
                            }

                            <div
                                //className={styles.line}
                                >

                                <div className={styles.users}>
                                    {
                                        item.users?.map((x, index) => (
                                            <div className={styles.avatar} key={index}>
                                                <img src={x.avatar} alt='Avatar' />
                                            </div>
                                        ))
                                    }
                                </div>

                                <div
                                    className={styles.counter}
                                    style = {
                                        type !== 'view'
                                        ? { fontSize: 12, minHeight: 42, color: 'black' }
                                        : { fontSize: 12, marginTop: '5px', marginBottom: '5px', color: 'black' }
                                    }>

                                    {
                                        type !== 'view'
                                        && (
                                              item?.shortDescription?.length > 60
                                            ? item?.shortDescription?.substring(0, 62) + '...'
                                            : item?.shortDescription
                                        )
                                    }

                                    {
                                        type === 'view'
                                        && (
                                            <p style={{minHeight: '42px'}}>
                                                Purchase Date :{' '}
                                                {
                                                    item?.updatedAt
                                                    ? moment(item?.updatedAt).format('DD/MM/YYYY hh:mm A')
                                                    : '-'
                                                }
                                                {/* {current_datetime(item?.updatedAt)} */}
                                            </p>
                                        )
                                    }
                                </div>
                            </div>

                            {
                                type === 'view'
                                && item.isFractional
                                && !resalePage && (
                                    <div className={styles.fractions}>
                                        {console.log(productDetail)}
                                        <span>
                                            Fractions Owned: <b>{productDetail?.fractionCount}</b>
                                        </span>
                                        <p>
                                            {' '}
                                            ${' '}
                                            {
                                                ownedAssetPage
                                                ? (productDetail?.listedAsset?.latestPrice * productDetail?.fractionCount)?.toFixed(4)
                                                : (item.regularPrice * productDetail?.fractionCount)?.toFixed(4)
                                            }
                                        </p>
                                    </div>
                                )
                            }

                            {
                                type === 'view'
                                && item.isFractional
                                && resalePage && (
                                    <div className={styles.fractions}>
                                        <span>
                                            Fractions: <b>{productDetail?.totalFractions}</b>
                                        </span>
                                        <p>
                                            {' '}
                                            ${' '}
                                            {
                                                formatPricingNumber(
                                                    productDetail.resalePrice * productDetail?.totalFractions
                                                )
                                            }
                                        </p>
                                    </div>
                                )
                            }

                            {/* {
                                showLabel
                                && (
                                    <div
                                        className={styles.line}
                                        style={{ marginTop: 18, flexWrap: 'wrap' }}
                                        ></div>
                                )
                            } */}
                        </div>

                        {
                            type === 'view'
                            //&& !resalePage
                            && item
                            && !item?.isOnSell
                            && (
                                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                    <button
                                        type='button'
                                        className={styles.resaleBtn}
                                        style={{ marginBottom: '10px' }}
                                        onClick={e => resaleHandleClick(e, item, productDetail)}>
                                        Resell Asset
                                    </button>
                                </div>
                            )
                        }

                        {
                            type === 'view'
                            //&& !resalePage
                            && item
                            //&& !item?.isOnSell
                            && (
                                <div style={{ textAlign: 'center' }}>
                                    <button
                                        type='button'
                                        className={styles.resaleBtn}
                                        style={{ marginBottom   : '10px' }}
                                        onClick={e => showBlockchainTransaction(item?.txHash)}>
                                        View Transaction
                                    </button>
                                </div>
                            )
                        }

                        {
                            type === 'view' && item?.isOnSell && (
                                <div style={{ textAlign: 'center' }}>
                                    <button
                                        type='button'
                                        className={styles.resaleBtn}
                                        onClick={e => history.push('/search01/all')}>
                                        Listed in marketplace{' '}
                                    </button>
                                </div>
                            )
                        }

                    </div>
                </div>
            ) : (
                <></>
            )
        }
        </>
    );
};

export default CardContainer;
