import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './style.scss';
import { axiosInstance } from '../../utils/API';
import { numberWithCommas } from '../../utils/formatPricingNumber';
import { countCart } from '../../redux/cartSlice';
import { CRYPTOCURRENCY_CONSTANTS, NAMES_CONSTANTS } from '../../constants';
import {
      PopUpAlert
    , PopUpWarning
    , PopUpConfirmation
} from '../../controller/utils'

const Cart = () => {

    const history = useHistory()
    const dispatch = useDispatch();
    //const count = useSelector(state => state.cart.countCart);
    //const exchangeRate = useSelector(state => state.counter.euroValue);
    //const [assets, setAssetList] = useState([]);
    let [assets, setAssetList] = useState([]);
    const [cartDetail, setCartDetail] = useState();

    const TimeAgo = ({ dateTime }) => {
        const timeAgo = moment(dateTime).fromNow();
        return <span>{timeAgo}</span>;
    };

    useEffect(() => {
        getListCart();
    }, []);

    const getConfig = () => {
        let config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        return config;
    };

    const getListCart = async () => {
        console.warn("Cart.getListCart()");

        const config = getConfig();
        const response = await axiosInstance.get('/carts?limit=100&page=1', config);
        console.warn("response", response);
        console.warn("response?.data", response?.data);

        if (response.data) {
            setAssetList(response.data?.result?.assets);
            setCartDetail(response.data?.result);
            //dispatch(countCart(response.data?.result?.totalItems)); // Total fractions
            dispatch(countCart(response.data?.result?.total)); // Total Assets in cart
        }
    };

    const clickQuickAmount = async function(event, item, amount) {
        console.log("clickQuickAmount(event, item, amount)", event, item, amount);

        console.log("    item = " + item);
        console.log("    item.index = " + item.index);
        console.log("    event = " + event);
        console.log("    amount = " + amount);
        console.log("    event.preventDefault = " + event.preventDefault);
        event.preventDefault();
        console.log("    event.stopPropagation = " + event.stopPropagation);
        event.stopPropagation();

        await handleUpdateCart(item, amount);
    };

    const handleUpdateCart = async function(item, amount) {
        //console.log("handleUpdateCart(item)", event, item, amount);
        //console.debug("handleUpdateCart(item, amount)", item, amount);
        console.debug('handleUpdateCart('+item.name+', '+amount+')');

        //console.log("    event = " + event);
        //console.log("    event.preventDefault = " + event.preventDefault);
        //event.preventDefault();
        //console.log("    event.stopPropagation = " + event.stopPropagation);
        //event.stopPropagation();

        //console.debug("    item = " + item);
        //console.debug("    item.index = " + item.index);
        console.debug("    item.id = " + item.id);
        //console.debug("    amount = " + amount);

        //item['assets.minimumInvestment']
        let minimumInvestment = item['assets.minimumInvestment'];
        console.debug("    minimumInvestment = " + minimumInvestment);
        let regularPrice = item['assets.regularPrice'];
        console.debug("    regularPrice = " + regularPrice);

        try {

            let amountNumber = parseInt(amount, 10);
            console.debug("    amountNumber = " + amountNumber);

            // Check for multiples of the price per split
            console.debug('    amountNumber % '+regularPrice+' = ' + (amountNumber  % regularPrice));
            
            let priceModulus = (amountNumber  % regularPrice);
            console.debug("    priceModulus = " + priceModulus);

            if (priceModulus != 0) {

                let amountInput = inputReferences[item.index].current;
                console.debug("    amountInput = " + amountInput);
                //amountInput.style.borderColor = 'red';
                amountInput.className = 'alert';
                //amountInput.focus();

                let amountHint = amountHintReferences[item.index].current;
                console.debug("    amountHint = " + amountHint);
                //amountHint.style.color = 'red';
                amountHint.className = 'alert';
                //amountHint.className += ' alert';
                //amountHint.classList.push('alert');

                //amountHint.innerText = 'test';
                //const textNode = document.createTextNode("Old Value");
                //amountHint.appendChild(textNode);
                amountHint.innerText = '(Multiples of ' + regularPrice + ')';
                return;

            } else {

                let amountInput = inputReferences[item.index].current;
                console.debug("    amountInput = " + amountInput);
                //amountInput.style.borderColor = 'unset';
                amountInput.className = '';
                //amountInput.focus();

                let amountHint = amountHintReferences[item.index].current;
                console.debug("    amountHint = " + amountHint);
                //amountHint.style.color = 'unset';
                amountHint.className = '';
                amountHint.innerText = '';
            }

            // Check for minimum investment
            
            if (
                (amountNumber < minimumInvestment)
                //|| (priceModulus != 0)
            ) {
                //PopUpWarning('Please enter an amount greater than the minimum investment amount.', null, 'error');
                //PopUpWarning(
                //    'Please enter an amount greater than the minimum investment amount.'
                //    , null
                //    , 'error'
                //).then( result => {
                //    console.log('result = ', result);
                //    let amountInput = inputReferences[item.index].current;
                //    console.log("    amountInput = " + amountInput);
                //    amountInput.focus();
                //});
                //let amountInput = inputReferences[item.index].current;
                //console.log("    amountInput = " + amountInput);
            
                //*    
                let amountInput = inputReferences[item.index].current;
                console.debug("    amountInput = " + amountInput);
                //amountInput.style.borderColor = 'red';
                amountInput.className = 'alert';
                //amountInput.focus();

                let amountHint = amountHintReferences[item.index].current;
                console.debug("    amountHint = " + amountHint);
                //amountHint.style.color = 'red';
                amountHint.className = 'alert';
                //amountHint.className += ' alert';
                //amountHint.classList.push('alert');

                //amountHint.innerText = '(Min.  ' + minimumInvestment + ' '+CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name+')';
                //amountHint.innerText = '(Min.  ' + numberWithCommas(minimumInvestment) + ' '+CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name+')';
                // Minimum Investment Amount: $2500 (25 splits)
                amountHint.innerText = `Minimum: ${ numberWithCommas(minimumInvestment) } ${ CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name }`;
                return;
                //*/
                //checkForMinimalAmount(amount); // Refactor this chunk of code?

            } else {

                let amountInput = inputReferences[item.index].current;
                console.debug("    amountInput = " + amountInput);
                //amountInput.style.borderColor = 'unset';
                amountInput.className = '';
                //amountInput.focus();

                let amountHint = amountHintReferences[item.index].current;
                console.debug("    amountHint = " + amountHint);
                //amountHint.style.color = 'unset';
                amountHint.className = '';
                //amountHint.classList.pop('alert');
                //amountHint.className -= ' alert';
                //amountHint.innerText = '';
                amountHint.innerText = `Minimum: ${ numberWithCommas(minimumInvestment) } ${ CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name }`;
            }


        } catch(error) {
            console.error("error = ", error)
        }

        const config = getConfig();

        await axiosInstance
            .post(
                //`cart/${item['id']}/${item['assets.id']}/${item.listedAssetId}`
                //`cart/${item.cartId}/${item['assets.id']}/${item.listedAssetId}`
                //`Carts/${item.cartId}/CartAssets/${item['id']}`
                `Carts/${item.cartId}/CartAssets/${item['id']}?amount=${amount}`
                , {
                    amount: amount
                }
                , config
            )
            .then(response => {
                //console.warndebug('        response = ', response);
                console.warn('handleUpdateCart('+item.name+', '+amount+'): response = ', response);
                console.debug('        response?.data = ', response?.data);
                console.debug('        response?.data?.success = ', response?.data?.success);
                if (response.data.success) {
                    getListCart();
                }

                //inputReferences[item.index].current.value = amount;
                //console.log(inputReferences[item.index].current.value);
            })
            //.catch(err => { });
            .catch(err => {
                console.error(err);
                console.warn('err = ', err);
                console.warn('err.response = ', err.response);
                console.warn('err.response?.data = ', err.response?.data);
                console.warn('err.response?.data?.message = ', err.response?.data?.message);
                //let respErr = err.response?.data?.message || 'Please switch to polygon main network'
                let respErr = err.response?.data?.message;
                if (!respErr) respErr = err.response.toString();
                //PopUpAlert('Alert', respErr, 'error')
                PopUpAlert(
                      respErr
                    , err.response?.data?.error
                    , 'error'
                )
            });

        return false;
    };

    //const handleDeleteCartItem = async item => {
    const handleDeleteCartItem = async function(event, item) {
        console.debug("handleDeleteCartItem(item)", event, item);
        console.debug("    item = " + item);
        console.debug("    event = " + event);
        console.debug("    event.preventDefault = " + event.preventDefault);
        event.preventDefault();
        console.debug("    event.stopPropagation = " + event.stopPropagation);
        event.stopPropagation();

        handleDeleteCart(item);

        return false;
    };

    const handleDeleteCart = async item => {
        //console.debug("handleDeleteCart(item)", item);
        console.debug("handleDeleteCart("+item.name+")");

        const config = getConfig();

        await axiosInstance
            .delete(
                //`carts/${item['assets.id']}/listedAssets/${item.listedAssetId}`
                `Carts/${item.cartId}/CartAssets/${item['id']}`
                , { isDeleteItem: true }
                , config
            )
            .then(response => {
                console.log("    response", response);
                console.log("    response?.data", response?.data);
                console.log("    response?.data?.success", response?.data?.success);
                if (response.data.success) {
                    getListCart();
                }
            })
            .catch(err => { });
    };

    /*
    const handleAddCartItem = async function(event, item) {
        console.log("handleAddCartItem(item)", event, item);
        console.log("handleAddCartItem(item): item = " + item);
        console.log("handleAddCartItem(item): event = " + event);
        console.log("handleAddCartItem(item): event.preventDefault = " + event.preventDefault);
        event.preventDefault();
        console.log("handleAddCartItem(item): event.stopPropagation = " + event.stopPropagation);
        event.stopPropagation();
        handleAddToCart(item);
        return false;
    };
    */

    const handleAddToCart = async assetInfo => {
        console.debug("handleAddToCart()", assetInfo);

        console.debug("assetInfo = ", assetInfo);
        console.debug("assetInfo.id = ", assetInfo.id);

        //if (!walletUserDetailsObject) {
        //    PopUpAlert('Alert', 'Please log in or connect your wallet to add items to your cart.', 'error');
        //    return;
        //}

        const config = getConfig();
        console.debug("config = ", config);

        //setAttemptAddToCard((attempt => attempt + 1))

        await axiosInstance
            //.post(`carts/${ assetInfo.id }/listedAssets/${ assetInfo.listedId }`, { quantity: 1 }, config)
            //.post(`carts/${ assetInfo.cartId }/listedAssets/${ assetInfo.listedAssetId }`, { quantity: 1 }, config)
            .post(`carts/${ assetInfo.assetId }/listedAssets/${ assetInfo.listedAssetId }`, { quantity: 1 }, config)
            .then(res => {
                if (res.data.success) {
                    /*
                    sendViewProductEvent({
                        addToCard: true,
                        initiateCheckout: true,
                        initiatePurchase: false
                    })
                    dispatch(countCart(res.data.result.totalItems));
                    */
                    history.push("/cart")
                    getListCart();
                }
            })
            .catch(error => {
                console.error(error);
                console.warn("error = ", error);
                console.warn("error?.message = ", error?.message);
                console.warn("error?.response = ", error?.response);
                console.warn("error?.response?.data = ", error?.response?.data);
                console.warn("error?.response?.data?.message = ", error?.response?.data?.message);

                let errorMessage = error?.response?.data.message;
                if (!errorMessage) {
                    errorMessage = error.err || error?.message
                        ? error?.message
                        : error?.data?.response?.message
                }
                //PopUpAlert('Alert', errorMessage, 'error');
            });
    };

    /*
    const calculateLineItemPrice = item => {
        console.log("calculateLineItemPrice(item)", item);
        return item.quantity * item['assets.regularPrice'];
    }
    */

    //const forceUpdate = React.useCallback(() => updateState({}), []);
    //const forceUpdate = React.useCallback(() => setAssetList(assets), [assets]);
    //const forceUpdate = React.useCallback(() => setAssetList(assets), []);


    const onBlurItemAmount = function(item, event) {
        console.debug("onBlurItemAmount(item, event)", item, event);

        let name = event.target.name;
        let value = event.target.value;
        console.debug("name = ", name);
        console.debug("value = ", value);

        //console.log("    event = " + event);
        //console.log("    amount = " + amount);
        //console.log("    event.preventDefault = " + event.preventDefault);
        //event.preventDefault();
        //console.log("    event.stopPropagation = " + event.stopPropagation);
        //event.stopPropagation();

        //await handleUpdateCart(item, value);
        handleUpdateCart(item, value);
    };

    
    const handleChangeSplits = function(item, event) {
        //console.log("handleChange(event)", event, item);
        console.debug("handleChangeSplits(event)", item, event);

        let currentQuantity = item.quantity;
        console.debug("currentQuantity = ", currentQuantity);

        let name = event.target.name;
        let value = event.target.value;
        console.debug("name = ", name);
        console.debug("value = ", value);

        /*
        console.log("inputReferences = ", inputReferences);
        console.log('inputReferences['+item.index+'].current = ', inputReferences[item.index].current);
        //item.price = value;
        //event.target.value = value;
        inputReferences[item.index].current.value = value;
        //console.log(inputReferences[item.index].current.value);
        */

        /*
        assets.forEach(function(asset) {
            console.log("asset.id = ", asset.id);
            console.log("item.id = ", item.id);
            if (asset.id == item.id) {
                asset.price = value;
            }
        });
        //forceUpdate();
        */

        //let itemAmount = asset.quantity = value / item['assets.regularPrice'];
        let itemAmount;

        ///*
        assets = assets.filter(function(asset) {
            console.debug("asset.id = ", asset.id);
            console.debug("item.id = ", item.id);
            if (asset.id == item.id) {
                asset.quantity = value;
                itemAmount = asset.quantity * item['assets.regularPrice'];
                console.debug("itemAmount = ", itemAmount);
                //itemAmount = asset.quantity * asset['assets.regularPrice'];
            }
            return asset;
        });

        setAssetList(assets);

        //getListCart();
        calculateOrderTotal();
        //*/

        //let itemAmount = asset.quantity = value / item['assets.regularPrice'];
        //await handleUpdateCart(item, itemAmount);
        handleUpdateCart(item, itemAmount);

          /*
          if (name === 'card_number') {
            const card = cardList?.find((each, i) => each.id === parseInt(value));
            // //
            setFields({ ...fields, email: card?.userEmail, [name]: value });
            setCardInfo(card);
          } else if (name === 'cvv') {
            value = cvvValidation(value);
            setFields({ ...fields, [name]: value });
          } else {
            setFields({ ...fields, [name]: value });
          }
          */
    };

    //const handleChangeAmount = e => {
    //const handleChangeAmount = function(event, item) {
    const handleChangeAmount = function(item, event) {
        //console.log("handleChangeAmount(event)", event, item);
        console.debug("handleChangeAmount(event)", item, event);

        let name = event.target.name;
        let value = event.target.value;
        console.debug("name = ", name);
        console.debug("value = ", value);

        /*
        console.log("inputReferences = ", inputReferences);
        console.log('inputReferences['+item.index+'].current = ', inputReferences[item.index].current);
        //item.price = value;
        //event.target.value = value;
        inputReferences[item.index].current.value = value;
        //console.log(inputReferences[item.index].current.value);
        */

        /*
        assets.forEach(function(asset) {
            console.log("asset.id = ", asset.id);
            console.log("item.id = ", item.id);
            if (asset.id == item.id) {
                asset.price = value;
            }
        });
        //forceUpdate();
        */
        ///*
        assets = assets.filter(function(asset) {
            console.debug("asset.id = ", asset.id);
            console.debug("item.id = ", item.id);
            if (asset.id == item.id) {
                //asset.price = value;
                asset.quantity = value / item['assets.regularPrice'];
            }
            return asset;
        });

        setAssetList(assets);

        //getListCart();
        calculateOrderTotal();
        //*/

        //await handleUpdateCart(item, amount);
        //handleUpdateCart(item, value);

          /*
          if (name === 'card_number') {
            const card = cardList?.find((each, i) => each.id === parseInt(value));
            // //
            setFields({ ...fields, email: card?.userEmail, [name]: value });
            setCardInfo(card);
          } else if (name === 'cvv') {
            value = cvvValidation(value);
            setFields({ ...fields, [name]: value });
          } else {
            setFields({ ...fields, [name]: value });
          }
          */
    };

    const calculateOrderTotal = () => {
        console.debug('Cart.calculateOrderTotal()');
        // use reduce() method to find the sum
        var sum = assets.reduce((accumulator, assetObject) => {
            return accumulator + (assetObject.quantity * assetObject['assets.regularPrice'])
        }, 0);
        console.debug('Cart.calculateOrderTotal(): sum = ', sum);
        cartDetail.totalAmount = sum;
    };

    const clickProceedToCheckout = () => {
        console.warn('clickProceedToCheckout()');

        // To do: add more validations
        if (
               (!assets || assets.length === 0) // check that there are some items in the cart
            || cartDetail.totalAmount === 0     // check that there is an amount greater than zero
        ) {

            PopUpWarning(
                //'Please input an investment amount greater than the minimum investment.'
                'Input your investment amount.'
                , null
                , 'error'
            ).then( result => {
                console.warn('result = ', result);
                let amountInput = inputReferences[0].current;
                console.warn("    amountInput = " + amountInput);
                if (amountInput)
                    amountInput.focus();
            });
            return;

        } else { // else check if the minimum investment amounts are met

            for (let i = 0; i < assets.length; i++) {
                console.warn('assets['+i+'] = ', assets[i]);
                console.warn('assets['+i+'].quantity = ', assets[i].quantity);
                console.warn('assets['+i+'].regularPrice = ', assets[i].regularPrice); // these currently do not work
                console.warn('assets['+i+'].minimumInvestment = ', assets[i].minimumInvestment); // these currently do not work
                console.warn('assets['+i+']["assets.regularPrice"] = ', assets[i]["assets.regularPrice"]);
                console.warn('assets['+i+']["assets.minimumInvestment"] = ', assets[i]["assets.minimumInvestment"]);

                let costPerFraction = assets[i]["assets.regularPrice"];
                console.warn('costPerFraction = ', costPerFraction);
                let minimumInvestmentAmount = assets[i]["assets.minimumInvestment"];
                console.warn('minimumInvestmentAmount = ', minimumInvestmentAmount);
                let investmentAmount = costPerFraction * assets[i].quantity;
                console.warn('investmentAmount = ', investmentAmount);

                if (investmentAmount < minimumInvestmentAmount) {

                    PopUpWarning(
                        //'Please input an investment amount greater than the minimum investment.'
                        'Please enter an investment amount greater than the minimum.'
                        , null
                        , 'error'
                    ).then( result => {
                        console.warn('result = ', result);
                        let amountInput = inputReferences[i].current;
                        console.warn("    amountInput = " + amountInput);
                        if (amountInput)
                            amountInput.focus();
                    });
                    return;
                }
            }
        }

        history.push("/checkout");
    };

    // Experimenting with handling the change of the investment amount using a dynamic array of references.
    let inputReferences = [];
    let itemsIndex = 0;
    let itemsIndex2 = 0;

    //const inputRef = useRef(null);
    inputReferences[0] = useRef(null);
    inputReferences[1] = useRef(null);

    let amountHintReferences = [];
    amountHintReferences[0] = useRef(null);

    return (

        <main className = 'edit-profile'>

            <section className = 'form'>

                <div className = 'max-width'>

                    <div className='cart-page'>

                        <div
                            className='container'
                            //className='flex-container'
                            >
                        {/*
                        */}

                            {/*
                            <div className='d-flex'>
                                <div className="back-btn btn">
                                    <img src="/images/icons/left-arrow-icon.svg" />
                                    <p>Back to Orders</p>
                                </div>
                            </div>
                            */}

                            <div
                                className = { "heading" }
                                style = {{
                                    display: 'flex'
                                    , flexDirection: 'row'
                                    , flexWrap: 'wrap'
                                    //, justifyContent: 'space-between'
                                    , justifyContent: 'flex-start'
                                }}>
                                <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                                    <h1 style = {{ display: 'inline-block' }}>
                                        Cart
                                    </h1>
                                </div>
                                <div style = {{ paddingLeft: '30px' }}>
                                    <img
                                        //src = '/images/icons/referrals-icon-28.jpg'
                                        src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                        style = {{
                                            //width: '50px'
                                            height: '60px'
                                            //, filter: 'var(--filter-theme-primary)'
                                            , filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                        }}
                                        />
                                </div>
                            </div>

                            <div
                                className = 'subtitle'
                                //style = {{
                                //    paddingTop: "30px"
                                //    , paddingBottom: "0px"
                                //}}
                                >
                                {
                                    assets.length > 0
                                    ? ( <span>Items in your Shopping Cart.</span> )
                                    : ( <span>There are no items in your Shopping Cart. Click <Link to='/search/all'>here</Link> to explore products.</span> )
                                }
                            </div>

                            <div
                                className='cart-main flex-container'
                                style = {{
                                    marginTop: '30px'
                                    , padding: '20px' // Overwrite the default padding for the cart flex-container
                                }}
                                >

                                <div className='left'>
                                    {/*
                                    <hr />
                                    <div className="item-type mobile">
                                      <b>NFT’s</b>
                                      <p>2 products added</p>
                                    </div>
                                    <div className="cart-item">
                                      <div className="item-img">
                                        <img src="/images/watch1.png" alt="" />
                                      </div>
                                      <div className="item-info">
                                        <div className="item-name">
                                          Sports Watch
                                        </div>
                                        <div className="item-price">
                                          0.08 MATIC
                                        </div>
                                        <div className="item-date">
                                          2 days ago
                                        </div>
                                      </div>
                                      <div className="delete-item-btn btn">
                                        <svg id="icon-close" viewBox="0 0 20 20" fill='#ccc' height="26px" width="26px">
                                          <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
                                        </svg>
                                      </div>
                                    </div>
                                    <div className="cart-item">
                                      <div className="item-img">
                                        <img src="/images/watch1.png" alt="" />
                                      </div>
                                      <div className="item-info">
                                        <div className="item-name">
                                          Sports Watch
                                        </div>
                                        <div className="item-price">
                                          0.08 MATIC
                                        </div>
                                        <div className="item-date">
                                          2 days ago
                                        </div>
                                      </div>
                                      <div className="delete-item-btn btn">
                                        <svg id="icon-close" viewBox="0 0 20 20" fill='#ccc' height="26px" width="26px">
                                          <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
                                        </svg>
                                      </div>
                                    </div>
                                    */}

                                    {/*
                                    <div className='item-type mobile'>
                                        <b>Watches</b>
                                        <p>{count} products added</p>
                                    </div>
                                    */}

                                    {
                                        assets.length > 0
                                        //&& assets?.map((item, index) => (
                                        && assets
                                            .sort((a, b) => a.id - b.id)
                                            .map(function(item, index) {

                                                item.index = itemsIndex++;

                                                {/* console.log(item); */}

                                                return (
                                                    <>
                                                    <div
                                                        key = { index }
                                                        className = 'cart-item'
                                                        //onClick={() => history.push('/item/' + item['assets.name'])}
                                                        //onClick={() => history.push('/item/' + item['assets.id'])}
                                                        //onClick = { () => history.push('/item/' + item['listedAssetId'])}
                                                        //style = {{ cursor: 'pointer' }}
                                                        style = {{ position: 'relative' }}
                                                        >

                                                        <div
                                                            className='item-info'
                                                            style = {{
                                                                padding: '10px'
                                                                , paddingRight: '0px'
                                                                , display: 'flex'
                                                                , flexDirection: 'row'
                                                            }}
                                                            >

                                                            <div
                                                                className = 'item-img'
                                                                style = {{
                                                                      backgroundImage: 'url('+item['assets.mediaPreviewUrl']+')'
                                                                    , backgroundRepeat: 'no-repeat'
                                                                    , backgroundPosition: 'center'
                                                                    , backgroundSize: 'cover'
                                                                    , width: '64px'
                                                                    , minWidth: '64px'
                                                                    , height: '64px'
                                                                }}
                                                                >
                                                                {/*
                                                                <img
                                                                    style = {{
                                                                        height: '100%'
                                                                        , width: 'auto'
                                                                        //, width: '100%'
                                                                        , minWidth: '62px'
                                                                    }}
                                                                    src = { item['assets.mediaPreviewUrl'] }
                                                                    alt=''
                                                                    />
                                                                */}
                                                            </div>

                                                            {/*
                                                            */}
                                                            <div
                                                                //className='item-info'
                                                                //style = {{ padding: '10px 0px 10px 20px' }}
                                                                style = {{
                                                                    paddingLeft: '20px'
                                                                    , margin: 'auto'
                                                                }}
                                                                >

                                                                <div
                                                                    className='item-name'
                                                                    >

                                                                    <Link to = { '/item/' + item['listedAssetId'] }>{ item['assets.name'] }</Link>

                                                                    {/*
                                                                    &nbsp;
                                                                    <span style = {{ fontSize: '.9em' }}>
                                                                    (min. {
                                                                        numberWithCommas(item['assets.regularPrice']?.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)) + ' ' + CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                                                    })
                                                                    </span>
                                                                    */}
                                                                </div>

                                                                {/*
                                                                    numberWithCommas(item['assets.regularPrice']?.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)) + ' ' + CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                                                */}

                                                                {/*
                                                                <div className='item-price'>
                                                                    
                                                                    <input
                                                                        type = "number"
                                                                        //value = { item.quantity }
                                                                        value = '0.00'
                                                                        editable = { 'true' }
                                                                        onClick = { (e) => e.stopPropagation() }
                                                                        style = {{
                                                                            width: '100px'
                                                                            , fontSize: '20px'
                                                                            , textAlign: 'right'
                                                                            //, backgroundColor: 'transparent'
                                                                            , border: 'var(--color-gray-200) 2px solid'
                                                                            , borderRadius: '3px'
                                                                            , padding: '3px 6px'
                                                                        }}></input>
                                                                </div>
                                                                */}

                                                                <div className='item-date'>
                                                                    <TimeAgo dateTime = { item.updatedAt } />
                                                                    {/*
                                                                    (Min. {
                                                                        numberWithCommas(item['assets.minimumInvestment']?.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)) + ' ' + CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                                                    })
                                                                    */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className = 'cart-item-center item-info'>

                                                            {/*
                                                            <div style = {{
                                                                backgroundColor: 'var(--color-gray-150)'
                                                                , borderRadius: '10px'
                                                                , padding: '5px 20px'
                                                            }}>
                                                                Splits: { item.quantity }
                                                            </div>
                                                            */}


                                                            <div
                                                                className='table'
                                                                //style = {{ paddingRight: '20px' }}
                                                                >
                                                                <div className='row'>

                                                                    <div
                                                                        className='cell'
                                                                        //style = {{ padding: '0px 10px' }}
                                                                        >
                                                                        <input
                                                                            ref = { inputReferences[item.index] }
                                                                            type = "number"
                                                                            //value = { item.quantity }
                                                                            //value = '0.00'
                                                                            //value = { item.quantity * item['assets.regularPrice'] }
                                                                            //value = { calculateLineItemPrice(item) }
                                                                            /*
                                                                            value = {
                                                                                item.price
                                                                                ? item.price
                                                                                : item.quantity * item['assets.regularPrice']
                                                                            }
                                                                            */
                                                                            value = {
                                                                                    item.quantity > 0
                                                                                    ? item.quantity
                                                                                    : ''
                                                                            }
                                                                            placeholder = '0'
                                                                            onChange = { handleChangeSplits.bind(this, item) }
                                                                            //onBlur = { onBlurItemAmount.bind(this, item) }
                                                                            editable = { 'true' }
                                                                            //onClick = { (e) => e.stopPropagation() }
                                                                            style = {{
                                                                                //width: '50px'
                                                                                /*
                                                                                , fontSize: '18px'
                                                                                , textAlign: 'right'
                                                                                //, backgroundColor: 'transparent'
                                                                                , border: 'var(--color-gray-200) 2px solid'
                                                                                , borderRadius: '3px'
                                                                                , padding: '3px 6px'
                                                                                , outline: 'none'
                                                                                */
                                                                            }}
                                                                            />
                                                                        &nbsp;Splits
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className='row'
                                                                    style = {{ position: 'relative' }}
                                                                    >
                                                                    <div
                                                                        //className='cell'
                                                                        className='cell item-date'
                                                                        //style = {{ padding: '0px 10px' }}
                                                                        style = {{
                                                                            //, position: 'absolute'
                                                                            position: 'relative'
                                                                            //, paddingLeft: '10px'
                                                                        }}
                                                                        //ref = { amountHintReferences[item.index] }
                                                                        >

                                                                        <div
                                                                            //className='cell item-date'
                                                                            ref = { amountHintReferences[item.index] }
                                                                            style = {{
                                                                                position: 'absolute'
                                                                                , whiteSpace: 'nowrap'
                                                                            }}
                                                                            >

                                                                            {/*
                                                                            (Min. {
                                                                                numberWithCommas(item['assets.minimumInvestment']?.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)) + ' ' + CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                                                            })
                                                                            */}
                                                                            1 Split = { item['assets.regularPrice'] } USD
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*
                                                        <div className="cart-item-right">
                                                        <div className='item-info'>
                                                        */}
                                                        <div className='cart-item-right item-info'>
                                                            {
                                                                item.quantity >= 0
                                                                && (
                                                                    <div className='table' style = {{ paddingRight: '20px' }}>

                                                                        {/*
                                                                        <div className='row'>
                                                                            <div
                                                                                //className='cell'
                                                                                className='cell item-date'
                                                                                style = {{ padding: '0px 10px' }}
                                                                                >
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                        */}

                                                                        <div className='row'>

                                                                            <div
                                                                                className='cell'
                                                                                //style = {{ padding: '0px 10px' }}
                                                                                >

                                                                                <div style = {{
                                                                                    width: '30px'
                                                                                    , display: 'inline-block'
                                                                                    , textAlign: 'right'
                                                                                }}>$&nbsp;</div>

                                                                                <input
                                                                                    ref = { inputReferences[item.index] }
                                                                                    type = "number"
                                                                                    //value = { item.quantity }
                                                                                    //value = '0.00'
                                                                                    //value = { item.quantity * item['assets.regularPrice'] }
                                                                                    //value = { calculateLineItemPrice(item) }
                                                                                    /*
                                                                                    value = {
                                                                                        item.price
                                                                                        ? item.price
                                                                                        : item.quantity * item['assets.regularPrice']
                                                                                    }
                                                                                    */
                                                                                    value = {
                                                                                          item.price
                                                                                        ? item.price
                                                                                        : item.quantity > 0
                                                                                            ? item.quantity * item['assets.regularPrice']
                                                                                            : ''
                                                                                    }
                                                                                    placeholder = '0.00'
                                                                                    //onChange = { handleChangeAmount.bind(this) }
                                                                                    onChange = { handleChangeAmount.bind(this, item) }
                                                                                    onBlur = { onBlurItemAmount.bind(this, item) }
                                                                                    editable = { 'true' }
                                                                                    onClick = { (e) => e.stopPropagation() }
                                                                                    /*
                                                                                    style = {{
                                                                                        width: '100px'
                                                                                        , fontSize: '18px'
                                                                                        , textAlign: 'right'
                                                                                        //, backgroundColor: 'transparent'
                                                                                        , border: 'var(--color-gray-200) 2px solid'
                                                                                        , borderRadius: '3px'
                                                                                        , padding: '3px 6px'
                                                                                        , outline: 'none'
                                                                                    }}
                                                                                    */
                                                                                    ></input>

                                                                                &nbsp;USD

                                                                                {/*
                                                                                <div
                                                                                    //className='cell item-date'
                                                                                    //ref = { amountHintReferences[item.index] }
                                                                                    style = {{
                                                                                        position: 'absolute'
                                                                                        , whiteSpace: 'nowrap'
                                                                                    }}
                                                                                    >
                                                                                    Minimum: $2,500
                                                                                </div>
                                                                                */}
                                                                            </div>

                                                                            {/*
                                                                            <div className='cell' style = {{ padding: '0px 10px' }}>
                                                                                <button
                                                                                    className = 'primary-inverse medium rounded'
                                                                                    style = {{ fontSize: '20px', fontWeight: 'bold', lineHeight: '20px', width: '40px', height: '40px' }}
                                                                                    onClick = { (e) => handleDeleteCartItem(e, item) }
                                                                                    > - </button>
                                                                            </div>
                                                                            <div className='cell' style = {{ padding: '0px 10px' }}>
                                                                                <button
                                                                                    className = 'primary-inverse medium rounded'
                                                                                    style = {{ fontSize: '20px', fontWeight: 'bold', lineHeight: '20px', width: '40px', height: '40px' }}
                                                                                    onClick = { (e) => handleAddCartItem(e, item) }
                                                                                    > + </button>
                                                                            </div>
                                                                            */}
                                                                        </div>

                                                                        <div
                                                                            className='row'
                                                                            style = {{ position: 'relative' }}
                                                                            >

                                                                            <div
                                                                                //className='cell'
                                                                                className='cell item-date'
                                                                                //style = {{ padding: '0px 10px' }}
                                                                                style = {{
                                                                                    //, position: 'absolute'
                                                                                    position: 'relative'
                                                                                    , paddingLeft: '24px'
                                                                                }}
                                                                                //ref = { amountHintReferences[item.index] }
                                                                                >

                                                                                <div
                                                                                    //className='cell item-date'
                                                                                    ref = { amountHintReferences[item.index] }
                                                                                    style = {{
                                                                                        position: 'absolute'
                                                                                        , whiteSpace: 'nowrap'
                                                                                    }}
                                                                                    >

                                                                                    {/*
                                                                                    (Min. {
                                                                                        numberWithCommas(item['assets.minimumInvestment']?.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)) + ' ' + CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                                                                    })
                                                                                    Minimum: $2,500
                                                                                    */}
                                                                                    Minimum: ${
                                                                                        numberWithCommas(item['assets.minimumInvestment']) + ' ' + CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }

                                                        </div>

                                                        <div
                                                            className='delete-item-btn btn'
                                                            style = {{
                                                                position: 'absolute'
                                                                , display: 'inline-block'
                                                                , top: '8px'
                                                                //, top: 'calc(50%-10px)'
                                                                , right: '0'
                                                                , paddingRight: '15px'
                                                            }}
                                                            >
                                                            <svg
                                                                //onClick={() => handleDeleteCart(item)}
                                                                //onClick = {(e) => handleDeleteCart(item)}
                                                                //onClick = { handleDeleteCartItem }
                                                                onClick = { (e) => handleDeleteCartItem(e, item) }
                                                                id='icon-close'
                                                                viewBox='0 0 20 20'
                                                                fill='#ccc'
                                                                height='26px'
                                                                width='26px'>
                                                                <path d='M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z'></path>
                                                            </svg>
                                                        </div>
                                                            
                                                    </div>

                                                    {/*
                                                    <div className="table">
                                                        <div className="row">
                                                            <div className="cell">
                                                                <button
                                                                    className = 'primary-inverse medium rounded'
                                                                    style = {{
                                                                        fontSize: '20px'
                                                                        , fontWeight: 'bold'
                                                                        , lineHeight: '20px'
                                                                        //, width: '40px'
                                                                        //, height: '40px'
                                                                    }}
                                                                    //onClick = { (e) => handleAddCartItem(e, item) }
                                                                    onClick = { (e) => clickQuickAmount(e, item, '0') }
                                                                    >$5,000</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    */}
                                                    <div
                                                        //className = 'flex-container quick-amounts'
                                                        className = 'quick-amounts'
                                                        style = {{
                                                            display: 'flex'
                                                            , flexWrap: 'wrap'
                                                            , flexDirection: 'row'
                                                            , justifyContent: 'space-evenly'
                                                            //, alignItems: 'center'
                                                            //, alignItems: 'flex-start'
                                                            , alignItems: 'top'
                                                            , fontSize: '14px'
                                                            , lineHeight: '14px'
                                                            //, margin: '0px'
                                                            , margin: '10px 0px'
                                                            , padding: '0px 10px'
                                                            , gap: '10px'
                                                        }}
                                                        >

                                                            <div
                                                                style = {{
                                                                      textAlign: 'center'
                                                                    //, margin: 'auto'
                                                                    , flex: '1 0 80px'
                                                                    , paddingTop: '6px'
                                                                    //, padding: '18px 0px'
                                                                    //, padding: '18px 0px 8px 0px'
                                                                }}>
                                                                <div
                                                                    className = 'title'
                                                                    //style = {{
                                                                    //    textAlign: 'center'
                                                                    //    , fontSize: '15px'
                                                                    //    , fontWeight: '700'
                                                                    //}}
                                                                    >
                                                                    Quick Amounts:
                                                                </div>
                                                            </div>

                                                            <div
                                                                style = {{
                                                                    margin: 'auto'
                                                                    , textAlign: 'center'
                                                                    , display: 'flex'
                                                                    , flexDirection: 'column'
                                                                }}>
                                                                <button
                                                                    className = 'primary-inverse small circular'
                                                                    onClick = { (e) => clickQuickAmount(e, item, '5000') }
                                                                    //style = {{
                                                                    //    position: 'relative'
                                                                    //    , margin: '0px'
                                                                    //    , fontSize: '13px'
                                                                        //, fontWeight: '400'
                                                                    //}}
                                                                    >
                                                                    <div>$5,000</div>
                                                                    {/*
                                                                    <small style = {{ position: 'absolute', display: 'inline-block', marginBottom: "5px" }}>({ 5000 / item['assets.regularPrice'] } Splits)</small>
                                                                    <div style = {{ position: 'absolute', display: 'inline-block', marginBottom: "5px" }}>({ 5000 / item['assets.regularPrice'] } Splits)</div>
                                                                    */}
                                                                </button>
                                                                <small>({ 5000 / item['assets.regularPrice'] } Splits)</small>
                                                            </div>

                                                            <div
                                                                style = {{
                                                                    margin: 'auto'
                                                                    , textAlign: 'center'
                                                                    , display: 'inline-block'
                                                                    , display: 'flex'
                                                                    , flexDirection: 'column'
                                                                }}>
                                                                <button
                                                                    className = 'primary-inverse small circular'
                                                                    onClick = { (e) => clickQuickAmount(e, item, '15000') }
                                                                    >
                                                                    <div>$15,000</div>
                                                                </button>
                                                                <small>({ 15000 / item['assets.regularPrice'] } Splits)</small>
                                                            </div>

                                                            <div
                                                                style = {{
                                                                    margin: 'auto'
                                                                    , textAlign: 'center'
                                                                    , display: 'inline-block'
                                                                    , display: 'flex'
                                                                    , flexDirection: 'column'
                                                                }}>
                                                                <button
                                                                    className = 'primary-inverse small circular'
                                                                    onClick = { (e) => clickQuickAmount(e, item, '25000') }
                                                                    >
                                                                    <div>$25,000</div>
                                                                </button>
                                                                <small>({ 25000 / item['assets.regularPrice'] } Splits)</small>
                                                            </div>

                                                            <div
                                                                style = {{
                                                                    margin: 'auto'
                                                                    , textAlign: 'center'
                                                                    , display: 'inline-block'
                                                                    , display: 'flex'
                                                                    , flexDirection: 'column'
                                                                }}>
                                                                <button
                                                                    className = 'primary-inverse small circular'
                                                                    onClick = { (e) => clickQuickAmount(e, item, '50000') }
                                                                    >
                                                                    <div>$50,000</div>
                                                                </button>
                                                                <small>({ 50000 / item['assets.regularPrice'] } Splits)</small>
                                                            </div>

                                                            <div
                                                                style = {{
                                                                    margin: 'auto'
                                                                    , textAlign: 'center'
                                                                    , display: 'inline-block'
                                                                    , display: 'flex'
                                                                    , flexDirection: 'column'
                                                                }}>
                                                                <button
                                                                    className = 'primary-inverse small circular'
                                                                    onClick = { (e) => clickQuickAmount(e, item, '100000') }
                                                                    >
                                                                    <div>$100,000</div>
                                                                </button>
                                                                <small>({ 100000 / item['assets.regularPrice'] } Splits)</small>
                                                            </div>

                                                    </div>
                                                    </>
                                                )
                                            }) // assets.map(function(item, index)
                                    }
                                </div>

                                <div className='right'>
                                    <div className='cart-info'>
                                        <div className='summary-text'>Summary</div>
                                        {/*
                                        <div className='cart-note'>
                                            Taxes will be calculated at checkout, where applicable.
                                        </div>
                                        */}

                                        {
                                            assets.length > 0
                                            //&& assets?.map((item, index) => (
                                            && assets
                                                .sort((a, b) => a.id - b.id)
                                                .map(function(item, index) {

                                                    item.index = itemsIndex2++;

                                                    {/* console.log(item); */}

                                                    return (
                                                        <>
                                                        <div
                                                            key = { index }
                                                            //className = 'cart-item'
                                                            className='order-total'
                                                            //onClick = { () => history.push('/item/' + item['listedAssetId'])}
                                                            style = {{ cursor: 'pointer' }}
                                                            >

                                                            <div
                                                                className='item-info'
                                                                style = {{
                                                                    width: '100%'
                                                                    //, border: 'green 2px solid'
                                                                }}
                                                                >

                                                                <div
                                                                    //className='item-name'
                                                                    className='order-total'
                                                                    >
                                                                    <div style = {{ display: 'inline-block' }}>
                                                                        { item['assets.name'] }
                                                                        &nbsp;
                                                                        <span style = {{ whiteSpace: 'nowrap' }}>X&nbsp;{ item.quantity }&nbsp;Split{ item.quantity > 1 ? 's' : '' }</span>
                                                                        &nbsp;&nbsp;
                                                                    </div>
                                                                    <span style = {{ whiteSpace: 'nowrap' }}>$&nbsp;
                                                                    {
                                                                        cartDetail?.totalAmount
                                                                        &&
                                                                            numberWithCommas(item.quantity * item['assets.regularPrice'])
                                                                    }
                                                                    </span>
                                                                </div>
                                                                {/*
                                                                <div className='item-name'>
                                                                    { item.quantity * item['assets.regularPrice'] }
                                                                </div>
                                                                */}

                                                            </div>
                                                                
                                                        </div>

                                                        </>
                                                    )
                                                }) // assets.map(function(item, index)
                                        }

                                        <div className='order-total'>
                                            <p>Order Total</p>
                                            <strong style = {{ whiteSpace: 'nowrap' }}>
                                            {
                                                cartDetail?.totalAmount
                                                &&
                                                    `${ CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name } $ ${ numberWithCommas(cartDetail.totalAmount) }`
                                            }
                                            </strong>
                                        </div>

                                        {/*
                                        <div className='order-total'>
                                            <p></p>
                                            <b>{
                                                numberWithCommas((cartDetail?.totalAmount || 0).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES))
                                            } {
                                                CRYPTOCURRENCY_CONSTANTS.DEFAULT_CRYPTOCURRENCY.name
                                            }</b>
                                        </div>
                                        */}

                                        {/*
                                        <Link
                                            to='/checkout' className='checkout-btn btn primary'>
                                            Proceed to Checkout
                                        </Link>
                                        */}
                                        <button
                                            type = 'button'
                                            //className = 'primary'
                                            className='theme-success large'
                                            //onClick = { () => history.push("/checkout") }
                                            onClick = { clickProceedToCheckout }
                                            style = {{
                                                //padding: "6px 12px"
                                                marginTop: "30px"
                                            }}
                                            disabled = { assets.length === 0 }
                                            >Proceed to Checkout</button>

                                        {/*
                                        <Link
                                            to='/checkout?receiver_type=send_gift'
                                            className='send-gift-btn btn'>
                                            <img src='/images/icons/gift_2.svg' alt='' />
                                            <p>Send as a gift</p>
                                        </Link>
                                        */}
                                    </div>
                                </div>
                            </div>
                        {/*
                        */}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Cart;
