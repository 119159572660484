import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
//import { CircularProgress } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Header.module.sass';
//import './MobileNav/style.scss';
import './style.scss';
import UserHeader from './UserHeader';
//import Categories from './Categories';
//import MobileNav from './MobileNav';
import { decrement } from '../../redux/counterSlice';
import { countCart } from '../../redux/cartSlice';
import {
      PopUpAlert
    , PopUpConfirmation
} from './../../controller/utils';
//import Notifications from './Notifications';
import NotificationsButton from './Notifications/NotificationsButton.js';
//import MobileUserHeader from './MobileUserHeader';
import { NAMES_CONSTANTS } from '../../constants';
import { setUser } from '../../redux/userSlice';
import { searchAssets, clearSearchAssets } from '../../redux/assetSlice';

import {
      base_url
    //, contract_auction_abi
    //, contract_erc20token_abi
    //, widgetMatic
    //, PopUpAlert
    //, connector
    //, Web3Provider
    //, getBalanceToken
    //, getProvider
} from "../../controller/utils";

// Dark header images
//const logoImageSrcDark = process.env.REACT_APP_IMAGES_LOGO_DARK;
let logoImageSrcDark = process.env.REACT_APP_IMAGES_LOGO_DARK;
const walletImageSrcDark = process.env.REACT_APP_IMAGES_WALLET_DARK;
//const hamburgerImageSrcDark = process.env.REACT_APP_IMAGES_HAMBURGER_DARK;
const hamburgerImageSrcDark = '/images/icons/Hamburger-menu-3-lines.svg';

// Light header images
let logoImageSrcLight = process.env.REACT_APP_IMAGES_LOGO;
const walletImageSrcLight = process.env.REACT_APP_IMAGES_WALLET;
//const hamburgerImageSrcLight = process.env.REACT_APP_IMAGES_HAMBURGER;
const hamburgerImageSrcLight = '/images/icons/Hamburger-menu-3-lines-dark.svg';


// Overrides for development
//logoImageSrcDark = '/images/logos/realsplit-logo-dark-277x69.jpg'
//logoImageSrcDark = '/images/logos/RealSplit-Logo-Specimen-55_390x99.png'
//logoImageSrcDark = '/images/logos/RealSplit-Logo-Specimen-56_384x99.png'
//logoImageSrcDark = '/images/logos/RealSplit-Logo-Red-Puzzle-Piece-Adjusted-Blue-Cut-Out_386x98.png'
//logoImageSrcDark = '/images/logos/RealSplit-Logo-Full-CRE-2.png'
//logoImageSrcDark = '/images/logos/RealSplit-Full-Logo-Arrow-Removed.png'
//logoImageSrcDark = '/images/logos/RealSplit-Full-Logo-Building-Reworked-Filled-Bottom_384x96.png'
logoImageSrcDark = '/images/logos/RealSplit-Full-Logo-Building-Reworked-Filled-Bottom_393x105.png'
//logoImageSrcDark = '/images/logos/RealSplit-Full-Logo-Building-Reworked-Filled-Bottom_399x115.png'

//logoImageSrcLight = '/images/logos/realsplit-logo-light-277x69.jpg'
//logoImageSrcLight = '/images/logos/RealSplit-Logo-Specimen-55_390x99.png'
//logoImageSrcLight = '/images/logos/RealSplit-Logo-Specimen-56-white-text_384x99.png'
logoImageSrcLight = '/images/logos/RealSplit-Full-Logo-Building-Reworked-Filled-Bottom-White-Font_393x105.png'


let handleScrollFunction; // External reference to the `handleScrollFunction` so it can be added and removed from the window listener in useEffect().

const Header = (props) => {
    console.info('Header()');
    console.debug('Header()', props);
    //console.warn('Header: props?.assets = ', props?.assets);
    //console.warn('Header: typeof props?.assets = ', typeof props?.assets);
    console.warn('Header: props.cartCount = ', props?.cartCount);
    //console.warn('Header: typeof props?.cartCount = ', typeof props?.cartCount);
    console.debug('Header: props.userDetails = ', props?.userDetails);
    console.debug('Header: props.userDetails.profilePicUrl = ', props?.userDetails?.profilePicUrl);
    console.debug('Header: props.toggleShowUserProfileMenu = ', props.toggleShowUserProfileMenu);

    // Assets in cart
    let [assets, setAssetList] = useState([]);
    const [cartDetail, setCartDetail] = useState();

    const userProfileMenuRef = useRef(null);
    const storeSearchText = useSelector(state => state.asset.searchText)
    const [searchText, setSearchText] = useState(storeSearchText || '');
    const dispatch = useDispatch();
    const history = useHistory();
    //const count = useSelector(state => state.cart.countCart)

    //const blockchainNetwork = localStorage.getItem('network');
    //const magicLink = localStorage.getItem('magicprovide');
    const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    console.debug('Header: userAccessToken = ', userAccessToken);
    //alert('Header: userAccessToken = ' + userAccessToken);

    const [className, setClassName] = useState(props.className ? props.className : 'light');
    //const [loading, setLoading] = useState(false);
    const [loaderStatus, setLoaderStatus] = useState(false);
    //const [isShowMobileNav, setIsShowMobileNav] = useState(false);
    //const [isShowMobileUserHeader, setIsShowMobileUserHeader] = useState(false);
    const [visibleNav, setVisibleNav] = useState(false);

    const status = useSelector((state) => state.counter.value);
    //const envValues = useSelector((state) => state?.keyVal.keyData);

    // Dynamic variables for the header images
    const [logoImageSrc, setLogoImageSrc] = React.useState(logoImageSrcLight);
    const [walletImageSrc, setWalletImageSrc] = React.useState(walletImageSrcLight);
    const [hamburgerImageSrc, setHamburgerImageSrc] = React.useState(hamburgerImageSrcLight);
    const [walletDetails, setWalletDetails] = useState(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));

    let walletDetailsObject;
    let profilePicUrl;

    if (walletDetails) {
        walletDetailsObject = JSON.parse(walletDetails);
        profilePicUrl = walletDetailsObject.profilePicUrl;
    }

    const getConfig = () => {
        /* Axios config
        let config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        return config;
        */
        return {
            method: 'GET'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            //, body: JSON.stringify(bodyObject)
        };
    };

    const handleScroll = (e) => {
        //console.info(`handleScroll($e)`)
        //console.log('handleScroll(e)', e)
        if (e.currentTarget) handleScrollY(e.currentTarget.scrollY);
    };

    const handleScrollY = (yPosition) => {
        //console.info(`handleScrollY($yPosition)`)
        //console.log('handleScrollY(yPosition)', yPosition)
        //console.log('handleScrollY('+yPosition+')');
        if (yPosition === 0) {
            if (props.isHomePage) {
                setIsDarkHeader();
            } else {
                setIsLightHeader();
            }
        } else if (yPosition > 0) {
            setIsLightHeader();
        }
    };

    const setIsDarkHeader = () => {
        setLogoImageSrc(logoImageSrcLight);
        setWalletImageSrc(walletImageSrcLight);
        setHamburgerImageSrc(hamburgerImageSrcLight);
        setClassName('dark');
    };

    const setIsLightHeader = () => {
        setLogoImageSrc(logoImageSrcDark);
        setWalletImageSrc(walletImageSrcDark);
        setHamburgerImageSrc(hamburgerImageSrcDark);
        setClassName('light');
    }

    const fetchListCartBak = async () => {
        console.warn("Header.fetchListCart()");

        /*
        const config = getConfig();
        const response = await axiosInstance.get('/carts?limit=100&page=1', config);
        console.warn("response", response);
        console.warn("response?.data", response?.data);
        if (response.data) {
            setAssetList(response.data?.result?.assets);
            setCartDetail(response.data?.result);
            //dispatch(countCart(response.data?.result?.totalItems)); // Total fractions
            //dispatch(countCart(response.data?.result?.total)); // Total Assets in cart
        }
        */

        let fetchConfig = getConfig();
        console.warn('    base_url = ', base_url);
        console.warn(`    base_url = ${base_url}`);
        //let fetchPath = '/carts?limit=100&page=1';
        let fetchPath = `${ base_url }carts?limit=100&page=1`;
        console.warn('    fetchPath = ', fetchPath);

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                console.warn('        fetchPromise.then(response)');
                console.warn('        ', response);
                console.warn('        response.ok = ' + response.ok);
                console.warn('        response.status = ' + response.status);
                console.warn('        response.statusText = ' + response.statusText);

                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //Swal.close();
                    //return response.json();
                    response.json().then(function(responseJson) {
                        console.warn('        fetch("'+fetchPath+'").then(responseJson)', responseJson);
                        console.warn('        ', responseJson);
                        console.warn('        responseJson = ', responseJson);
                        console.warn('        responseJson.result = ', responseJson.result);
                        console.warn('        responseJson.result?.assets = ', responseJson.result?.assets);
                        setAssetList(responseJson.result?.assets);
                        setCartDetail(responseJson.result);
                        //dispatch(countCart(responseJson.result?.totalItems)); // Total fractions
                        dispatch(countCart(responseJson.result?.total)); // Total Assets in cart
                    });

                } else { // E.g. 401 Unauthorized
                    response.json().then(function(responseJson) {
                        console.warn('fetch("'+fetchPath+'").then(responseJson)', responseJson);
                        console.warn(responseJson);
                        console.warn('responseJson = ', responseJson);
                        //console.warn('responseJson.result = ', responseJson.result);
                        //console.warn('responseJson.result?.assets = ', responseJson.result?.assets);
                        /*
                        setAssetList(responseJson.result?.assets);
                        setCartDetail(responseJson.result);
                        //dispatch(countCart(responseJson.result?.totalItems)); // Total fractions
                        dispatch(countCart(responseJson.result?.total)); // Total Assets in cart
                        */
                        //response.status
                        if (response.status == 401) {
                            setAssetList([]);
                            setCartDetail(null);
                            dispatch(countCart(0)); // set cart count to zero
                        }
                    });
                }
            })
            /*
            .then(function(responseJson) {
                console.warn('fetch("'+fetchPath+'").then(responseJson)', responseJson);
                console.warn(responseJson);
                console.warn('responseJson = ', responseJson);
                console.warn('responseJson.result = ', responseJson.result);
                console.warn('responseJson.result?.assets = ', responseJson.result?.assets);
                setAssetList(responseJson.result?.assets);
                setCartDetail(responseJson.result);
                //dispatch(countCart(responseJson.result?.totalItems)); // Total fractions
                dispatch(countCart(responseJson.result?.total)); // Total Assets in cart
            })
            //*/
            ;

    };

    /*
    useEffect(() => {
        console.warn('Header: useEffect(() => {');
        console.warn('userAccessToken = ', userAccessToken);
        if (userAccessToken) fetchListCart();
    }, []);
    */

    useEffect(() => {
        console.debug('Header: useEffect(() => {');
        console.debug('    props.isHomePage = ', props.isHomePage);
        //
        if (props.isHomePage) {
            window.addEventListener('scroll', handleScrollFunction = handleScroll.bind(null), false);
        } else {
            window.removeEventListener('scroll', handleScrollFunction, false);
            setIsLightHeader();
        }
        handleScrollY(0);
    }, []);

    /*
    const useQuery = () => {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
    };
    */

    /*
    const handleDisconnect = async (e) => {
        console.log('handleDisconnect(e)');

        e.preventDefault();
        dispatch(setUser({}))
        dispatch(decrement());

        localStorage.removeItem('magicprovide')
        localStorage.removeItem('tokenbalance');
        localStorage.removeItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
        localStorage.removeItem(NAMES_CONSTANTS.USER_WALLET_DETAILS);
        localStorage.removeItem('network');

        //PopUpAlert('Success', 'Wallet Disconnected', 'success');
        PopUpConfirmation('Success', 'Wallet Disconnected', 'success');

        setTimeout(() => {
            history.push('/');
            window.location.reload();
        }, 10);
    };
    */

    const handleTokenExpired = async (e) => {
      alert('handleTokenExpired(' + e + ')');

      if (e) e.preventDefault();
      localStorage.removeItem('tokenbalance');
      localStorage.removeItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
      localStorage.removeItem(NAMES_CONSTANTS.USER_WALLET_DETAILS);
      localStorage.removeItem('network');
      PopUpAlert('Success', 'Wallet Disconnected', 'success');
      history.push('/');
    };

    /*
    const toggleDrawerDisiplay = (e) => {
      setVisibleNav(!visibleNav);
    };
    */

    /*
    const logoutHandle = () => {
      console.log('logoutHandle()');

      dispatch(decrement());
      localStorage.removeItem('accessToken')
      localStorage.removeItem('magicprovide')
      localStorage.removeItem('tokenbalance');

      localStorage.removeItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
      localStorage.removeItem(NAMES_CONSTANTS.USER_WALLET_DETAILS);
      localStorage.removeItem('network');
      localStorage.removeItem('textureToken');
      history.push('/');
    }
    */

    /*
    const handleLogin = () => {

      if (!status) {
        document.getElementById('login_button').click();
        return
      }
      history.push('/edit-profile');
    }
    */

    /*
    const closeDrawer = () => {
      setVisibleNav(false);
    }

    const loginClose = () => {
    }
    */

    //const handleShowMobileNav = () => {
    //    console.log('handleShowMobileNav()');
    //    setIsShowMobileNav(true)
    //};

    //const handleShowMobileUserHeader = () => {
    //    setIsShowMobileUserHeader(true)
    //};

    //closeModal={() => setIsShowMobileNav(false)}
    const handleSearch = () => {
        if (searchText) {
            //closeModal();
            //setIsShowMobileNav(false);
            dispatch(searchAssets(searchText));
            history.push('/search/all');
        }
    };

    const handleOnKeySearch = (e) => {
        if (e.key === 'Enter') {
          console.error(e.key, 'key');
          handleSearch();
        }
    };

    //setIsShowMobileUserHeader();

    /*
    const clickBecomeInvestor = async function(event) {
        console.debug("clickBecomeInvestor()", event);
        console.debug("event.preventDefault = " + event.preventDefault);
        event.preventDefault();
        console.debug("event.stopPropagation = " + event.stopPropagation);
        event.stopPropagation();

        //props.chooseWalletType();
        //props.showLoginDialog();
        //props.showRegisterDialog();
        props.loginRegisterFunctions.showRegisterDialog();

        return false;
    };
    */

    const headerNav = () => (

        <nav
            //className = 'right'
            //style = {{
                //display: 'flex'
                //, flexDirection: 'row'
                //, flexWrap: 'wrap'
                //, alignItems: 'center'
                //, padding: '20px 0px'
                //, marginTop: '20px'
            //}}
            >

            <div
                className = 'table'
                //style = {{
                //      backgroundColor: 'rgba(0, 0, 0, 0.15)'
                    //, backgroundColor: 'rgba(255, 255, 255, 0.5)'
                //    , borderRadius: '20px'
                //    , padding: '3px 10px'
                //    , display: 'table'
                //        border: 'white 2px solid'
                //}}
                >

                <div
                    className = 'row'
                    //style = {{
                    //    display: 'table-row'
                    //    border: 'white 2px solid'
                    //}}
                    >

                    <div
                        className = 'cell'
                        //style = {{
                            //display: 'table-cell', padding: '0px 10px'
                        //}}
                        >
                        <Link
                            to='/about'
                            style = {{
                                cursor: 'pointer'
                                , whiteSpace: 'nowrap'
                                //, backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                //, backgroundColor: 'rgba(0, 0, 0, 0.15)'
                                //, borderRadius: '20px'
                                //, padding: '3px 10px'
                                //, display: 'table-cell'
                            }}>
                            About Us
                        </Link>
                    </div>

                    {/*
                    <div>|</div>
                    */}

                    <div
                        className = 'cell'
                        //style = {{ display: 'table-cell', padding: '0px 10px' }}
                        >
                        <Link
                            to='/search/all'
                            style = {{
                                cursor: 'pointer'
                                , whiteSpace: 'nowrap'
                                //, backgroundColor: 'rgba(255, 255, 255, 0.25)'
                                //, backgroundColor: 'rgba(0, 0, 0, 0.15)'
                                //, borderRadius: '20px'
                                //, padding: '3px 10px'
                                //, display: 'table-cell'
                            }}>
                            Our Portfolio
                        </Link>
                    </div>

            {/*                
            */}
            </div>

            </div>

            {/*
                userAccessToken === null
                &&
                    <div>
                        <Link
                            to = '/register'
                            style = {{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                            //onClick = { () => props.chooseWalletType() }
                            //onClick = { (e) => clickBecomeInvestor(e) }
                            //className = 'become-investor'
                            >
                            <button
                                //type = 'button'
                                //className = { 'connect-wallet' }
                                //className = { 'primary-inverse connect-wallet' }
                                className = 'become-investor'
                                //className = 'primary-inverse large become-investor'
                                //onClick={() => props.chooseWalletType()}
                                //onClick = { chooseWalletType }
                                onClick = { (e) => clickBecomeInvestor(e) }
                                >
                                Become an Investor
                            </button>
                        </Link>
                    </div>
            */}

            {/*
            <div
                style={{
                      margin: "auto 10px"
                    , marginRight: "0px"
                }}
                >
                <div>
                    <button
                        type='button'
                        className={'connect-wallet'}
                        onClick={() => props.chooseWalletType()}
                        >
                        Investor Login
                    </button>
                </div>
            </div>
            */}

        </nav>
    );

    const scrollToTop = () => {
        console.warn('Header.scrollToTop()');
        window.scrollTo({
              top: 0
            , behavior: 'smooth'
        });
    };

    return (

        <>
        {
            loaderStatus
            && (
                <div className={styles.LoaderBg}>
                    <CircularProgress style={{ color: '#ffffff' }} />
                </div>
            )
        }

        <header
            //className = { cn(className, styles.header) }
            className = { className }
            style = {{
                  position: 'fixed'
                , zIndex: 2
                , width: '100%'
                , margin: 'auto'
            }}>

            {/*
                The desktop header element only visible in desktop mode.
            */}
            <div
                //className = { styles.headerContainerDesktop }>
                className = { cn(
                      'header-container-desktop'
                    , styles.headerContainerDesktop
                    , 'max-width'

                )}
                //style = {{
                //      maxWidth: '1024px'
                //    , margin: 'auto'
                //}}
                >

                <div
                    //className = { styles.nav }
                    className = { 'logo-container' }
                    //style = {{
                    //      display: 'flex'
                    //    , flexDirection: 'row'
                    //    , alignItems: 'center'
                    //    , padding: '30px 0px'
                    //}}
                    >
                    <div
                        className = { "logo-image" }
                        style = {{ marginLeft: '0px' }}
                        //onClick = { (e) => scrollToTop() }
                        >
                        <Link
                            to='/' style={{ cursor: 'pointer' }}
                            onClick = { (e) => scrollToTop() }
                            >
                            <img
                                //onClick = { (e) => scrollToTop() }
                                src = { logoImageSrc }
                                style = {{
                                    verticalAlign: 'bottom'
                                    , maxHeight: '70px'
                                }}/>
                        </Link>
                    </div>
                </div>

                <div
                    //className = { styles.nav }
                    //className = { cn(styles.nav, "nav-content") }
                    //className = 'right'
                    className = 'header-content header-right'
                    style = {{
                          display: 'flex'
                        , flexDirection: 'row'
                        , alignItems: 'center'
                        //, padding: '20px 0px'
                    }}>

                    {/*
                    <div
                        className="input-search"
                        style={{ marginLeft: '0px' }}
                        >
                        <input type="text" placeholder="Search" onChange={(e) => setSearchText(e.target.value)} onKeyDown={(e) => handleOnKeySearch(e)} />
                        <div className="search-btn" onClick={handleSearch}>
                            <svg viewBox="0 0 20 20" width="20px" height="20px" fill=''>
                                <path d="M12.9 14.32c-1.34 1.049-3.050 1.682-4.908 1.682-4.418 0-8-3.582-8-8s3.582-8 8-8c4.418 0 8 3.582 8 8 0 1.858-0.633 3.567-1.695 4.925l0.013-0.018 5.35 5.33-1.42 1.42-5.33-5.34zM8 14c3.314 0 6-2.686 6-6s-2.686-6-6-6v0c-3.314 0-6 2.686-6 6s2.686 6 6 6v0z" />
                            </svg>
                        </div>
                    </div>
                    */}

                    {/*
                    <div
                        //className="input-search"
                        className = 'input-box input-search'
                        style = {{
                            //    margin: "10px"
                            //  margin: '0px 10px 10px 10px'
                            //, marginLeft: '0px'
                            //, margin: "10px"
                        }}
                        >
                        <input
                            type = 'text'
                            placeholder = 'Search'
                            onChange = {(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => handleOnKeySearch(e)}
                            //style = {{
                            //    width: 'calc(100% - 20px)'
                            //    , outline: 'none'
                            //}}
                            />
                        <div className="search-btn" onClick={handleSearch}>
                            <svg viewBox="0 0 20 20" width="20px" height="20px" fill=''>
                                <path d="M12.9 14.32c-1.34 1.049-3.050 1.682-4.908 1.682-4.418 0-8-3.582-8-8s3.582-8 8-8c4.418 0 8 3.582 8 8 0 1.858-0.633 3.567-1.695 4.925l0.013-0.018 5.35 5.33-1.42 1.42-5.33-5.34zM8 14c3.314 0 6-2.686 6-6s-2.686-6-6-6v0c-3.314 0-6 2.686-6 6s2.686 6 6 6v0z" />
                            </svg>
                        </div>
                    </div>
                    */}

                    { headerNav() }

                    {
                        //userAccessToken !== null // for development/testing
                        userAccessToken === null
                        ? 
                            <>

                            {/* headerNav() */}

                            <div>
                                {/*
                                <Link
                                    to = '/edit-profile'
                                    style = {{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                    >
                                */}
                                    <button
                                        className = 'become-investor'
                                        //onClick = { (e) => clickBecomeInvestor(e) }
                                        onClick = { (e) => props.loginRegisterFunctions.clickBecomeInvestor(e) }
                                        //onClick = { (e) => history.push('/edit-profile') }
                                        >
                                        {/*
                                        Become an Investor
                                        */}
                                        Early Registration
                                    </button>
                                {/*
                                </Link>
                                */}
                            </div>

                            <div
                                style={{
                                      margin: "auto 10px"
                                    , marginRight: "0px"
                                }}>
                                <div>
                                    <button
                                        //type = 'button'
                                        //className = { 'connect-wallet' }
                                        //className = { 'primary connect-wallet' }
                                        className = { 'theme-success connect-wallet' }
                                        //onClick = {() => props.chooseWalletType()}
                                        //onClick = {() => props.showLoginDialog()}
                                        onClick = {() => props.loginRegisterFunctions.showLoginDialog()}
                                        >
                                        {/*
                                        Investor Login
                                        */}
                                        Login
                                    </button>
                                </div>
                            </div>
                            </>

                        : <UserHeader
                            assets = { assets }
                            loginRegisterFunctions = { props.loginRegisterFunctions }
                            //handleDisconnectFunction = { handleDisconnect }
                            handleTokenExpiredFunction = { handleTokenExpired }
                            userDetails = { props.userDetails }
                            //userProfileMenuRef = { userProfileMenuRef }
                            userProfileMenuRef = { props.userProfileMenuRef }
                            userProfileButtonRef = { props.userProfileButtonRef }
                            toggleShowUserProfileMenu = { props.toggleShowUserProfileMenu }
                            toggleShowNotificationsPopup = { props.toggleShowNotificationsPopup }
                            handleShowNotificationsPopup = { props.handleShowNotificationsPopup }
                            notificationsButtonRef = { props.notificationsButtonRef }
                            onClickNotificationsButton = { props.onClickNotificationsButton }
                            cartCount = { props.cartCount }
                            />
                    }
                </div>
            </div>

            {/*
                This is the mobile header element only visible in mobile and tablet mode.
            */}
            <div
                //className = { styles.headerContainerMobile }
                className = { cn(
                    'header-container-mobile'
                    , styles.headerContainerMobile
                    , 'max-width'
                )}
                >

                <div
                    //className = { styles.nav }
                    className = { 'logo-container' }
                    //style = {{
                    //    flexBasis: '50%'
                    //    width: "100%"
                    //}}
                    >

                    <div
                        className = { 'logo-image' }
                        style = {{ marginLeft: '0px' }}
                        >
                        <Link
                            to = '/'
                            style = {{ cursor: 'pointer' }}
                            onClick = { (e) => scrollToTop() }
                            >
                            <img
                                src = { logoImageSrc }
                                style = {{
                                    verticalAlign: 'bottom'
                                    , maxHeight: '50px'
                                    , maxWidth: '200px'
                                    , minWidth: '150px'
                                    , width: '100%'
                                }}/>
                        </Link>
                    </div>

                </div>

                <div
                    //className = { styles.nav }
                    //className = { cn(styles.nav, "nav-content") }
                    //className = 'right'
                    className = 'header-content header-right'
                    >
                    <div
                        //className={styles.right}
                        className = 'mobile-nav'
                        //style = {{
                        //    display: 'flex'
                        //    , flexDirection: 'row'
                        //    //, flexWrap: 'wrap'
                        //}}
                        >

                        {
                            //count > 0
                            props.cartCount > 0
                            && (

                                <div
                                    className = 'mobile-nav-button'
                                    style = {{ position: "relative", cursor: "pointer" }}
                                    onClick={() => history.push("/cart")}>
                                    <img
                                        className = 'svg-icon'
                                        src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                        width = { "35px" }
                                        //height={"30px"}
                                        />
                                    <div
                                        style = {{
                                            position: "absolute"
                                            , top: 22, left: 22
                                            //width: '30px', height: '30px'
                                            , border: "1px solid red"
                                            , borderRadius: "50%"
                                            , display: "flex"
                                            , justifyContent: "center"
                                            , alignItems: "center"
                                            , background: "red"
                                            , cursor: "pointer"
                                            , minWidth: "15px"
                                            , minHeight: "15px"
                                        }}>

                                        <div
                                            style = {{
                                                fontSize: "15px"
                                                , lineHeight: "15px"
                                                , color: "#fff"
                                                //, padding: "0 10px"
                                                //, minWidth: "20px"
                                                //, minHeight: "20px"
                                                , verticalAlign: "middle"
                                                , textAlign: "center"
                                                , margin: "auto"
                                            }}
                                        >{ props.cartCount }</div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            //!userAccessToken // for development/testing to show the user dropdown menu when not logged in
                            userAccessToken
                            && (
                                <>
                                <div
                                    //className = { styles.navMobileBtn }
                                    //onClick = { handleShowMobileUserHeader }
                                    className = 'mobile-nav-button'
                                    //style = {{ padding: '0px 10px' }}
                                    >

                                    {/*
                                    <Notifications
                                        className = { styles.notification_button}
                                        //style = {{ padding: '0px 10px' }}
                                        />
                                    */}
                                    <NotificationsButton
                                        className = { styles.notification_button}
                                        //style = {{ padding: '0px 10px' }}
                                        toggleShowNotificationsPopup = { props.toggleShowNotificationsPopup }
                                        handleShowNotificationsPopup = { props.handleShowNotificationsPopup }
                                        notificationsButtonRef = { props.notificationsButtonRef }
                                        onClickNotificationsButton = { props.onClickNotificationsButton }
                                        />
                                    
                                </div>

                                <div
                                    //className = { styles.navMobileBtn }
                                    className = 'mobile-nav-button'
                                    onClick = { props.handleShowMobileUserHeader }
                                    //style = {{ padding: '0px 10px' }}
                                    >
                                    <img
                                        //style = {{
                                        //      borderRadius: "50%"
                                        //    , objectFit: "cover"
                                        //}}
                                        className = 'svg-icon'
                                        src = {
                                            //profilePicUrl
                                            props?.userDetails?.profilePicUrl
                                            || "/images/icons/iconmonstr-user-20.svg"
                                            //|| "/images/avatar-icon-large.png"
                                            //|| "/images/icons/iconmonstr-user-33.svg"
                                            //|| "/images/icons/iconmonstr-user-1.svg"
                                        }
                                        alt=""
                                        />
                                </div>

                                </>
                            )
                        }
                        
                        <div
                            //className = { styles.navMobileBtn }
                            className = 'mobile-nav-button'
                            //onClick = { handleShowMobileNav }
                            onClick = { props.handleShowMobileNav }
                            //style = {{ paddingRight: '30px' }}
                            //style = {{ padding: '0px 30px 0px 10px' }}
                            >
                            <img src = { hamburgerImageSrc } alt="" />
                        </div>
                
                    </div>

                    {/*
                        userAccessToken
                        && (
                            <MobileUserHeader
                                isOpen = { isShowMobileUserHeader }
                                closeModal = { () => setIsShowMobileUserHeader(false) }
                                //handleDisconnectFunction = { handleDisconnect }
                                loginRegisterFunctions = { props.loginRegisterFunctions }
                                handleTokenExpiredFunction = { handleTokenExpired }
                                walletDetails = { walletDetails }
                                userDetails = { props.userDetails }
                                //walletDetailsObject = { walletDetailsObject }
                                />
                        )
                    */}

                    {/*
                    <MobileNav
                        isOpen = { isShowMobileNav }
                        closeModal = { () => setIsShowMobileNav(false) }
                        chooseWalletType = { () => props.chooseWalletType() }
                        headerNav = { headerNav }
                        //handleDisconnectFunction = { handleDisconnect }
                        loginRegisterFunctions = { props.loginRegisterFunctions }
                        />
                    */}
                </div>
            </div>
        </header>
        </>
    );
};

export default Header;
