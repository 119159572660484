import Swal from 'sweetalert2';
import Web3 from 'web3';

import { axiosInstance } from '../utils/API';
import { decryptData } from '../utils/decrypt';
import { MetaKeep } from 'metakeep';
import Web3Modal from "web3modal";

// Web3 Wallet Connect
//import WalletConnectProvider from '@walletconnect/web3-provider';

import { switchNetWork } from '../utils/wallet';
import { NAMES_CONSTANTS } from '../constants';

let envChainId = decryptData(process.env.REACT_APP_METAMASK_NETWORK_CHAIN_ID);
//const envRpcUrl = envValues ? decryptData(envValues?.REACT_APP_METAMASK_NETWORK_RPC_URL) : '';
let envRpcUrl = decryptData(process.env.REACT_APP_METAMASK_NETWORK_RPC_URL);
const rpcUrlObj = {
    [ process.env.REACT_APP_METAMASK_NETWORK_CHAIN_ID ]: process.env.REACT_APP_METAMASK_NETWORK_RPC_URL
};

// export const connector = new WalletConnect({
//   bridge: 'https://bridge.walletconnect.org' // Required
// });

// mock wallet connect
export const connector = {connected: false, createSession: () => {}, killSession: () => {}};

export async function checkNetwork(chainId) {
    // const id = 80001
    if (chainId !== Number(process.env.REACT_APP_CHAIN)) {
      await switchNetWork(process.env.REACT_APP_CHAIN)
    }
    return true;
}

export const apiCall = async () => {
    let response = await axiosInstance.get('/user/getClientVariables');
    const res = response?.data?.result;
    envChainId = res ? decryptData(res?.REACT_APP_METAMASK_NETWORK_CHAIN_ID) : '';
    envRpcUrl = res ? decryptData(res?.REACT_APP_METAMASK_NETWORK_RPC_URL) : '';

    // const magicLinkKey = res ? decryptData(res?.REACT_APP_MAGIC_LINK_PUBLIC_KEY) : '';
    // let customNodeOptions = {
    //     rpcUrl: envRpcUrl,
    //     chainId: +envChainId,
    // }
    // magic = new Magic(magicLinkKey, {
    //     // testMode: true,
    //     extensions: [new OAuthExtension()],
    //     network: customNodeOptions,
    // });

    //Web3Provider = provider === 'metamask' ? new Web3(Web3.givenProvider) : new Web3(magic.rpcProvider);
    //Web3Provider = web3ProviderName === 'metamask'
    //               ? new Web3(Web3.givenProvider)
    //               : new Web3(magic.rpcProvider);
    
    //Web3Provider = new Web3(Web3.givenProvider);
    //
};

apiCall();

export const base_url = process.env.REACT_APP_API_URL;

// 1 MATIC = $0.633716 USD - June 28th 2023
export const MATIC_USD_EXCHANGE_RATE = 0.633716;

export const contract_erc20token_abi = [
    {
      inputs: [
        { internalType: 'string', name: 'name_', type: 'string' },
        { internalType: 'string', name: 'symbol_', type: 'string' },
        { internalType: 'uint256', name: 'totalSupply_', type: 'uint256' },
        { internalType: 'uint8', name: 'decimals_', type: 'uint8' }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'owner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'spender',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Approval',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address'
        }
      ],
      name: 'Paused',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32'
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32'
        }
      ],
      name: 'RoleAdminChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        }
      ],
      name: 'RoleGranted',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        }
      ],
      name: 'RoleRevoked',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'from', type: 'address' },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'Transfer',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address'
        }
      ],
      name: 'Unpaused',
      type: 'event'
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'MINTER_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'PAUSER_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'owner', type: 'address' },
        { internalType: 'address', name: 'spender', type: 'address' }
      ],
      name: 'allowance',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'approve',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
      name: 'burn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'burnFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'decimals',
      outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }
      ],
      name: 'decreaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
      name: 'getRoleAdmin',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'hasRole',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'spender', type: 'address' },
        { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
      ],
      name: 'increaseAllowance',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'pause',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'paused',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'sender', type: 'address' },
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' }
      ],
      name: 'transferFrom',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'unpause',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    }
];

export const contract_auction_abi = [
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'receiver', type: 'address' },
            { internalType: 'uint256', name: 'percentageValue', type: 'uint256' }
          ],
          internalType: 'struct EvokeMarketplace.Fee',
          name: '_platformFee',
          type: 'tuple'
        },
        { internalType: 'address', name: '_rootAdmin', type: 'address' },
        {
          internalType: 'address payable',
          name: '_escrowContractAddress',
          type: 'address'
        }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'auctioner',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'quantity',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'erc20Token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'heighestBid',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'heighestBidder',
          type: 'address'
        }
      ],
      name: 'AuctionCancelled',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'nftAddress',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'auctioner',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'basePrice',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'salePrice',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'erc20Token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'quantity',
          type: 'uint256'
        }
      ],
      name: 'AuctionCreated',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'auctioner',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'heighestBidder',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'erc20Token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'quantity',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'heighestBid',
          type: 'uint256'
        }
      ],
      name: 'AuctionSettled',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'tokenContract',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'auctioner',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'bidder',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'erc20Token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'quantity',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'price',
          type: 'uint256'
        }
      ],
      name: 'BidPlaced',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'from', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'FundReceived',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'receiver', type: 'address' },
            { internalType: 'uint256', name: 'percentageValue', type: 'uint256' }
          ],
          indexed: false,
          internalType: 'struct EvokeMarketplace.Fee',
          name: 'sellerProfit',
          type: 'tuple'
        },
        {
          components: [
            { internalType: 'address', name: 'receiver', type: 'address' },
            { internalType: 'uint256', name: 'percentageValue', type: 'uint256' }
          ],
          indexed: false,
          internalType: 'struct EvokeMarketplace.Fee',
          name: 'platformFee',
          type: 'tuple'
        }
      ],
      name: 'FundTransfer',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'nftAddress',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'seller',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'price',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'erc20Token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'quantity',
          type: 'uint256'
        }
      ],
      name: 'NftListed',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'nftAddress',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'seller',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'price',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'erc20Token',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'buyer',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'quantity',
          type: 'uint256'
        }
      ],
      name: 'NftSold',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'buyer',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'price',
          type: 'uint256'
        }
      ],
      name: 'RefundAuction',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32'
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32'
        }
      ],
      name: 'RoleAdminChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        }
      ],
      name: 'RoleGranted',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        }
      ],
      name: 'RoleRevoked',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256'
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'sellerOrAdmin',
          type: 'address'
        }
      ],
      name: 'SaleCanceled',
      type: 'event'
    },
    { stateMutability: 'payable', type: 'fallback' },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'address', name: 'receiver', type: 'address' },
            { internalType: 'uint256', name: 'percentageValue', type: 'uint256' }
          ],
          internalType: 'struct EvokeMarketplace.Fee',
          name: '_platformFee',
          type: 'tuple'
        }
      ],
      name: 'PlatformFee',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
            { internalType: 'uint256', name: '_quantity', type: 'uint256' },
            { internalType: 'address', name: '_nftAddress', type: 'address' },
            { internalType: 'address', name: '_sellerAddress', type: 'address' },
            { internalType: 'uint256', name: '_saleType', type: 'uint256' }
          ],
          internalType: 'struct EvokeMarketplace.CartItem[]',
          name: '_cartItems',
          type: 'tuple[]'
        }
      ],
      name: 'buyNft',
      outputs: [],
      stateMutability: 'payable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
        { internalType: 'address', name: '_nftAddress', type: 'address' },
        { internalType: 'address', name: '_auctioner', type: 'address' }
      ],
      name: 'cancelAuction',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
        { internalType: 'address', name: '_nftAddress', type: 'address' },
        { internalType: 'address', name: '_sellerAddress', type: 'address' }
      ],
      name: 'cancelListing',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
        { internalType: 'uint256', name: '_basePrice', type: 'uint256' },
        { internalType: 'uint256', name: '_salePrice', type: 'uint256' },
        { internalType: 'uint256', name: '_quantity', type: 'uint256' },
        { internalType: 'address', name: '_erc20Token', type: 'address' },
        { internalType: 'address', name: '_auctioner', type: 'address' },
        { internalType: 'address', name: '_nftAddress', type: 'address' },
        { internalType: 'string', name: '_ipfsHash', type: 'string' },
        { internalType: 'address', name: '_royaltyReciever', type: 'address' },
        { internalType: 'uint256', name: '_royaltyPercentage', type: 'uint256' },
        { internalType: 'bool', name: '_isResell', type: 'bool' }
      ],
      name: 'createAuction',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: '_buyer', type: 'address' },
        {
          components: [
            { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
            { internalType: 'uint256', name: '_quantity', type: 'uint256' },
            { internalType: 'address', name: '_nftAddress', type: 'address' },
            { internalType: 'address', name: '_sellerAddress', type: 'address' },
            { internalType: 'uint256', name: '_saleType', type: 'uint256' }
          ],
          internalType: 'struct EvokeMarketplace.CartItem[]',
          name: '_cartItems',
          type: 'tuple[]'
        }
      ],
      name: 'fiatPurchase',
      outputs: [],
      stateMutability: 'payable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        { internalType: 'address', name: '_nftAddress', type: 'address' },
        { internalType: 'address', name: '_auctioner', type: 'address' }
      ],
      name: 'getAuction',
      outputs: [
        {
          components: [
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
            { internalType: 'uint256', name: 'basePrice', type: 'uint256' },
            { internalType: 'uint256', name: 'salePrice', type: 'uint256' },
            { internalType: 'address', name: 'erc20Token', type: 'address' },
            { internalType: 'uint256', name: 'quantity', type: 'uint256' },
            { internalType: 'address', name: 'auctioner', type: 'address' },
            { internalType: 'address', name: 'currentBidder', type: 'address' },
            { internalType: 'uint256', name: 'bidAmount', type: 'uint256' },
            { internalType: 'bool', name: 'isResell', type: 'bool' }
          ],
          internalType: 'struct EvokeMarketplace.Auction',
          name: '',
          type: 'tuple'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
      name: 'getRoleAdmin',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'uint256', name: 'index', type: 'uint256' }
      ],
      name: 'getRoleMember',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
      name: 'getRoleMemberCount',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        { internalType: 'address', name: '_nftAddress', type: 'address' },
        { internalType: 'address', name: '_sellerAddress', type: 'address' }
      ],
      name: 'getSale',
      outputs: [
        {
          components: [
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
            { internalType: 'uint256', name: 'price', type: 'uint256' },
            { internalType: 'uint256', name: 'quantity', type: 'uint256' },
            { internalType: 'address', name: 'erc20Token', type: 'address' },
            { internalType: 'address', name: 'seller', type: 'address' },
            { internalType: 'bool', name: 'isResell', type: 'bool' }
          ],
          internalType: 'struct EvokeMarketplace.Sale',
          name: '',
          type: 'tuple'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'hasRole',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
        { internalType: 'uint256', name: '_price', type: 'uint256' },
        { internalType: 'uint256', name: '_quantity', type: 'uint256' },
        { internalType: 'address', name: '_erc20Token', type: 'address' },
        { internalType: 'address', name: '_seller', type: 'address' },
        { internalType: 'address', name: '_nftAddress', type: 'address' },
        { internalType: 'string', name: '_ipfsHash', type: 'string' },
        { internalType: 'address', name: '_royaltyReciever', type: 'address' },
        { internalType: 'uint256', name: '_royaltyPercentage', type: 'uint256' },
        { internalType: 'bool', name: '_isResell', type: 'bool' }
      ],
      name: 'listNft',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
        { internalType: 'uint256', name: '_price', type: 'uint256' },
        { internalType: 'address', name: '_nftAddress', type: 'address' },
        { internalType: 'address', name: '_auctioner', type: 'address' }
      ],
      name: 'placeBid',
      outputs: [],
      stateMutability: 'payable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address payable',
          name: '_escrowContractAddress',
          type: 'address'
        }
      ],
      name: 'setEscrowContractAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
        { internalType: 'address', name: '_nftAddress', type: 'address' },
        { internalType: 'address', name: '_auctioner', type: 'address' }
      ],
      name: 'settleAuction',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    { stateMutability: 'payable', type: 'receive' }
];

export const nft_contract_abi = [
    {
      inputs: [
        { internalType: 'string', name: 'name_', type: 'string' },
        { internalType: 'string', name: 'symbol_', type: 'string' },
        { internalType: 'string', name: 'baseTokenURI_', type: 'string' },
        { internalType: 'address', name: 'giftNftAddress_', type: 'address' }
      ],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address'
        },
        { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' }
      ],
      name: 'ApprovalForAll',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address'
        }
      ],
      name: 'Paused',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'previousAdminRole',
          type: 'bytes32'
        },
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'newAdminRole',
          type: 'bytes32'
        }
      ],
      name: 'RoleAdminChanged',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        }
      ],
      name: 'RoleGranted',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'sender',
          type: 'address'
        }
      ],
      name: 'RoleRevoked',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'uint256',
          name: 'tokenId',
          type: 'uint256'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'account',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'percentage',
          type: 'uint256'
        }
      ],
      name: 'RoyaltyAdded',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address'
        },
        { indexed: true, internalType: 'address', name: 'from', type: 'address' },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'ids',
          type: 'uint256[]'
        },
        {
          indexed: false,
          internalType: 'uint256[]',
          name: 'values',
          type: 'uint256[]'
        }
      ],
      name: 'TransferBatch',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'operator',
          type: 'address'
        },
        { indexed: true, internalType: 'address', name: 'from', type: 'address' },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'value',
          type: 'uint256'
        }
      ],
      name: 'TransferSingle',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'string', name: 'value', type: 'string' },
        { indexed: true, internalType: 'uint256', name: 'id', type: 'uint256' }
      ],
      name: 'URI',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address'
        }
      ],
      name: 'Unpaused',
      type: 'event'
    },
    {
      inputs: [],
      name: 'ADMIN_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'MINTER_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'PAUSER_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'URI_SETTER_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'addMinter',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'id', type: 'uint256' }
      ],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address[]', name: 'accounts', type: 'address[]' },
        { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }
      ],
      name: 'balanceOfBatch',
      outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'id', type: 'uint256' },
        { internalType: 'uint256', name: 'value', type: 'uint256' }
      ],
      name: 'burn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
        { internalType: 'uint256[]', name: 'values', type: 'uint256[]' }
      ],
      name: 'burnBatch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
      name: 'exists',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
      name: 'getRoleAdmin',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'giftContract',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'hasRole',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'address', name: 'operator', type: 'address' }
      ],
      name: 'isApprovedForAll',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'receiver', type: 'address' },
        { internalType: 'uint256', name: 'collectibleId', type: 'uint256' },
        { internalType: 'uint256', name: 'ntokens', type: 'uint256' },
        { internalType: 'string', name: 'IPFS_hash', type: 'string' },
        {
          components: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'percentage', type: 'uint256' }
          ],
          internalType: 'struct EvokeCert.Royalties',
          name: 'royalties',
          type: 'tuple'
        }
      ],
      name: 'mint',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'receiver', type: 'address' },
        { internalType: 'uint256[]', name: 'collectibleIds', type: 'uint256[]' },
        { internalType: 'uint256[]', name: 'ntokens', type: 'uint256[]' },
        { internalType: 'string', name: 'IPFS_hash', type: 'string' }
      ],
      name: 'mintBatch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'name',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'pause',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'paused',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' }
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
        { internalType: 'uint256', name: '_salePrice', type: 'uint256' }
      ],
      name: 'royaltyInfo',
      outputs: [
        { internalType: 'address', name: 'receiver', type: 'address' },
        { internalType: 'uint256', name: 'royaltyAmount', type: 'uint256' }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
        { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
        { internalType: 'bytes', name: 'data', type: 'bytes' }
      ],
      name: 'safeBatchTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'id', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'bytes', name: 'data', type: 'bytes' }
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        { internalType: 'address', name: 'operator', type: 'address' },
        { internalType: 'bool', name: 'approved', type: 'bool' }
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'symbol',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
      name: 'totalSupply',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'unpause',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'string', name: 'baseTokenURI', type: 'string' }],
      name: 'updateBaseURI',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'newGiftContractAddress',
          type: 'address'
        }
      ],
      name: 'updateGiftContractAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
      name: 'uri',
      outputs: [{ internalType: 'string', name: '', type: 'string' }],
      stateMutability: 'view',
      type: 'function'
    }
];

export function current_datetime(dateinput) {
    let date;
    let incomingDate;
    // date = new Date(dateinput);
    if (dateinput) {
      incomingDate = new Date(dateinput);
      date = new Date(incomingDate.toString());
    } else {
      return null;
    }
    const utcDate =
      date.getUTCDate() > 9 ? date.getUTCDate() : '0' + date.getUTCDate();
    const utcMonth =
      date.getUTCMonth() > 8
        ? date.getUTCMonth() + 1
        : '0' + (date.getUTCMonth() + 1);
    const utcFullYear = date.getUTCFullYear();
    // > 9 ? date.getUTCFullYear() : "0"+date.getUTCFullYear();
    const utcHours =
      date.getUTCHours() > 9 ? date.getUTCHours() : '0' + date.getUTCHours();
    const utcMins =
      date.getUTCMinutes() > 9
        ? date.getUTCMinutes()
        : '0' + date.getUTCMinutes();
    const utcSecs =
      date.getUTCSeconds() > 9
        ? date.getUTCSeconds()
        : '0' + date.getUTCSeconds();
    let formatted_date =
      utcDate +
      '/' +
      utcMonth +
      '/' +
      utcFullYear +
      ' ' +
      utcHours +
      ':' +
      utcMins +
      ':' +
      utcSecs +
      ' UTC';
    // var hours = date.getHours();
    // var minutes = date.getMinutes();
    // var ampm = hours >= 12 ? 'pm' : 'am';
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    // var strTime = hours + ':' + minutes + ' ' + ampm;
    // let formatted_date =  date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear() + " " + strTime ;
    return formatted_date;
}

let providerOptions = {
    /*
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: process.env.REACT_APP_INFURA_ID,
        rpc: rpcUrlObj
      }
    }
    */
};
const web3Modal = new Web3Modal({
    network: 'testnet', // optional
    cacheProvider: false, // optional
    providerOptions // required
});

export async function getProvider(network) {
    let provider;
    if(!network) {
      network = localStorage.getItem('network');
    }
    if(network === 'metakeep') {
      const userDetail = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));
      const metaKeepSdk = new MetaKeep({
        appId: process.env.REACT_APP_METAKEEP_API_ID,
        chainId: process.env.REACT_APP_CHAIN,
        user: {
          email: userDetail.email,
        },
        rpcNodeUrls: {
          80001: process.env.REACT_APP_METAMASK_NETWORK_RPC_URL
      }
      });
      provider = await metaKeepSdk.ethereum;
    }else {
      if (connector.connected) {
        provider = await web3Modal.connectTo('walletconnect');
      } else {
        provider = Web3.givenProvider;
      }
    }
  
    return provider;
}

export async function getBalanceToken() {
    console.log('getBalanceToken()')
    try {
        // const web3 = provider === 'metamask' ? new Web3(Web3.givenProvider) : new Web3(magic.rpcProvider);
        const web3Provider = await getProvider();
        console.log('web3Provider', web3Provider);

        const web3 = new Web3(web3Provider);
        console.log('web3', web3);

        const accounts = await web3.eth.getAccounts();
        console.log('accounts', accounts);

        // const erc20Contract = await new web3.eth.Contract(contract_erc20token_abi, process.env.REACT_APP_ERC20_ADDRESS_DEV);
        let balance = await web3.eth.getBalance(accounts[0]);
        console.log('balance', balance);

        balance = web3.utils.fromWei(balance, 'ether');
        console.log('balance', balance);

        localStorage.setItem('tokenbalance', balance);
        console.log('localStorage.getItem(\'tokenbalance\')', localStorage.getItem('tokenbalance'));

        let localStorageKeyName = NAMES_CONSTANTS.USER_WALLET_DETAILS;
        console.log('localStorageKeyName', localStorageKeyName);

        const detail = JSON.parse(localStorage.getItem(localStorageKeyName));
        localStorage.setItem(localStorageKeyName, JSON.stringify({ ...detail, balance }));
        console.log('localStorage.getItem(\''+localStorageKeyName+'\')', localStorage.getItem(localStorageKeyName));

    } catch (err) {
        return null;
    }
}

// export const widgetMatic = new Widget({
//     target: '',
//     appName: 'artenik',
//     autoShowTime: 0,
//     position: 'bottom-right',
//     height: 630,
//     width: 540,
//     overlay: false,
//     network: 'mainnet',
//     closable: true,
// });

// export const widgetMatic = new Widget({
//     target: '',
//     appName: "Metlantis",
//     position: "bottom-right",
//     network: "mainnet",
//     // style: {
//     //     color: 'blue'
//     // }
// });

export const PopUpAlert = (title, text, icon) => {
    return Swal.fire({
        title,
        text,
        icon,
        //confirmButtonColor: 'var(--color-theme-warning)',
        //confirmButtonColor: 'var(--color-theme-positive)',
        confirmButtonColor: 'var(--color-theme-primary)',
        iconColor: 'var(--color-theme-alert)',
        customClass: { confirmButton: 'primary large' }
    });
};

export const PopUpWarning = (title, text, icon) => {
    return Swal.fire({
        title,
        text,
        icon,
        //confirmButtonColor: 'var(--color-theme-warning)',
        //confirmButtonColor: 'var(--color-theme-positive)',
        confirmButtonColor: 'var(--color-theme-primary)',
        iconColor: 'var(--color-theme-warning)',
        customClass: { confirmButton: 'primary large' }
    });
};

export const PopUpWarningThen = (title, text, icon, callbackFunction) => {
    Swal.fire({
          title
        , text
        , icon
        //, confirmButtonColor: 'var(--color-theme-warning)'
        //, confirmButtonColor: 'var(--color-theme-positive)'
        , confirmButtonColor: 'var(--color-theme-primary)'
        , iconColor: 'var(--color-theme-warning)'
        , customClass: { confirmButton: 'primary large' }
        /*
        , showCloseButton: true
        , showConfirmButton: true
        , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
        , confirmButtonText: 'Connect'
        , buttonsStyling: false
        , customClass: {
              container: 'custom-container'
            , popup: 'custom-popup custom-popup-add-email'
            , confirmButton: "gold large stretch rounded"
        }
        , input: 'email'
        , inputLabel: 'Please add your email address'
        //, inputValue: email
        , inputValue: 'service.account@wearefuturetech.com'
        , inputPlaceholder: 'Enter your email address'
        */

    /*
    }).then((result) => {
        if (result.value) {
            //alert("Result: " + result.value);
        }
    });
    */
    }).then(callbackFunction);
};

export const PopUpConfirmation = (title, text, icon) => {
    return Swal.fire({
        title,
        text,
        icon,
        confirmButtonColor: 'var(--color-theme-primary)',
        //confirmButtonColor: 'var(--color-theme-positive)',
        iconColor: 'var(--color-theme-positive)',
        customClass: { confirmButton: 'primary large' }
    });
};

export const getCountryName = (countryCode) => {
    console.warn('getCountryName('+ countryCode +')');
    //const countryObject = country.filter((countryCode) => word.length > 6);
    const countryObject = country.find((element) => element.code == countryCode);
    console.warn('countryObject = ', countryObject);
    return countryObject.name;
};

export const country = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'Andorra', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
    { name: 'Australia', code: 'AU' },
    { name: 'Austria', code: 'AT' },
    { name: 'Azerbaijan', code: 'AZ' },
    { name: 'Bahamas', code: 'BS' },
    { name: 'Bahrain', code: 'BH' },
    { name: 'Bangladesh', code: 'BD' },
    { name: 'Barbados', code: 'BB' },
    { name: 'Belarus', code: 'BY' },
    { name: 'Belgium', code: 'BE' },
    { name: 'Belize', code: 'BZ' },
    { name: 'Benin', code: 'BJ' },
    { name: 'Bermuda', code: 'BM' },
    { name: 'Bhutan', code: 'BT' },
    { name: 'Bolivia', code: 'BO' },
    { name: 'Bosnia and Herzegovina', code: 'BA' },
    { name: 'Botswana', code: 'BW' },
    { name: 'Bouvet Island', code: 'BV' },
    { name: 'Brazil', code: 'BR' },
    { name: 'British Indian Ocean Territory', code: 'IO' },
    { name: 'Brunei Darussalam', code: 'BN' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Burkina Faso', code: 'BF' },
    { name: 'Burundi', code: 'BI' },
    { name: 'Cambodia', code: 'KH' },
    { name: 'Cameroon', code: 'CM' },
    { name: 'Canada', code: 'CA' },
    { name: 'Cape Verde', code: 'CV' },
    { name: 'Cayman Islands', code: 'KY' },
    { name: 'Central African Republic', code: 'CF' },
    { name: 'Chad', code: 'TD' },
    { name: 'Chile', code: 'CL' },
    { name: 'China', code: 'CN' },
    { name: 'Christmas Island', code: 'CX' },
    { name: 'Cocos (Keeling) Islands', code: 'CC' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Comoros', code: 'KM' },
    { name: 'Congo', code: 'CG' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD' },
    { name: 'Cook Islands', code: 'CK' },
    { name: 'Costa Rica', code: 'CR' },
    { name: "Cote D'Ivoire", code: 'CI' },
    { name: 'Croatia', code: 'HR' },
    { name: 'Cuba', code: 'CU' },
    { name: 'Cyprus', code: 'CY' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Djibouti', code: 'DJ' },
    { name: 'Dominica', code: 'DM' },
    { name: 'Dominican Republic', code: 'DO' },
    { name: 'Ecuador', code: 'EC' },
    { name: 'Egypt', code: 'EG' },
    { name: 'El Salvador', code: 'SV' },
    { name: 'Equatorial Guinea', code: 'GQ' },
    { name: 'Eritrea', code: 'ER' },
    { name: 'Estonia', code: 'EE' },
    { name: 'Ethiopia', code: 'ET' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK' },
    { name: 'Faroe Islands', code: 'FO' },
    { name: 'Fiji', code: 'FJ' },
    { name: 'Finland', code: 'FI' },
    { name: 'France', code: 'FR' },
    { name: 'French Guiana', code: 'GF' },
    { name: 'French Polynesia', code: 'PF' },
    { name: 'French Southern Territories', code: 'TF' },
    { name: 'Gabon', code: 'GA' },
    { name: 'Gambia', code: 'GM' },
    { name: 'Georgia', code: 'GE' },
    { name: 'Germany', code: 'DE' },
    { name: 'Ghana', code: 'GH' },
    { name: 'Gibraltar', code: 'GI' },
    { name: 'Greece', code: 'GR' },
    { name: 'Greenland', code: 'GL' },
    { name: 'Grenada', code: 'GD' },
    { name: 'Guadeloupe', code: 'GP' },
    { name: 'Guam', code: 'GU' },
    { name: 'Guatemala', code: 'GT' },
    { name: 'Guernsey', code: 'GG' },
    { name: 'Guinea', code: 'GN' },
    { name: 'Guinea-Bissau', code: 'GW' },
    { name: 'Guyana', code: 'GY' },
    { name: 'Haiti', code: 'HT' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
    { name: 'Holy See (Vatican City State)', code: 'VA' },
    { name: 'Honduras', code: 'HN' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'Hungary', code: 'HU' },
    { name: 'Iceland', code: 'IS' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Iran, Islamic Republic Of', code: 'IR' },
    { name: 'Iraq', code: 'IQ' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Isle of Man', code: 'IM' },
    { name: 'Israel', code: 'IL' },
    { name: 'Italy', code: 'IT' },
    { name: 'Jamaica', code: 'JM' },
    { name: 'Japan', code: 'JP' },
    { name: 'Jersey', code: 'JE' },
    { name: 'Jordan', code: 'JO' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Kiribati', code: 'KI' },
    { name: "Korea, Democratic People'S Republic of", code: 'KP' },
    { name: 'Korea, Republic of', code: 'KR' },
    { name: 'Kuwait', code: 'KW' },
    { name: 'Kyrgyzstan', code: 'KG' },
    { name: "Lao People'S Democratic Republic", code: 'LA' },
    { name: 'Latvia', code: 'LV' },
    { name: 'Lebanon', code: 'LB' },
    { name: 'Lesotho', code: 'LS' },
    { name: 'Liberia', code: 'LR' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Lithuania', code: 'LT' },
    { name: 'Luxembourg', code: 'LU' },
    { name: 'Macao', code: 'MO' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
    { name: 'Madagascar', code: 'MG' },
    { name: 'Malawi', code: 'MW' },
    { name: 'Malaysia', code: 'MY' },
    { name: 'Maldives', code: 'MV' },
    { name: 'Mali', code: 'ML' },
    { name: 'Malta', code: 'MT' },
    { name: 'Marshall Islands', code: 'MH' },
    { name: 'Martinique', code: 'MQ' },
    { name: 'Mauritania', code: 'MR' },
    { name: 'Mauritius', code: 'MU' },
    { name: 'Mayotte', code: 'YT' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Micronesia, Federated States of', code: 'FM' },
    { name: 'Moldova, Republic of', code: 'MD' },
    { name: 'Monaco', code: 'MC' },
    { name: 'Mongolia', code: 'MN' },
    { name: 'Montserrat', code: 'MS' },
    { name: 'Morocco', code: 'MA' },
    { name: 'Mozambique', code: 'MZ' },
    { name: 'Myanmar', code: 'MM' },
    { name: 'Namibia', code: 'NA' },
    { name: 'Nauru', code: 'NR' },
    { name: 'Nepal', code: 'NP' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'Netherlands Antilles', code: 'AN' },
    { name: 'New Caledonia', code: 'NC' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nicaragua', code: 'NI' },
    { name: 'Niger', code: 'NE' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Niue', code: 'NU' },
    { name: 'Norfolk Island', code: 'NF' },
    { name: 'Northern Mariana Islands', code: 'MP' },
    { name: 'Norway', code: 'NO' },
    { name: 'Oman', code: 'OM' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Palau', code: 'PW' },
    { name: 'Palestinian Territory, Occupied', code: 'PS' },
    { name: 'Panama', code: 'PA' },
    { name: 'Papua New Guinea', code: 'PG' },
    { name: 'Paraguay', code: 'PY' },
    { name: 'Peru', code: 'PE' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Pitcairn', code: 'PN' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Puerto Rico', code: 'PR' },
    { name: 'Qatar', code: 'QA' },
    { name: 'Reunion', code: 'RE' },
    { name: 'Romania', code: 'RO' },
    { name: 'Russian Federation', code: 'RU' },
    { name: 'RWANDA', code: 'RW' },
    { name: 'Saint Helena', code: 'SH' },
    { name: 'Saint Kitts and Nevis', code: 'KN' },
    { name: 'Saint Lucia', code: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC' },
    { name: 'Samoa', code: 'WS' },
    { name: 'San Marino', code: 'SM' },
    { name: 'Sao Tome and Principe', code: 'ST' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Senegal', code: 'SN' },
    { name: 'Serbia and Montenegro', code: 'CS' },
    { name: 'Seychelles', code: 'SC' },
    { name: 'Sierra Leone', code: 'SL' },
    { name: 'Singapore', code: 'SG' },
    { name: 'Slovakia', code: 'SK' },
    { name: 'Slovenia', code: 'SI' },
    { name: 'Solomon Islands', code: 'SB' },
    { name: 'Somalia', code: 'SO' },
    { name: 'South Africa', code: 'ZA' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sri Lanka', code: 'LK' },
    { name: 'Sudan', code: 'SD' },
    { name: 'Suriname', code: 'SR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ' },
    { name: 'Swaziland', code: 'SZ' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Syrian Arab Republic', code: 'SY' },
    { name: 'Taiwan, Province of China', code: 'TW' },
    { name: 'Tajikistan', code: 'TJ' },
    { name: 'Tanzania, United Republic of', code: 'TZ' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Timor-Leste', code: 'TL' },
    { name: 'Togo', code: 'TG' },
    { name: 'Tokelau', code: 'TK' },
    { name: 'Tonga', code: 'TO' },
    { name: 'Trinidad and Tobago', code: 'TT' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Turkmenistan', code: 'TM' },
    { name: 'Turks and Caicos Islands', code: 'TC' },
    { name: 'Tuvalu', code: 'TV' },
    { name: 'Uganda', code: 'UG' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Arab Emirates', code: 'AE' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'United States Minor Outlying Islands', code: 'UM' },
    { name: 'Uruguay', code: 'UY' },
    { name: 'Uzbekistan', code: 'UZ' },
    { name: 'Vanuatu', code: 'VU' },
    { name: 'Venezuela', code: 'VE' },
    { name: 'Viet Nam', code: 'VN' },
    { name: 'Virgin Islands, British', code: 'VG' },
    { name: 'Virgin Islands, U.S.', code: 'VI' },
    { name: 'Wallis and Futuna', code: 'WF' },
    { name: 'Western Sahara', code: 'EH' },
    { name: 'Yemen', code: 'YE' },
    { name: 'Zambia', code: 'ZM' },
    { name: 'Zimbabwe', code: 'ZW' }
];

export const UsStateList = [
    {
      name: 'Alabama',
      code: 'AL'
    },
    {
      name: 'Alaska',
      code: 'AK'
    },
    {
      name: 'American Samoa',
      code: 'AS'
    },
    {
      name: 'Arizona',
      code: 'AZ'
    },
    {
      name: 'Arkansas',
      code: 'AR'
    },
    {
      name: 'California',
      code: 'CA'
    },
    {
      name: 'Colorado',
      code: 'CO'
    },
    {
      name: 'Connecticut',
      code: 'CT'
    },
    {
      name: 'Delaware',
      code: 'DE'
    },
    {
      name: 'District Of Columbia',
      code: 'DC'
    },
    {
      name: 'Federated States Of Micronesia',
      code: 'FM'
    },
    {
      name: 'Florida',
      code: 'FL'
    },
    {
      name: 'Georgia',
      code: 'GA'
    },
    {
      name: 'Guam',
      code: 'GU'
    },
    {
      name: 'Hawaii',
      code: 'HI'
    },
    {
      name: 'Idaho',
      code: 'ID'
    },
    {
      name: 'Illinois',
      code: 'IL'
    },
    {
      name: 'Indiana',
      code: 'IN'
    },
    {
      name: 'Iowa',
      code: 'IA'
    },
    {
      name: 'Kansas',
      code: 'KS'
    },
    {
      name: 'Kentucky',
      code: 'KY'
    },
    {
      name: 'Louisiana',
      code: 'LA'
    },
    {
      name: 'Maine',
      code: 'ME'
    },
    {
      name: 'Marshall Islands',
      code: 'MH'
    },
    {
      name: 'Maryland',
      code: 'MD'
    },
    {
      name: 'Massachusetts',
      code: 'MA'
    },
    {
      name: 'Michigan',
      code: 'MI'
    },
    {
      name: 'Minnesota',
      code: 'MN'
    },
    {
      name: 'Mississippi',
      code: 'MS'
    },
    {
      name: 'Missouri',
      code: 'MO'
    },
    {
      name: 'Montana',
      code: 'MT'
    },
    {
      name: 'Nebraska',
      code: 'NE'
    },
    {
      name: 'Nevada',
      code: 'NV'
    },
    {
      name: 'New Hampshire',
      code: 'NH'
    },
    {
      name: 'New Jersey',
      code: 'NJ'
    },
    {
      name: 'New Mexico',
      code: 'NM'
    },
    {
      name: 'New York',
      code: 'NY'
    },
    {
      name: 'North Carolina',
      code: 'NC'
    },
    {
      name: 'North Dakota',
      code: 'ND'
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP'
    },
    {
      name: 'Ohio',
      code: 'OH'
    },
    {
      name: 'Oklahoma',
      code: 'OK'
    },
    {
      name: 'Oregon',
      code: 'OR'
    },
    {
      name: 'Palau',
      code: 'PW'
    },
    {
      name: 'Pennsylvania',
      code: 'PA'
    },
    {
      name: 'Puerto Rico',
      code: 'PR'
    },
    {
      name: 'Rhode Island',
      code: 'RI'
    },
    {
      name: 'South Carolina',
      code: 'SC'
    },
    {
      name: 'South Dakota',
      code: 'SD'
    },
    {
      name: 'Tennessee',
      code: 'TN'
    },
    {
      name: 'Texas',
      code: 'TX'
    },
    {
      name: 'Utah',
      code: 'UT'
    },
    {
      name: 'Vermont',
      code: 'VT'
    },
    {
      name: 'Virgin Islands',
      code: 'VI'
    },
    {
      name: 'Virginia',
      code: 'VA'
    },
    {
      name: 'Washington',
      code: 'WA'
    },
    {
      name: 'West Virginia',
      code: 'WV'
    },
    {
      name: 'Wisconsin',
      code: 'WI'
    },
    {
      name: 'Wyoming',
      code: 'WY'
    }
];

export const canadaStateList = [
    {
      name: 'Alberta',
      code: 'AB'
    },
    {
      name: 'British Columbia',
      code: 'BC'
    },
    {
      name: 'Manitoba',
      code: 'MB'
    },
    {
      name: 'New Brunswick',
      code: 'NB'
    },
    {
      name: 'Newfoundland and Labrador',
      code: 'NL'
    },
    {
      name: 'Northwest Territories',
      code: 'NT'
    },
    {
      name: 'Nova Scotia',
      code: 'NS'
    },
    {
      name: 'Nunavut',
      code: 'NU'
    },
    {
      name: 'Ontario',
      code: 'ON'
    },
    {
      name: 'Prince Edward Island',
      code: 'PE'
    },
    {
      name: 'Quebec',
      code: 'QC'
    },
    {
      name: 'Saskatchewan',
      code: 'SK'
    },
    {
      name: 'Yukon Territory',
      code: 'YT'
    }
];
