import React, { useState, useEffect } from "react";
//import cn from "classnames";
import styles from "../userItem/useritem.module.sass";
import {
    useHistory
    //, useParams
} from "react-router";
import API
    //, { axiosInstance }
    from "../../utils/API";
import Card from "./../../components/Card";
import { useSelector } from 'react-redux';
import ReactPaginate from "react-paginate";
import axios from '../../utils/API';
import { PopUpAlert } from "../../controller/utils";
//import { CircularProgress } from "@material-ui/core";
//import { CircularProgress } from '@mui/material';
//import { Link } from "react-router-dom";
import { NAMES_CONSTANTS } from '../../constants';

const ResellItem = () => {

    // This was being used for "logged in" status. Now using the userAccessToken from the localStorage instead.
    const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    //const history = useHistory();
    const [params, setParams] = useState({ limit: 9, page: 1 });
    const [count, setCount] = useState(5);
    const [assets, setAssets] = useState([]);
    const [loaderActive, setLoaderActive] = useState(true)
    const euroValue = useSelector((state) => state.counter.euroValue);

    useEffect(() => {
      getAllAssets();
    }, [params]);

    const getAllAssets = async () => {
        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , params
        };

        try {
            let userData = await API.get("/asset/resale", config);
            //let userData = await API.get("/asset/all?popular=true", config); // Temporary for development
            setAssets(userData?.data?.result.assets?.rows || []);
            setCount(userData?.data?.result?.totalPages || 0);
            setLoaderActive(false)
        } catch (err) {
            setLoaderActive(false)
            //console.log(err);
        }
    };

    const resaleCancelClick = async (e, item) => {
          alert('resaleCancelClick');

          e.stopPropagation();
          let config = {
              headers: {
                  Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
              }
          };
          await API.put(`/asset/resale?id=${item?.id}`, {}, config).then((res) => {
              getAllAssets();
          }).catch((err) => {
              PopUpAlert('Alert', err?.message ? err?.message : err?.data?.response?.message, 'error');
          });
    }

    console.log(assets)

    const handlePageClick = ({ selected: selectedPage }) => {
        //console.log(selectedPage, "hello");
        setParams({ ...params, page: selectedPage + 1 })
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    return (
        <>
        <main className = 'edit-profile'>

            <section
                className = 'form'
                style = {{
                    backgroundColor: "var(--color-gray-100)"
                }}
                >

                <div
                    className = 'max-width'
                    >

                    <div
                        className = { "heading" }
                        style = {{
                            display: 'flex'
                            , flexDirection: 'row'
                            , flexWrap: 'wrap'
                            //, justifyContent: 'space-between'
                            , justifyContent: 'flex-start'
                        }}>
                        <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <h1 style = {{ display: 'inline-block' }}>
                                Resell Assets
                            </h1>
                        </div>
                        {/*
                        <div style = {{ paddingLeft: '30px' }}>
                            <img
                                //src = '/images/icons/referrals-icon-28.jpg'
                                src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                style = {{
                                    //width: '50px'
                                    height: '60px'
                                    //, filter: 'var(--filter-theme-primary)'
                                    , filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                }}
                                />
                        </div>
                        */}
                    </div>

                    <div
                        className = 'subtitle'
                        //style = {{
                        //    paddingTop: "30px"
                        //    , paddingBottom: "0px"
                        //}}
                        >
                        {/*
                            assets.length > 0
                            ? ( <span>Assets you have listed for resale.</span> )
                            : ( <span>You have not listed any assets for resale yet.</span> )
                        */}
                        <span>Assets you have listed for resale.</span>

                    </div>

                    {
                        userAccessToken
                        ? 
                            <div
                                //className = { styles.wrapper }
                                className = "flex-container"
                                style = {{ marginTop: '30px' }}
                                >
                                {
                                    assets.length !== 0
                                    ? (
                                        <>
                                        <div
                                            className={styles.list}
                                            style = {{
                                                flexGrow: 1
                                            }}
                                            >
                                            {
                                                assets.map((x, index) => (
                                                    <div className={styles.assetContainer}>
                                                        <Card
                                                            className = { styles.card }
                                                            item = { x?.asset }
                                                            key = { index }
                                                            type = { "view" }
                                                            orderId = "157"
                                                            showLabel = { true }
                                                            price = { euroValue }
                                                            cancelOption = { true }
                                                            resaleCancelClick = { resaleCancelClick }
                                                            //resaleHandleClick = { resaleHandleClick }
                                                            productDetail = { x }
                                                            resalePage = { true }
                                                            />
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <ReactPaginate
                                            previousLabel={"← Previous"}
                                            nextLabel={"Next →"}
                                            pageCount={count || 1}
                                            onPageChange={handlePageClick}
                                            containerClassName={styles.pagination}
                                            pageClassName={styles.pagination_page}
                                            previousLinkClassName={styles.pagination__link__previous}
                                            nextLinkClassName={styles.pagination__link__next}
                                            disabledClassName={styles.pagination__link__disabled}
                                            activeClassName={styles.pagination__link__active}
                                          // pageRangeDisplayed={0}
                                          // marginPagesDisplayed={1}
                                            />
                                        </>
                                    ) : (
                                        <>
                                        <div
                                            className = { styles.info }
                                            style = {{ textAlign: 'left', fontSize: 'unset' }}
                                            >
                                            You do not have any assets listed for resale yet.
                                        </div>
                                        </>
                                    )
                                }
                            </div>
                        :
                            <div className={styles.wrapper}>
                                <div style={{ textAlign: "center", fontSize: 15 }}>Connect Wallet to view products.</div>
                            </div>
                    }
                </div>
            </section>
        </main>
        </>
    );
};

export default ResellItem;
