import React from "react";

export default function ModalDialogWrapper(Component, Id) {
    console.log("ModalDialogWrapper()")

    return () => (
        <div className = { 'outer ' + Id }>
            <div className="inner">
                <Component />
            </div>
        </div>
    );
}
