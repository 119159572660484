import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import { axiosInstance } from './../../utils/API';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

export default function FailPayment(props) {
    const history = useHistory();
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('orderId');

    useEffect(() => {
        axiosInstance.post(`/asset/order/coin/${id}`).then((res) => console.log('res for cancel',res)).catch((err)=> console.log('error fail',err));
        Swal.fire("Alert", "payment cancelled", "error").then(res => history.push('/'))
    },[])

    return <div style={{height:"700px", background:"#fff"}}></div>
}