/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    listCart: [],
    countCart: 0,
    totalCart: 0
  },
  reducers: {
    countCart: (state, data) => {
      state.countCart = data.payload;
    },
    empty: (state, data) => {
      state.countCart = 0;
      state.totalCart = 0;
      state.listCart = [];
    },
    listCart: (state, data) => {
      state.listCart = [...state.listCart, data.payload];
    },
    totalCart: (state, data) => {
      state.totalCart += data.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { countCart, listCart, empty, totalCart } = cartSlice.actions;

export default cartSlice.reducer;
