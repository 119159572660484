import { CRYPTOCURRENCY_CONSTANTS } from '../constants';

export const displayPrice = (productInfo, exchangeRate) => {
  const price = Math.ceil(Number(productInfo?.regularPrice || productInfo?.latestPrice) / Number(exchangeRate))
  return price.toFixed(2)
}

export const displayCryptoPrice = (productInfo) => {
  return (productInfo?.regularPrice || (productInfo?.latestPrice) || 0)?.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)
}
