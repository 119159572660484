//import styles from "./ContactUs.module.sass";
//import cn from "classnames";
//import { axiosInstance } from "../../utils/API";
//import { PopUpAlert } from "../../controller/utils";
//import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ContactForm from './ContactForm.js'

const ContactUs = (props) => {

    //const secretKey = useSelector((state) => state?.keyVal?.keyData);
    // const key = decryptData(secretKey?.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY);
    //const key = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

    //const [about, setAbout] = useState(null);
    const history = useHistory()
    // useEffect(() => {
    //   axiosInstance.get('/webcontent?key=about_us').then((res) => {
    //     setAbout(res?.data?.result[0]);
    //   }).catch((err) => {
    //     PopUpAlert("Alert", err?.data?.response?.error ? err?.data?.response?.error : err.message, "error")
    //   })
    // }, []);
    const redirectNft = () => {
        history.push('/search/all')
    }

    return (
        <>
        <main
            className = 'edit-profile'
            >
            <section
                //className={styles.aboutsection}
                className = 'form'
                >
                <div
                    //className={cn("container", styles.container)}
                    className = 'max-width'
                    >

                    {/* <GoogleReCaptchaProvider reCaptchaKey={key}> */}
                    <ContactForm
                        userDetails = { props.userDetails }
                        />
                    {/* </GoogleReCaptchaProvider> */}

                </div>
            </section>

        </main>
        </>
    );
};

export default ContactUs;
