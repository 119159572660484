import React, { useState } from 'react';
import styles from "./Header.module.sass";
//import cn from 'classnames'

const LoginModal = ({ close, loginMethod, connectWallet, connectDisable }) => {

    const [
        email
        //, setEmail
    ] = useState('');
    //const [loginType, setLoginType] = useState(false);
    const [error, setError] = useState(false);

    /*
    const handleSubmit = () => {
        if (email) {
            setError(false);
            loginMethod('normal', email);
        } else {
            setError(true);
        }
    };
    */

    return (
        <div className = { styles.loginModal }>
            <div className = { styles.loginModalContent }>

                <div className = { styles.loginModalClose } onClick = { () => close() }>
                    <span>
                        &#10005;
                    </span>
                </div>

                {/*
                <h2>Login</h2>
                */}
                
                {
                    <div className = { styles.formGroup }>

                        <div className = { styles.walletIcon }>
                            <img src="/images/icons/left-arrow-icon.svg" alt="" />
                        </div>

                        <h2>Connect your wallet to get started. This will enable you to buy and sell items on our platform securely.</h2>

                        {/* Previous implementation with specific and unique className.
                        <button type='button' className={styles.connectBtn} onClick={connectWallet} disabled={connectDisable}>
                            Connect Wallet
                        </button>
                        */}
                        {/* New implementation with global className. */}
                        <button
                            type = 'button'
                            //className = { "gold large full" }
                            className = { "gold rounded full" }
                            onClick = { connectWallet }
                            disabled = { connectDisable }>
                            Connect Wallet
                        </button>

                        {/* Previous implementation with specific and unique className.
                        <button type='button' className = { styles.cancelBtn } onClick = { () => close() }>
                            Cancel
                        </button>
                        */}
                        <button type='button' className = { "clear rounded full" } onClick = { () => close() }>
                            Cancel
                        </button>
                        {/* <span className={styles.links} onClick={()=>setLoginType(true)}>Or sign in with email</span> */}
                    </div>
                }
            </div>
        </div>
    );
}

export default LoginModal;
