import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Notifications.module.sass";
import NotificationItem from "./NotificationItem";
import Icon from "../../Icon";
import { useNotificationList } from "./apis/useListNotifications";

//const NotificationsButton = ({ className }) => {
const NotificationsButton = (props) => {
    console.info('NotificationsButton()');
    console.debug('NotificationsButton()', props);
    //console.debug('NotificationsButton: props.setVisible = ', props.setVisible);

    /*
    const [visible, setVisible] = useState(false);
    const { data } = useNotificationList();
    const [lastFetchNotification] = useState(() =>
        localStorage.getItem("lastFetchNotification")
    );
    */
    const [hasNewNotification, setHasNewNotification] = useState(false);
    const [currentLatestNotification, setCurrentLatestNotification] = useState(false);

    /*
    useEffect(() => {
        if (data) {
            const latestNotification = String(data.result?.notifications?.rows[0]?.id);
            if (latestNotification && latestNotification !== lastFetchNotification) {
                setHasNewNotification(true);
                setCurrentLatestNotification(latestNotification);
            }
        }
    }, [data]);
    */

    return (

        <div
            //className = { cn(styles.notification, className) }
            ref = { props.notificationsButtonRef }
            className = { cn(styles.notification) }
            >
        
            <button
                //ref = { props.notificationsButtonRef }
                className = { cn(styles.head, { [styles.active]: hasNewNotification }) }
                /*
                onClick = { () => {
                    //setVisible(!visible);
                    props.toggleShowNotificationsPopup();
                    setHasNewNotification(false);
                    localStorage.setItem(
                      "lastFetchNotification",
                      currentLatestNotification
                    );
                }}
                */
                //onClick = { (event) => {
                onMouseUp = { (event) => {
                    props.onClickNotificationsButton(event);
                }}
                >

                <img
                    className = 'svg-icon'
                    src = '/images/icons/notifications-circle-outline_1.svg'
                    alt = ''
                    style = {{ width: '32px', height: '32px' }}
                    />

            </button>

        </div>
    );
};

export default NotificationsButton;
