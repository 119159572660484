import { useMutation } from "react-query";
import { axiosInstance } from "../../../utils/API";
import { NAMES_CONSTANTS } from '../../../constants';

const mutationFunc = (payload) => {
  return axiosInstance.post(`/carts/checkout`, payload, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN),
    },
  })
};

export const useCreateStripeCheckoutCart = (config) => {
  return useMutation({
    mutationFn: (payload) => mutationFunc(payload),
    ...config,
  });
};
