import React from "react";
import cn from "classnames";
import styles from "./Notifications.module.sass";
//import NotificationItem from "../../components/Header/Notification/NotificationItem";
import NotificationItem from "../../components/Header/Notifications/NotificationItem";
import { useNotificationList } from "../../components/Header/Notification/apis/useListNotifications";

const Notifications = () => {

    //const { data } = useNotificationList();
    const { data, isLoading, isError, refetch } = useNotificationList();
    //console.warn('Notifications: responseData = ', responseData);
    console.warn('Notifications: data = ', data);
    console.warn('Notifications: isLoading = ', isLoading);
    console.warn('Notifications: isError = ', isError);
    console.warn('Notifications: refetch = ', refetch);

    const refreshNotificationsList = (e) => {
        console.warn('Notifications.refreshNotificationsList(e)')
        refetch();
    };

    return (

        <main className = 'edit-profile'>

            <section className = 'form'>

                <div className = 'max-width'>

                    {/*
                    <form
                        className = { "max-width" }
                    */}
                    <div
                        className = 'container'
                        style = {{ margin: "0px", padding: "0px" }}>

                        {/*
                        <div
                            className = { "heading" }
                            style = {{
                                display: 'flex'
                                , flexDirection: 'row'
                                , flexWrap: 'wrap'
                                //, justifyContent: 'space-between'
                                , justifyContent: 'flex-start'
                            }}>
                        */}

                            {/*
                            <div>
                                <h1 style = {{ display: 'inline-block' }}>
                                    Notifications
                                </h1>

                                <div className = { "subtitle" }>
                                    Here are all your notifications. They can be mark as read.
                                </div>

                            </div>
                            */}
                            <div
                                className = { "heading" }
                                style = {{
                                    display: 'flex'
                                    , flexDirection: 'row'
                                    , flexWrap: 'wrap'
                                    //, justifyContent: 'space-between'
                                    , justifyContent: 'flex-start'
                                }}>
                                <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                                    <h1 style = {{ display: 'inline-block' }}>
                                        Notifications
                                    </h1>
                                </div>
                                <div style = {{ paddingLeft: '30px' }}>
                                    <img
                                        //src = '/images/icons/referrals-icon-28.jpg'
                                        src = '/images/icons/notifications-circle-outline-scratch.svg'
                                        alt = 'Circle Outline Icon'
                                        style = {{
                                            //width: '50px'
                                            height: '60px'
                                            //, filter: 'var(--filter-theme-primary)'
                                            //, filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                        }}
                                        />
                                </div>
                            </div>

                        {/*
                        </div>
                        */}

                        {/*
                        <div
                            className = 'subtitle'
                            //style = {{ paddingTop: "30px", paddingBottom: "30px" }}
                            >
                            Here are all your notifications. They can be mark as read.
                        </div>
                        */}

                        <div
                            className = { cn("flex-container", styles.referWrapper) }
                            >
                            <div
                                style = {{
                                      flexGrow: 1
                                }}>

                                {
                                    //responseData
                                    data
                                    //&& responseData.result.notifications.rows.map((item) => (
                                    && data.result.notifications.rows.map((item) => (
                                        <NotificationItem
                                            key = { item.date }
                                            item = { item }
                                            dateFormat = 'dddd, MMMM D, YYYY - h:MM a'
                                            refreshNotificationsList = { refreshNotificationsList }
                                            />
                                    ))
                                }

                            </div>
                        </div>
                    {/*
                    </form>
                    */}
                    </div>
                </div>
            </section>
        </main>                              
    );
};

export default Notifications;
