import React from 'react';
//import styles from './Video.module.sass'
import cn from "classnames";
import VideoHeader from './VideoHeader'

//const Video = () => {
const Video = props => {

    let videoSrc;
    if (props.filePath) {
        videoSrc = props.filePath
    } else {
        videoSrc = "/videos/" + props.fileName;
    }

    return (
        <>
        <section
            //className={styles.banner_section}
            //className = 'video-section'
            //className = { cn(styles.banner_section, 'video-section') }
            className = 'video-section'
            >
            <div className = 'container max-width'>
                {/*
                <h2
                    style = {{
                        color: 'white'
                        , textAlign: 'left'
                        , whiteSpace: 'pre-wrap'
                    }}
                    >{ props.heading }</h2>
                */}
                <VideoHeader
                    test = { 'TEST' }
                    heading = { props.heading }
                    styleObject = { props.styleObject }
                    />
                <video
                    controls
                    src = { videoSrc }
                    style = {{ width: '100%' }}
                    type = 'video/mp4'
                    />
            </div>
        </section>
        </>
    );
}

export default Video;
