import { useQuery } from "react-query";
import { axiosInstance } from "../../../../utils/API";
import { NAMES_CONSTANTS } from '../../../../constants';

const queryFunc = async () => {

    const { data } = await axiosInstance.get(
        "/notification"
        , {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        }
    );

    return data;
};

export const useNotificationList = (config) => {
    return useQuery({
          queryKey: ["useNotificationList"]
        , queryFn: queryFunc
        , onSuccess: (data) => {}
        , ...config
    });
};
