import { useHistory } from "react-router";

const VictoriaAI = () => {

    const history = useHistory()
    const redirectNft = () => {
        history.push('/search/all')
    }

    return (
        <>
        <main className = 'edit-profile'>
            <section className = 'form'>
                <div className = 'max-width'>

                    <div>
                        
                        <h1 style = {{ textAlign: "left", padding: '20px' }}>Victoria AI</h1>
                        
                        <p style = {{ textAlign: "justify", padding: '20px' }}>
                            RealSplit&trade; uses a cutting-edge Conversational AI tool from Somee AI called “Victoria AI” to provide 24/7 customer service to our members.
                        </p>
                        <p style = {{ textAlign: "justify", padding: '20px' }}>
                            Victoria is a clone of a real-life person, Victoria Jancke who is a top model and professional speaker that wanted to create an AI clone of herself so that she could give her fans the ability to interact with her 24/7.
                        </p>

                        {/*
                        <script
                            type="module"
                            src="https://agent.d-id.com/v1/index.js"
                            data-name="did-agent"
                            data-mode="fabio"
                            data-client-key="Z29vZ2xlLW9hdXRoMnwxMDIzMjQ5MTE5Mzg1NjU2NzU0NjI6Uk9fQnlzQW9kOE1ZSDZGaDZpTklJ"
                            data-agent-id="agt_bIwk5QSa">
                            data-monitor="true"
                        </script>
                        */}

                    </div>
                </div>
            </section>

        </main>
        </>
    );
};

export default VictoriaAI;
