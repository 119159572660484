import React, { useState } from "react";
import cn from "classnames";
//import styles from "./../UserProfile/UserProfile.module.sass";
import styles from "./UserProfile.module.sass";
import Swal from "sweetalert2";
import { axiosInstance } from "../../utils/API";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
    storeUserVerifyStatus
    //, setProvider
} from '../../redux/counterSlice';
import {
      PopUpAlert
    , PopUpConfirmation
    , country
    , canadaStateList
    , UsStateList
    , getCountryName
} from "../../controller/utils";
//import { CircularProgress } from "@material-ui/core";
//import { CircularProgress } from '@mui/material';
//import { useEffect } from "react";
import HtmlComment from "../../components/HtmlComment";
import { base_url } from './../../controller/utils';
import { NAMES_CONSTANTS } from '../../constants';
import Dropdown from "../../components/Dropdown";

const TEXTURE_API_BASE_URL = process.env.REACT_APP_TEXTURE_API_BASE_URL;
const TEXTURE_API_USERNAME = process.env.REACT_APP_TEXTURE_API_USERNAME;
const TEXTURE_API_PASSWORD = process.env.REACT_APP_TEXTURE_API_PASSWORD;

const UserProfile = ({
      className
    , value
}) => {
    console.warn('UserProfile()')
    
    const [productDetails, setProductDetails] = React.useState();
    const [error, setError] = useState(null)
    const [userDetails, setUserDetails] = React.useState({});
    const [textureCustomerObject, setTextureCustomerObject] = React.useState({});

    //const [address, setAddress] = React.useState("");
    const [walletAddress, setWalletAddress] = React.useState("");
    const [profilePic, setProfilePic] = React.useState(null);
    const [confirmedTerm, setConfirmTerm] = React.useState(false);
    const [loaderShow, setLoaderShow] = useState(false)
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    let status = useSelector((state) => state.counter.value);
    //
    //status = 1;

    const [editablePersonalInformation, setEditablePersonalInformation] = useState(false)
    const [editableEmploymentInformation, setEditableEmploymentInformation] = useState(false)
    const [editableLocationInformation, setEditableLocationInformation] = useState(false)
    const [editableFinancialInformation, setEditableFinancialInformation] = useState(false)
    const [editableInvestmentPreferences, setEditableInvestmentPreferences] = useState(false)

    const updateEditablePersonalInformation = (isEditablePersonalInformation) => {
        console.warn('updateEditablePersonalInformation('+isEditablePersonalInformation+')');
        setEditablePersonalInformation(isEditablePersonalInformation);
    };

    const updateEditableLocationInformation = (isEditableLocationInformation) => {
        console.warn('updateEditableLocationInformation('+isEditableLocationInformation+')');
        setEditableLocationInformation(isEditableLocationInformation);
    };

    const updateEditableEmploymentInformation = (isEditableEmploymentInformation) => {
        console.warn('updateEditableEmploymentInformation('+isEditableEmploymentInformation+')');
        setEditableEmploymentInformation(isEditableEmploymentInformation);
    };

    const updateEditableFinancialInformation = (isEditableFinancialInformation) => {
        console.warn('updateEditableFinancialInformation('+isEditableFinancialInformation+')');
        setEditableFinancialInformation(isEditableFinancialInformation);
    };

    const updateEditableInvestmentPreferences = (isEditableInvestmentPreferences) => {
        console.warn('updateEditableInvestmentPreferences('+isEditableInvestmentPreferences+')');
        setEditableInvestmentPreferences(isEditableInvestmentPreferences);
    };

    const getConfig = () => {
        
        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        if (!localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)) {
            PopUpAlert('Alert..', 'No wallet connection found', 'error')
            return;
            //return null;
        }
        return config;
    };

    const getProfileDetails = async () => {
        

        /*
        if (!status) {
            // PopUpAlert('Disconnected', 'Connect to view your account userDetails', 'error');
            history.push('/search/all');
            return;
        }
        */

        const config = getConfig();
        if (!config) {
            //history.push('/edit-profile');
            history.push('/');
            return;
        }

        await axiosInstance
            .get(`user/get-profile`, config)
            .then((res) => {

                //const { firstName, lastName, email, mobileNumber, userName, profilePicUrl, status } = res.data?.result;
                //setUserDetails({ firstName, lastName, email, mobileNumber, userName });

                console.log('res = ', res);
                console.log('res.data = ', res.data);
                console.log('res.data.result = ', res.data.result);

                const { firstName, lastName, email, mobileNumber, userName, profilePicUrl, status, addresses } = res.data?.result;
                //const { firstName, lastName, email, mobileNumber, userName, profilePicUrl, status } = res.data?.result;
                //
                //setUserDetails({ firstName, lastName, email, mobileNumber, userName });
                setUserDetails(res.data?.result);
                console.log('userDetails = ', userDetails);

                dispatch(storeUserVerifyStatus(status));

                if (profilePicUrl) {
                    setProfilePic(profilePicUrl);
                    updateHeaderProfileImage(profilePicUrl);
                }

                let textureCapitalCustomerId = res.data.result.textureCapitalCustomerId;
                console.log('textureCapitalCustomerId = ', textureCapitalCustomerId);

                if (!textureCapitalCustomerId) {
                    console.log('localStorage.getItem(\'textureCapitalCustomerId\') = ' + localStorage.getItem('textureCapitalCustomerId'));
                    let textureCapitalCustomerId = localStorage.getItem('textureCapitalCustomerId');
                    console.log('textureCapitalCustomerId = ', textureCapitalCustomerId);
                }

                if (textureCapitalCustomerId) {

                    localStorage.setItem('textureCapitalCustomerId', textureCapitalCustomerId);

                    console.log('localStorage.getItem(\'textureToken\') = ' + localStorage.getItem('textureToken'));
                    let textureToken = localStorage.getItem('textureToken');
                    if (textureToken) {
                        fetchGetTextureCustomer(textureCapitalCustomerId);
                    } else {
                        fetchTextureToken(textureCapitalCustomerId);
                    }
                }
            })
            .catch((err) => {
                if (err.response.data.message === 'Your account is blocked by Admin') {
                    PopUpAlert("Alert..", err.response.data.message, "error").then(() => {
                        document.getElementById('connectWallet').click()
                    });
                } else {
                  PopUpAlert("Alert..", err.response.data.message, "error")
                }

                //history.push('/search/all');
                //setProfileDetails();
            });
    };

        /*
    {
        "token": "eyJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lcl9pZCI6IjMwMjIxY2NlLTkxNjgtNGZiYy04MzY3LTMwNzE0NmU5Y2ZjNSIsImV4cCI6MTY4NzE2NzkyM30.7evVnV3ynUUyNRjNy0fmz4FwafdgyMTmacReML6aHiA",
        "exp": "06-19-2023 09:45",
        "customer_id": "30221cce-9168-4fbc-8367-307146e9cfc5"
    }
    */
    //let fetchTextureToken = (fetchTokenCallbackFunction, textureCapitalCustomerId) => {
    let fetchTextureToken = (textureCapitalCustomerId) => {
        console.log('fetchTextureToken(textureCapitalCustomerId:' + textureCapitalCustomerId + ')');

        let fetchConfig = {
            method: 'POST'
            , headers: {
                  'Accept': 'application/json'
                  //'Accept': '*/*'
                //, 'Content-Type': 'application/json'
                //, Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: null
        };

        console.log('TEXTURE_API_BASE_URL = ', TEXTURE_API_BASE_URL);
        console.log(`TEXTURE_API_BASE_URL = ${TEXTURE_API_BASE_URL}`);
        //{{BASE_URL}}/api/v1/auth/login?email={{username}}&password={{password}}
        let fetchPath = `${TEXTURE_API_BASE_URL}/api/v1/auth/login?email=${TEXTURE_API_USERNAME}&password=${TEXTURE_API_PASSWORD}`;
        console.log('fetchPath = ', fetchPath);

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                console.log('fetchTextureToken(' + textureCapitalCustomerId + ').then(response)');
                console.log(response);
                console.log('response.ok = ' + response.ok);
                console.log('response.status = ' + response.status);
                console.log('response.statusText = ' + response.statusText);
                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    return response.json();
                }
            })

            /*
                {
                    "token": "eyJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lcl9pZCI6IjMwMjIxY2NlLTkxNjgtNGZiYy04MzY3LTMwNzE0NmU5Y2ZjNSIsImV4cCI6MTY4NzUyOTM1NH0.dM2xxStZ5G0dV7Jlrn-yE5BD5uUsX-zjx7OSyzkPm00",
                    "exp": "06-23-2023 14:09",
                    "customer_id": "30221cce-9168-4fbc-8367-307146e9cfc5"
                }
            */
            //*
            .then(function(responseJson) {
                    console.log('fetchTextureToken(' + textureCapitalCustomerId + ')');
                    console.log('function(responseJson)');
                    console.log(responseJson);


                    //getProfileDetails();
                    let textureToken = responseJson.token;
                    console.log('textureToken = ', textureToken);
                    console.log('localStorage.getItem(\'textureToken\') ' + localStorage.getItem('textureToken'));
                    localStorage.setItem('textureToken', textureToken)
                    console.log('localStorage.getItem(\'textureToken\') ' + localStorage.getItem('textureToken'));

                    //fetchCreateTextureCustomer(textureCapitalCustomerId);
                    fetchGetTextureCustomer(textureCapitalCustomerId);
            });
            //*/
            //.then(fetchTokenCallbackFunction);

    }; // fetchUpdateProfile()

    /*
        Texture Capital Customer Object:
        {
            "id": "5e5859bc-c551-43fc-bb97-f9f93bc1347a",
            "customer_type": "User",
            "full_name": "dermot doherty",
            "approved_to_trade": false,
            "email": "service.account@wearefuturetech.com",
            "telephone_number": null,
            "address_line1": null,
            "address_line2": null,
            "city": null,
            "state": "Gto.",
            "zip": null,
            "domicile": "MEX"
        }
    */
    let fetchGetTextureCustomer = (textureCapitalCustomerId) => {
        console.log('fetchGetTextureCustomer(textureCapitalCustomerId:' + textureCapitalCustomerId + ')');

        console.log('Authorization: Bearer ' + localStorage.getItem('textureToken'));

        let fetchConfig = {
            method: 'GET'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                //, 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem('textureToken')
            }
            //, body: JSON.stringify(textureCustomerObject)
        };

        console.log(`TEXTURE_API_BASE_URL = ${TEXTURE_API_BASE_URL}`);
        let fetchPath = `${TEXTURE_API_BASE_URL}/api/v1/customers/${textureCapitalCustomerId}`;
        console.log('fetchPath = ', fetchPath);

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                console.log('fetch('+fetchPath+').then(response = ', response);
                console.log('response.ok = ' + response.ok);
                console.log('response.status = ' + response.status);
                console.log('response.statusText = ' + response.statusText);

                if (response.ok) { // status of 200 signifies sucessful HTTP call, 'response.ok' is shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //Swal.close();
                    //return response.json();
                    response.json().then(function(responseJson) {
                        console.log(responseJson);
                        //getProfileDetails();
                        setTextureCustomerObject(responseJson);
                    });
                } else {
                    response.json().then(function(responseJson) {
                        console.log(responseJson);
                        // Signature has expired
                        let responseError = responseJson.errors;
                        console.log("responseError = ", responseError);
                        if (responseError == "Signature has expired")
                            fetchTextureToken(textureCapitalCustomerId);
                    });
                }
            })
            .then(function(responseJson) {
                console.log('fetch("'+fetchPath+'").then(responseJson)');
                console.log(responseJson);
                //getProfileDetails();

                setTextureCustomerObject(responseJson);

                //let textureCapitalCustomerId = responseJson.id;
                //console.log('textureCapitalCustomerId = ', textureCapitalCustomerId);
                //localStorage.setItem('textureCapitalCustomerId', textureCapitalCustomerId);
                //console.log('localStorage.getItem(\'textureCapitalCustomerId\') ' + localStorage.getItem('textureCapitalCustomerId'));

                //getProfileDetails();
            /*
            })
            .catch((err) => {

                alert(err);
                //if (err.response.data.message === 'Your account is blocked by Admin') {
                //    PopUpAlert("Alert..", err.response.data.message, "error").then(() => {
                //        document.getElementById('connectWallet').click()
                //    });
                //} else {
                //  PopUpAlert("Alert..", err.response.data.message, "error")
                //}
                //history.push('/search/all');
                //setProfileDetails();
            */
            });

    }; // fetchGetTextureCustomer()

    /*
    const setProfileDetails = async () => {
        const firstName = "First Name"
            , lastName = "Last Name"
            , email = "Email"
            , mobileNumber = "Mobile Number"
            , userName = "User Name"
            , profilePicUrl = "profilePicUrl"
            , status = "1"
            ;
        setUserDetails({ firstName, lastName, email, mobileNumber, userName });

        //dispatch(storeUserVerifyStatus(status))
        //if (profilePicUrl) {
        //    setProfilePic(profilePicUrl);
        //    updateHeaderProfileImage(profilePicUrl);
        //}
    };
    */

    const getProfilePicUrl = async () => {
        console.log('getProfilePicUrl()');
        
        const config = getConfig();
        await axiosInstance
            .get(`user/get-profile`, config).then((res) => {
                const { profilePicUrl, status } = res.data?.result;
                console.log('profilePicUrl = ' + profilePicUrl);
                console.log('status = ' + status);
                
                dispatch(storeUserVerifyStatus(status))
                if (profilePicUrl) {
                    setProfilePic(profilePicUrl);
                }
            });
    };

    React.useEffect(() => {
        getProfileDetails(id);
        const walletAddress = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))?.accounts;
        //setAddress(walletAddress);
        setWalletAddress(walletAddress);
    }, [status]);

    const handleImageUpload = (e) => {
        document.getElementById('my_file').click();
    };

    const uploadFile = (e) => {
        console.log('uploadFile(e)');
        
        setLoaderShow(true);
        const config = getConfig();
        const pic = document.getElementById('profile_pic');
        let file = e.target.files[0];
        console.log('file = ', file);

        if (
               file?.type === 'image/jpeg'
            || file?.type === 'image/jpg'
            || file?.type === 'image/png'
        ) {

            let formData = new FormData();
            formData.append("image", file);
            console.log('formData = ', formData);
            
            axiosInstance
                .post(`/user/update-profile-pic`, formData, config)
                .then(res => {
                    console.log('res = ', res);
                    getProfilePicUrl();
                    setLoaderShow(false);
                    PopUpConfirmation(
                          "Great!"
                        , "Profile image successfully uploaded"
                        , "success"
                    )
                    .then(res => {
                        // window.location.reload();
                        setError({ ...error, profile: '' });
                    });
                })
                .catch(err => {
                    setLoaderShow(false);
                    PopUpAlert("Alert!", err?.response?.data?.message ? err?.response?.data?.message : 'Invalid media file, for IMAGE, only JPEG, PNG, is allowed!', "error");
                    e.target.value = null;
                })
        } else {
            setLoaderShow(false);
            PopUpAlert('Alert!', 'Please upload image in jpeg / jpg / png format', 'error').then((res) => window.location.reload())
        }
    };

    const validation = () => {
        console.warn('UserProfile: validation()');

        let valid = true
        let field = userDetails;
        let errors = {}

        if (!field['firstName'] || field['firstName']?.trim() == "") {
            errors['firstName'] = 'Please enter your first name'
            valid = false
        }

        if (!field['lastName'] || field['lastName']?.trim() == "") {
            errors['lastName'] = 'Please enter your last name'
            valid = false
        }

        if (!field["email"] || field['email']?.trim() == "") {
            errors["email"] = "Please enter your email";
            valid = false;
        }

        //if (profilePic === null) {
        //    errors["profile"] = "Please upload your profile image";
        //    valid = false;
        //}

        // 
        if (field["email"] !== null) {

            if (
                !field["email"].match(
                    /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
                )
            ) {
                errors["email"] = "Please enter a valid email address";
                valid = false;
            }
        }

        //if (!field['userName'] || field['userName']?.trim() == "") {
        //    errors['userName'] = 'Please enter the user name'
        //    valid = false
        //}

        console.warn(errors)
        console.warn('valid = ', valid)
        setError(errors);
        return valid
    };

    const handleSubmitBak = (e) => {
        console.log('handleSubmit(e)')
        alert('handleSubmit(e)')

        e.preventDefault();

        if (validation()) {
            const config = getConfig();
            console.log('config = ' + config)
            console.log(config)
            console.log('userDetails = ' + userDetails)
            console.log(userDetails)
            axiosInstance
                .patch(`/user/update/`, userDetails, config)
                .then(res => {
                    if (res?.data?.success) {
                        PopUpAlert("Great!", "Profile userDetails successfully saved", "success")
                        .then((result) => {
                            if (res?.data?.result?.status == "verified") {
                                window.location.reload()
                            } else {
                                PopUpAlert("Success", "Verification mail sent to " + res?.data?.result?.email, "success")
                                .then(result => {
                                    window.location.reload()
                                })
                            }
                        });
                        getProfileDetails();
                        setConfirmTerm(false);
                    } else {
                        PopUpAlert('Alert', res?.data?.message, 'error');
                    }
                })
                .catch(err => {
                    PopUpAlert('Error', err.response?.data?.message || "something went wrong", 'error');
                })
        }
    };

    const handleSubmit = (e) => {
        console.warn('handleSubmit(e)')

        e.preventDefault();

        if (validation()) {

            console.warn('userDetails = ', userDetails);
            console.warn('userDetails.email = ' + userDetails.email);
            userDetails.userName = userDetails.email;
            console.warn('userDetails.userName = ', userDetails.userName);

            console.warn('userDetails.addressLine1 = ', userDetails.addressLine1);
            console.warn('userDetails.addressLine2 = ', userDetails.addressLine2);

            let addressObject = {
                  "addressLine1": userDetails.addressLine1
                , "addressLine2": userDetails.addressLine2
                , "townCity": userDetails.townCity
                , "countyStateProvince": userDetails.countyStateProvince
                , "postalCode": userDetails.postalCode
                , "countryCode": userDetails.countryCode
                , "isMainAddress": true
                , "isDeleted": false
                //, "id": 11
            };
            console.warn('addressObject = ', addressObject);

            let addresses = [
                addressObject
            ];

            userDetails.addresses = addresses;
            console.warn('userDetails.addresses = ', userDetails.addresses);

            //let textureCustomerObject = createTextureCustomerObject(userDetails);
            //fetchUpdateProfile(userDetails, textureCustomerObject);
            fetchUpdateProfile(userDetails);
        }
    };

    /*
    "customer": {
        "first_name": "Joseph",
        "last_name": "Schumpeter",
        "email": "joey@schumps.com",
        "domicile": "USA",
        "state": "GA",
        "telephone_number": "212-555-1212",
        "tax_id": "333-22-4444",
        "dob": "1950-01-08", # use the example format
        "address_line1": "123 West Vienna Street",
        "address_line2": "Apt. 25",
        "city": "Triesch",
        "zip": "12345",
        "occupation": "Professor",
        "employer": "University of Vienna",
        "finra_affiliation": "false", # indicates whether or not the customer is affiliated with a B/D, exchange or similar
        "income": "100_000", # commas may not be used, use underscore if a delimiter is desired
        "net_worth": "3_000_000",
        "invest_objective": "Speculation",
        "liquidity_need": "Does not matter",
        "terms_of_service": "true"
    }
    */

    //let fetchUpdateProfile = (bodyObject, textureCustomerObject) => {
        //console.warn('fetchUpdateProfile(bodyObject:'+typeof bodyObject+', textureCustomerObject)');

    let fetchUpdateProfile = (bodyObject) => {
        console.warn('fetchUpdateProfile(bodyObject:'+typeof bodyObject+')');
        console.warn('bodyObject = ', bodyObject);
        //console.warn('textureCustomerObject = ', textureCustomerObject);
        console.warn('Authorization: Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN));

        let fetchConfig = {
            method: 'PATCH'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: JSON.stringify(bodyObject)
        };

        console.warn('base_url = ', base_url);
        console.warn(`base_url = ${base_url}`);
        let fetchPath = `${base_url}user/update`;
        console.warn('fetchPath = ', fetchPath);

        //*
        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                console.warn('fetchPromise.then(response)');
                console.warn(response);
                console.warn('response.ok = ' + response.ok);
                console.warn('response.status = ' + response.status);
                console.warn('response.statusText = ' + response.statusText);
                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //Swal.close();
                    return response.json();
                }
            })
            .then(function(responseJson) {
                console.warn('fetch("'+fetchPath+'").then(responseJson)');
                console.warn(responseJson);
                console.warn(responseJson.result);
                //getProfileDetails();
                //fetchTextureToken();
                //fetchTextureToken(handleTextureTokenResponse, textureCustomerObject);
                //fetchTextureToken(textureCustomerObject);

                setEditablePersonalInformation(false);
                setEditableLocationInformation(false);
                //setEditableEmploymentInformation(false);
                //setEditableFinancialInformation(false);
                //setEditableInvestmentPreferences(false);

                setUserDetails(responseJson.result);
                console.warn('userDetails = ', userDetails);
            });
        //*/
    }; // fetchUpdateProfile()

    const EMPTY_STRING = "";

    /*
    const handleCancel = () => {
        setUserDetails({
              firstName: EMPTY_STRING
            , lastName: EMPTY_STRING
            , email: EMPTY_STRING
            , mobileNumber: EMPTY_STRING
            , userName: EMPTY_STRING
        })
        setError(null);
        getProfileDetails();
        // setConfirmTerm(false);
    };
    */

    const updateHeaderProfileImage = (x) => {

        let profileImageElement = document.getElementById('header-profile-image');
        profileImageElement.setAttribute('src', x);

        /*
        //const walletAddress = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))?.accounts;
        //const profileImage = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))?.profileImage;
        //alert("profileImage = " + profileImage);
        const walletUserDetails = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))
        //const profileImage = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))?.profileImage;
        //let profilePicObject = {
        //    profilePicUrl: x
        //};
        //localStorage.setItem('REALSPLIT_USER_WALLET_DETAILS', JSON.stringify({...walletUserDetails, x }))
        //localStorage.setItem('REALSPLIT_USER_WALLET_DETAILS', JSON.stringify({...walletUserDetails, profilePicObject }))
        //localStorage.setItem('REALSPLIT_USER_WALLET_DETAILS', JSON.stringify({...walletUserDetails, { profilePicUrl: x } }))
        localStorage.setItem('REALSPLIT_USER_WALLET_DETAILS', JSON.stringify({...walletUserDetails, profilePicUrl: x }))
        */
     
        const walletUserDetails = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS))
      
        localStorage.setItem(NAMES_CONSTANTS.USER_WALLET_DETAILS, JSON.stringify({...walletUserDetails, profilePicUrl: x }))

    };

    //let addresses = userDetails?.addresses;
    //console.log('addresses = ' + addresses)

    //const ConsoleLog = ({ children }) => {
    //    return null;
    //};

    //const clickAddAnotherAddress = function() {
    //    //alert('clickAddIcon');
    //    showAddDeliveryAddressModal();
    //};

    const showAddDeliveryAddressModal = () => {
        
        //let productName = productDetails?.name;
        //let creatorName = productDetails?.creatorName;

        //let input1 = '<input type="text"/>'
        let input1 = '<div class="input"><input type="text" name="swal-input1" id="swal-input1" placeholder="Address line 1" /></div>';
        //let cell1 = '<div class="cell" style="text-align: left;">'+input1+'</div>';
        //let row1 = '<div class="row">' + cell1 + '</div>';
        let row1 = '<div class="flex-item" style="text-align: left;">'+input1+'</div>';

        //let input2 = '<input type="text"/>';
        let input2 = '<div class="input"><input type="text" name="swal-input2" id="swal-input2" placeholder="Address line 1" /></div>';
        //let cell2 = '<div class="cell" style="text-align: left;">'+input2+'</div>';
        //let row2 = '<div class="row">' + cell2 + '</div>';
        let row2 = '<div class="flex-item" style="text-align: left;">'+input2+'</div>';

        let input3 = '<div class="input"><input type="text" name="swal-input3" id="swal-input3" placeholder="ZIP/Postal Code" /></div>';
        let row3 = '<div class="flex-item" style="text-align: left;">'+input3+'</div>';

        let input4 = '<div class="input"><input type="text" name="swal-input4" id="swal-input4" placeholder="Town / City" /></div>';
        let row4 = '<div class="flex-item" style="text-align: left;">'+input4+'</div>';

        let input5 = '<div class="input"><input type="text"  name="swal-input5" id="swal-input5" placeholder="State" /></div>';
        let row5 = '<div class="flex-item" style="text-align: left;">'+input5+'</div>';

        //let divTableString = '<div class="table" style="width: 100%;">'+row1+row2+'</div>'
        let divTableString = '<div class="flex" style="width: 100%;">' + row1 + row2 + row3 + row4 + row5 + '</div>'

        let htmlString = '<p class="title" style="text-align: left;">Please add at least one delivery address</p>';

        htmlString += divTableString;

        Swal.fire({
              title: 'Add Delivery Address'
            , showCloseButton: true
            , showConfirmButton: true
            , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
            //, confirmButtonText: 'Checkout'
            , confirmButtonText: 'Add Address'
            , buttonsStyling: false
            , preConfirm: () => {
                console.log('preConfirm()')

                console.log('document.getElementById(\'swal-input1\').value = ' + document.getElementById('swal-input1').value)
                console.log('document.getElementById(\'swal-input2\').value = ' + document.getElementById('swal-input2').value)
                console.log('document.getElementById(\'swal-input3\').value = ' + document.getElementById('swal-input3').value)
                console.log('document.getElementById(\'swal-input4\').value = ' + document.getElementById('swal-input4').value)
                console.log('document.getElementById(\'swal-input5\').value = ' + document.getElementById('swal-input5').value)

                let addressLine1 = document.getElementById('swal-input1').value;
                let addressLine2 = document.getElementById('swal-input2').value;
                let postalCode = document.getElementById('swal-input3').value;
                let townCity = document.getElementById('swal-input4').value;
                let countyStateProvince = document.getElementById('swal-input5').value;
                let countryCode = 'US';

                
                
                userDetails.userName = userDetails.email;
                

                let addressObject = {
                      "addressLine1": addressLine1
                    , "addressLine2": addressLine2
                    , "townCity": townCity
                    , "countyStateProvince": countyStateProvince
                    , "postalCode": postalCode
                    , "countryCode": countryCode
                    //, "isMainAddress": true
                    //, "isDeleted": false
                    //, "id": 11
                };
                
                

                //let addresses = [
                //    addressObject
                //];

                userDetails.addresses.push(addressObject);
                

                /*
                axiosInstance
                    .patch(`/user/update/`, userDetails, config)
                    .then(res => {
                        if (res?.data?.success) {
                            PopUpAlert("Great!", "Profile userDetails successfully saved", "success")
                            .then((result) => {
                                if (res?.data?.result?.status == "verified") {
                                    window.location.reload()
                                } else {
                                    PopUpAlert("Success", "Verification mail sent to " + res?.data?.result?.email, "success")
                                    .then(result => {
                                        window.location.reload()
                                    })
                                }
                            });
                            getProfileDetails();
                            setConfirmTerm(false);
                        } else {
                            PopUpAlert('Alert', res?.data?.message, 'error');
                        }
                    })
                    .catch(err => {
                        PopUpAlert('Error', err.response?.data?.message || "something went wrong", 'error');
                    })
                */
                fetchUpdateProfile(userDetails);

                //Swal.close();
                //return true;
                return false;
            }
            , customClass: {
                  container: 'custom-container'
                , popup: 'custom-popup custom-popup-add-address'
                , confirmButton: "gold large stretch rounded"
            }
            //, input: 'email'
            //, inputLabel: 'Please add your email address'
            //, inputPlaceholder: 'Enter your email address'
            , html: htmlString
        //})
        }).then((result) => {
            if (result.value) {
                
                alert("Result: " + result.value);
                //showModalDialogForm();
            }
        });
    };

    let fetchUpdateProfileBak = (bodyObject) => {

        let fetchConfig = {
            method: 'PATCH'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: JSON.stringify(bodyObject)
        };

        let fetchPath = `${base_url}user/update`;
        let fetchPromise = fetch(fetchPath, fetchConfig);

        fetchPromise
            .then(function(response) {
                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    Swal.close();
                    return response.json();
                }
            })
            .then(function(responseJson) {
                getProfileDetails();
            });
    }; // let fetchUpdateProfile

    const handleChange = (e) => {
        console.warn('handleChange('+e+')');

        //setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
        //setError({ ...error, [e.target.name]: '' })
        handleUserDetailsChangeNameValue(e.target.name, e.target.value);
    };

    
    //const handleChangeAddress = (event, addressIndex) => {
    //    console.warn('handleChangeAddress(event:'+event+', addressIndex:'+addressIndex+')');
    //    alert('handleChangeAddress(event:'+event+', addressIndex:'+addressIndex+')');
    const handleChangeAddress = (event) => {
        console.warn('handleChangeAddress(event:'+event+')');

        let eventTarget = event.target;
        console.warn('eventTarget = ', eventTarget);
        //console.warn('eventTarget.dataAddressIndex = ', eventTarget.dataAddressIndex);
        //console.warn('eventTarget.dataaddressindex = ', eventTarget.dataaddressindex);
        //console.warn('eventTarget.getAttribute(\'address-index\')', eventTarget.getAttribute('address-index'));
        console.warn('eventTarget.getAttribute(\'dataaddressindex\')', eventTarget.getAttribute('dataaddressindex'));
        console.warn('typeof eventTarget.getAttribute(\'dataaddressindex\')', typeof eventTarget.getAttribute('dataaddressindex'));

        let addressIndex = eventTarget.getAttribute('dataaddressindex');
        addressIndex = parseInt(addressIndex);
        console.warn('addressIndex = ', addressIndex);

        //setUserDetails({ ...userDetails, [event.target.name]: event.target.value })
        //setError({ ...error, [event.target.name]: '' })
        //handleUserDetailsChangeNameValue(event.target.name, event.target.value);
        console.warn('userDetails.addresses.length = ', userDetails.addresses.length);
        console.warn('userDetails.addresses = ', userDetails.addresses);
        let addressObject = userDetails.addresses[addressIndex];
        console.warn('addressObject = ', addressObject);

        let elementName = eventTarget.name, elementValue = eventTarget.value;
        console.warn(elementName, elementValue);

        // Update the field
        addressObject[elementName] = elementValue;

        //setUserDetails({ ...userDetails })
        // Also add the field to the userDetails object
        handleUserDetailsChangeNameValue(elementName, elementValue);
    };

    let nbsp = '\u00A0';
    const YesNoArray = ['Yes', 'No'];
    const finraOptions = YesNoArray;

    const setFinraAffiliationValue = (value) => {
        console.warn('UserProfile.setFinraAffiliationValue('+value+')');
        //userDetails?.finra_affiliation = x;
        //setUserDetails({ ...userDetails, ['finra_affiliation']: value })
        let name = 'finra_affiliation';
        handleUserDetailsChangeNameValue(name, value);
    };

    const handleUserDetailsChangeNameValue = (name, value) => {
        console.warn('UserProfile.handleUserDetailsChangeNameValue('+name+', '+value+')');

        console.warn('userDetails = ', userDetails);

        setUserDetails({ ...userDetails, [name]: value })
        setError({ ...error, [name]: '' })

        //setTimeout(() => logUserDetails(), 1000);
        //setTimeout(function() { logUserDetails() }, 1000);
        //setTimeout(() => logUserDetails(), 5000);
    };

    const logUserDetails = () => {
        console.warn('UserProfile.logUserDetails()');
        console.warn('userDetails = ', userDetails);
    };

    return (

        <main
            //className = { cn("edit-profile", "user-profile") }
            className = {
                //"edit-profile", "user-profile"
                //"edit-profile"
                //'edit-profile user-profile'
                cn(
                    'edit-profile'
                    , !editablePersonalInformation ? 'user-profile' : ''
                    , !editablePersonalInformation ? 'user-profile' : ''
                )
            }
            //style = {{ paddingTop: "230px" }}
            >

            <section
                className = { "form" }
                //style = {{ padding: "0px 50px" }}
                >

                <div className = { "max-width" }>

                    <div
                        className = { "heading" }
                        style = {{
                            display: 'flex'
                            , flexDirection: 'row'
                            , flexWrap: 'wrap'
                            //, justifyContent: 'space-between'
                            , justifyContent: 'flex-start'
                        }}>
                        <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <h1 style = {{ display: 'inline-block' }}>
                                Profile
                            </h1>
                        </div>
                        {/*
                        <div style = {{ paddingLeft: '30px' }}>
                            <img
                                //src = '/images/icons/referrals-icon-28.jpg'
                                src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                style = {{
                                    //width: '50px'
                                    height: '60px'
                                    //, filter: 'var(--filter-theme-primary)'
                                    , filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                }}
                                />
                        </div>
                        */}
                    </div>

                    <form
                        className = { "max-width" }
                        onSubmit = { handleSubmit }
                        style = {{ margin: "0px", padding: "0px" }}>

                        {/*
                        <div
                            className = { "button-container" }
                            //style = {{ paddingBottom: "40px" }}
                            >
                            <h1>Profile</h1>
                        </div>
                        */}

                        {/*
                        <p style = {{ maxWidth: "736px" }}>
                            Thank you for joining RealSplit, we’d like to know more about you, please enter your name, email address and a profile photo below. you can also add a Shipping address for when you purchase a product.
                        </p>
                        */}

                        {/*                        
                        <div className = { 'form-section' }>
                        */}

                            <div
                                //style = {{ flexBasis: "50%" }}
                                //style = {{
                                //    gap: "32px"
                                //    , alignItems: "flex-start"
                                //}}
                                //className = { "flex-container" }
                                className = {
                                    editablePersonalInformation ? 'flex-container editable' : 'flex-container'
                                }
                                >

                                <div
                                    /*
                                    className = { "form-heading" }
                                    */
                                    style = {{
                                          display: "flex"
                                        //, padding: "10px"
                                        //, background: "#EBEBEB"
                                        //, borderRadius: "6px"
                                        //, fontFamily: "'Poppins'"
                                        //, fontStyle: "normal"
                                        //, fontWeight: "700"
                                        //, fontSize: "14px"
                                        //, lineHeight: "12px"
                                        //, textTransform: "uppercase"
                                        //, color: "#000000"
                                        , width: "100%"
                                        , justifyContent: 'space-between'
                                    }}
                                    //className = {
                                    //    editablePersonalInformation ? 'editable' : ''
                                    //}
                                    >
                                    <div className = { "form-heading" }>
                                        Personal Information
                                    </div>
                                    <div>
                                        {
                                            !editablePersonalInformation
                                            ? 
                                                <button
                                                    className = { cn("theme-success") }
                                                    //onClick = { () => history.push('/edit-profile') }
                                                    //onClick = { () => setEditablePersonalInformation(true) }
                                                    //onClick = { () => { updateEditablePersonalInformation(true) }
                                                    //onClick = { () => { updateEditablePersonalInformation(true) }}
                                                    onClick = { (e) => {
                                                        updateEditablePersonalInformation(true);
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                    >
                                                    <div
                                                        style = {{
                                                            //padding: '4px 8px'
                                                            display: 'table'
                                                        }}
                                                        >
                                                        <div className = { "row" }>
                                                            <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                                <img src="images/icons/edit-icon-white.svg"alt = 'Edit Icon' />
                                                            </div>
                                                            <div className = { "cell text" }>
                                                                Edit
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            :
                                                <button
                                                    className = { "theme-alert" }
                                                    //onClick = { () => history.push('/user-profile') }
                                                    onClick = { (e) => {
                                                        updateEditablePersonalInformation(false);
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                    >
                                                    <div
                                                        style = {{
                                                            display: 'table'
                                                        }}
                                                        >
                                                        <div className = { "row" }>
                                                            <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                                <img src="images/icons/edit-icon-white.svg" alt = 'Edit Icon' />
                                                            </div>
                                                            <div className = { "cell text" }>
                                                                Cancel
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                        }
                                    </div>
                                </div>

                                <div
                                    className = { "profile-photo" }
                                    style = {{ flexBasis: "100%" }}
                                    >
                                    <label>Profile Image</label>
                                    <div className = { "table" }>
                                        <div className = { "cell" }  style = {{ paddingRight: "20px" }}>
                                            <img
                                                style = {{ width: '86px', height: '86px', borderRadius: '43px' }}
                                                src = { profilePic || "images/avatar-icon-large.png" }
                                                alt = 'Profile Pic'
                                                />
                                        </div>
                                        <div className = { "cell" }  style = {{ verticalAlign: "top", marginTop: "0" }}>

                                            <div className = { "profile-photo-box" }>
                                                {/*
                                                <div>
                                                    <div className = { "title" }>Profile photo</div>
                                                </div>
                                                */}
                                                <div>
                                                    <div className = { "subtitle" }>We recommend an image of at least 400x400. Gifs work too</div>
                                                </div>

                                                <div  style = {{ marginTop: "0px" }}>
                                                    <button
                                                        type="button"
                                                        className = { "rounded-clear light" }
                                                        onClick = { handleImageUpload }
                                                        style = {{ width: "auto", padding: "8px 12px", marginTop: "0px" }}
                                                        >Upload</button>
                                                </div>

                                                <input
                                                    type = 'file'
                                                    id = 'my_file'
                                                    style = {{ display: 'none' }}
                                                    onChange = { (e) => uploadFile(e) }
                                                    accept = 'image/*'
                                                    />
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div>

                                    <label>First name</label>

                                    <div
                                        className = { "input-box" }
                                        //style = {{ paddingTop: "8px", paddingBottom: "8px", marginTop: "0px" }}
                                        >
                                        {
                                            editablePersonalInformation
                                            ? 
                                                <input
                                                    type="text"
                                                    name = "firstName"
                                                    placeholder="Enter First name"
                                                    value = { userDetails?.firstName }
                                                    onChange = { handleChange }
                                                    />
                                            :
                                                userDetails?.firstName ?
                                                    <div
                                                        onClick = { (e) => {
                                                            updateEditablePersonalInformation(true);
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        style = {{
                                                            display: "inline-block"
                                                            , width: "100%"
                                                            , height: "100%"
                                                        }}
                                                        >
                                                        { userDetails?.firstName }
                                                    </div>
                                                : nbsp
                                        }
                                    </div>

                                    {
                                        error && error?.firstName && <div className = 'error-msg'>{ error?.firstName }</div>
                                    }


                                </div>
                                <div>

                                    <label>Last Name</label>
                                    <div className = { "input-box" }>
                                        {
                                            editablePersonalInformation
                                            ? 
                                                <input
                                                    //className={styles.input}
                                                    type="text"
                                                    name="lastName"
                                                    placeholder="Enter Last name"
                                                    value = { userDetails?.lastName }
                                                    onChange = { handleChange }
                                                    //disabled = { !confirmedTerm }
                                                    />
                                            :
                                                userDetails?.lastName
                                                //? userDetails?.lastName
                                                ? <div
                                                        onClick = { (e) => {
                                                            updateEditablePersonalInformation(true);
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        style = {{
                                                            display: "inline-block"
                                                            , width: "100%"
                                                            , height: "100%"
                                                        }}
                                                        >
                                                        { userDetails?.lastName }
                                                    </div>
                                                : nbsp
                                        }
                                    </div>
                                    {
                                        error && error?.lastName && <div className = 'error-msg'>{ error?.lastName }</div>
                                    }
                                </div>

                                <div>
                                    <label>Email</label>
                                    <div className = { "input-box" }>
                                        {
                                            editablePersonalInformation
                                            ?
                                                <>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    placeholder="Enter Your Email"
                                                    onChange = { handleChange }
                                                    value = { userDetails?.email }
                                                    onChange = { handleChange }
                                                    //disabled = { !confirmedTerm }
                                                    />
                                                <input type="hidden" name="userName" />
                                                </>
                                            :
                                                userDetails?.email
                                                //? userDetails?.email
                                                ?
                                                    <div
                                                        onClick = { (e) => {
                                                            updateEditablePersonalInformation(true);
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        style = {{
                                                            display: "inline-block"
                                                            , width: "100%"
                                                            , height: "100%"
                                                        }}
                                                        >
                                                        { userDetails?.email }
                                                    </div>
                                                : nbsp
                                        }
                                    </div>
                                    {
                                        error?.email && <div className = 'error-msg'>{ error?.email }</div>
                                    }
                                </div>

                                <div>
                                    <label>Telephone Number (Optional)</label>
                                    <div className = { "input-box" }>
                                        {
                                            editablePersonalInformation
                                            ?
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="000-000-0000"
                                                    onChange = { handleChange }
                                                    value = { userDetails?.phone }
                                                    />
                                            :
                                                userDetails?.phone
                                                //? userDetails?.phone
                                                ?
                                                    <div
                                                        onClick = { (e) => {
                                                            updateEditablePersonalInformation(true);
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        style = {{
                                                            display: "inline-block"
                                                            , width: "100%"
                                                            , height: "100%"
                                                        }}
                                                        >
                                                        { userDetails?.phone }
                                                    </div>
                                                : nbsp
                                        }
                                    </div>
                                    {
                                        //error?.telephone_number
                                        error?.phone
                                        && <div className = { styles.errorMsg }>{ error?.phone }</div>
                                    }
                                </div>

                                {/*
                                <div>
                                    <label>Domicile</label>
                                    <div className = { "input-box" }>
                                        <select
                                            //className={styles.input}
                                            type="text"
                                            name="domicile"
                                            placeholder="Domicile"
                                            value = { textureCustomerObject?.domicile }
                                            onChange = { handleChange }
                                            //disabled = { !confirmedTerm }
                                            >
                                            <option value="">Select</option>
                                            <option value="USA">United States</option>
                                            <option value="MEX">Mexico</option>
                                        </select>
                                    </div>
                                    {
                                        error?.domicile
                                        && <div className = { styles.errorMsg }>{ error?.domicile }</div>
                                    }
                                </div>

                                <div>
                                    <label>State</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="state"
                                            id="state"
                                            placeholder="State"
                                            onChange = { handleChange }
                                            //value = { address?.countyStateProvince }
                                            value = { textureCustomerObject?.state }
                                            />
                                    </div>
                                    {
                                        error?.domicile
                                        && <div className = { styles.errorMsg }>{ error?.domicile }</div>
                                    }
                                </div>

                                <div>
                                    <label>Date of Birth</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="dob"
                                            id="dob"
                                            placeholder="E.g. 1970-01-31"
                                            onChange = { handleChange }
                                            value = { textureCustomerObject?.dob }
                                            />
                                    </div>
                                    {
                                        error?.dob
                                        && <div className = { styles.errorMsg }>{ error?.dob }</div>
                                    }
                                </div>

                                <div>
                                    <label>Tax ID (SSN)</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="tax_id"
                                            id="tax_id"
                                            placeholder="xxx-xx-xxxx"
                                            onChange = { handleChange }
                                            value = { textureCustomerObject?.tax_id }
                                            />
                                    </div>
                                    {
                                        error?.tax_id
                                        && <div className = { styles.errorMsg }>{ error?.tax_id }</div>
                                    }
                                </div>
                                */}

                                {
                                    editablePersonalInformation
                                    &&
                                        <div style = {{ flexBasis: "100%" }}>
                                            <div
                                                className = { "button-container" }
                                                style = {{
                                                    display: "flex"
                                                    , justifyContent: "center"
                                                    //, padding: "10px 0px 0px 0px"
                                                }}
                                                >
                                                <div className = { "button-wrapper" }>
                                                    <button
                                                        type = "submit"
                                                        value = "Save"
                                                        style = {{ color: "#fff" }}
                                                        //className = { "gold-background" }
                                                        className = { "theme-success large" }
                                                        >
                                                        Save
                                                    </button>
                                                </div>

                                                {/*
                                                <div className = { "button-wrapper" }>
                                                    <button
                                                        className = { "gilded large curved" }
                                                        onClick = { () => history.push("/user-profile") }
                                                        >
                                                        Cancel
                                                    </button>
                                                </div>
                                                */}

                                            </div>
                                        </div>
                                }

                            </div>

                            <div
                                //className = { "flex-container" }
                                className = {
                                    editableLocationInformation ? 'flex-container editable' : 'flex-container'
                                }
                                >

                                <div
                                    //className = { "form-heading" }
                                    style = {{
                                          display: "flex"
                                        , width: "100%"
                                        , justifyContent: 'space-between'
                                        , flexBasis: "100%"
                                    }}
                                    >
                                    <div className = { "form-heading" }>
                                        Location (Optional)
                                    </div>
                                    <div>
                                        {
                                            !editableLocationInformation
                                            ?
                                                <button
                                                    className = { "theme-success" }
                                                    style = {{
                                                        padding: '4px 8px'
                                                    }}
                                                    //onClick={ () => history.push('/edit-profile') }
                                                    onClick = { (e) => {
                                                        updateEditableLocationInformation(true);
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                    >
                                                    <div
                                                        style = {{
                                                            display: 'table'
                                                        }}
                                                        >
                                                        <div className = { "row" }>
                                                            <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                                <img
                                                                    //src="images/icons/edit-icon-white.svg"
                                                                    src="images/icons/svgrepo/edit-svgrepo-com.svg"
                                                                    alt = 'Edit Icon'
                                                                    //src = "images/icons/edit-icon-white.svg"
                                                                    style = {{ width: '16px' }}
                                                                    />
                                                            </div>
                                                            <div className = { "cell text" }>
                                                                Edit
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            :
                                                <button
                                                    className = { "theme-alert" }
                                                    //onClick = { () => history.push('/user-profile') }
                                                    onClick = { (e) => {
                                                        updateEditableLocationInformation(false);
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                    >
                                                    <div
                                                        style = {{
                                                            display: 'table'
                                                        }}
                                                        >
                                                        <div className = { "row" }>
                                                            <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                                <img src="images/icons/edit-icon-white.svg" alt = 'Edit Icon' />
                                                            </div>
                                                            <div className = { "cell text" }>
                                                                Cancel
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                        }
                                        
                                    </div>
                                </div>

                                {
                                      userDetails?.addresses?.length
                                    ? userDetails?.addresses?.map((address, index) =>
                                        <>
                                            <div>
                                                <label>Address line 1</label>
                                                <div className = { "input-box" }>
                                                    {
                                                        editableLocationInformation
                                                        ?
                                                            <input
                                                                type="text"
                                                                name = "addressLine1"
                                                                id = "addressLine1"
                                                                dataaddressindex = { index }
                                                                value = { address?.addressLine1 != null ? address?.addressLine1 : '' }
                                                                //disabled = { !confirmedTerm }
                                                                onChange = { handleChangeAddress }
                                                                //onChange = { handleChangeAddress.bind(index) }
                                                                />
                                                        :
                                                            address?.addressLine1
                                                            //? address?.addressLine1
                                                            ?
                                                                <div
                                                                    onClick = { (e) => {
                                                                        updateEditableLocationInformation(true);
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }}
                                                                    style = {{
                                                                        display: "inline-block"
                                                                        , width: "100%"
                                                                        , height: "100%"
                                                                    }}
                                                                    >
                                                                    { address?.addressLine1 }
                                                                </div>
                                                            : <span>&nbsp;</span>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <label>Address line 2</label>
                                                <div className = { "input-box" }>
                                                    {
                                                        editableLocationInformation
                                                        ?
                                                            <input
                                                                type="text"
                                                                name="addressLine2"
                                                                id="addressLine2"
                                                                dataaddressindex = { index }
                                                                //placeholder="Address line 2"
                                                                value = { address?.addressLine2 != null ? address?.addressLine2 : '' }
                                                                //disabled = { !confirmedTerm }
                                                                onChange = { handleChangeAddress }
                                                                />
                                                        :
                                                            address?.addressLine2
                                                            //? address?.addressLine2
                                                            ?
                                                                <div
                                                                    onClick = { (e) => {
                                                                        updateEditableLocationInformation(true);
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }}
                                                                    style = {{
                                                                        display: "inline-block"
                                                                        , width: "100%"
                                                                        , height: "100%"
                                                                    }}
                                                                    >
                                                                    { address?.addressLine2 }
                                                                </div>
                                                            : <span>&nbsp;</span>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <label>Town/City</label>
                                                <div className = { "input-box" }>
                                                    {
                                                        editableLocationInformation
                                                        ?
                                                            <input
                                                                type="text"
                                                                name="townCity"
                                                                id="townCity"
                                                                dataaddressindex = { index }
                                                                //placeholder="Town / City"
                                                                value = { address?.townCity != null ? address?.townCity : '' }
                                                                //disabled = { !confirmedTerm }
                                                                onChange = { handleChangeAddress }
                                                                />
                                                        :
                                                            address?.townCity
                                                            //? address?.townCity
                                                            ?
                                                                <div
                                                                    onClick = { (e) => {
                                                                        updateEditableLocationInformation(true);
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }}
                                                                    style = {{
                                                                        display: "inline-block"
                                                                        , width: "100%"
                                                                        , height: "100%"
                                                                    }}
                                                                    >
                                                                    { address?.townCity }
                                                                </div>
                                                            : <span>&nbsp;</span>
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                <label>State</label>
                                                <div className = { "input-box" }>
                                                    {
                                                        editableLocationInformation
                                                        ?
                                                            <input
                                                                type="text"
                                                                name="countyStateProvince"
                                                                id="countyStateProvince"
                                                                dataaddressindex = { index }
                                                                //placeholder="State"
                                                                value = { address?.countyStateProvince != null ? address?.countyStateProvince : '' }
                                                                //disabled = { !confirmedTerm }
                                                                onChange = { handleChangeAddress }
                                                                />
                                                        :
                                                            address?.countyStateProvince
                                                            //? address?.countyStateProvince
                                                            ?
                                                                <div
                                                                    onClick = { (e) => {
                                                                        updateEditableLocationInformation(true);
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }}
                                                                    style = {{
                                                                        display: "inline-block"
                                                                        , width: "100%"
                                                                        , height: "100%"
                                                                    }}
                                                                    >
                                                                    { address?.countyStateProvince }
                                                                </div>
                                                            : <span>&nbsp;</span>
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                <label>ZIP/Postal Code</label>
                                                <div className = { "input-box" }>
                                                    {
                                                        editableLocationInformation
                                                        ?
                                                            <input
                                                                type = "text"
                                                                name = "postalCode"
                                                                id = "postalCode"
                                                                dataaddressindex = { index }
                                                                //placeholder = "ZIP/Postal Code"
                                                                value = { address?.postalCode != null ? address?.postalCode : '' }
                                                                //disabled = { !confirmedTerm }
                                                                onChange = { handleChangeAddress }
                                                                />
                                                        :
                                                            address?.postalCode
                                                            //? address?.postalCode
                                                            ?
                                                                <div
                                                                    onClick = { (e) => {
                                                                        updateEditableLocationInformation(true);
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }}
                                                                    style = {{
                                                                        display: "inline-block"
                                                                        , width: "100%"
                                                                        , height: "100%"
                                                                    }}
                                                                    >
                                                                    { address?.postalCode }
                                                                </div>
                                                            : <span>&nbsp;</span>
                                                    }
                                                </div>
                                            </div>

                                            {/*
                                            <input type="hidden" name="countryCode" id="countryCode" value="US" />
                                            */}
                                            <div>
                                                <label>Country</label>
                                                <div className = { "input-box" }>
                                                    {
                                                        editableLocationInformation
                                                        ?
                                                            <select
                                                                //className={styles.formControl}
                                                                name = 'countryCode'
                                                                dataaddressindex = { index }
                                                                //value={fields?.country}
                                                                //value = { address?.countryCode }
                                                                value = { address?.countryCode != null ? address?.countryCode : '' }
                                                                //onChange={e => onHandleChange(e)}
                                                                onChange = { handleChangeAddress }
                                                                >
                                                                <option value=''>Select the Country</option>
                                                                {
                                                                    country?.map((each, i) => (
                                                                        <option key={i} value={each?.code}>
                                                                            {each?.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        //: nbsp
                                                        //: address?.countryCode ? address?.countryCode : <span>&nbsp;</span>
                                                        :
                                                            address?.countryCode
                                                            //? getCountryName(address?.countryCode)
                                                            ?
                                                                <div
                                                                    onClick = { (e) => {
                                                                        updateEditableLocationInformation(true);
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }}
                                                                    style = {{
                                                                        display: "inline-block"
                                                                        , width: "100%"
                                                                        , height: "100%"
                                                                    }}
                                                                    >
                                                                    { getCountryName(address?.countryCode) }
                                                                </div>
                                                            : <span>&nbsp;</span>
                                                        
                                                    }
                                                    {error?.country && (<label className={styles.error}>{error?.country}</label>)}
                                                </div>
                                            </div>
                                        {/*</div>*/}
                                        </>
                                    ) : 
                                        <>
                                        
                                        <div>
                                            <label>Address line 1 (New)</label>
                                            <div className = { "input-box" }>
                                                {
                                                    editableLocationInformation
                                                    ?
                                                        <input
                                                            type="text"
                                                            name = "addressLine1"
                                                            id = "addressLine1"
                                                            //placeholder = "Address line 1"
                                                            //value = { userDetails?.addresses?[0]?.addressLine1? }
                                                            value = { userDetails?.addressLine1 }
                                                            //value = { userDetails?.addresses?.length }
                                                            onChange = { handleChange }
                                                            //disabled = { !confirmedTerm }
                                                            />
                                                    //: nbsp
                                                    : userDetails?.addressLine1 ? userDetails?.addressLine1 : nbsp
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <label>Address line 2</label>
                                            <div className = { "input-box" }>
                                                {
                                                    editableLocationInformation
                                                    ?
                                                        <input
                                                            type="text"
                                                            name="addressLine2"
                                                            id="addressLine2"
                                                            //placeholder="Address line 2"
                                                            onChange = { handleChange }
                                                            //value = { address?.addressLine2 }
                                                            value = { userDetails?.addressLine2 }
                                                            //disabled = { !confirmedTerm }
                                                            />
                                                    //: nbsp
                                                    : userDetails?.addressLine2 ? userDetails?.addressLine2 : nbsp
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <label>Town or City</label>
                                            <div className = { "input-box" }>
                                                {
                                                    editableLocationInformation
                                                    ?
                                                        <input
                                                            type="text"
                                                            name="townCity"
                                                            id="townCity"
                                                            //placeholder="Town / City"
                                                            onChange = { handleChange }
                                                            //value = { address?.townCity }
                                                            value = { userDetails?.townCity }
                                                            />
                                                    //: nbsp
                                                    : userDetails?.townCity ? userDetails?.townCity : nbsp
                                                }
                                            </div>
                                        </div>

                                        <div>
                                            <label>State, County or Province</label>
                                            <div className = { "input-box" }>
                                                {
                                                    editableLocationInformation
                                                    ?
                                                        <input
                                                            type = "text"
                                                            name = "countyStateProvince"
                                                            id = "countyStateProvince"
                                                            //placeholder = "ZIP/Postal Code"
                                                            onChange = { handleChange }
                                                            value = { userDetails?.countyStateProvince }
                                                            />
                                                    //: nbsp
                                                    : userDetails?.countyStateProvince ? userDetails?.countyStateProvince : nbsp
                                                }
                                            </div>
                                        </div>

                                        <div>
                                            <label>Zip/Postal Code</label>
                                            <div className = { "input-box" }>
                                                {
                                                    editableLocationInformation
                                                    ?
                                                        <input
                                                            type = "text"
                                                            name = "postalCode"
                                                            id = "postalCode"
                                                            //placeholder = "ZIP/Postal Code"
                                                            onChange = { handleChange }
                                                            value = { userDetails?.postalCode }
                                                            />
                                                    //: nbsp
                                                    : userDetails?.postalCode ? userDetails?.postalCode : nbsp
                                                }
                                            </div>
                                        </div>

                                        <div>
                                            <label>Country</label>
                                            <div className = { "input-box" }>
                                                {
                                                    editableLocationInformation
                                                    ?
                                                        <select
                                                            //className={styles.formControl}
                                                            name='countryCode'
                                                            //value={fields?.country}
                                                            value = { userDetails?.countryCode }
                                                            //onChange={e => onHandleChange(e)}
                                                            //onChange = { e => onHandleChange(e) }
                                                            onChange = { handleChange }
                                                            >
                                                            <option value=''>Select the Country</option>
                                                            {
                                                                country?.map((each, i) => (
                                                                    <option
                                                                        key = { i }
                                                                        //value = { each?.name }
                                                                        value = { each?.code }
                                                                        >
                                                                        {each?.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    //: nbsp
                                                    :
                                                        userDetails?.countryCode
                                                            ? getCountryName(userDetails?.countryCode)
                                                            : <span>&nbsp;</span>
                                                }
                                                {/*error?.country && (<label className={styles.error}>{error?.country}</label>)*/}
                                            </div>
                                        </div>

                                        </>
                                }

                                {
                                    editableLocationInformation
                                    &&
                                        <div style = {{ flexBasis: "100%" }}>
                                            <div
                                                className = { "button-container" }
                                                style = {{
                                                    display: "flex"
                                                    , justifyContent: "center"
                                                    //, padding: "10px 0px 0px 0px"
                                                }}
                                                >
                                                <div className = { "button-wrapper" }>
                                                    <button
                                                        type = "submit"
                                                        value = "Save"
                                                        style = {{ color: "#fff" }}
                                                        //className = { "gold-background" }
                                                        className = { "theme-success large" }
                                                        >
                                                        Save
                                                    </button>
                                                </div>

                                                {/*
                                                <div className = { "button-wrapper" }>
                                                    <button
                                                        className = { "gilded large curved" }
                                                        onClick = { () => history.push("/user-profile") }
                                                        >
                                                        Cancel
                                                    </button>
                                                </div>
                                                */}

                                            </div>
                                        </div>
                                }
                            </div>

                            {/*
                            <div style = {{ flexBasis: "100%" }}>
                                <div className = { "table" } style = {{ maxWidth: "600px", margin: "auto" }}>
                                    <div className = { "row" }>
                                        <div className = { "cell" }>
                                            <input
                                                type = "checkbox"
                                                id = "terms_of_service"
                                                name = "terms_of_service"
                                                //value = "Create Profile"
                                                onChange = { handleChange }
                                                style = {{
                                                    width: "20px"
                                                    , height: "20px"
                                                    , verticalAlign: "middle"
                                                    , border: "2px solid #E6E8EC"
                                                }}
                                                />
                                        </div>
                                        <div className = { "cell" }>
                                            <label
                                                for="terms_of_service"
                                                style = {{
                                                    paddingLeft: "10px"
                                                    , userSelect: "none"
                                                    , paddingBottom: "0px"
                                                }}
                                                >
                                                By clicking here you understand our <Link to="/terms">Terms of Service</Link> and to our <Link to="/terms">Privacy Policy</Link>.
                                                Please read these carefully before using the Site. 
                                            </label>
                                            {
                                                error?.terms_of_service
                                                && <div className = { styles.errorMsg }>{ error?.terms_of_service }</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}

                            {/*
                            <div style = {{ flexBasis: "100%" }}>
                                Already have an account ? &nbsp;<a href="" className = { "gold" }>Login</a>
                            </div>
                            */}

                            {/*
                            <div style = {{ flexBasis: "100%" }}>
                                <div
                                    className = { "button-container" }
                                    style = {{
                                        display: "flex"
                                        , justifyContent: "center"
                                        , padding: "26px 0px"
                                    }}
                                    >
                                    <div className = { "button-wrapper" }>
                                        <button
                                            type = "submit"
                                            value = "Save"
                                            style = {{ color: "#fff" }}
                                            //className = { "gold-background" }
                                            className = { "theme-success" }
                                            >
                                            Update Profile
                                        </button>
                                    </div>

                                    <div className = { "button-wrapper" }>
                                        <button
                                            className = { "gilded large curved" }
                                            onClick = { () => history.push("/user-profile") }
                                            >
                                            Cancel
                                        </button>
                                    </div>

                                </div>
                            </div>
                            */}

                        {/*
                        </div>
                        */}

                    </form>
                </div>
            </section>
        </main>
    );
};

export default UserProfile;
