import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import counterReducer from "../redux/counterSlice";
import cartReducer from "../redux/cartSlice";
import keyReducer from '../redux/keySlice'
import assetReducer from '../redux/assetSlice'
import notificationSlice from '../redux/notificationSlice'
import userSlice from '../redux/userSlice'
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';

const persistConfig = {
  key: "root",
  storage
};

const reducers = combineReducers({
  counter: counterReducer,
  keyVal : keyReducer,
  cart: cartReducer,
  asset : assetReducer,
  notification : notificationSlice,
  user : userSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})
