import
    React
    , {
          useState
        //, forwardRef
        //, useRef
        //, useImperativeHandle
    }
    from "react";

import {
    useHistory
    //, Link
} from "react-router-dom";

import OutsideClickHandler from "react-outside-click-handler";
//import styles from "./User/User.module.sass";
//import Swal from "sweetalert2";
import { axiosInstance } from "../../utils/API";
//import Subcategories from "./Subcategories";
import cn from 'classnames';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';

const CategoriesSelect = (props) => {
//const CategoriesSelect = forwardRef((props, ref) => {
//const CategoriesSelect = forwardRef((props, selectCategoriesReference) => {

    console.warn("CategoriesSelect(props)", props);
    //console.warn("props.categoryObjectArray = ", props.categoryObjectArray);
    //console.warn("props.categoryObjectArray?.length = ", props.categoryObjectArray?.length);
    //console.log("CategoriesSelect(props, ref)", props, ref)
    //console.log("CategoriesSelect(props, ref)", props, selectCategoriesReference);

    //alert('ref = '+ ref);
    //console.log('selectCategoriesReference = '+ selectCategoriesReference);

    //const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [subVisible, setSubVisible] = useState(false);
    //const [categoryObjectArray, setCategoryObjectArray] = useState([]);
    let [subCategories, setSubcategory] = useState([]);

    /*
    const getConfig = () => {
        console.log("CategoriesSelect.getConfig()")

        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("userToken")
            }
        };
        return config;
    };
    */

    //const callCategoriesSelect = async () => {
    //    console.log("CategoriesSelect: callCategoriesSelect()")
    //    alert("CategoriesSelect: callCategoriesSelect()")
    //};

    /*
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    //useImperativeHandle(ref, () => ({
    useImperativeHandle(selectCategoriesReference, () => ({

        getAlert() {
            alert("getAlert from Child");
        }

    }));
    */

    /*
    const getCategoryObjectArray = async () => {
        console.log("CategoriesSelect: getCategoryObjectArray()")

        //const result = await axiosInstance.get("/asset/categories?page=1&limit=100")
        axiosInstance.get("/asset/categories?page=1&limit=100")
            .then((result) => {
                console.log("result", result);
                console.log("result?.data?.result?.categories", result?.data?.result?.categories);
                console.log("result?.data?.result?.categories?rows", result?.data?.result?.categories?.rows);
                let categories = result?.data?.result?.categories?.rows?.map(
                    (category) => {
                        return {
                            name: category.name
                            , id: category.id
                            , subCategories: category.subCategories
                        }
                    }
                );
                console.log("categories = ", categories)
                console.log("categories.length = " + categories.length)
                if (categories?.length) {
                    //setCategoryObjectArray([{ name: "All", categories: "" }, ...categories]);
                    setCategoryObjectArray([...categories]);
                } else {
                    setCategoryObjectArray([])
                }
                console.log("categoryObjectArray", categoryObjectArray)
                console.log("typeof categoryObjectArray", typeof categoryObjectArray)
            })
            .catch((err) => {
            });
    };

    React.useEffect(() => {
        getCategoryObjectArray();
    }, []);
    */

    /*
    const handleSubCat = (e) => {
      setVisible(false)
    };

    const handleSubCategory = (e, category) => {
      console.log("handleSubCategory(e:" + e + ", category:" + category + ")")
      const selectedCategory = categoryObjectArray.find(item => item?.name === category.name);
      const subCategories = selectedCategory.subCategories
      if (subCategories) {
        setSubcategory(subCategories);
        setSubVisible(true);
      } else {
        setSubcategory({ name: "All", subCategories: false })
        setSubVisible(false);
      }
    };
    */

    const handleClose = () => {
      setVisible(false);
      setSubcategory({});
    };

    const toggleVisible = () => {
      setVisible(!visible);
      setSubcategory({});
    };

    /*
    const closeDrawerHandler = (category) => {
      if (category.subCategories == '') props.closeDrawer()
    };
    */

    /*
    const ConsoleLog = ({ children }) => {
      return null;
    };
    */

    const handleChangeCategory = (event) => {
        console.warn('CategoriesSelect.handleChangeCategory(event)', event);

        console.warn('event.target = ', event.target);
        console.warn('event.target.checked = ', event.target.checked);
        console.warn('event.target.value = ', event.target.value);

        props.onChangeCategory(event);
        //let categoryIds = props.onChangeCategory(event);
        //console.warn('categoryIds = ', categoryIds);
        //props.categoryIds = categoryIds;
        //props.setCategoryIds(categoryIds);

        /*
        let inputCheckbox = event.target;
        console.warn('inputCheckbox = ', inputCheckbox);

        //let selectedCategoryName = e.target.name;
        let selectedCategoryId = event.target.value;
        console.warn('selectedCategoryId = ', selectedCategoryId);
        //console.warn('selectedCategoryName', selectedCategoryName);
        let selectedCategoryIdNumber = Number(selectedCategoryId);
        console.warn('selectedCategoryIdNumber = ', selectedCategoryIdNumber);

        //let categoryObjectArray = props.categoryObjectArray;
        //console.warn('categoryObjectArray = ', categoryObjectArray);

        //let indexOfCategoryId = categoryObjectArray.indexOf(selectedCategoryIdNumber);
        //console.warn('indexOfCategoryId', indexOfCategoryId);
        //const selectedCategoryIndex = props.categoryObjectArray.findIndex((category) => category.id == selectedCategoryId);
        //console.warn('selectedCategoryIndex = ', selectedCategoryIndex);
        const selectedCategory = props.categoryObjectArray.find((category) => category.id == selectedCategoryId);
        console.warn('selectedCategory = ', selectedCategory);
        console.warn('selectedCategory.checked = ', selectedCategory.checked);

        const { id, name} = selectedCategory;
        console.warn(`Category with id ${selectedCategoryId} is ${id} ${name}`);

        console.warn('inputCheckbox.checked = ', inputCheckbox.checked);
        console.warn('Before: selectedCategory.checked = ', selectedCategory.checked);
        if (inputCheckbox.checked) {
            //selectedCategory.checked = false;
            selectedCategory.checked = true;
        } else {
            //selectedCategory.checked = 'checked';
            //selectedCategory.checked = true;
            selectedCategory.checked = false;
        }
        console.warn('After: selectedCategory.checked = ', selectedCategory.checked);

        //props.setCategoryObjectArray(props.categoryObjectArray);
        //this.forceUpdate();
        //this.setState(this.state);
        */
    };

    const onChangeCategoryBak = (event) => {
        console.log('CategoriesSelect.onChangeCategory(event)', event);

        console.log('event.target', event.target);
        console.log('event.target.checked', event.target.checked);
        console.log('event.target.value', event.target.value);

        let inputCheckbox = event.target;
        console.log('inputCheckbox = ', inputCheckbox);

        //let selectedCategoryName = e.target.name;
        let selectedCategoryId = event.target.value;
        console.log('selectedCategoryId = ', selectedCategoryId);
        //console.log('selectedCategoryName', selectedCategoryName);
        let selectedCategoryIdNumber = Number(selectedCategoryId);
        console.log('selectedCategoryIdNumber = ', selectedCategoryIdNumber);

        let categoryObjectArray = props.categoryObjectArray;
        console.log('categoryObjectArray = ', categoryObjectArray);

        //let indexOfCategoryId = categoryObjectArray.indexOf(selectedCategoryIdNumber);
        //console.log('indexOfCategoryId', indexOfCategoryId);
        //const searchIndex = carList.findIndex((car) => car.model=="X5");
        const selectedCategoryIndex = categoryObjectArray.findIndex((category) => category.id == selectedCategoryId);
        console.log('selectedCategoryIndex = ', selectedCategoryIndex);
        const selectedCategory = categoryObjectArray.find((category) => category.id == selectedCategoryId);
        console.log('selectedCategory = ', selectedCategory);
        console.log('selectedCategory.checked = ', selectedCategory.checked);

        const { id, name} = selectedCategory;
        console.log(`Category with id ${selectedCategoryId} is ${id} ${name}`);

        console.log('inputCheckbox.checked = ', inputCheckbox.checked);
        if (inputCheckbox.checked) {
            //selectedCategory.checked = false;
            selectedCategory.checked = true;
        } else {
            //selectedCategory.checked = 'checked';
            //selectedCategory.checked = true;
            selectedCategory.checked = false;
        }

        //const selectedCategory = categoryObjectArray.find(item => item?.name === selectedCategoryName);
        //const selectedCategory = categoryObjectArray.find(item => item?.id === selectedCategoryId);
        //console.log('selectedCategory', selectedCategory);

        props.onChangeCategory(event);
    };

    return (
        <React.Fragment>
            <OutsideClickHandler onOutsideClick={() => handleClose()}>
                <div
                    className = 'select'
                    onClick={toggleVisible}
                    //className={styles.categories}
                    style = {{
                        cursor: "pointer"
                        , userSelect: "none"
                        , whiteSpace: "nowrap"
                        //, paddingLeft: '15px'
                        //, paddingRight: '15px'
                    }}
                    >

                    <div
                        style = {{
                            paddingLeft: '15px'
                            , paddingRight: '15px'
                        }}
                        >

                        <div className = 'table'>
                            <div className = 'row'>

                                <div className = 'cell' style = {{ verticalAlign: 'middle' }}>
                                    Property Type
                                </div>

                                <div
                                    className = 'cell'
                                    style = {{
                                        verticalAlign: 'middle'
                                        , width: '50px'
                                    }}>

                                    {
                                        props.categoryIds.length > 0
                                        &&
                                        <div
                                            className = 'badge'
                                            //style = {{
                                                //color: var(--color-gray-white, var(--White, #FFF));
                                                //text-align: center;
                                                //font-family: Source Sans 3;
                                                //font-size: 14px;
                                                //font-style: normal;
                                                //font-weight: 700;
                                                //line-height: 100%; 
                                                //marginLeft: '5px'
                                                //, paddingRight: '25px'
                                            //}}
                                            >
                                            {/*
                                            { categoryObjectArray?.length }
                                            */}
                                            { props.categoryIds.length }
                                        </div>
                                    }
                                </div>

                                <div className = 'cell' style = {{ verticalAlign: 'middle' }}>
                                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ paddingLeft: "5px" }}>
                                        <path d="M12 1L6.5 6L1 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*
                    <div
                        //style = {{ paddingRight: '10px' }}
                        >
                        <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ paddingLeft: "5px" }}>
                            <path d="M12 1L6.5 6L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ paddingLeft: "5px" }}>
                            <path d="M12 1L6.5 6L1 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    */}
                                
                </div>
                {
                    visible
                    && (
                        <>
                        <div
                            id="categories-dropdown-menu"
                            /*
                            style={{
                              position: "absolute"
                              //, padding: "16px"
                              //, marginLeft: "-10px"
                              , marginTop: "10px"
                              , backgroundColor: "rgb(255, 255, 255)"
                              , filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 4px)"
                              , boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 4px"
                              //, borderRadius: "24px"
                              , display: "block"
                              , zIndex: "9"
                            }}
                            */
                            >

                            <div
                                id = "CategoriesSelect#flex-container"
                                className = { "flex-container" }
                                style={{
                                    justifyContent: "start"
                                    //, rowGap: "5px"
                                    , padding: '3px 0px'
                                }}>

                                {
                                    props.categoryObjectArray?.length
                                    ? props.categoryObjectArray
                                        .sort((a, b) => a.id - b.id)
                                        //.sort((a, b) => a.priority - b.priority)
                                        .map((category, index) =>

                                        <>

                                        { console.warn(category) }

                                        <div
                                            id = { index }
                                            key = { index }
                                            className = { "category" }
                                            style = {{
                                                padding: '5px 8px'
                                            }}>

                                            {/*
                                            <div
                                                className = 'table'
                                                style={{ cursor: "pointer", userSelect: "none" }}
                                                >
                                                <div className={"row"}>
                                                    <div className = 'cell checkbox'>
                                                        <input
                                                            type = 'checkbox'
                                                            className = 'checkbox'
                                                            //name = { category.id }
                                                            id = { category.id }
                                                            name = { 'categories' }
                                                            value = { category.id }
                                                            checked = { category.checked }
                                                            //onChange = { e => { console.log('onChange()', e) }}
                                                            //onChange = { e => { props.selectCategory(e) }}
                                                            //onChange = { e => { selectCategory(e) }}
                                                            onChange = { e => { onChangeCategory(e) }}
                                                            />
                                                    </div>
                                                    <div className={"cell"}>
                                                        <label htmlFor = { category.id }>
                                                            { category.name }
                                                        </label>
                                                    </div>
                                                    <div className = {
                                                            cn(
                                                                  'cell'
                                                                , 'dot'
                                                                //, category.name
                                                            )
                                                        }>
                                                        <div>
                                                            <span
                                                                //className = {"dot"}
                                                                className = { cn('dot', category.name) }
                                                                ></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            */}

                                            <label
                                                htmlFor = { category.id }
                                                //className = 'primary-inverse'
                                                style = {{
                                                    whiteSpace: 'nowrap'
                                                    //, width: '100%'
                                                    , display: 'flex'
                                                    , alignItems: 'center'
                                                    , borderRadius: '5px'
                                                    , padding: '12px'
                                                    , fontSize: '20px'
                                                    //, letterSpacing: '1px'
                                                    , fontFamily: "'Source Sans 3'"
                                                }}
                                                className = {
                                                    cn(
                                                        'mobile-nav-item'
                                                        , category.name
                                                        , 'medium'
                                                        , 'text-shadow'
                                                    )
                                                }
                                                //checked = { category.checked }
                                                //onChange = { e => { onChangeCategory(e) }}

                                                /*
                                                onClick = { e => {
                                                    console.warn('onClickCategorySelect()');
                                                    console.warn('onClickCategorySelect(e)', e);
                                                    console.warn('Before: category.checked = ', category.checked);
                                                    if (category.checked) {
                                                        category.checked = false;
                                                    } else {
                                                        category.checked = true;
                                                        //categoryIds.pop(category.id);
                                                    }
                                                    console.warn('After: category.checked = ', category.checked);

                                                    e.stopPropagation(); //
                                                    e.preventDefault();
                                                    //category.checked = !category.checked;
                                                }}
                                                */
                                                >
                                                <div
                                                    //type = "checkbox"
                                                    style = {{
                                                        flexBasis: '50px'
                                                    }}>

                                                    <input
                                                        type = "checkbox"
                                                        style = {{
                                                             width: '20px'
                                                            , height: '20px'
                                                            , verticalAlign: 'middle'
                                                            //, flexBasis: '50px'
                                                            //, boxShadow: 'inset -3px -3px 3px 0px rgba(0, 0, 0, 0.25)'
                                                            , boxShadow: 'inset -1px -1px 3px 2px rgba(0, 0, 0, 0.25)'
                                                            , display: 'none'
                                                            , visibility: 'hidden'
                                                        }}
                                                        className = 'checkbox'
                                                        id = { category.id }
                                                        name = { 'categories' }
                                                        value = { category.id }
                                                        //checked = { category.checked }
                                                        //checked = { category.checked ? 'checked' : '' }
                                                        checked = { category.checked ? true : false }
                                                        //defaultChecked = { category.checked }
                                                        //onChange = { e => { onChangeCategory(e) }}
                                                        onChange = { handleChangeCategory }
                                                        />
                                                    
                                                    {
                                                        category.checked
                                                        ? <CheckBoxIcon
                                                            style = {{
                                                                  width: '30px'
                                                                , height: '30px'
                                                                //, color: 'red'
                                                            }} />
                                                        : <CheckBoxOutlineBlank
                                                            style = {{
                                                                  width: '30px'
                                                                , height: '30px'
                                                                //, color: 'red'
                                                            }} />
                                                    }
                                                    
                                                </div>

                                                { category.name }

                                            </label>

                                        </div>
                                        </>
                                    )
                                    : <p
                                        //className={styles.error}
                                        >No category found</p>
                                }
                            </div>
                        </div>
                        </>
                    )
                }
            </OutsideClickHandler>
        </React.Fragment>
    )
};
//});

export default CategoriesSelect
