/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      account: null,
      balance: 0,
      userId: null,
      email: null,
      network: null,
    }
  },
  reducers: {
    setUser: (state, data) => {
      state.user =  data.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
