/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const assetSlice = createSlice({
  name: 'asset',
  initialState: {
    searchText: ''
  },
  reducers: {
    searchAssets: (state, data) => {
      state.searchText = data.payload;
    },
    clearSearchAssets: (state, data) => {
      state.searchText = '';
    },
  }
});

// Action creators are generated for each case reducer function
export const { searchAssets, clearSearchAssets } = assetSlice.actions;

export default assetSlice.reducer;
