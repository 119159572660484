export const getPagePosition = function(element) {
    const rect = element.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX
        , top: rect.top + window.scrollY
    };
};

export const getFixedPosition = function(element) {
    const rect = element.getBoundingClientRect();
    return {
        left: rect.left
        , top: rect.top
    };
};

export const getFixedPosition2 = function(element) {
    var offset = element.offset();
    var windowDocument = window.document;
    return {
        left: offset.left - windowDocument.scrollLeft(),
        top: offset.top - windowDocument.scrollTop()
    };
};