import React
    //, { useState, useEffect }
from "react";
import styles from './ReferredAffiliates.module.sass';
//import cn from "classnames";
import { useHistory, Link } from "react-router-dom";
//import { useSelector } from 'react-redux';
import AffiliateTable from "./AffiliateTable";
//import { CircularProgress } from "@material-ui/core";
//import { CircularProgress } from '@mui/material';
import { NAMES_CONSTANTS } from '../../constants';

//const ReferredAffiliates = () => {
const ReferredAffiliates = (props) => {
    console.log('ReferredAffiliates()', props);

    console.log('props.userDetails = ', props.userDetails);
    /*
    let localStorageKeyName = NAMES_CONSTANTS.USER_WALLET_DETAILS;
    console.log('       localStorageKeyName = ', localStorageKeyName);
    console.log('       localStorage.getItem('+localStorageKeyName+') = ', localStorage.getItem(localStorageKeyName));
    const userDetail = JSON.parse(localStorage.getItem(localStorageKeyName));
    console.log('       userDetail = ', userDetail);
    //const userId = userDetail.userId;
    */
    let userId;
    //if (userDetail) {
    if (props.userDetails) {
        //userId = userDetail.userId;
        userId = props.userDetails?.id;
        console.log('       userId = ', userId);
    }
    //localStorage.setItem(localStorageKeyName, JSON.stringify({ ...detail, balance }));
    //console.log('       localStorage.getItem(\''+localStorageKeyName+'\')', localStorage.getItem(localStorageKeyName));

    //const history = useHistory()
    //const [activeIndex, setActiveIndex] = useState(0);
    //const loginStatus = useSelector((state) => state.counter.value);
    const loginStatus = true;
    
    /*
    let navLinks = [
          "All"
        , "Level 1"
        , "Level 2"
        , "Level 3"
    ];
    */

    /*
    const handleClickBackButton = () => {
        alert('handleClickBackButton()');
    };

    const clickLogin = () => {
    };
    */

    return (

        /*
        <main>
            <section>
                <div className = { cn("container", "max-width") }>
        */
        <main className = 'edit-profile'>
            <section
                className = 'form'
                style = {{
                    backgroundColor: "var(--color-gray-100)"
                }}>
                <div className = 'max-width'>

                    {/*
                    <form className = 'max-width'>
                    */}

                    {/*
                    <div
                        style = {{
                            paddingBottom: "30px"
                        }}>

                        <button
                            type = "button"
                            className = { cn("rounded-clear", "light") }
                            //onClick = { handleClickBackButton }
                            onClick = { () => history.push("/my-assets") }
                            style = {{
                                  padding: "10px 15px"
                                , border: "2px solid #E6E8EC"
                            }}>
                            <div className = { "table" }>
                                <div className = { "cell" }><img src="images/icons/left-arrow-icon.png" style = {{ verticalAlign: "middle" }} /></div>
                                <div className = { "cell" } style = {{ verticalAlign: "middle", paddingLeft: "15px" }}>Back to Profile</div>
                            </div>
                        </button>

                    </div>
                    */}

                    {/*
                    <h1>
                        Referred Affiliates
                    </h1>
                    */}
                    <div
                        className = { "heading" }
                        style = {{
                            display: 'flex'
                            , flexDirection: 'row'
                            , flexWrap: 'wrap'
                            //, justifyContent: 'space-between'
                            , justifyContent: 'flex-start'
                        }}>
                        <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <h1 style = {{ display: 'inline-block' }}>
                                Referred Affiliates
                            </h1>
                        </div>
                        <div style = {{ paddingLeft: '30px' }}>
                            <img
                                //src = '/images/icons/referrals-icon-28.jpg'
                                src = '/images/icons/referred-affiliates-1.png'
                                alt = 'Referred Affiliates'
                                style = {{
                                    //width: '50px'
                                    height: '70px'
                                    //, filter: 'var(--filter-theme-primary)'
                                }}
                                />
                        </div>
                    </div>

                    <div
                        className = { "subtitle" }
                        //style = {{
                        //    paddingTop: "30px"
                        //    , paddingBottom: "30px"
                        //    , margin: 'auto 50px'
                        //}}
                        >
                        <p>
                            People you have referred to RealSplit using your <Link to="/my-referral-link" style = {{ cursor: "pointer" }}>referral link</Link>.
                        </p>

                        <p
                            //style = {{
                            //    color: 'var(--color-gray-600)'
                            //    , margin: 'auto 50px'
                            //}}
                            >
                            {/*
                            Simply share your unique
                            &nbsp;<Link to="/my-referral-link" style = {{ cursor: "pointer" }}>referral link</Link>&nbsp;
                            <Link to="/my-referral-link" style = {{ cursor: "pointer" }}> referral link </Link>
                            */}
                            Anyone can be an affiliate (referrer) at RealSplit.
                            Simply share your unique referral link with your contacts and when they sign up, using your link and buy splits you will earn a commission on the fees from their transactions.
                            Furthermore, when the person you referred (your referee) refers others to RealSplit, you will earn a commission on their purchases too.
                            Rewards are earned on up to 3 levels of referral connections.
                            {/*
                            <br />There are up to 3 levels of referral connections.
                            <br />Rewards are paid up to 3 levels of affiliates, so when your referrals refer others, you will receive commission on their purchases too.
                            */}
                        </p>
                    </div>

                    {
                        loginStatus
                        ? <div
                            //className = { styles.wrapper }
                            className = "flex-container"
                            style = {{
                                marginTop: '30px'
                                , justifyContent: 'flex-end'
                            }}
                            >

                            <AffiliateTable
                                //index = { activeIndex }
                                //setActiveIndex = { setActiveIndex }
                                userId = { userId }
                                />

                        </div>
                        
                        : <div style = {{ padding: '20px 0px' }}>
                            <button
                                type = { 'button' }
                                className = 'primary'
                                //onClick = { () => clickLogin() }
                                //onClick={() => history.push('/login')}
                                onClick={() => props.chooseWalletType()}
                                >
                                Login
                            </button>
                             &nbsp;&nbsp;
                             to view Details
                        </div>
                    }

                    {/*
                    </form>
                    */}
                </div>
            </section>
        </main>
    )
}

export default ReferredAffiliates