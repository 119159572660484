import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Web3 from "web3";
import moment from 'moment';
// import cn from "classnames";
// import parse from "html-react-parser";
// import axios from "axios";
// import ReactPlayer from "react-player";
 import { Link } from "react-router-dom";
// import Web3Modal from "web3modal";
// import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share'

import styles from "./ItemAcceptance.module.sass";
//import headerStyles from "../../components/Header/Header.module.sass";
import {
    axiosInstance
    //, axiosInstanceBlockChain
} from "../../utils/API";
import { base_url } from './../../controller/utils';
// import Info from "./Info";
// import PurchaseInfo from "./PurchaseInfo";
// import FullScreen from "./../FullSreen";
// import Counter from "../counter/Counter";
// import { base_url, checkNetwork, connector, getBalanceToken, PopUpAlert, magic, Web3Provider, getProvider} from './../../controller/utils';

import {
    PopUpAlert
    , connector
    //,  contract_auction_abi
    //, contract_erc20token_abi
    //, erc20addess
    //, contract_address
    //, widgetMatic
    //, Web3Provider
    //, getBalanceToken
    //, getProvider
} from "../../controller/utils";
import { decryptData } from "../../utils/decrypt";
import { ITEM_ACCEPTANCE, NAMES_CONSTANTS } from "../../constants";
// import Lightbox from "./Lightbox";
// import Loader from "./Loader";
// import BidHistory from "./BidHistory";
// import { checkNetwork } from "./../../controller/utils";
// import { decrement } from "../../redux/counterSlice";
// import ResallModal from "../userItem/ResallModal";
// import ResellModal from "../MyAssets/ResellModal";
// import Socialicon from "../../components/SocialIcons";
// import { CircularProgress } from "@material-ui/core";

// const base_url = process.env.REACT_APP_API_URL;

let navLinks;

const dataSample = {
  "id": 4323,
  "createdAt": '03/15/2023',
  "asset": {
    "id": 23,
    "name": "El Capitan Blue Gold Watch",
    "creatorName": "Craig Shelly",
    "ipfsPreviewImageUrl": "/images/products/watch-black.png"
  }
}

/*
const statusSample = [
  'processing',
  'on_delivery',
  'received',
  'success'
]
*/

const ItemAcceptance = (props) => {
  const [walletDetails, setWalletDetails] = useState(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));
  // 
  // 
  // 

  let walletDetailsObject;
  //let tokenBal;
  //let profilePicUrl;

  if (walletDetails) {
    walletDetailsObject = JSON.parse(walletDetails);
  }

  if (props?.mode !== "view") {
    navLinks = ["Info", "Description", "Creator"];
  } else {
    navLinks = ["Info", "Description", "Creator", "Purchase Info"];
  }

  //console.log("props = " + props)
  //
  //const testValue = useSelector(function(state) { alert(state); return state.counter.euroValue});
  //const testValue = useSelector((state) => { 

  const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  //const secretKey = useSelector((state) => state?.keyVal?.keyData);
  //const verifyUser = useSelector((state)=> state?.counter?.userVerifyStatus);
  let verifyUser = useSelector((state) => state?.counter?.userVerifyStatus);
  const walletStatus = useSelector((state) => state.counter.value);

  //const dispatch = useDispatch();

  //let provider, account, balance;
  let provider;

  if (!connector.connected) {
    // create new session
    connector.createSession();
  }

  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  //const socialShareUrl = "https://mcfayden-dev.devtomaster.com" + location?.pathname;
  const searchParams = new URLSearchParams(location.search);
  const orderIdParams = searchParams.get('orderId');
  const assetIdParams = searchParams.get('assetId');
  const userId = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS))?.userId;

  //const [activeIndex, setActiveIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  //const [subSubCategories, setSubSubCategories] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [orderDetail, setOrderDetail] = useState(dataSample);
  const [details, setDetails] = useState({});

  // const [error, setError] = useState(null)
  // const [address, setAddress] = React.useState("");

  const [open, setOpen] = useState(false);
  //const [active, setActive] = useState(false);
  const [loaderActive, setLoaderActive] = useState(false);
  //const [showFull, setShowFull] = useState(false);
  //const [bidding, setBidding] = useState(false);
  //const [isUpcomingBid, setIsUpcomingBid] = useState(false);
  const [productPreviews, setProductPreviews] = useState([]);
  //const [shortLimit, setShortLimit] = useState(450);
  //const [cLimit, setCLimit] = useState(500);
  //const [limit, setLimit] = useState(500);
  const [productInfo, setProductInfo] = useState(null);
  const [orderId, setOrderId] = useState(null)
  const [resalePopup, setResalePopup] = useState(false);
  const [coinPaymentReq, setCoinPaymentReq] = useState(null);
  const [fractionQuantity, setFractionQuantity] = useState(1);
  const [errorFraction, setErrorFraction] = useState(false);
  //const [isAuction, setIsAuction] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true); //show loader initially only

  const isDelivered = !!(orderDetail?.status && (orderDetail?.status === ITEM_ACCEPTANCE.ORDER_STATUS.delivered.value || orderDetail?.status === ITEM_ACCEPTANCE.ORDER_STATUS.success.value))

  useEffect(() => {
    getProductDetails(id);
    const detailInterval = setInterval(() => {
      getProductDetails(id)
    }, 30000);
    return () => clearInterval(detailInterval);
  }, [initialLoad]); // The reason for having initialLoad as dependecy is
  // because, with empty dependecy, the setinterval will
  // take only the initial values of the state hence the
  // loader inside the getProductdetails gets called everytime.

  //const checkbalance = (balance) => {
  //  return balance >= productDetails.salePrice ? true : false;
  //};

  useEffect(() => {
    if (orderIdParams) {
      getOrderDetail()
    }
  }, [orderIdParams])

  /*
  const handleCircelPayment = () => {
    const access = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    if (access) {
      history.push({ pathname: `/item/${id}/checkout`, state: { productDetails, fromPrevious: true } })
    } else {
      setOpen(false);
      PopUpAlert('error', 'Please login and proceed to checkout', 'error').then((res) => {
        document.getElementById('login_button').click();
      })
    }
  };
  */

  /*
  const handleBuy = () => {

    console.log("details.firstName = " + details.firstName)
    console.log(details.firstName)
    console.log("details?.firstName = " + details?.firstName)

    //let web3 = connector.connected
    //    ? new Web3(provider)
    //    : new Web3(Web3.givenProvider);
    //console.log('======', web3.currentProvider)
    //if ((!web3.currentProvider && !connector.connected) || !walletStatus) {
    //    startCheckoutprocess();
    //    return;
    //}
    startCheckoutprocess();
  };
  */

  const attachListener = () => {
    
    //document.addEventListener("message", (data) => 
    document.addEventListener("message", (data) => alert("Event is", data));
  };


  const showModalDialogForm = () => {
    Swal.withForm({
      title: 'Cool example'
      , text: 'Any text that you consider useful for the form'
      , showCancelButton: true
      , confirmButtonColor: '#DD6B55'
      , confirmButtonText: 'Get form data!'
      , closeOnConfirm: true
      , formFields: [
        { id: 'name', placeholder: 'Name Field' }
        , { id: 'nickname', placeholder: 'Add a cool nickname' }
      ]
      //, function(isConfirm) {
      // do whatever you want with the form data
      // // { name: 'user name', nickname: 'what the user sends' }
    })
  };

  //resell part code
  const resaleHandleClick = (e, id) => {
    e.stopPropagation();
    setOrderId(id)
    setResalePopup(true);
  };

  const closeModal = () => {
    setResalePopup(false)
  };

  const getConfig = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN),
      },
    };
    if (!localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)) {
      PopUpAlert('Alert..', 'No wallet connection found', 'error')
      return
    }
    return config
  };

  const getProductDetails = async (id) => {

    if (id) {

      let config = {
        headers: {
          Authorization: localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
        }
      };

      setLoaderActive(initialLoad)

      await axiosInstance
        .get(`/asset/view/${id}`, props.mode === "view" ? config : "")
        .then((res) => {
          
          setProductDetails(res?.data?.result?.asset);
          setProductInfo(res?.data?.result)
          //res?.data?.result?.asset.auctions?.[0]?.startDate && checkForUpcomingBid(res?.data?.result?.asset.auctions?.[0]?.startDate);
          // //
          setInitialLoad(false)
          setLoaderActive(false)
          setCategories([
            {
              category: "black",
              content: res?.data?.result?.asset.category?.name,
            }
          ]);

          setSubCategories([
            {
              category: "black",
              content: res?.data?.result?.asset.subCategory?.name,
            }
          ]);

          /*
          setSubSubCategories([
            {
              category: 'black',
              content: res?.data?.result?.league?.name
            },
            {
              category: 'black',
              content: res?.data?.result?.team?.name
            }
          ]);
          */
          
          setProductPreviews(res?.data?.result?.asset?.files);
        })
        .catch((err) => {
          setLoaderActive(false)
          PopUpAlert("Alert!", err.response?.data?.message, "error").then(
            (res) => history.push("/")
          );
        }
        ); // axiosInstance.get()
    } // if (id)
  }; // getProductDetails(id)

  const handleEditionChange = (e) => {
    // console.log(e.target.value,'value')
    const value = e.target.value;
    setFractionQuantity(value)
    if (value !== '') {
      setErrorFraction('')
    }
  };

  const preventNonNumericalInput = (e) => {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    const regex = !charStr.match(/^[0-9]+$/) && charStr !== '.' && charCode !== 8;
    if (regex) e.preventDefault();
  }

  useEffect(() => {
    console.log(coinPaymentReq)
  }, [coinPaymentReq])

  useEffect(() => {
    if (props.mode === "view" && !location?.state?.fromPrevious) {
      history.push("/item")
    }
  }, [])

  const resaleFlag = () => {
    if (productDetails?.isFractional) {
      if (productInfo?.userId == `${userId}`) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  };

  const checkIfMine = () => {
    if (productDetails?.isFractional) {
      return false
    } else {
      if (`${userId}` != productDetails?.purchasedBy) {
        return true
      } else {
        return false
      }
    }
  };

  const getOrderDetail = async () => {
    const config = getConfig()
    const res = await axiosInstance.get(`/orders/${orderIdParams}`, config)

    if (res.data) {
      setOrderDetail(res.data?.result)
    } else {
      console.error(`Somthing went wrong at GET /orders/${orderIdParams}`, res)
    }
  }

  const postMintingOrder = async () => {
    const config = getConfig()
    let bodyParams = {};
    if (orderDetail?.assets && Array.isArray(orderDetail?.assets)) {
      const thisAsset = orderDetail?.assets?.find(x => x.assetId === Number(assetIdParams))
      bodyParams.assetId = thisAsset.assetId  
    } else {
      bodyParams.assetId = orderDetail?.assetId
    }
    try {
      const res = await axiosInstance.post(`orders/${orderIdParams}/minting`, bodyParams, config)
      if (res.status === 200 || res.status === '200') {
        Swal.fire({
          title: "Success!",
          text: res?.data?.message || 'Successfully! Your request have been sent.',
          icon: "success",
        })
      } else {
        Swal.fire({
          title: "Alert",
          text: res?.data?.errors?.map((err) => err.msg) || res?.data?.message || 'Something went wrong!',
          icon: "error",
        });
        console.error(`Somthing went wrong at POST /minting/${orderIdParams}`, res)
      }
    } catch (err) {
      Swal.fire({
        title: "Alert",
        text: 'Something went wrong!',
        icon: "error",
      });
      console.error(`Somthing went wrong at `, err)
    }
  }

  const showOrderStatus = (status) => {
    switch (status) {
      case ITEM_ACCEPTANCE.ORDER_STATUS.on_delivery.value: {
        return (
          <>
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                width: '50%',
                margin: 'auto',
                paddingTop: '25px',
                paddingBottom: '5px'
              }}>
              <img src='images/icons/shipping-box-icon.svg' />
              <img src='images/icons/black-shipping-truck-icon.svg' />
              <img src='/images/icons/shipping-receive-icon.png' />
            </div>
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '50%',
                margin: 'auto',
                paddingTop: '5px',
                paddingBottom: '9px'
              }}>
              <img src='images/icons/checkmark-white-on-black.svg' />
              <img src='images/icons/black-dotted-line.svg' className={styles.blackDooted} />
              <img src='images/icons/checkmark-white-on-black.svg' />
              <img src='images/icons/grey-dotted-line.svg' className={styles.blackDooted} />
              <img src='images/icons/checkmark-white-on-grey.svg' />
            </div>
            <div
              style={{
                padding: '9px 0px 18px 0px',
                textAlign: 'center',
                margin: 'auto'
              }}>
              <strong
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: '700',
                  fontSize: '18px',
                  lineHeight: '37px'
                }}>
                Your Product is on the way to your location
              </strong>
            </div>
          </>
        );
      }
      case ITEM_ACCEPTANCE.ORDER_STATUS.delivered.value: {
        return (
          <>
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                width: '50%',
                margin: 'auto',
                paddingTop: '25px',
                paddingBottom: '5px'
              }}>
              <img src='images/icons/shipping-box-icon.svg' />
              <img src='images/icons/black-shipping-truck-icon.svg' />
              <img src='/images/icons/black-shipping-receive-icon.svg' />
            </div>
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '50%',
                margin: 'auto',
                paddingTop: '5px',
                paddingBottom: '9px'
              }}>
              <img src='images/icons/checkmark-white-on-black.svg' />
              <img src='images/icons/black-dotted-line.svg' className={styles.blackDooted} />
              <img src='images/icons/checkmark-white-on-black.svg' />
              <img src='images/icons/black-dotted-line.svg' className={styles.blackDooted} />
              <img src='images/icons/checkmark-white-on-black.svg' />
            </div>
            <div
              style={{
                padding: '9px 0px 18px 0px',
                textAlign: 'center',
                margin: 'auto'
              }}>
              <strong
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: '700',
                  fontSize: '18px',
                  lineHeight: '37px'
                }}>
                Your Product is delivered
              </strong>
            </div>
          </>
        );
      }
      case ITEM_ACCEPTANCE.ORDER_STATUS.success.value: {
        return (
          <>
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                width: '50%',
                margin: 'auto',
                paddingTop: '25px',
                paddingBottom: '5px'
              }}>
              <img src='images/icons/shipping-box-icon.svg' />
              <img src='images/icons/black-shipping-truck-icon.svg' />
              <img src='/images/icons/black-shipping-receive-icon.svg' />
            </div>
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '50%',
                margin: 'auto',
                paddingTop: '5px',
                paddingBottom: '9px'
              }}>
              <img src='images/icons/checkmark-white-on-black.svg' />
              <img src='images/icons/black-dotted-line.svg' className={styles.blackDooted} />
              <img src='images/icons/checkmark-white-on-black.svg' />
              <img src='images/icons/black-dotted-line.svg' className={styles.blackDooted} />
              <img src='images/icons/checkmark-white-on-black.svg' />
            </div>
            <div
              style={{
                padding: '9px 0px 18px 0px',
                textAlign: 'center',
                margin: 'auto'
              }}>
              <strong
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: '700',
                  fontSize: '18px',
                  lineHeight: '37px'
                }}>
                Your Product is delivered
              </strong>
            </div>
          </>
        );
      }
      default:
        return (
          <>
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                width: '50%',
                margin: 'auto',
                paddingTop: '25px',
                paddingBottom: '5px'
              }}>
              <img src='images/icons/shipping-box-icon.svg' />
              <img src='images/icons/shipping-truck-icon.svg' />
              <img src='/images/icons/shipping-receive-icon.svg' />
            </div>
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '50%',
                margin: 'auto',
                paddingTop: '5px',
                paddingBottom: '9px'
              }}>
              <img src='images/icons/checkmark-white-on-black.svg' />
              <img src='images/icons/grey-dotted-line.svg' className={styles.blackDooted} />
              <img src='images/icons/checkmark-white-on-grey.svg' className={styles.blackDooted} />
              <img src='images/icons/grey-dotted-line.svg' className={styles.blackDooted} />
              <img src='images/icons/checkmark-white-on-grey.svg' className={styles.blackDooted} />
            </div>
            <div
              style={{
                padding: '9px 0px 18px 0px',
                textAlign: 'center',
                margin: 'auto'
              }}>
              <strong
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: '700',
                  fontSize: '18px',
                  lineHeight: '37px'
                }}>
                Your Product is Being Packaged
              </strong>
            </div>
          </>
        );
    }
  }

  const displayAssetImage = () => {
    if (orderDetail?.asset) {
      return (
        <img src={orderDetail?.asset?.ipfsPreviewImageUrl || orderDetail?.asset?.mediaPreviewUrl} style={{ objectFit: 'contain', width: '100%', height: 'auto' }} />
      )
    } else if (orderDetail?.assets && Array.isArray(orderDetail?.assets)) {
      const thisAsset = orderDetail?.assets?.find(x => x.assetId === Number(assetIdParams))
      if (thisAsset)
        return (
          <img src={thisAsset["assets.ipfsPreviewImageUrl"] || thisAsset["assets.mediaPreviewUrl"]} style={{ objectFit: 'contain', width: '100%', height: 'auto' }} />
        )
    }
    return <></>
  }

  return (
    <>
      <main className={"item-acceptance"}>
        <section className={"form"}>
          <div className={"max-width"}>
            <div className={"flex"} style={{ gap: "100px" }}>
              <div style={{ margin: "0 auto", flex: "1" }}>
                <div style={{
                  background: "#FFFFFF"
                  , padding: "10px"
                  , boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)"
                  , borderRadius: "20px"
                  , width: "fit-content"
                  , margin: "auto"
                }}>
                  <div className={"watch-image-box"} style={{ padding: "40px 80px", margin: "auto", textAlign: "center", maxWidth: '383px' }}>
                    {displayAssetImage()}
                  </div>
                </div>
              </div>
              <div style={{ flexBasis: "555px", paddingTop: "30px" }}>
                <div className={"table thank-you"}>
                  <div className={"row"} style={{ paddingRight: "10px" }}>
                    <div className={"cell"} style={{ paddingRight: "10px" }}>
                      <strong>Thank you for purchasing {orderDetail.asset?.name}</strong>
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"cell"}>
                      Please follow the instructions below to receive your product, the accompanying digital Certificate plus the NFT.
                    </div>
                  </div>
                </div>
                <div className={"table creator"}>
                  <div className={"cell"} style={{ paddingRight: "10px" }}>
                    <img src="images/avatar-icon-large.png" style={{ width: "50px", verticalAlign: "middle" }} />
                  </div>
                  <div className={"cell text"} style={{ verticalAlign: "middle" }}>
                    Creator
                    <br /><span>{orderDetail?.asset?.creatorName}</span>
                  </div>
                </div>
                <div className={"product-name"} style={{ textTransform: ' uppercase' }}>{orderDetail?.asset?.name}</div>
                {showOrderStatus(orderDetail?.status)}
                <div className={"table dates"}>
                  <div className={"row"}>
                    <div className={"cell"}>
                      Purchase Date
                    </div>
                    <div className={"cell date"}>
                      {moment(orderDetail?.createdAt).format('YYYY-MM-DD')}
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className={"cell"}>
                      Expected Time for Delivery
                    </div>
                    <div className={"cell date"}>
                      {moment(orderDetail?.createdAt).add(2, 'days').format('YYYY-MM-DD')}
                    </div>
                  </div>
                </div>
                {isDelivered && (
                  <>
                    <div style={{ paddingTop: "20px" }}>
                      <strong style={{
                        fontFamily: 'Poppins'
                        , fontStyle: "normal"
                        , fontWeight: "700"
                        , fontSize: "20px"
                        , lineHeight: "36px"
                      }}>
                        Let us know of the receipt of the product
                      </strong>
                    </div>
                    <div className={"description"}>
                      <p>
                        * Once you receive the product, you’ll need to let us know that the product has been received and everything is in order so we will send you the Digital Certificate and the Giveaway NFT <a href="">What can I do with my Digital Colletibles.</a>
                      </p>
                      <p>
                        Your NFTs will <strong>not be available</strong> until you confirm receipt of the product.
                      </p>
                      <p>
                        To learn more about this, please read our <a href="">Terms and Conditions.</a>
                      </p>
                    </div>
                    <div className={"description"} style={{ color: "#666666", paddingTop: "20px" }}>
                      <p style={{ textAlign: "center", margin: "auto" }}>
                        Did you receive the product?
                      </p>
                    </div>
                    <div className={"button-container"} style={{ justifyContent: "center", margin: "10px auto 10px auto" }}>
                      <button
                        disabled={orderDetail.minted.filter(asset => asset.assetId === Number(assetIdParams)).length}
                        className={`${orderDetail.minted.filter(asset => asset.assetId === Number(assetIdParams)).length ? 'grey' : 'gold'} rounded`}
                        onClick={postMintingOrder}
                      >Yes, I want my NFTs!</button>
                    </div>
                    <div style={{
                      fontWeight: "400"
                      //, fontSize: "12px"
                      //, lineHeight: "18px"
                      , color: "#666666"
                      , fontFamily: "'Red Hat Display'"
                      , fontStyle: "normal"
                      , fontWeight: "700"
                      , fontSize: "16px"
                      , lineHeight: "22px"
                    }}>
                      <p style={{ textAlign: "center", margin: "auto", color: "var(--color-gold)" }}>
                        <strong>I have an issue with the product</strong> - <Link
                          to="/contactus"
                          style={{
                              cursor: "pointer"
                              , whiteSpace: "nowrap"
                              , color: "var(--gold)"
                          }}
                          >
                          Contact Us
                        </Link>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ItemAcceptance;
