import React from "react";
//import useDarkMode from "use-dark-mode";

const Image = ({ className, src, srcDark, srcSet, srcSetDark, alt, style = {} }) => {

    //const darkMode = useDarkMode(true);

    return (
        <img
            className = { className }
            //srcSet = { darkMode.value ? srcSetDark : srcSet }
            srcSet = { srcSet }
            //src = { darkMode.value ? srcDark : src }
            src = { src }
            alt = { alt }
            style = { style }
            />
    );
};

export default Image;
