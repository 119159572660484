import React from 'react';
//import styles from './Video.module.sass'
//import cn from "classnames";

//const Video = () => {
const VideoHeader = props => {

    /*
    let videoSrc;
    if (props.filePath) {
        videoSrc = props.filePath
    } else {
        videoSrc = "/videos/" + props.fileName;
    }
    */

    return (
        <>
        <h2
            style = { props.styleObject }
            >{ props.heading }</h2>
        </>
    );
}

export default VideoHeader;
