import React, { useState, useEffect } from "react";
//import cn from "classnames";
import styles from "./MyAssets.module.sass";
import {
    useHistory
    //, Link
    , useParams } from "react-router-dom";
import API, { axiosInstance } from "../../utils/API";
//import CardContainer from "./../../components/CardContainer";
import CardContainer from "./CardContainer";
import { useSelector } from 'react-redux';
//import ReactPaginate from "react-paginate";
//import axios from '../../utils/API';
import ResellModal from "./ResellModal";
import { PopUpAlert } from "../../controller/utils";
//import { CircularProgress } from "@material-ui/core";
import { CircularProgress } from '@mui/material';
import { NAMES_CONSTANTS } from '../../constants';

//const navLinks = ["Info", "Description"];
const blockchainNetwork = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

/*
const dummyResults = {
    "orders": {
      "count": 16,
      "rows": [
        {
          "id": 21,
          "assetId": 70,
          "userId": 6,
          "listedAssetId": 78,
          "fractionCount": 0,
          "createdAt": "2023-05-08T12:39:14.398Z",
          "updatedAt": "2023-05-08T12:39:14.398Z",
          "listedAsset": {
            "id": 78,
            "assetId": 70,
            "userId": 6,
            "auctionId": 72,
            "availableFractions": 0,
            "soldFractions": 0,
            "totalFractions": 0,
            "resalePrice": null,
            "saleType": "auction",
            "isCarousal": false,
            "reservePrice": 0.001,
            "isResell": true,
            "blockchainConfirmation": true,
            "latestPrice": 0.01,
            "createdAt": "2023-05-08T12:33:10.631Z",
            "updatedAt": "2023-05-08T12:39:14.406Z"
          },
          "asset": {
            "availableEdition": 1,
            "id": 70,
            "name": "testauction08#1/1",
            "description": "<p>testauction08</p>",
            "shortDescription": null,
            "imageUrl": null,
            "longVideoUrl": null,
            "shortVideoUrl": null,
            "animationUrl": null,
            "status": "active",
            "quantity": 1,
            "saleType": "auction",
            "mediaType": "image",
            "audioThumbnail": null,
            "thumbnailUrl": null,
            "ipfsMetaUrl": "513bcda55aa15a4b3278373a2e080d332ff9f7c540da798f793d20d2bf7dacd493aa159c33a8b08a2ad8f2446d90fd06",
            "ipfsImageUrl": "0a867c02f29af893e8eef3595470b7974835cbdca6423a71c24dec08f83d8f8932ecbbedff61d0af0b2e9115cca38056ba464ff442aed2a799cc888e370edc62535bf1a687c43bdef25f4b7b3cee36aa1e3ea6db308e47f93e832b96a11d597e",
            "ipfsPreviewImageUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/nLOMbzThO.gif",
            "mediaPreviewUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/nLOMbzThO.gif",
            "ipfsAnimationUrl": null,
            "ipfsShortVideoUrl": null,
            "ipfsLongVideoUrl": null,
            "ipfsAudioUrl": null,
            "attributes": null,
            "salePrice": 11,
            "regularPrice": 11,
            "tokenId": "70",
            "categoryId": 1,
            "subCategoryId": 1,
            "fileId": [
              90
            ],
            "creatorName": "testauction08",
            "creatorDescription": "testauction08",
            "purchaseCount": 0,
            "availableStock": 1,
            "purchasedBy": 6,
            "isOnSell": true,
            "royaltyCommission": 0,
            "isDownloadable": true,
            "isBuyNowForAuction": false,
            "auctionBuyNowPrice": null,
            "currentEdition": 1,
            "soldEdition": 0,
            "totalEdition": 1,
            "currency": "euro(€)",
            "parentId": null,
            "assetState": null,
            "isEditionMintToAdmin": false,
            "descriptionMint": "",
            "isCarrosel": false,
            "isPurchased": true,
            "isDeleted": false,
            "isFractional": false,
            "blockchainConfirmation": true,
            "createdAt": "2023-05-08T12:33:10.581Z",
            "updatedAt": "2023-05-08T14:21:38.704Z",
            "files": [
              {
                "id": 90,
                "name": "SampleGIFImage_135kbmb.gif",
                "type": "image",
                "url": "0a867c02f29af893e8eef3595470b7974835cbdca6423a71c24dec08f83d8f8932ecbbedff61d0af0b2e9115cca38056ba464ff442aed2a799cc888e370edc62535bf1a687c43bdef25f4b7b3cee36aa1e3ea6db308e47f93e832b96a11d597e",
                "previewUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/nLOMbzThO.gif",
                "position": 1,
                "isThumbnail": null,
                "defaultThumbnailUrl": null,
                "isShortVideo": null,
                "isIpfs": true,
                "cid": "24448185f16e15a0cef0a367287f3a15cc73b53408abc862a2c678182440f67c28391268a843dd3a2584416b4776fa39"
              }
            ]
          }
        },
        {
          "id": 20,
          "assetId": 68,
          "userId": 6,
          "listedAssetId": 69,
          "fractionCount": 0,
          "createdAt": "2023-05-08T11:08:42.660Z",
          "updatedAt": "2023-05-08T11:08:42.660Z",
          "listedAsset": {
            "id": 69,
            "assetId": 68,
            "userId": 6,
            "auctionId": 64,
            "availableFractions": 0,
            "soldFractions": 0,
            "totalFractions": 0,
            "resalePrice": null,
            "saleType": "auction",
            "isCarousal": false,
            "reservePrice": 0.001,
            "isResell": true,
            "blockchainConfirmation": true,
            "latestPrice": 0.01,
            "createdAt": "2023-05-08T10:55:27.188Z",
            "updatedAt": "2023-05-08T11:08:42.665Z"
          },
          "asset": {
            "availableEdition": 1,
            "id": 68,
            "name": "test Auction asset06#1/1",
            "description": "<p>test Auction asset06</p>",
            "shortDescription": null,
            "imageUrl": null,
            "longVideoUrl": null,
            "shortVideoUrl": null,
            "animationUrl": null,
            "status": "active",
            "quantity": 1,
            "saleType": "auction",
            "mediaType": "image",
            "audioThumbnail": null,
            "thumbnailUrl": null,
            "ipfsMetaUrl": "1b23a38ece21a6b27d4e0337b5ecab63cb734caa18830dab9156b7ad2929c9863ba30a582bbb30ee7241958bccb1cfa3",
            "ipfsImageUrl": "0a867c02f29af893e8eef3595470b7974835cbdca6423a71c24dec08f83d8f8932ecbbedff61d0af0b2e9115cca38056ba464ff442aed2a799cc888e370edc62535bf1a687c43bdef25f4b7b3cee36aa1e3ea6db308e47f93e832b96a11d597e",
            "ipfsPreviewImageUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/9dsPRK485.gif",
            "mediaPreviewUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/9dsPRK485.gif",
            "ipfsAnimationUrl": null,
            "ipfsShortVideoUrl": null,
            "ipfsLongVideoUrl": null,
            "ipfsAudioUrl": null,
            "attributes": null,
            "salePrice": 12,
            "regularPrice": 12,
            "tokenId": "68",
            "categoryId": 1,
            "subCategoryId": 1,
            "fileId": [
              88
            ],
            "creatorName": "test Auction asset06",
            "creatorDescription": "test Auction asset06",
            "purchaseCount": 0,
            "availableStock": 1,
            "purchasedBy": 6,
            "isOnSell": false,
            "royaltyCommission": 0,
            "isDownloadable": true,
            "isBuyNowForAuction": false,
            "auctionBuyNowPrice": null,
            "currentEdition": 1,
            "soldEdition": 0,
            "totalEdition": 1,
            "currency": "euro(€)",
            "parentId": null,
            "assetState": null,
            "isEditionMintToAdmin": false,
            "descriptionMint": "",
            "isCarrosel": false,
            "isPurchased": true,
            "isDeleted": false,
            "isFractional": false,
            "blockchainConfirmation": true,
            "createdAt": "2023-05-08T10:55:27.147Z",
            "updatedAt": "2023-05-08T14:13:59.924Z",
            "files": [
              {
                "id": 88,
                "name": "SampleGIFImage_135kbmb.gif",
                "type": "image",
                "url": "0a867c02f29af893e8eef3595470b7974835cbdca6423a71c24dec08f83d8f8932ecbbedff61d0af0b2e9115cca38056ba464ff442aed2a799cc888e370edc62535bf1a687c43bdef25f4b7b3cee36aa1e3ea6db308e47f93e832b96a11d597e",
                "previewUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/9dsPRK485.gif",
                "position": 1,
                "isThumbnail": null,
                "defaultThumbnailUrl": null,
                "isShortVideo": null,
                "isIpfs": true,
                "cid": "24448185f16e15a0cef0a367287f3a15cc73b53408abc862a2c678182440f67c28391268a843dd3a2584416b4776fa39"
              }
            ]
          }
        },
        {
          "id": 19,
          "assetId": 67,
          "userId": 6,
          "listedAssetId": 68,
          "fractionCount": 0,
          "createdAt": "2023-05-08T11:07:06.866Z",
          "updatedAt": "2023-05-08T11:07:06.866Z",
          "listedAsset": {
            "id": 68,
            "assetId": 67,
            "userId": 6,
            "auctionId": 63,
            "availableFractions": 0,
            "soldFractions": 0,
            "totalFractions": 0,
            "resalePrice": null,
            "saleType": "auction",
            "isCarousal": false,
            "reservePrice": 0.001,
            "isResell": true,
            "blockchainConfirmation": true,
            "latestPrice": 0.01,
            "createdAt": "2023-05-08T10:53:39.400Z",
            "updatedAt": "2023-05-08T11:07:06.867Z"
          },
          "asset": {
            "availableEdition": 1,
            "id": 67,
            "name": "test Auction asset05#1/1",
            "description": "<p>test Auction asset04</p>",
            "shortDescription": null,
            "imageUrl": null,
            "longVideoUrl": null,
            "shortVideoUrl": null,
            "animationUrl": null,
            "status": "active",
            "quantity": 1,
            "saleType": "auction",
            "mediaType": "image",
            "audioThumbnail": null,
            "thumbnailUrl": null,
            "ipfsMetaUrl": "0f10561b57abf44aea67d6803e1da1f63d548d372c52e750dbcd73a584fefb3b98773a8612581c930810032150290c05",
            "ipfsImageUrl": "0a867c02f29af893e8eef3595470b7974835cbdca6423a71c24dec08f83d8f8932ecbbedff61d0af0b2e9115cca38056ba464ff442aed2a799cc888e370edc62535bf1a687c43bdef25f4b7b3cee36aa1e3ea6db308e47f93e832b96a11d597e",
            "ipfsPreviewImageUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/by9ZvNdPu.gif",
            "mediaPreviewUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/by9ZvNdPu.gif",
            "ipfsAnimationUrl": null,
            "ipfsShortVideoUrl": null,
            "ipfsLongVideoUrl": null,
            "ipfsAudioUrl": null,
            "attributes": null,
            "salePrice": 11,
            "regularPrice": 11,
            "tokenId": "67",
            "categoryId": 1,
            "subCategoryId": 1,
            "fileId": [
              87
            ],
            "creatorName": "test Auction asset04",
            "creatorDescription": "test Auction asset04",
            "purchaseCount": 0,
            "availableStock": 1,
            "purchasedBy": 6,
            "isOnSell": false,
            "royaltyCommission": 0,
            "isDownloadable": true,
            "isBuyNowForAuction": false,
            "auctionBuyNowPrice": null,
            "currentEdition": 1,
            "soldEdition": 0,
            "totalEdition": 1,
            "currency": "euro(€)",
            "parentId": null,
            "assetState": null,
            "isEditionMintToAdmin": false,
            "descriptionMint": "",
            "isCarrosel": false,
            "isPurchased": true,
            "isDeleted": false,
            "isFractional": false,
            "blockchainConfirmation": true,
            "createdAt": "2023-05-08T10:53:39.343Z",
            "updatedAt": "2023-05-08T14:13:59.929Z",
            "files": [
              {
                "id": 87,
                "name": "SampleGIFImage_135kbmb.gif",
                "type": "image",
                "url": "0a867c02f29af893e8eef3595470b7974835cbdca6423a71c24dec08f83d8f8932ecbbedff61d0af0b2e9115cca38056ba464ff442aed2a799cc888e370edc62535bf1a687c43bdef25f4b7b3cee36aa1e3ea6db308e47f93e832b96a11d597e",
                "previewUrl": "https://evoke-dev.s3.us-east-1.amazonaws.com/by9ZvNdPu.gif",
                "position": 1,
                "isThumbnail": null,
                "defaultThumbnailUrl": null,
                "isShortVideo": null,
                "isIpfs": true,
                "cid": "24448185f16e15a0cef0a367287f3a15cc73b53408abc862a2c678182440f67c28391268a843dd3a2584416b4776fa39"
              }
            ]
          }
        }
      ]
    },
    "totalPages": 2
};
//*/

//const MyAssets = () => {
const MyAssets = props => {
    console.log('MyAssets()', props);
    
    //const { id } = useParams();
    const history = useHistory();
    const [params, setParams] = useState({ limit: 20, page: 1 });
    const [count, setCount] = useState(5);
    //const [details, setDetails] = React.useState({});
    const [userDetails, setUserDetails] = React.useState({});
    const [profilePic, setProfilePic] = React.useState(null);
    const [assets, setAssets] = useState([]);
    const [orderId, setOrderId] = useState(null);
    const [currentAsset, setCurrentAsset] = useState(null);
    const [resalePopup, setResalePopup] = useState(false);
    const [loaderActive,setLoaderActive]=useState(false);
    const [assetFullInfo, setAssetFullInfo]=useState(null);
    //const loginStatus = useSelector((state) => state.counter.value);
    const euroValue = useSelector((state) => state.counter.euroValue) || 0;

    const [categoryFilterObj, setCategoryFilterObj] = useState(null);
    const [categoryList, setCategoryList] = useState([]);

    const getCategoryList = async () => {
        console.log('getCategoryList()');
        await axiosInstance.get('/asset/categories?page=1&limit=100')
            .then(result => {
                console.log(result?.data?.result?.categories);
                console.log(result?.data?.result?.categories.rows);
                setCategoryFilterObj(result?.data?.result?.categories?.rows);
                let categories = result?.data?.result?.categories?.rows?.map(
                    category => category.name
                );
                setCategoryList(['All', ...categories]);
            })
            .catch(err => { });
    };

    useEffect(() => {
        getCategoryList();
        getProfileDetails();
        getAllAssets();
    }, [params]);

    const resaleHandleClick = (e, item, productDetail) => {
        e.stopPropagation();
        setOrderId(item?.id)
        setCurrentAsset(item)
        setAssetFullInfo(productDetail)
        setResalePopup(true);
    }

    const closeModal = () => {
        setResalePopup(false)
    };

    const getConfig = () => {
        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN),
            }
        };
        if (!localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)) {
            PopUpAlert('Alert..', 'No wallet connection found', 'error')
            return;
        }
        return config;
    };

    const resellMethod = async (price, fractionCount) => {
        const data = {
            orderId: orderId,
            assetId: currentAsset?.id,
            regularPrice: +price,
            saleType: 1
        };

        if (currentAsset?.isFractional){
          data.quantity = +fractionCount
        }

        
        const config = getConfig();
        await axiosInstance.post('/asset/resale', data, config).then((res) => {
            setResalePopup(false)
            setLoaderActive(false)
            getAllAssets();
            PopUpAlert('Success', "Asset added successfully to resale",'success')
        }).catch((err) => {
            setLoaderActive(false)
            PopUpAlert('Alert', err?.data?.response?.message ? err?.data?.response?.message : err?.message, 'error')
        });
    };

    const getAllAssets = async () => {
        

        setLoaderActive(true);

        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , params
        };

        try {

            let response = await API.get("user/orders/purchased/", config);
            
            //response.data.result = dummyResults; // Temporary for development

            setAssets(response?.data?.result?.orders?.rows);
            setCount(response?.data?.result?.totalPages);

            setLoaderActive(false);
        } catch (err) {
            setLoaderActive(false);
            //
        }
    };

    const getProfileDetails = async () => {

        const config = getConfig();
        await axiosInstance
            .get(`user/get-profile`, config)
            .then((res) => {

                const { firstName, lastName, email, mobileNumber, userName, profilePicUrl, status, addresses } = res.data?.result;
                //setUserDetails({ firstName, lastName, email, publicAddress, mobileNumber, userName });
                setUserDetails(res.data?.result);

                //dispatch(storeUserVerifyStatus(status))
                if (profilePicUrl) setProfilePic(profilePicUrl);
            })
            .catch((err) => {
                //alert("err = " + err);
                console.log('Error: err = ' + err)
                //*
                if (err.response.data.message === 'Your account is blocked by Admin') {
                    PopUpAlert("Alert..", err.response.data.message, "error").then(() => {
                        document.getElementById('connectWallet').click()
                    });
                } else {
                    PopUpAlert("Alert..", err.response.data.message, "error");
                }
                props.disconnectUserFunction();
                history.push('/search/all');
                //*/
            });
    };

    /*
    const handlePageClick = ({ selected: selectedPage }) => {
        //
        setParams({ ...params, page: selectedPage + 1 });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    };
    */

    const clickSearch = function() {
        
        history.push('/search/all');
    };

    /*
    // E.g. https://mumbai.polygonscan.com/address/0xd072f7d4c6be619fd42b97045a45b0cdcbc09898#tokentxnsErc1155
    const clickWalletAddress = function(walletAddress) {
        //window.open('https://mumbai.polygonscan.com/address/' + walletAddress, '_blank');
        let blockchainExplorerUrl = 'https://' + blockchainNetwork + '/address/' + walletAddress + '#tokentxnsErc1155';
        window.open(blockchainExplorerUrl, '_blank');
    };
    */

    //*
    let categoryName;
    const getCategoryName = (categoryId) => {
        console.log('getCategoryName('+categoryId+')');
        console.log('categoryFilterObj', categoryFilterObj);
        console.log('categoryList', categoryList);
        return categoryFilterObj?.filter(
            item => item.id === categoryId
        )[0]?.name;
    };
    //*/

    console.log('assets[0]', assets[0]);

    const defaultBackgroundImage = 'https://realsplit-s3-bucket-us-east-1.s3.amazonaws.com/dY9rWfp-Zl.jpeg';

    return (
        <>

        {loaderActive && <div className={styles.loaderContent}><CircularProgress /> </div>}

        {
            resalePopup
            && <ResellModal
                closeModal = { closeModal }
                resellMethod = { resellMethod }
                item = { currentAsset }
                productInfo = { assetFullInfo }
                getAllAssets = { getAllAssets }
                loaderShow = { setLoaderActive }
                />}

        <main
            className = 'item-main'
            >

            <section
                className = { "profile-top" }
                style = {
                    assets != null
                    ? {
                        //backgroundImage: 'url(' + assets[0].thumbnailUrl + ')'
                        backgroundImage: 'url(' + (assets[0]?.asset?.mediaPreviewUrl || defaultBackgroundImage) + ')'
                    }
                    : {
                        backgroundImage: 'https://realsplit-s3-bucket-us-east-1.s3.amazonaws.com/dY9rWfp-Zl.jpeg'
                    }
                }
                >

                <div className = { "button-box" }>

                    {/*
                    <button type="button" className = { "rounded-clear" }>
                        <div className = { "table" }>
                            <div className = { "cell" }>Edit cover photo</div>
                            <div className = { "cell" }>
                                <img
                                    src="images/icons/edit-cover-photo-icon.svg"
                                    style = {{
                                          width: "20px"
                                        , height: "20px"
                                        , verticalAlign: "bottom"
                                    }} />
                            </div>
                        </div>
                    </button>
                    */}

                    {/*
                    <button
                        type="button"
                        //onClick = "followLink('sign-up.jsp')"
                        //onClick={(e) => toggleVisible()}
                        onClick = {() => {
                            
                            //history.push(`/search/${x.name}`);
                            history.push('/edit-profile');
                            //closeDrawerHandler(x);
                        }}
                        className = { "rounded-clear" }
                        >
                        <div className = { "table" }>
                            <div className = { "cell" }>Edit profile</div>
                            <div className = { "cell" }><img src="images/icons/edit-profile-icon.svg" style = {{ width: "20px", height: "20px", verticalAlign: "bottom" }} /></div>
                        </div>
                    </button>
                    */}


                </div>

            </section>

            <section className = { 'profile-main form' } style = {{ paddingTop: "0px" }}>
                <div className = { "max-width" }>

                    <div className = { "flex-container" }>

                        <div className = { "profile-badge" }>

                            <div>

                                <div style = {{ marginLeft: "0px", marginBottom: "40px" }}>
                                    <div className = { "table" }>

                                        <div className = { "row" }>
                                            <div className = { "cell" } style = {{ width: "100%", textAlign: "center", margin: "auto", padding: "8px 16px" }}>
                                                <img
                                                    className = { "avatar" }
                                                    //src="images/avatar-icon-large.png"
                                                    src = {
                                                           profilePic
                                                        || "images/avatar-icon-large.png"
                                                        || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                    }
                                                    />
                                            </div>
                                        </div>

                                        <div className = { "row" }>
                                            <div className = { "cell" } style = {{ verticalAlign: "top", marginTop: "0" }}>

                                                <div className = { "profile-photo-box" } style = {{ paddingTop: "20px" }}>

                                                    <div className = { "row" }>

                                                        <div
                                                            className = { "cell title" }
                                                            /*
                                                            style = {{
                                                                display: "block"
                                                                , textAlign: "center"
                                                                , textTransform: "capitalize"
                                                                , padding: '10px 0px 0px'
                                                                , textTransform: 'capitalize'
                                                                , color: 'var(--color-theme-primary)'
                                                                , fontWeight: '700'
                                                                , fontSize: '1.2em'
                                                            }}
                                                            */
                                                            >
                                                            {userDetails?.firstName} {userDetails?.lastName}
                                                        </div>
                                                    </div>

                                                        {/*
                                                        <div className = { "cell subtitle" } style = {{ display: "block", textAlign: "center" }}>0xc4c16a645...b21a</div>
                                                        */}

                                                        {/*
                                                        <div className = { "cell subtitle" }
                                                            style = {{
                                                                  display: "block"
                                                                , textAlign: "center"
                                                                , maxWidth: "200px"
                                                                , textOverflow: "ellipsis"
                                                                , overflow: "hidden"
                                                                //, fontFamily: "'Poppins'"
                                                                //, fontStyle: "normal"
                                                                //, fontWeight: "500"
                                                                //, fontSize: "14px"
                                                                //, lineHeight: "24px"
                                                                //, color: "#777E91"
                                                                , cursor: "pointer"
                                                            }}
                                                            onClick = {() => { clickWalletAddress(userDetails?.publicAddress) }}
                                                            >
                                                            { userDetails?.publicAddress }
                                                        </div>
                                                        */}

                                                    <div className = { "row" }>
                                                        <div
                                                            className = 'cell subtitle'
                                                            /*
                                                            style = {{
                                                                maxWidth: '150px'
                                                                , whiteSpace: 'wrap'
                                                                , wordWrap: 'break-word'
                                                                , fontSize: '12px'
                                                                //, fontWeight: 'bold'
                                                                , fontWeight: '500'
                                                                , textAlign: 'center'
                                                                , lineHeight: '12px'
                                                                , margin: 'auto'
                                                            }}
                                                            */
                                                            >
                                                            {/*
                                                            */}
                                                            <div
                                                                /*
                                                                style = {{
                                                                    maxWidth: '200px'
                                                                    , whiteSpace: 'wrap'
                                                                    , wordWrap: 'break-word'
                                                                    , fontSize: '14px'
                                                                    //, fontWeight: 'bold'
                                                                    , fontWeight: '500'
                                                                    , textAlign: 'center'
                                                                    , lineHeight: '15px'
                                                                    , margin: 'auto'
                                                                    , padding: '10px 0px'
                                                                    , paddingTop: '0px'
                                                                    , color: 'var(--color-gray-600)'
                                                                    , fontWeight: '400'
                                                                }}
                                                                */
                                                                >
                                                                { userDetails?.email }
                                                            {/*
                                                            */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className = { "row" }>
                                                        <div className = { "cell description" }>
                                                            { userDetails?.bio }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className = { "butons-box" }>

                                    {/*
                                    <div className = { "clear-button" }>
                                        <img src="images/icons/upload-icon.png" style = {{ verticalAlign: "bottom" }} />
                                    </div>

                                    <div className = { "clear-button" }>
                                        <img src="images/icons/ellipsis-icon.png" />
                                    </div>
                                    */}

                                    <button
                                        type="button"
                                        onClick = {() => {
                                            history.push('/user-profile');
                                        }}
                                        //className = { "rounded-clear" }
                                        className = "primary-inverse medium rounded"
                                        >
                                        <div className = { "table" }>
                                            <div className = { "cell" }>Edit profile</div>
                                            {/*
                                            <div className = { "cell" }><img src="images/icons/edit-profile-icon.svg" style = {{ width: "20px", height: "20px", verticalAlign: "bottom" }} /></div>
                                            */}
                                        </div>
                                    </button>

                                </div>

                                {/*
                                <div className = { "social-box" }>
                                    <div>
                                        <img src="images/icons/twitter-icon.svg" />
                                    </div>
                                    <div>
                                        <img src="images/icons/instagram-icon.svg" />
                                    </div>
                                    <div>
                                        <img src="images/icons/facebook-icon.svg" />
                                    </div>
                                </div>

                                <hr />
                                */}

                                <p className = { "description" } style = {{ paddingTop: '20px', paddingBottom: '0px' }}>Member since Mar 15, 2021</p>

                            </div>
                        </div>

                        <div style = {{ flex: 1 }}>

                            <section
                                //className = { "search-results" }
                                //className = { "my-assets" }
                                className = { 'my-assets search-results' }
                                style = {{ backgroundColor: 'unset' }}
                                >

                                <div
                                    className = { "max-width" }
                                    //style = {{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >

                                    {
                                        assets.length !== 0 ? (
                                            <>
                                            <div
                                                //className = {styles.list}
                                                className = { "container card-container assets-container item-container" }
                                                //style = {{ gap: "10px", justifyContent: "space-around" }}
                                                >
                                                {
                                                    assets.map((x, index) => {
                                                        { categoryName = getCategoryName(x?.asset.categoryId) }
                                                        return (
                                                            <div className = { styles.cardContainer } key = { index }>
                                                                <CardContainer
                                                                    className = { styles.card }
                                                                    asset = { x.asset }
                                                                    listedAsset = { x.listedAsset }
                                                                    key = { index }
                                                                    type = { "view" }
                                                                    orderId = "157"
                                                                    showLabel = { true }
                                                                    price = { euroValue }
                                                                    productDetails = { x }
                                                                    ownedAssetPage = { true }
                                                                    resalePage = { true }
                                                                    categoryName = { categoryName }
                                                                    resaleHandleClick = { resaleHandleClick }
                                                                    handleAddToCart = { props.handleAddToCart }
                                                                    />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            </>
                                        ) : (
                                            <div style = {{ paddingTop: "20px" }}>
                                                <div>
                                                    <div>
                                                        <h2
                                                            style = {{
                                                                  fontFamily: '"Roboto Slab"'
                                                                , fontStyle: "normal"
                                                                , fontWeight: "500"
                                                                , fontSize: "30px"
                                                                , lineHeight: "24px"
                                                            }}
                                                            >
                                                            You haven’t purchased any investments yet.
                                                        </h2>
                                                    </div>
                                                </div>

                                                <div
                                                    className = { "button-container" }
                                                    style = {{
                                                        marginLeft: '0'
                                                        , textAlign: 'left'
                                                        , justifyContent: 'flex-start'
                                                    }}>
                                                    <div className = { "button-wrapper" } style = {{ marginLeft: '0', marginTop: "40px", textAlign: 'left' }}>
                                                        <button
                                                            style = {{ marginLeft: '0', textAlign: 'left' }}
                                                            className = { "primary large" }
                                                            onClick = {() => { clickSearch(); }}
                                                            >Search our Portfolio</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </section>

                        </div>

                    </div>
                </div>
            </section>
        </main>
        </>
    );
};

export default MyAssets;
