import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./AddWalletAddressModal.scss";

const AddPhysicalAddressModal = ({ isOpen = false, closeModal, addPhysicalAddress, action = 'view' }) => {

    //const history = useHistory();
    const [addressDestinationName, setAddressDestinationName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [state, setState] = useState("");

    const [town, setTown] = useState("");
    const [mobile, setMobile] = useState("");

    const handleCloseModal = () => {
      closeModal();
    };

    const handleAdd = () => {
        const bodyParams = {
          addressDestinationName,
          addressLine1,
          addressLine2,
          postalCode,
          town,
          mobile,
          state
        }
        if (addPhysicalAddress) {
          addPhysicalAddress(bodyParams);
          closeModal();
        }
    }

    return (
        <>
        {
            isOpen
            && <div className="modal-overlay" onClick={handleCloseModal} />
        }
        <div className={`add-wallet-address-modal-container ${isOpen ? "show" : ""}`}>
            <span className="close" onClick={handleCloseModal} title="Close">
                <svg id="icon-close" viewBox="0 0 20 20" fill='#black' height="20px" width="20px">
                    <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
                </svg>
            </span>
            <div className="modal-content">
                <div className="modal-header">Add Delivery Address</div>
                <div className="modal-form">
                    <div className="label text-right">Please add your receiver wallet address </div>
                    <div className="add-wallet-address-input">
                      <input
                        type="text"
                        name="address_destination"
                        placeholder="Address Destination Name"
                        value={addressDestinationName || ""}
                        onChange={(e) => setAddressDestinationName(e.target.value)}
                      />
                    </div>
                    <div className="add-wallet-address-input">
                      <input
                        type="text"
                        name="address_line_1"
                        placeholder="Address line 1"
                        value={addressLine1 || ""}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      />
                    </div>
                    <div className="add-wallet-address-input">
                      <input
                        type="text"
                        name="address_line_2"
                        placeholder="Address line 2"
                        value={addressLine2 || ""}
                        onChange={(e) => setAddressLine2(e.target.value)}
                      />
                    </div>
                    <div className="add-wallet-address-input">
                      <input
                        type="text"
                        name="postal_code"
                        placeholder="State"
                        value={state || ""}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div className="input-group d-flex">
                      <div className="add-wallet-address-input">
                        <input
                          type="text"
                          name="town"
                          placeholder="Town / City"
                          value={town || ""}
                          onChange={(e) => setTown(e.target.value)}
                        />
                      </div>
                      <div className="add-wallet-address-input">
                        <input
                          type="text"
                          name="postal_code"
                          placeholder="ZIP / Postal Code"
                          value={postalCode || ""}
                          onChange={(e) => setPostalCode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="add-wallet-address-input">
                      <input
                        type="text"
                        name="mobile"
                        placeholder="Mobile"
                        value={mobile || ""}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                    <button className="add-wallet-address-btn btn" onClick={handleAdd}>
                      {action === 'edit' ? 'Edit' : 'Add'}
                    </button>
                </div>
            </div>
        </div>
        </>
    );
};
export default AddPhysicalAddressModal;
