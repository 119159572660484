import React from "react";
import styles from "./Item.module.sass";
//import { CircularProgress } from "@material-ui/core";
import { CircularProgress } from '@mui/material';

const Loader = (props) => {
    const { active, auction } = props;
    return (
        <div
            className={styles.checkout}
            style={{ display: active ? "flex" : "none" }}
        >
            <div
                className={styles.checkoutInner}
                style={{ height: "45%" }}
                onClick={(e) => e.stopPropagation()}
            >
                <h2>Transaction in Progress</h2>
                <div className={styles.checkoutDetail}>
                    <div>
                        {auction ?
                        <p style={{ textAlign: "center" }}>Bidding in Progress</p>    
                        :
                        <p style={{ textAlign: "center" }}>Item purchase in progress</p>
                        }
                      
                    </div>
                </div>
                <div style={{ textAlign: "center", padding: 20 }}>
                    <CircularProgress />
                    <div>
                        <span> Please do not refresh the page.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader