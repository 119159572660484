import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
//import { useHistory } from 'react-router';
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Notifications.module.sass";
import NotificationItem from "./NotificationItem";
import Icon from "../../Icon";
import { useNotificationList } from "./apis/useListNotifications";

//const NotificationsPopup = ({ className }) => {
const NotificationsPopup = (props) => {
    console.warn('NotificationsPopup()');
    console.warn('NotificationsPopup()', props);
    console.warn('NotificationsPopup: props.notificationsPopupRef = ', props.notificationsPopupRef)

    const history = useHistory();

    //const [visible, setVisible] = useState(false);

    //const { responseData } = useNotificationList();
    //const { responseData, isLoading, isError, refetch } = useQuery('myData', fetchData);
    //const { responseData, isLoading, isError, refetch } = useNotificationList();
    const { data, isLoading, isError, refetch } = useNotificationList();
    //console.warn('NotificationsPopup: responseData = ', responseData);
    console.warn('NotificationsPopup: data = ', data);
    console.warn('NotificationsPopup: isLoading = ', isLoading);
    console.warn('NotificationsPopup: isError = ', isError);
    console.warn('NotificationsPopup: refetch = ', refetch);

    const [lastFetchNotification] = useState(() => localStorage.getItem("lastFetchNotification"));
    const [hasNewNotification, setHasNewNotification] = useState(false);
    const [currentLatestNotification, setCurrentLatestNotification] = useState(false);
    //const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        //if (responseData) {
        if (data) {
            //const latestNotification = String(responseData.result?.notifications?.rows[0]?.id);
            const latestNotification = String(data.result?.notifications?.rows[0]?.id);
            //notifications = responseData.result?.notifications;
            //console.warn('notifications = ', notifications);
            //const latestNotification = String(notifications?.rows[0]?.id);
            if (latestNotification && latestNotification !== lastFetchNotification) {
                setHasNewNotification(true);
                setCurrentLatestNotification(latestNotification);
            }
        }
    //}, [responseData]);
    }, [data]);

    const refreshNotificationsList = (e) => {
        console.warn('NotificationsPopup.refreshNotificationsList(e)')
        refetch();
    };

    /*
    useEffect(() => {
        console.warn('NotificationsPopup: useEffect(() => {...')
        console.warn('    closePopupMenu();');
        closePopupMenu();
        //console.warn('    props.closePopupMenu();');
        //props.closePopupMenu();
    }, [history.location.pathname]);
    */
    const closePopupMenu = (e) => {
        console.warn('NotificationsPopup.closePopupMenu(e)')
        props.onOutsideClickNotificationsPopup(e);
    };

    const clickSeeAllNotifications = (e) => {
        console.warn('NotificationsPopup.clickSeeAllNotifications(e)')

        history.push('/notifications');
        //props.handleShowNotificationsPopup(!props.isShowNotificationsPopup);
        //props.onOutsideClickNotificationsPopup(e)
        //props.handleShowNotificationsPopup(!props.isShowNotificationsPopup);
        closePopupMenu(e);
    };

    return (
        <OutsideClickHandler
            //onOutsideClick={() => setVisible(false)}
            //onOutsideClick = { () => props.handleShowNotificationsPopup(false) }
            //onOutsideClick = { () => props.setIsShowNotificationsPopup(false) }
            //onOutsideClick = { () => props.onOutsideClickNotificationsPopup() }
            onOutsideClick = { (e) => props.onOutsideClickNotificationsPopup(e) }
            >
            <div
                ref = { props.notificationsPopupRef }
                //className = { cn(styles.notification, className) }
                //className = { cn(styles.notification) }
                style = {{
                    zIndex: '5'
                    , position: 'fixed'
                    , display: 'block'
                }}
                >
        
                {/*
                <button
                    className={cn(styles.head, { [styles.active]: hasNewNotification })}
                    onClick={() => {
                        setVisible(!visible);
                        setHasNewNotification(false);
                        localStorage.setItem(
                          "lastFetchNotification",
                          currentLatestNotification
                        );
                    }}>

                    <Icon name="notification" size="24" />
                    <img
                        className = 'svg-icon'
                        //src = '/images/icons/notification-bell-svgrepo-com.svg'
                        //src = '/images/icons/notification-bell-icon.svg'
                        src = '/images/icons/notifications-circle-outline_1.svg'
                        alt = ''
                        style = {{ width: '32px', height: '32px' }}
                        />
                </button>
                */}    

                {
                    //visible
                    props.isShowNotificationsPopup
                    && (
                        <div
                            className={styles.body}
                            >
                            <div
                                //className={cn("h4", styles.title)}
                                style = {{
                                    display: 'flex'
                                    , flexDirection: 'row'
                                    , justifyContent: 'space-between'
                                    //, paddingLeft: '16px'
                                    , paddingBottom: '10px'
                                }}
                                >

                                <h2>Notifications</h2>

                                {/*
                                <Link
                                    //className = { cn("button-small", styles.button) }
                                    className = 'button-primary medium'
                                    to = "/notifications"
                                    //onClick = {() => setVisible(!visible)}
                                    onClick = { () => props.handleShowNotificationsPopup(!props.isShowNotificationsPopup) }
                                    >
                                    See all
                                </Link>
                                */}

                                <button
                                    //className = { cn("button-small", styles.button) }
                                    className = 'primary medium circular'
                                    //to = "/notifications"
                                    //onClick = {() => setVisible(!visible)}
                                    //onClick = { () => props.handleShowNotificationsPopup(!props.isShowNotificationsPopup) }
                                    onClick = { clickSeeAllNotifications }
                                    style = {{ marginTop: '-8px' }}
                                    >
                                    See All
                                </button>
                            </div>

                            <div className = { styles.list }>
                                {
                                    //responseData
                                    data
                                    //&& responseData.result.notifications.rows.map((notificationItem) => (
                                    && data.result.notifications.rows.map((notificationItem) => (
                                    //&& notifications.rows.map((notificationItem) => (
                                        <NotificationItem
                                            key = { notificationItem.id }
                                            item = { notificationItem }
                                            closePopupMenu = { closePopupMenu }
                                            dateFormat = 'ddd, MMM D, YYYY h:MM a'
                                            refreshNotificationsList = { refreshNotificationsList }
                                            />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </OutsideClickHandler>
    );
};

export default NotificationsPopup;
