export const NAMES_CONSTANTS = {
      USER_WALLET_DETAILS: 'REALSPLIT_USER_WALLET_DETAILS'
    , USER_ACCESS_TOKEN: 'REALSPLIT_USER_ACCESS_TOKEN'
};

export const ITEM_ACCEPTANCE = {
    ORDER_STATUS: {
        new: {
            value: 'new', color: '#FF881A', text: 'Order Created'
        }, // ORDER CREATED, STEP 1
        paid: {
            value: 'paid', color: '#FF881A', text: 'Paid'
        }, // USER PAID STEP 2
        on_delivery: {
            value: 'on_delivery', color: '#FF881A', text: 'In Transit'
        }, // payment success, start trigger fed-ex STEP 3
        delivered: {
            value: 'delivered', color: '#17B514', text: 'Waiting for Acceptance'
        }, // user received asset, should trigger endEscrow STEP 4.1
        refund: {
            value: 'refund', color: '#E61515', text: 'Refund'
        }, // need to refund STEP 4.2
        success: {
            value: 'success', color: '#17B514', text: 'Completed'
        } // endEscrow successfully, user received token STEP 5
    }
}

export const CRYPTOCURRENCY_CONSTANTS = {
    DEFAULT_CRYPTOCURRENCY: {
        //name: 'MATIC'
        name: 'USD'
    }
    , CRYPTO_PRICE_DECIMAL_PLACES: 2
}

export const FILTER_CONSTANTS = {
  CURRENCY: {
    usd: {
      value: 'usd',
      name: 'USD',
    },
    eth: {
      value: 'eth',
      name: 'ETH',
    },
  },
  STATUS: {
    buyNow: {
      value: 'buy_now',
      name: 'Buy Now',
    },
    listed: {
      value: 'listed',
      name: 'Listed',
    },
    onAution: {
      value: 'on_aution',
      name: 'On Aution',
    },
    new: {
      value: 'new',
      name: 'New',
    },
    hasOffers: {
      value: 'has_offers',
      name: 'Has Offers',
    },
  },
  QUANTITY: {
    allItems: {
      value: 'all_items',
      name: 'All Items',
    },
    singleItems: {
      value: 'single_items',
      name: 'Single Items',
    },
    bundle: {
      value: 'bundle',
      name: 'Bundle',
    },
  },
  SORT_BY: {
    priceLowestToHighest: {
      value: 'price_lowest',
      name: 'Price - Lowest',
    },
    priceHighestToLowest: {
      value: 'price_highest',
      name: 'Price - Highest',
    },
    mostViewed: {
      value: 'most_viewed',
      name: 'Most viewed',
    },
    bestOffer: {
      value: 'best_offer',
      name: 'Best offer',
    },
    recentlyListed: {
      value: 'recently_listed',
      name: 'Recently listed',
    },
    recentlySold: {
      value: 'recently_sold',
      name: 'Recently sold',
    },
    recentlyCreated: {
      value: 'recently_created',
      name: 'Recently created',
    },
    highestLastSale: {
      value: 'highest_last_sale',
      name: 'Highest last sale',
    },
    oldest: {
      value: 'oldest',
      name: 'Oldest',
    },
  },
}

export const PAGINATE = {
    PAGE_RANGE_DISPLAYED: 1,
    MARGIN_PAGES_DISPLAYED: 2
}
