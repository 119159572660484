import React, { useState } from "react";
import styles from './Auctions.module.sass';
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import BidCards from "./BidCards";
//import { CircularProgress } from "@material-ui/core";
//import { CircularProgress } from '@mui/material';
import { NAMES_CONSTANTS } from "../../constants";

const Auctions = () => {

    // This was being used for "logged in" status. Now using the userAccessToken from the localStorage instead.
    //const loginStatus = useSelector((state) => state.counter.value);
    const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);

    //const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(0);

    let navLinks = [
          "Active Bids"
        , "Auctions Won"
        , "Auctions Lost"
    ];

    return (

        <main className = 'edit-profile'>

            <section
                className = 'form'
                style = {{
                    backgroundColor: "var(--color-gray-100)"
                }}
                >

                <div
                    className = 'max-width'
                    >

                    {/*
                    <div
                        style = {{
                            paddingBottom: "30px"
                        }}>

                        <button
                            type = "button"
                            className = { cn("rounded-clear", "light") }
                            //onClick = { handleClickBackButton }
                            onClick = { () => history.push("/my-assets") }
                            style = {{
                                  padding: "10px 15px"
                                , border: "2px solid #E6E8EC"
                            }}>
                            <div className = { "table" }>
                                <div className = { "cell" }><img src="images/icons/left-arrow-icon.png" style = {{ verticalAlign: "middle" }} /></div>
                                <div className = { "cell" } style = {{ verticalAlign: "middle", paddingLeft: "15px" }}>Back to Profile</div>
                            </div>
                        </button>

                    </div>
                    */}

                    <div
                        className = { "heading" }
                        style = {{
                            display: 'flex'
                            , flexDirection: 'row'
                            , flexWrap: 'wrap'
                            //, justifyContent: 'space-between'
                            , justifyContent: 'flex-start'
                        }}>
                        <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <h1 style = {{ display: 'inline-block' }}>
                                Auctions
                            </h1>
                        </div>
                        {/*
                        <div style = {{ paddingLeft: '30px' }}>
                            <img
                                //src = '/images/icons/referrals-icon-28.jpg'
                                src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                style = {{
                                    //width: '50px'
                                    height: '60px'
                                    //, filter: 'var(--filter-theme-primary)'
                                    , filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                }}
                                />
                        </div>
                        */}
                    </div>

                    <div
                        className = 'subtitle'
                        //style = {{
                        //    paddingTop: "30px"
                        //    , paddingBottom: "0px"
                        //}}
                        >
                        {/*
                            assets.length > 0
                            ? ( <span>Auctions you are participating in.</span> )
                            : ( <span>You are not participating in any auctions yet.</span> )
                        */}
                        <span>Auctions you are participating in.</span>

                    </div>

                    {/*
                        userAccessToken
                        ?
                            <div className={styles.wrapper}>
                                <div
                                    //className={styles.nav}
                                    style = {{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}
                                    >
                                    {
                                        navLinks.map((x, index) => (
                                            <div>
                                            <button
                                                className={cn(
                                                //    { [styles.active]: index === activeIndex },
                                                    { ['active']: index === activeIndex }
                                                    //, styles.link
                                                    , 'primary-inverse'
                                                    , 'rounded'
                                                    , 'medium'
                                                )}
                                                onClick = { () => setActiveIndex(index) }
                                                key = { index }
                                                >
                                                { x }
                                            </button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        : 
                            <div>Login to view Details</div>
                    */}

                    <div
                        //className = { styles.wrapper }
                        className = "flex-container"
                        style = {{ marginTop: '30px' }}
                        >
                        <BidCards index={activeIndex} />
                    </div>

                </div>
            </section>
        </main>
    )
}

export default Auctions