import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
//import styles from './HomePage.module.sass';
import { useSelector } from 'react-redux';
import Main from '../Main';
import Footer from '../Footer';
//import axios from 'axios';
import { useDispatch } from 'react-redux';
//import { setEuroValue } from '../../redux/counterSlice';
import { axiosInstance } from '../../utils/API';
//import { keyDetails } from '../../redux/keySlice';
//import { apiCall } from '../../controller/utils';
import {
    countCart
    , empty
    //, listCart
    //, totalCart
} from '../../redux/cartSlice';
import { NAMES_CONSTANTS } from '../../constants';
import MobileNav from '../Header/MobileNav';
//import DummyVictoria from '../DummyVictoria';
import MobileUserHeader from '../Header/MobileUserHeader';
import UserProfileMenu from '../Header/UserHeader/UserProfileMenu.js';
//import { UserInterface } from '../../utils/UserInterface';
import { getPagePosition, getFixedPosition } from '../../utils/UserInterface';
import NotificationsPopup from '../Header/Notifications/NotificationsPopup.js';
import OutsideClickHandler from "react-outside-click-handler";

const HomePage = props => {
    console.info('HomePage()');
    console.log('HomePage()', props);

    const { pathname } = useLocation();
    const userWalletDetails = useSelector(store => store.user.user)

    useEffect(() => {
        console.debug('HomePage: useEffect(() => {');
        //console.warn('window.scrollTo(0, 0)');
        window.scrollTo(0, 0);
        //window.scrollTo({
        //      top: 0
        //    , behavior: 'smooth'
        //});
        clearAllBodyScrollLocks();
        document.getElementsByTagName('body')[0].classList.remove('search');
    }, [pathname]);

    const dispatch = useDispatch();

    const userProfileMenuRef = useRef(null);
    const userProfileButtonRef = useRef(null);
    const notificationsPopupRef = useRef(null);
    const notificationsButtonRef = useRef(null);
    const notificationsButtonSvgRef = useRef(null);

    //let showUserProfileMenu = false;
    const [isShowMobileNav, setIsShowMobileNav] = useState(false);
    const [isShowMobileUserHeader, setIsShowMobileUserHeader] = useState(false);
    const [isShowUserProfileMenu, setIsShowUserProfileMenu] = useState(false);
    //const [isShowUserProfileMenu, setIsShowUserProfileMenu] = useState(showUserProfileMenu);
    const [isShowNotificationsPopup, setIsShowNotificationsPopup] = useState(false);

    const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    console.debug('HomePage: userAccessToken = ', userAccessToken);
    const [walletDetails, setWalletDetails] = useState(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));
    console.debug('HomePage: walletDetails = ', walletDetails);

    const onOutsideClickUserProfileMenu = (e) => {
        //console.warn('HomePage.onOutsideClickUserProfileMenu(e)', e);

        //console.warn('e.target = ', e.target);
        //console.warn('userProfileButtonRef.current = ', userProfileButtonRef.current);

        if (userProfileButtonRef.current.contains(e.target)) { // Short circuit this function if the button being clicked is a descendant of the User Profile button
            //console.warn('userProfileButtonRef.current.contains(e.target)');
            return;
        }

        //console.warn('isShowUserProfileMenu = ', isShowUserProfileMenu);
        if (isShowUserProfileMenu) {

            let showUserProfileMenu = !isShowUserProfileMenu;
            //console.warn('showUserProfileMenu = ', showUserProfileMenu);
            setIsShowUserProfileMenu(showUserProfileMenu);

            let userProfileMenu = userProfileMenuRef.current;
            //console.warn('userProfileMenu = ', userProfileMenu);

            if (userProfileMenu) {
                //console.log('userProfileMenu = ', userProfileMenu);
                //console.warn('userProfileMenu.style.display = ', userProfileMenu.style.display);
                if (showUserProfileMenu) {
                    userProfileMenu.style.display = 'block';
                } else {
                    userProfileMenu.style.display = 'none';
                }
            }
        }
    };

    const onOutsideClickNotificationsPopup = (e) => {
        //console.warn('HomePage.onOutsideClickNotificationsPopup(e)', e);

        //console.warn('e.target = ', e.target);
        //console.warn('notificationsButtonSvgRef.current = ', notificationsButtonSvgRef.current);

        if (notificationsButtonSvgRef.current == e.target) { // Short circuit this function if the button being clicked is the notifications button
            //console.warn('notificationsButtonSvgRef.current == e.target');
            return;
        }

        //console.warn('isShowNotificationsPopup = ', isShowNotificationsPopup);
        if (isShowNotificationsPopup) setIsShowNotificationsPopup(false);
    };

    const onClickNotificationsButton = (e) => {
        //console.warn('HomePage.onClickNotificationsButton(e)', e);

        //console.warn('e.target = ', e.target);
        notificationsButtonSvgRef.current = e.target;

        toggleShowNotificationsPopup(e.currentTarget);
    };

    const toggleShowNotificationsPopup = (notificationsButton) => {
        //console.warn('HomePage.toggleShowNotificationsPopup('+notificationsButton+')');

        //console.warn('isShowNotificationsPopup = ', isShowNotificationsPopup);
        //setIsShowNotificationsPopup(!isShowNotificationsPopup);
        let showNotificationsPopup = !isShowNotificationsPopup;
        //console.warn('showNotificationsPopup = ', showNotificationsPopup);
        setIsShowNotificationsPopup(showNotificationsPopup);

        let notificationsPopup = notificationsPopupRef.current;
        //console.warn('notificationsPopup = ', notificationsPopup);

        if (notificationsPopup) {
            //console.log('notificationsPopup = ', notificationsPopup);
            //console.warn('notificationsPopup.style.display = ', notificationsPopup.style.display);
            if (showNotificationsPopup) {
                notificationsPopup.style.display = 'block';
                //setTimeout(positionNotificationsPopup, 10, notificationsButton);
            } else {
                notificationsPopup.style.display = 'none';
            }
        }

        setTimeout(positionNotificationsPopup, 10, notificationsButton);
    };

    const positionNotificationsPopup = (notificationsButton) => {
        //console.warn('HomePage.positionNotificationsPopup('+notificationsButton+')');

        //console.warn('isShowNotificationsPopup = ', isShowNotificationsPopup);

        let notificationsButtonWidth;
        let notificationsPopupWidth;

        let notificationsPopup = notificationsPopupRef.current;
        if (notificationsPopup) {
            //console.warn('notificationsPopup = ', notificationsPopup);
            notificationsPopupWidth = notificationsPopup.offsetWidth;
            //console.warn('notificationsPopupWidth = ', notificationsPopupWidth);
        }

        if (notificationsButton) {

            notificationsButtonWidth = notificationsButton.offsetWidth;
            //console.warn('notificationsButtonWidth = ', notificationsButtonWidth);

            let xyPosition = getFixedPosition(notificationsButton);
            //console.warn('xyPosition = ', xyPosition);

            if (xyPosition) {
                notificationsPopup.style.left = xyPosition.left + 'px';
                notificationsPopup.style.top = (xyPosition.top + 50) + 'px';
                //return;
            }
        }

        /*
        let notificationsButtonHalfWidth = parseInt(notificationsButtonWidth / 2);
        console.log('notificationsButtonHalfWidth = ', notificationsButtonHalfWidth);

        let notificationsPopupHalfWidth = parseInt(notificationsPopupWidth / 2);
        console.log('notificationsPopupHalfWidth = ', notificationsPopupHalfWidth);

        if (notificationsPopupHalfWidth > 0 && notificationsPopupHalfWidth > notificationsButtonHalfWidth) {
            let notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels = notificationsPopupHalfWidth - notificationsButtonHalfWidth;
            console.log('notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels = ', notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels);
            notificationsPopup.style.marginLeft = -(notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels) + 'px';
        }
        */
        let notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels = 15;
        notificationsPopup.style.marginLeft = -(notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels) + 'px';
    };

    const handleShowMobileNav = () => {
        //console.warn('HomePage.handleShowMobileNav()');
        setIsShowMobileNav(true);
    };

    const handleCloseMobileUserHeader = () => {
        //console.warn('HomePage.handleCloseMobileUserHeader()');
        setIsShowMobileUserHeader(false);
        //setIsShowUserProfileMenu(false);
        hideUserProfileMenu();
    };

    const handleShowMobileUserHeader = () => {
        //console.warn('HomePage.handleShowMobileUserHeader()');
        setIsShowMobileUserHeader(true);
    };

    const hideUserProfileMenu = (e) => {
        //console.warn('Page.hideUserProfileMenu(e)');

        //console.warn('isShowUserProfileMenu = ', isShowUserProfileMenu);
        setIsShowUserProfileMenu(false);

        let userProfileMenu = userProfileMenuRef.current;
        //console.warn('userProfileMenu = ', userProfileMenu);
        if (userProfileMenu) {
            //console.warn('userProfileMenu.style.display = ', userProfileMenu.style.display);
            userProfileMenu.style.display = 'none';
        }
    };
    
    const toggleShowUserProfileMenu = (e) => {
        //console.warn('HomePage.toggleShowUserProfileMenu(e)');

        //console.warn('isShowUserProfileMenu = ', isShowUserProfileMenu);
        //setIsShowUserProfileMenu(!isShowUserProfileMenu);
        let showUserProfileMenu = !isShowUserProfileMenu;
        //console.warn('showUserProfileMenu = ', showUserProfileMenu);
        setIsShowUserProfileMenu(showUserProfileMenu);

        let userProfileMenu = userProfileMenuRef.current;
        //console.warn('userProfileMenu = ', userProfileMenu);

        if (userProfileMenu) {
            //console.log('userProfileMenu = ', userProfileMenu);
            //console.warn('userProfileMenu.style.display = ', userProfileMenu.style.display);
            if (showUserProfileMenu) {
                userProfileMenu.style.display = 'block';
                //setTimeout(positionUserProfileMenu, 1);
            } else {
                userProfileMenu.style.display = 'none';
            }
        }

        setTimeout(positionUserProfileMenu, 100);
    };

    const positionUserProfileMenu = () => {
        //console.warn('HomePage.positionUserProfileMenu()');

        //console.warn('isShowUserProfileMenu = ', isShowUserProfileMenu);

        let userProfileButtonWidth;
        let userProfileMenuWidth;

        //let userProfileMenuRef = props.userProfileMenuRef;
        //console.warn('userProfileMenuRef = ', userProfileMenuRef);
        //console.warn('userProfileMenuRef?.current = ', userProfileMenuRef?.current);
        let userProfileMenu = userProfileMenuRef.current;
        if (userProfileMenu) {
            //console.warn('userProfileMenu = ', userProfileMenu);
            //const { offsetWidth, offsetHeight } = userProfileMenu;
            //console.log('offsetWidth = ', offsetWidth);
            //console.log('offsetHeight = ', offsetHeight);
            userProfileMenuWidth = userProfileMenu.offsetWidth;
            //console.warn('userProfileMenuWidth = ', userProfileMenuWidth);
        }

        //console.warn('userProfileButtonRef = ', userProfileButtonRef);
        //console.warn('userProfileButtonRef?.current = ', userProfileButtonRef?.current);
        let userProfileButton = userProfileButtonRef.current;
        if (userProfileButton) {
            //console.warn('userProfileButton = ', userProfileButton);
            //const { offsetWidth, offsetHeight } = userProfileButton;
            //console.log('offsetWidth = ', offsetWidth);
            //console.log('offsetHeight = ', offsetHeight);
            //userProfileButton
            userProfileButtonWidth = userProfileButton.offsetWidth;
            //console.warn('userProfileButtonWidth = ', userProfileButtonWidth);

            let xyPosition = getFixedPosition(userProfileButton);
            //console.warn('xyPosition = ', xyPosition);

            if (xyPosition) {
                userProfileMenu.style.left = xyPosition.left + 'px';
                userProfileMenu.style.top = (xyPosition.top + 35) + 'px';
                //return;
            }
        }
        
        let userProfileButtonHalfWidth = parseInt(userProfileButtonWidth / 2);
        //console.log('buttonHalfWidth = ', buttonHalfWidth);

        let userProfileMenuHalfWidth = parseInt(userProfileMenuWidth / 2);
        //console.log('menuHalfWidth = ', menuHalfWidth);

        if (userProfileMenuHalfWidth > 0 && userProfileMenuHalfWidth > userProfileButtonHalfWidth) {
            let menuHalfWidthWiderThanButtonHalfWidthPixels = userProfileMenuHalfWidth - userProfileButtonHalfWidth;
            //console.warn('menuHalfWidthWiderThanButtonHalfWidthPixels = ', menuHalfWidthWiderThanButtonHalfWidthPixels);
            //console.log('menuHalfWidthWiderThanButtonHalfWidthPixels = ', menuHalfWidthWiderThanButtonHalfWidthPixels);
            //userProfileMenu.style.marginLeft = -menuWiderThanButtonPixels + 'px';
            //userProfileMenu.style.marginLeft = -(menuHalfWidth - buttonHalfWidth) + 'px';
            userProfileMenu.style.marginLeft = -(menuHalfWidthWiderThanButtonHalfWidthPixels) + 'px';
        }
    };
    

    /*
    const getpricefeed = async () => {
        const url = process.env.REACT_APP_ADMIN_URL;
        await axios
            .get(url + '/admin/exchange/rate')
            .then(res => dispatch(setEuroValue(res.data?.result?.exchangeRate)));
    };

    useEffect(() => {
        getpricefeed();
        dataEncrypt();
        const myInterval = setInterval(() => {
            getpricefeed();
        }, 30000);

        return () => {
            clearInterval(myInterval);
        };
    });

    const dataEncrypt = async () => {
        axiosInstance.get('/user/getClientVariables').then(res => {
            dispatch(keyDetails(res?.data?.result));
            apiCall();
        });
    };
    */

    const getConfig = () => {
        let config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        return config;
    };

    /*
    useEffect(() => {
        console.warn('HomePage: useEffect(() => {');

        const config = getConfig();
        console.warn('    config = ', config);
        const getListCart = async () => {
            const res = await axiosInstance.get('/carts?limit=20&page=1', config);
            console.warn('    res = ', res);
            dispatch(empty());
            if (res.data) {
                dispatch(countCart(res.data.result.totalItems));
            }
        };
        
        if (userWalletDetails.account) {
            getListCart();
        }

    }, [userWalletDetails]);
    */

    /*
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    */

    return (
        <>

            {
                userAccessToken
                && (
                    <>

                        {/*
                        <OutsideClickHandler onOutsideClick = { (e) => onOutsideClickMobileUserHeader(e) }>
                        */}
                            <MobileUserHeader
                                isOpen = { isShowMobileUserHeader }
                                //closeModal = { () => setIsShowMobileUserHeader(false) }
                                closeModal = { () => handleCloseMobileUserHeader() }
                                //handleDisconnectFunction = { handleDisconnect }
                                loginRegisterFunctions = { props.loginRegisterFunctions }
                                //handleTokenExpiredFunction = { handleTokenExpired }
                                walletDetails = { walletDetails }
                                userDetails = { props.userDetails }
                                //walletDetailsObject = { walletDetailsObject }
                                onOutsideClickUserProfileMenu = { onOutsideClickUserProfileMenu }
                                />
                        {/*
                        </OutsideClickHandler>
                        */}

                        <OutsideClickHandler onOutsideClick = { (e) => onOutsideClickUserProfileMenu(e) }>
                            <UserProfileMenu
                                loginRegisterFunctions = { props.loginRegisterFunctions }
                                userDetails = { props.userDetails }
                                toggleShowUserProfileMenu = { toggleShowUserProfileMenu }
                                onOutsideClickUserProfileMenu = { onOutsideClickUserProfileMenu }
                                userProfileMenuRef = { userProfileMenuRef }
                                userProfileButtonRef = { userProfileButtonRef }
                                componentId = { 'profile-dropdown-menu' }
                                //componentClass = { 'profile-dropdown-menu' }
                                />
                        </OutsideClickHandler>

                        <NotificationsPopup
                            toggleShowNotificationsPopup = { toggleShowNotificationsPopup }
                            //handleShowNotificationsPopup = { handleShowNotificationsPopup }
                            isShowNotificationsPopup = { isShowNotificationsPopup }
                            notificationsPopupRef = { notificationsPopupRef }
                            //notificationsButtonRef = { notificationsButtonRef }
                            setIsShowNotificationsPopup = { setIsShowNotificationsPopup }
                            onOutsideClickNotificationsPopup = { onOutsideClickNotificationsPopup }
                            />

                    </>
                )
            }

            {/*
            <OutsideClickHandler onOutsideClick = { (e) => onOutsideClickMobileNav(e) }>
            */}
                <MobileNav
                    isOpen = { isShowMobileNav }
                    closeModal = { () => setIsShowMobileNav(false) }
                    //headerNav = { headerNav }
                    //handleDisconnectFunction = { handleDisconnect }
                    loginRegisterFunctions = { props.loginRegisterFunctions }
                    />
            {/*
            </OutsideClickHandler>
            */}

            <Main
                isHomePage = { props.isHomePage }
                userDetails = { props.userDetails }
                //connectWalletFunction = { props.connectWalletFunction }
                //clickConnectWalletFunction = { props.clickConnectWalletFunction }
                //chooseWalletType = { props.chooseWalletType }
                //showRegisterDialog = { props.showRegisterDialog }
                //showLoginDialog = { props.showLoginDialog }
                loginRegisterFunctions = { props.loginRegisterFunctions }
                handleShowMobileNav = { handleShowMobileNav }
                handleShowMobileUserHeader = { handleShowMobileUserHeader }
                userProfileMenuRef = { userProfileMenuRef }
                userProfileButtonRef = { userProfileButtonRef }
                toggleShowUserProfileMenu = { toggleShowUserProfileMenu }

                toggleShowNotificationsPopup = { toggleShowNotificationsPopup }
                //handleShowNotificationsPopup = { handleShowNotificationsPopup }
                isShowNotificationsPopup = { isShowNotificationsPopup }
                notificationsButtonRef = { notificationsButtonRef }
                onClickNotificationsButton = { onClickNotificationsButton }

                cartCount = { props.cartCount }
                />

            <Footer />

            {/*<DummyVictoria />*/}

        </>
    );
};

export default withRouter(HomePage);
