import React, { useEffect, useState, useRef } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { setEuroValue } from "../../redux/counterSlice";
import { axiosInstance } from "../../utils/API";
import { keyDetails } from "../../redux/keySlice";
import {apiCall} from '../../controller/utils'
import { NAMES_CONSTANTS } from '../../constants';
import MobileNav from '../Header/MobileNav';
//import DummyVictoria from '../DummyVictoria';
import MobileUserHeader from '../Header/MobileUserHeader';
import UserProfileMenu from '../Header/UserHeader/UserProfileMenu.js';
import { getPagePosition, getFixedPosition } from '../../utils/UserInterface';
import NotificationsPopup from '../Header/Notifications/NotificationsPopup.js';
import OutsideClickHandler from "react-outside-click-handler";

//import LoginModal from "../Header/LoginModal";

const Page = (props) => {
    //console.info('Page()');
    //console.debug('Page()', props);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        clearAllBodyScrollLocks();
    }, [pathname]);

    const dispatch = useDispatch();

    const userProfileMenuRef = useRef(null);
    const userProfileButtonRef = useRef(null);
    const notificationsPopupRef = useRef(null);
    const notificationsButtonRef = useRef(null);
    const notificationsButtonSvgRef = useRef(null);

    //let showUserProfileMenu = false;
    const [isShowMobileNav, setIsShowMobileNav] = useState(false);
    const [isShowMobileUserHeader, setIsShowMobileUserHeader] = useState(false);
    const [isShowUserProfileMenu, setIsShowUserProfileMenu] = useState(false);
    //const [isShowUserProfileMenu, setIsShowUserProfileMenu] = useState(showUserProfileMenu);
    const [isShowNotificationsPopup, setIsShowNotificationsPopup] = useState(false);

    const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    //console.debug('Page: userAccessToken = ', userAccessToken);
    const [walletDetails, setWalletDetails] = useState(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));
    //console.debug('Page: walletDetails = ', walletDetails);

    const onOutsideClickUserProfileMenu = (e) => {
        //console.warn('Page.onOutsideClickUserProfileMenu(e)', e);

        //console.warn('e.target = ', e.target);
        //console.warn('userProfileButtonRef.current = ', userProfileButtonRef.current);

        if (userProfileButtonRef.current.contains(e.target)) { // Short circuit this function if the button being clicked is a descendant of the User Profile button
            //console.warn('userProfileButtonRef.current.contains(e.target)');
            return;
        }

        //console.warn('isShowUserProfileMenu = ', isShowUserProfileMenu);
        if (isShowUserProfileMenu) {

            let showUserProfileMenu = !isShowUserProfileMenu;
            //console.warn('showUserProfileMenu = ', showUserProfileMenu);
            setIsShowUserProfileMenu(showUserProfileMenu);

            let userProfileMenu = userProfileMenuRef.current;
            //console.warn('userProfileMenu = ', userProfileMenu);

            if (userProfileMenu) {
                //console.log('userProfileMenu = ', userProfileMenu);
                //console.warn('userProfileMenu.style.display = ', userProfileMenu.style.display);
                if (showUserProfileMenu) {
                    userProfileMenu.style.display = 'block';
                } else {
                    userProfileMenu.style.display = 'none';
                }
            }
        }
    };

    const onOutsideClickNotificationsPopup = (e) => {
        //console.warn('Page.onOutsideClickNotificationsPopup(e)', e);

        //console.warn('e.target = ', e.target);
        //console.warn('notificationsButtonSvgRef.current = ', notificationsButtonSvgRef.current);

        if (notificationsButtonSvgRef.current == e.target) { // Short circuit this function if the button being clicked is the notifications button
            //console.warn('notificationsButtonSvgRef.current == e.target');
            return;
        }

        //console.warn('isShowNotificationsPopup = ', isShowNotificationsPopup);
        if (isShowNotificationsPopup) setIsShowNotificationsPopup(false);
    };

    const onClickNotificationsButton = (e) => {
        //console.warn('Page.onClickNotificationsButton(e)', e);

        //console.warn('e.target = ', e.target);
        notificationsButtonSvgRef.current = e.target;

        toggleShowNotificationsPopup(e.currentTarget);
    };

    const toggleShowNotificationsPopup = (notificationsButton) => {
        //console.warn('Page.toggleShowNotificationsPopup('+notificationsButton+')');

        //console.warn('isShowNotificationsPopup = ', isShowNotificationsPopup);
        //setIsShowNotificationsPopup(!isShowNotificationsPopup);
        let showNotificationsPopup = !isShowNotificationsPopup;
        //console.warn('showNotificationsPopup = ', showNotificationsPopup);
        setIsShowNotificationsPopup(showNotificationsPopup);

        let notificationsPopup = notificationsPopupRef.current;
        //console.warn('notificationsPopup = ', notificationsPopup);

        if (notificationsPopup) {
            //console.log('notificationsPopup = ', notificationsPopup);
            //console.warn('notificationsPopup.style.display = ', notificationsPopup.style.display);
            if (showNotificationsPopup) {
                notificationsPopup.style.display = 'block';
                //setTimeout(positionNotificationsPopup, 10, notificationsButton);
            } else {
                notificationsPopup.style.display = 'none';
            }
        }

        setTimeout(positionNotificationsPopup, 10, notificationsButton);
    };

    const positionNotificationsPopup = (notificationsButton) => {
        //console.warn('Page.positionNotificationsPopup('+notificationsButton+')');

        //console.warn('isShowNotificationsPopup = ', isShowNotificationsPopup);

        let notificationsButtonWidth;
        let notificationsPopupWidth;

        let notificationsPopup = notificationsPopupRef.current;
        if (notificationsPopup) {
            //console.warn('notificationsPopup = ', notificationsPopup);
            notificationsPopupWidth = notificationsPopup.offsetWidth;
            //console.warn('notificationsPopupWidth = ', notificationsPopupWidth);
        }

        if (notificationsButton) {

            notificationsButtonWidth = notificationsButton.offsetWidth;
            //console.warn('notificationsButtonWidth = ', notificationsButtonWidth);

            let xyPosition = getFixedPosition(notificationsButton);
            //console.warn('xyPosition = ', xyPosition);

            if (xyPosition) {
                notificationsPopup.style.left = xyPosition.left + 'px';
                notificationsPopup.style.top = (xyPosition.top + 50) + 'px';
                //return;
            }
        }

        /*
        let notificationsButtonHalfWidth = parseInt(notificationsButtonWidth / 2);
        console.log('notificationsButtonHalfWidth = ', notificationsButtonHalfWidth);

        let notificationsPopupHalfWidth = parseInt(notificationsPopupWidth / 2);
        console.log('notificationsPopupHalfWidth = ', notificationsPopupHalfWidth);

        if (notificationsPopupHalfWidth > 0 && notificationsPopupHalfWidth > notificationsButtonHalfWidth) {
            let notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels = notificationsPopupHalfWidth - notificationsButtonHalfWidth;
            console.log('notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels = ', notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels);
            notificationsPopup.style.marginLeft = -(notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels) + 'px';
        }
        */
        let notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels = 15;
        notificationsPopup.style.marginLeft = -(notificationsPopupHalfWidthWiderThanButtonHalfWidthPixels) + 'px';
    };

    const handleShowMobileNav = () => {
        //console.warn('Page.handleShowMobileNav()');
        setIsShowMobileNav(true);
    };

    const handleCloseMobileUserHeader = () => {
        //console.warn('Page.handleCloseMobileUserHeader()');
        setIsShowMobileUserHeader(false);
        //setIsShowUserProfileMenu(false);
        //toggleShowUserProfileMenu();
        hideUserProfileMenu();
    };

    const handleShowMobileUserHeader = () => {
        //console.warn('Page.handleShowMobileUserHeader()');
        setIsShowMobileUserHeader(true);
    };
    
    const hideUserProfileMenu = (e) => {
        //console.warn('Page.hideUserProfileMenu(e)');

        //console.warn('isShowUserProfileMenu = ', isShowUserProfileMenu);
        setIsShowUserProfileMenu(false);

        let userProfileMenu = userProfileMenuRef.current;
        //console.warn('userProfileMenu = ', userProfileMenu);
        if (userProfileMenu) {
            //console.warn('userProfileMenu.style.display = ', userProfileMenu.style.display);
            userProfileMenu.style.display = 'none';
        }
    };

    const toggleShowUserProfileMenu = (e) => {
        //console.warn('Page.toggleShowUserProfileMenu(e)');

        //console.warn('isShowUserProfileMenu = ', isShowUserProfileMenu);
        //setIsShowUserProfileMenu(!isShowUserProfileMenu);
        let showUserProfileMenu = !isShowUserProfileMenu;
        //console.warn('showUserProfileMenu = ', showUserProfileMenu);
        setIsShowUserProfileMenu(showUserProfileMenu);

        let userProfileMenu = userProfileMenuRef.current;
        //console.warn('userProfileMenu = ', userProfileMenu);

        if (userProfileMenu) {
            //console.log('userProfileMenu = ', userProfileMenu);
            //console.warn('userProfileMenu.style.display = ', userProfileMenu.style.display);
            if (showUserProfileMenu) {
                userProfileMenu.style.display = 'block';
                //setTimeout(positionUserProfileMenu, 1);
            } else {
                userProfileMenu.style.display = 'none';
            }
        }

        setTimeout(positionUserProfileMenu, 100);
    };

    const positionUserProfileMenu = () => {
        //console.warn('Page.positionUserProfileMenu()');

        //console.warn('isShowUserProfileMenu = ', isShowUserProfileMenu);

        let userProfileButtonWidth;
        let userProfileMenuWidth;

        //let userProfileMenuRef = props.userProfileMenuRef;
        //console.warn('userProfileMenuRef = ', userProfileMenuRef);
        //console.warn('userProfileMenuRef?.current = ', userProfileMenuRef?.current);
        let userProfileMenu = userProfileMenuRef.current;
        if (userProfileMenu) {
            //console.warn('userProfileMenu = ', userProfileMenu);
            //const { offsetWidth, offsetHeight } = userProfileMenu;
            //console.log('offsetWidth = ', offsetWidth);
            //console.log('offsetHeight = ', offsetHeight);
            userProfileMenuWidth = userProfileMenu.offsetWidth;
            //console.warn('userProfileMenuWidth = ', userProfileMenuWidth);
        }

        //console.warn('userProfileButtonRef = ', userProfileButtonRef);
        //console.warn('userProfileButtonRef?.current = ', userProfileButtonRef?.current);
        let userProfileButton = userProfileButtonRef.current;
        if (userProfileButton) {
            //console.warn('userProfileButton = ', userProfileButton);
            //const { offsetWidth, offsetHeight } = userProfileButton;
            //console.log('offsetWidth = ', offsetWidth);
            //console.log('offsetHeight = ', offsetHeight);
            //userProfileButton
            userProfileButtonWidth = userProfileButton.offsetWidth;
            //console.warn('userProfileButtonWidth = ', userProfileButtonWidth);

            let xyPosition = getFixedPosition(userProfileButton);
            //console.warn('xyPosition = ', xyPosition);

            if (xyPosition) {
                userProfileMenu.style.left = xyPosition.left + 'px';
                userProfileMenu.style.top = (xyPosition.top + 35) + 'px';
                //return;
            }
        }
        
        let userProfileButtonHalfWidth = parseInt(userProfileButtonWidth / 2);
        //console.log('buttonHalfWidth = ', buttonHalfWidth);

        let userProfileMenuHalfWidth = parseInt(userProfileMenuWidth / 2);
        //console.log('menuHalfWidth = ', menuHalfWidth);

        if (userProfileMenuHalfWidth > 0 && userProfileMenuHalfWidth > userProfileButtonHalfWidth) {
            let menuHalfWidthWiderThanButtonHalfWidthPixels = userProfileMenuHalfWidth - userProfileButtonHalfWidth;
            //console.warn('menuHalfWidthWiderThanButtonHalfWidthPixels = ', menuHalfWidthWiderThanButtonHalfWidthPixels);
            //console.log('menuHalfWidthWiderThanButtonHalfWidthPixels = ', menuHalfWidthWiderThanButtonHalfWidthPixels);
            //userProfileMenu.style.marginLeft = -menuWiderThanButtonPixels + 'px';
            //userProfileMenu.style.marginLeft = -(menuHalfWidth - buttonHalfWidth) + 'px';
            userProfileMenu.style.marginLeft = -(menuHalfWidthWiderThanButtonHalfWidthPixels) + 'px';
        }
    };


    //const [loginModal, setLoginModal] = useState(false);

    /*
    const getpricefeed = async () => {
        const url = process.env.REACT_APP_ADMIN_URL
        await axios
            .get(url+'/admin/exchange/rate')
            .then(res => dispatch(setEuroValue(res.data?.result?.exchangeRate)))
    }

    useEffect(() => {
        getpricefeed(); 
        dataEncrypt();
        const myInterval = setInterval(() => {
            getpricefeed()
        }, 30000);
        return () => {
            clearInterval(myInterval);
        };
    })

    const dataEncrypt = async ()=>{
        axiosInstance.get('/user/getClientVariables').then((res)=>{
            dispatch(keyDetails(res?.data?.result));   
            apiCall()
        });
    };
    */

    /*
    const loginClose = () => {
        
        setLoginModal(false)
        setConnectWalletBtnDisable(false)
    };
    */

    return (
        <>

            {/*
            {
                loginModal
                && <LoginModal
                    close = { () => loginClose() }
                    //loginMethod = { magicSocialLoginMethods }
                    //connectWallet = { handleConnectWallet }
                    //connectWallet = { props.connectWalletFunction }
                    //connectWallet = { connectWalletFunction }
                    //connectDisable = { connectWalletBtnDisable }
                />
            }
            */}

            {
                userAccessToken
                && (
                    <>

                        <MobileUserHeader
                            isOpen = { isShowMobileUserHeader }
                            //closeModal = { () => setIsShowMobileUserHeader(false) }
                            closeModal = { () => handleCloseMobileUserHeader() }
                            //handleDisconnectFunction = { handleDisconnect }
                            loginRegisterFunctions = { props.loginRegisterFunctions }
                            //handleTokenExpiredFunction = { handleTokenExpired }
                            walletDetails = { walletDetails }
                            userDetails = { props.userDetails }
                            //walletDetailsObject = { walletDetailsObject }
                            onOutsideClickUserProfileMenu = { onOutsideClickUserProfileMenu }
                            />

                        <OutsideClickHandler onOutsideClick = { (e) => onOutsideClickUserProfileMenu(e) }>                        
                            <UserProfileMenu
                                //handleDisconnectFunction = { props.handleDisconnectFunction }
                                //handleTokenExpiredFunction = { props.handleTokenExpiredFunction }
                                userDetails = { props.userDetails }
                                //ref = { userProfileMenuRef }
                                //userProfileMenuRef = { userProfileMenuRef }
                                //userProfileMenuRef = { props.userProfileMenuRef }
                                componentId = { 'profile-dropdown-menu' }

                                loginRegisterFunctions = { props.loginRegisterFunctions }
                                handleShowMobileNav = { handleShowMobileNav }
                                handleShowMobileUserHeader = { handleShowMobileUserHeader }
                                userProfileMenuRef = { userProfileMenuRef }
                                userProfileButtonRef = { userProfileButtonRef }
                                toggleShowUserProfileMenu = { toggleShowUserProfileMenu }
                                />
                        </OutsideClickHandler>

                        <NotificationsPopup
                            toggleShowNotificationsPopup = { toggleShowNotificationsPopup }
                            //handleShowNotificationsPopup = { handleShowNotificationsPopup }
                            isShowNotificationsPopup = { isShowNotificationsPopup }
                            notificationsPopupRef = { notificationsPopupRef }
                            //notificationsButtonRef = { notificationsButtonRef }
                            setIsShowNotificationsPopup = { setIsShowNotificationsPopup }
                            onOutsideClickNotificationsPopup = { onOutsideClickNotificationsPopup }
                            />

                    </>
                )
            }

            <MobileNav
                isOpen = { isShowMobileNav }
                closeModal = { () => setIsShowMobileNav(false) }
                //headerNav = { headerNav }
                //handleDisconnectFunction = { handleDisconnect }
                loginRegisterFunctions = { props.loginRegisterFunctions }
                />

            <Header
                className = "light"
                connectWalletFunction       = { props.connectWalletFunction }
                clickConnectWalletFunction  = { props.clickConnectWalletFunction }
                chooseWalletType            = { props.chooseWalletType }
                isHomePage                  = { props.isHomePage }
                userDetails                 = { props.userDetails }
                loginRegisterFunctions      = { props.loginRegisterFunctions }

                fetchListCart      = { props.fetchListCart }
                cartCount          = { props.cartCount }

                // Side menu props
                handleShowMobileNav         = { handleShowMobileNav }
                handleShowMobileUserHeader  = { handleShowMobileUserHeader }
                userProfileMenuRef          = { userProfileMenuRef }
                userProfileButtonRef        = { userProfileButtonRef }
                toggleShowUserProfileMenu   = { toggleShowUserProfileMenu }

                // Notifications popup props
                toggleShowNotificationsPopup = { toggleShowNotificationsPopup }
                isShowNotificationsPopup = { isShowNotificationsPopup }
                notificationsButtonRef = { notificationsButtonRef }
                onClickNotificationsButton = { onClickNotificationsButton }                
                />

            { props.children }

            <Footer />

            {/*<DummyVictoria />*/}
        </>
    );
};

export default withRouter(Page)