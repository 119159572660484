import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: '',
    user: {},
    euroValue: '',
    userVerifyStatus:null,
    profilePicValue:null,
    wallet:null,
    kycVerifyStatus: null
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = true
    },
    decrement: (state) => {
      state.value = false
      state.user = {}
    },
    details: (state, data) => {
      state.user.account = data.payload.account
      state.user.balance = data.payload.balance
    },
    setEuroValue: (state, data) => {
      state.euroValue = data.payload
    },
    storeUserVerifyStatus:(state,data)=>{
      state.userVerifyStatus = data.payload
    },
    setProfilePicValue : (state,data)=>{
      state.profilePicValue = data.payload
    },
    setKycVerifyStatus:(state,data)=>{
      state.kycVerifyStatus = data.payload
    },
    setWallet :(state,data)=>{
      state.wallet = data.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, details, setProvider, setEuroValue,storeUserVerifyStatus, setProfilePicValue, setWallet, setKycVerifyStatus } = counterSlice.actions

export default counterSlice.reducer