import React from "react";
import cn from "classnames";
import styles from "./Resell.module.sass";
import Image from "../Image";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
import { axiosInstance } from "../../utils/API";
import { useParams, useHistory } from "react-router";
import { NAMES_CONSTANTS } from '../../constants';

const ResellForm = ({
  className,
  value,
}) => {

  const [productDetails, setProductDetails] = React.useState();
  const [details, setDetails] = React.useState({ salePrice: "", saleType: "", currentPrice: "" });

  const { id } = useParams();
  const history = useHistory();

  const getProductDetails = async (id) => {
    await axiosInstance
      .get(`/asset/view/${id}`)
      .then((res) => {
        setProductDetails(res?.data?.result);
        setDetails({ ...details, currentPrice: res?.data?.result?.salePrice, saleType: res?.data?.result?.saleType })
      })
      .catch(() => { });
  };

  React.useEffect(() => {
    getProductDetails(id);
  }, [])

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { regularPrice, saleType } = details;
    let config = {
      headers: {
        Authorization: "Bearer "+localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN),
      },
    };
    let body = {
      regularPrice,
      saleType
    }
    axiosInstance
      .patch(`/asset/publish/${id}`, body, config)
      .then(res => {
        Swal.fire("Great!", "NFT Published For Resell", "success")
        history.push('/search');
      })
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.title}
        style={{
          borderBottom: "1px solid #999",
          width: "100%",
          padding: "10px 0px",
          fontSize: 20,
          marginBottom: 40
        }}
      >
        Resell Asset
      </div>
      <div style={{ marginBottom: 20 }}>
        {productDetails?.ipfsShortVideoUrl ||
          productDetails?.ipfsAudioUrl ?
          <div
            className="player-wrapper"
            style={{ position: "relative", width: "220px", height: 140, marginBottom: 20 }}
          >
            <ReactPlayer
              url={
                productDetails?.ipfsShortVideoUrl
                  ? productDetails.ipfsShortVideoUrl
                  : productDetails?.ipfsAudioUrl
              }
              width="100%"
              height="100%"
              controls={true}
              light={
                productDetails?.audioThumbnail && productDetails?.ipfsAudioUrl
                  ? productDetails?.audioThumbnail
                  : productDetails?.ipfsShortVideoUrl
                    ? false
                    : true
              }
              style={{ position: "absolute", top: "0", left: "0" }}
            />
          </div>
          : <img
            src={productDetails?.ipfsImageUrl}
            alt="logo.png"
            style={{ width: 300, height: 280, marginBottom: 18 }}
          ></img>
        }

        <p style={{ fontSize: 18 }}>{productDetails?.name}</p>
      </div>
      <form className={cn(styles.form, className)} action="" onSubmit={handleSubmit}>
        <div>
          <label className={styles.priceField} htmlFor={"currentprice"}>Current Price: </label>
          <input
            className={styles.input}
            value={details.currentPrice}
            name={"currentprice"}
            disabled
          />
          <Image className={styles.btn} alt="logo.png"
            src="/images/euro.png"
            srcDark="/images/euro.png">
          </Image>
        </div>
        {details.saleType === "fixed" &&
          <div>
            <label className={styles.priceField} htmlFor={"currentprice"}>Updated Price: </label>
            <input
              className={styles.input}
              value={details.salePrice}
              onChange={(e) => setDetails({ ...details, salePrice: parseInt(e.target.value) })}
              name={"salePrice"}
              type="number"
              min="0.000001"
              required
              step="any"
            />
            <Image className={styles.btn} alt="logo.png"
              src="/images/euro.png"
              srcDark="/images/euro.png">
            </Image>
          </div>
        }
        <div>
          <label className={styles.priceField} htmlFor={"currentprice"}>Sale Type: </label>
          <select
            className={styles.input}
            value={details.saleType}
            onChange={handleChange}
            name={"saleType"}
          >
            {/* <option value="auction">Auction</option> */}
            <option value="fixed">Fixed</option>
          </select>
        </div>

        {details.saleType === "auction" &&
          <>
            <div>
              <label className={styles.priceField} htmlFor={"currentprice"}>Opening price: </label>
              <input
                className={styles.input}
                value={details.currentPrice}
                name={"opening_price"}
                disabled
              />
            </div>
            <div>
              <label className={styles.priceField} htmlFor={"currentprice"}>Lowest price to accept: </label>
              <input
                className={styles.input}
                value={details.currentPrice}
                name={"lowest_price"}
                disabled
              />
            </div>
            <div>
              <label className={styles.priceField} htmlFor={"currentprice"}>Bid increment: </label>
              <input
                className={styles.input}
                value={details.currentPrice}
                name={""}
                disabled
              />
            </div>
          </>
        }

        <button className={"button-small"} style={{ borderRadius: 3 }} type="submit">
          Publish
        </button>
      </form>
    </div>
  );
};

export default ResellForm;
