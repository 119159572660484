import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";
import styles from "../Notifications.module.sass";
import moment from "moment";
//import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { NAMES_CONSTANTS } from '../../../../constants';
import {
    base_url
    , PopUpAlert
    , PopUpWarning
    , PopUpWarningThen
    , PopUpConfirmation
} from "../../../../controller/utils";

//const NotificationItem = ({ className, item }) => {
//const NotificationItem = (props) => {
//const NotificationItem = props => {
//*
const NotificationItem = ({
      className
    , item
    , closePopupMenu
    , dateFormat
    , refreshNotificationsList
}) => {
//*/

    /*
    let item = props.item
    , className = props.className
    , closePopupMenu = props.closePopupMenu
    , dateFormat = props.dateFormat
    , refreshNotificationsList = props.refreshNotificationsList;
    //*/

    console.warn('item = ', item);
    console.warn('className = ', className);
    console.warn('closePopupMenu = ', closePopupMenu);
    console.warn('dateFormat = ', dateFormat);
    console.warn('refreshNotificationsList = ', refreshNotificationsList);


    //const dateFormat = 'MMM DD, YYYY';
    //const dateFormat = 'ddd MMM DD YYYY hh:MM a';
    //const dateFormat = 'ddd, MMMM D, YYYY - h:MM a';

    const [visible, setVisible] = useState(false);

    const history = useHistory();

    const getConfig = () => {

        let localStorageKeyNameUserAccessToken = NAMES_CONSTANTS.USER_ACCESS_TOKEN;
        console.log('localStorageKeyNameUserAccessToken = ', localStorageKeyNameUserAccessToken);

        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(localStorageKeyNameUserAccessToken),
            }
        };
        
        return config
    };

    //const clickNotificationItem = (itemPath) => {
    const clickNotificationItem = (event, itemPath) => {
        console.warn('clickNotificationItem(event:'+event+', itemPath:"'+itemPath+'")');
        console.warn('clickNotificationItem(event, itemPath)', event, itemPath);

        history.push(itemPath);
        closePopupMenu(event);
    };

    /*
    const changeNotificationItem = (event, itemId) => {
        console.warn('changeNotificationItem(event:'+event+', itemId:'+itemId+')');
        //alert('changeNotificationItem(event:'+event+', itemId:'+itemId+')');

        console.warn('event.target.checked = ', event.target.checked);
        fetchUpdateNotificationItem(itemId, event.target.checked);
    };
    */

    const changeNotificationItem = (event, item) => {
        console.warn('changeNotificationItem(event:'+event+', item:'+item+')');
        //alert('changeNotificationItem(event:'+event+', itemId:'+itemId+')');

        console.warn('event.target.checked = ', event.target.checked);
        fetchUpdateNotificationItem(event.target, item);
    };

    //const fetchUpdateNotificationItem = (itemId, isRead) => {
    //    console.warn("NotificationItem.fetchUpdateNotificationItem(itemId, isRead)");
    const fetchUpdateNotificationItem = (checkboxElement, item) => {
        console.warn('fetchUpdateNotificationItem(checkboxElement:'+checkboxElement+', item:'+item+')');

        let fetchConfig = getConfig();
        let fetchPath = `${ base_url }update-notification?isRead=${ checkboxElement.checked }&id=${ item.id }`;
        //let fetchPath = `${ base_url }update-notification?isRead=${ isRead }&notificationId=${ itemId }`;
        console.warn('    fetchPath = ', fetchPath);
        //alert('    fetchPath = ' + fetchPath);

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                //console.warn('fetch(' + fetchPath + ').then(response)', response);
                //console.warn ('        App.fetchListCart().then(response)', response);
                console.warn ('        App.fetchListCart().then(response)');
                console.warn('        response.ok = ' + response.ok);
                console.warn('        response.status = ' + response.status);
                console.warn('        response.statusText = ' + response.statusText);

                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //Swal.close();
                    //return response.json();
                    response.json().then(function(responseJson) {
                        //console.warn('            fetch("'+fetchPath+'").then(responseJson)', responseJson);
                        console.warn('            responseJson = ', responseJson);
                        console.warn('            responseJson.result = ', responseJson.result);
                        //console.debug('            responseJson.result?.assets = ', responseJson.result?.assets);
                        //setCartAssetsList(responseJson.result?.assets);
                        //setCartDetail(responseJson.result);
                        //dispatch(countCart(responseJson.result?.assets.length)); // Total Assets in cart

                        //item.isRead = true;
                        //props.refreshNotificationsList();
                        refreshNotificationsList();
                    });

                } else { // E.g. 401 Unauthorized

                    //try {

                        response.json().then(function(responseJson) {
                            //console.warn('        fetch("'+fetchPath+'").then(responseJson)', responseJson);
                            console.warn('        responseJson = ', responseJson);
                            //console.warn('responseJson.result = ', responseJson.result);
                            //console.warn('responseJson.result?.assets = ', responseJson.result?.assets);
                            /*
                            setAssetList(responseJson.result?.assets);
                            setCartDetail(responseJson.result);
                            //dispatch(countCart(responseJson.result?.totalItems)); // Total fractions
                            dispatch(countCart(responseJson.result?.total)); // Total Assets in cart
                            */
                            console.warn('        response.status = ', response.status);
                            //response.status
                            if (response.status == 401) {
                                //setCartAssetsList([]);
                                //setCartDetail(null);
                                //dispatch(countCart(0)); // set cart count to zero
                            }
                            PopUpWarning(responseJson.message, responseJson.error, 'error');
                        })
                        .catch(function(responseText) {
                            console.error(responseText);
                            PopUpWarning('Unable to save the notification', response.statusText, 'warning');
                        });

                    //} catch(error) {
                    //    console.error(error);
                    //    PopUpWarning('Unable to save the notification', response.status, 'warning');
                    //}
                }
            })
            /*
            .then(function(responseJson) {
                console.warn('fetch("'+fetchPath+'").then(responseJson)', responseJson);
                console.warn(responseJson);
                console.warn('responseJson = ', responseJson);
                console.warn('responseJson.result = ', responseJson.result);
            })
            //*/
            ;

    }; // fetchUpdateNotificationItem()

    const killEvent = (e) => {
        e.stopPropagation();
        //e.preventDefault();
    };

    return (

        <button
            className = {
                cn(
                    styles.item
            //        className
            //        , styles.item
            //        //, 'primary-inverse medium'
            //        , 'medium'
                    //, 'rounded'
                    , 'notification'
                )
            }
            //className = 'item notification'
            //style = {{
                //backgroundColor: 'transparent'
            //    backgroundColor: 'var(--color-gray-100)'
            //    , border: 'var(--color-gray-150) 2px solid'
            //}}
            //to = { item.path }
            //onClick = { () => setVisible(!visible) }
            //onClick = { () => props.closePopupMenu() }
            //onClick = { () => closePopupMenu() }
            //onClick = { closePopupMenu }
            //onClick = { clickNotificationItem }
            //onClick = { () => clickNotificationItem(item.path) }
            onClick = { (e) => clickNotificationItem(e, item.path) }
            >

            <div
                //className = { styles.date }
                //className = { styles.item }
                //style = {{ textAlign: 'right' }}
                style = {{
                      //display: 'inline-block'
                      display: 'block'
                    , marginRight: '0'
                    , marginTop: '0'
                    , textAlign: 'right'
                    , verticalAlign: 'top'
                    //, fontSize: '.8em'
                    //, color: 'var(--color-gray-600)'
                    //, fontFamily: '"Open Sans Light"'
                    //, fontFamily: '"Open Sans"'
                    //, fontWeight: '500'
                    //, paddingBottom: '5px'
                    //, marginTop: '-16px'
                    //, backgroundColor: 'var(--color-gray-200)'
                }}>
                <div
                    //className = { styles.date }
                    //className = { styles.item }
                    //style = {{ textAlign: 'right' }}
                    style = {{
                          display: 'inline-block'
                          //display: 'block'
                        , marginRight: '0'
                        , marginTop: '0'
                        , textAlign: 'right'
                        , verticalAlign: 'top'
                        , fontSize: '.8em'
                        , color: 'var(--color-gray-600)'
                        //, fontFamily: '"Open Sans Light"'
                        , fontFamily: '"Open Sans"'
                        , fontWeight: '500'
                        //, paddingBottom: '5px'
                        //, marginTop: '-16px'
                        , backgroundColor: 'var(--color-gray-150)'
                        , borderTopLeftRadius: '0px'
                        , borderTopRightRadius: '5px'
                        , borderBottomLeftRadius: '8px'
                        , paddingLeft: '5px'
                        , paddingRight: '5px'
                    }}>
                    { moment(item.createdAt).format(dateFormat) }
                </div>
            </div>

            <div
                className = 'notifications-button'
                //style = {{
                //      display: 'block'
                //}}
                >

                {
                    item.image
                    && (
                        <div className={styles.preview}>
                            <img src={item.image} alt="Notification" />
                        </div>
                    )
                }

                <div
                    className={styles.details}
                    //style = {{ textAlign: 'right' }}
                    >

                    {/*
                    <div
                        className = { styles.date }
                        //style = {{ textAlign: 'right' }}
                        style = {{
                              textAlign: 'right'
                            , fontSize: '.8em'
                            , marginRight: '0'
                            //, color: 'var(--color-gray-600)'
                            //, fontFamily: '"Open Sans Light"'
                            //, fontFamily: '"Open Sans"'
                            //, fontWeight: '500'
                            //, paddingBottom: '5px'
                            //, marginTop: '-16px'
                            //, backgroundColor: 'var(--color-gray-200)'
                        }} >
                        <div
                            className = { styles.date }
                            //style = {{ textAlign: 'right' }}
                            style = {{
                                display: 'inline-block'
                                , marginRight: '0'
                                , textAlign: 'right'
                                , fontSize: '.8em'
                                , color: 'var(--color-gray-600)'
                                //, fontFamily: '"Open Sans Light"'
                                , fontFamily: '"Open Sans"'
                                , fontWeight: '500'
                                , paddingBottom: '5px'
                                , marginTop: '-16px'
                                , backgroundColor: 'var(--color-gray-200)'
                            }}>
                            { moment(item.createdAt).format(dateFormat) }
                        </div>
                    </div>
                    */}

                    <div
                        className = { styles.subtitle }
                        style = {{
                            fontSize: '1.1em'
                            , textAlign: 'left'
                        }}
                        >
                        { item.title }
                    </div>
                    <div
                        className = { styles.price }
                        style = {{
                            fontSize: '.9em'
                            , color: 'var(--color-gray-700)'
                            , fontFamily: '"Open Sans"'
                            , textAlign: 'left'
                        }}
                        >
                        { item.description }
                    </div>
                    {/*
                    <div className={styles.date}>{item.createdAt}</div>
                    */}

                </div>

                {/*
                <div
                    className={styles.status}
                    //style={{ backgroundColor: item.color }}
                    style={{ backgroundColor: 'red' }}
                    ></div>
                */}

                <div
                    style = {{
                        display: 'inline-block'
                        //, marginTop: '-10px'
                        , color: 'var(--color-gray-500)'
                        , fontSize: '11px'
                    }}
                    >
                    {/*
                    <RadioGroup
                        classes={{
                            //root: classes.radioGroupRoot,
                            //label: classes.label,
                        }}
                        name="isEditionMintToAdmin"
                        //value={state?.isEditionMintToAdmin}
                        //onChange={(e) => handleChange(e)}
                        >
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                            //disabled={isEdit ? true : false}
                            />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                            //disabled={isEdit ? true : false}
                            />
                    </RadioGroup>
                    <Radio
                    */}
                    Read<br/>
                    <Checkbox
                        //checked = { item.isRead }
                        checked = { item.isRead ? item.isRead : false }
                        onClick = { (e) => killEvent(e) }
                        //onChange = { (e) => changeNotificationItem(e, item.id) }
                        onChange = { (e) => changeNotificationItem(e, item) }
                        style = {{
                            marginTop: '-8px'
                            //, borderColor: 'var(--color-gray-500)'
                            //, border: 'var(--color-gray-500) 2px solid'
                        }} />
                </div>

            </div>

        </button>
    );
};

export default NotificationItem;
