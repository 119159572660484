import {
    useRef
    , forwardRef
    , useEffect
} from 'react';
import ReactDOM, { createPortal } from "react-dom";
//import { ReactDOM, createPortal } from 'react-dom';
//import { createPortal } from 'react-dom';
import { getPagePosition, getFixedPosition } from '../../utils/UserInterface';

const MapMarker = props => {
    //console.warn('MapMarker');
    //console.warn('MapMarker', props);

    const markerPopupReference = useRef(null);

    let circleRadius = parseInt(props.circleRadius);
    let fillColor = props.fillColor;
    let widthHeight = parseInt(props.widthHeight);
    let viewBox = '0 0 ' + widthHeight + ' ' + widthHeight;

    //console.warn('viewBox = ', viewBox);
    //console.warn('fillColor = ', fillColor);
    //console.warn('widthHeight = ', widthHeight);
    //console.warn('typeof widthHeight = ', typeof widthHeight);

    //let svgWidthHeight = widthHeight + 4;
    //console.warn('svgWidthHeight = ', svgWidthHeight);
    //console.warn('typeof svgWidthHeight = ', typeof svgWidthHeight);

    //console.warn('circleRadius = ', circleRadius);
    let outsideCircleRadius = circleRadius + 2;
    //console.warn('outsideCircleRadius = ', outsideCircleRadius);

    let centerX = widthHeight / 2;
    let centerY = centerX;
    //let circleX = widthHeight / 2;
    //let circleY = circleX;

    let circleStyleObject = {
        fill: fillColor
        , opacity: '0.66'
    };

    let svgStyleObject = {
        //  width: svgWidthHeight
        width: widthHeight
        //, height: svgWidthHeight
        , height: widthHeight
        //, backgroundColor: 'red'
        //, fill: 'red'
        //, border: 'black 1px solid'
        //, marginLeft: '-'+centerX+'px'
        //, marginTop: '-'+centerX+'px'
    };

    useEffect(()=> {
        //console.warn('MapMarker: useEffect(()=> {...');

        //console.warn('props.lat = ', props.lat);
        //setLat(props.lat);
        //setLong(props.lng);
        //console.warn(userLat, userLng);

        let assetLatitude = props.lat, assetLongitude = props.lng;
        let assetLatLng = { lat: assetLatitude, lng: assetLongitude };
        //let assetLatLng = new mapsApiReference.current.LatLng(assetLatitude, assetLongitude);
        //console.warn('    assetLatLng = ', assetLatLng);
        //console.warn('    typeof assetLatLng = ', typeof assetLatLng);
        //asset.latLng = assetLatLng;
        props.asset.latLng = assetLatLng;
    }, []);

    const TestElement = props => {
        //return <div style = {{ backgroundColor: 'red', width: '10px', height: '10px', borderRadius: '10px' }}>&nbsp;</div>
        
        let styleObject = {
            //  width: svgWidthHeight
            //width: widthHeight
            //, height: svgWidthHeight
            //, height: widthHeight
            //, backgroundColor: 'red'
            //, fill: 'red'
            //, border: 'black 1px solid'
            //, marginLeft: '-'+centerX+'px'
            //, marginTop: '-'+centerX+'px'
            backgroundColor: 'red'
            , position: 'absolute'
            //, top: props.xyPosition.top
            //, left: props.xyPosition.left
            , marginTop: '-20px'
            , marginLeft: '-20px'
        };
        return (
            <div
                style = { styleObject }
                //ref = { markerPopupReference }
                >
                { props.asset.name }
            </div>
        )
    };

    
    const onMouseLeaveMarker = (e) => {
        console.warn('onMouseLeaveMarker()', e);
        //console.warn('props.asset = ', props.asset);

        markerPopupReference.current.style.display = 'none';
        console.warn('markerPopupReference.current.style = ', markerPopupReference.current.style);
    };

    //const onClickMarker = () => {
    const onClickMarker = (e) => {
        console.warn('onClickMarker()', e);
        console.warn('props.asset = ', props.asset);
        console.warn('e.target = ', e.target);
        console.warn('e.pageX = ', e.pageX);
        console.warn('e.pageY = ', e.pageY);

        //let xyPosition = getFixedPosition(e.target);
        let xyPosition = getPagePosition(e.target);
        console.warn('xyPosition = ', xyPosition);

        //ReactDOM.render(
        //createPortal(
        /*
        ReactDOM.createPortal(
            <TestElement
                xyPosition = { xyPosition }
                asset = { props.asset }
                ref = { markerPopupReference }
                />
            //, document.getElementById("root")
            //, document.getElementsByTagName('body')[0]
            , document.getElementById("map-markers")
        );
        */
        console.warn('markerPopupReference.current = ', markerPopupReference.current);
        console.warn('markerPopupReference.current.style = ', markerPopupReference.current.style);
        console.warn('parseInt(xyPosition.top).toString() = ', parseInt(xyPosition.top).toString());
        markerPopupReference.current.style.top = parseInt(xyPosition.top) +'px';
        markerPopupReference.current.style.left = parseInt(xyPosition.left) +'px';
        markerPopupReference.current.style.display = 'inline-block';
        console.warn('markerPopupReference.current.style = ', markerPopupReference.current.style);

        /*
        let styleObject = {
            backgroundColor: 'red'
            , position: 'absolute'
            , top: xyPosition.top
            , left: xyPosition.left
            , marginTop: '-20px'
            , marginLeft: '-20px'
        };
        markerPopupReference.current.style = { styleObject };
        */
    };

    const Tooltip = forwardRef((props, ref) => (
        createPortal(
            <div
                ref = { ref }
                style = {{
                    display: 'none'
                    , position: 'absolute'
                    , paddingTop: '0px'
                    , maxWidth: '300px'
                    , maxHeight: '486px'
                    , minWidth: '0px'
                    //, -webkit-box-sizing: 'border-box'
                    , boxSizing: 'border-box'
                    , overflow: 'hidden'
                    , top: '0'
                    , left: '0'
                    //, -webkit-transform: 'translate3d(-50%,-100%,0)'
                    , transform: 'translate3d(-50%,-100%,0)'
                    , backgroundColor: 'white'
                    , borderRadius: '8px'
                    , padding: '12px'
                    , paddingTop: '12px'
                    //, -webkit-box-shadow: '0 2px 7px 1px rgba(0,0,0,.3)'
                    , boxShadow: '0 2px 7px 1px rgba(0,0,0,.3)'
                    , display: '-webkit-box'
                    , display: '-webkit-flex'
                    , display: 'flex'
                    //, -webkit-box-orient: 'vertical'
                    //, -webkit-box-direction: 'normal'
                    //, -webkit-flex-direction: 'column'
                    , flexDirection: 'column'
                    , fontWeight: '300'
                    , fontSize: '13px'
                }}
                >

                <img
                    src = { props.asset.mediaPreviewUrl }
                    style = {{ width: '100%' }}
                    />

                <div
                    style = {{
                          width: '100%'
                        , color: 'var(--color-gray-800, #343A40)'
                        , fontFamily: 'Roboto Slab'
                        , fontSize: '16px'
                        , fontStyle: 'normal'
                        , fontWeight: '400'
                        , lineHeight: '18px'
                        , whiteSpace: 'nowrap'
                        , textOverflow: 'ellipsis'
                        , paddingTop: '8px'
                        , textAlign: 'center'
                        , margin: 'auto'
                    }}>
                    { props.asset.name }
                </div>
            </div>
            //, document.body
            , document.getElementById("map-markers")
        )
    ));

    return (
        <div>

            {/*
                ReactDOM.createPortal(
                    <TestElement
                        //xyPosition = { xyPosition }
                        asset = { props.asset }
                        //ref = { markerPopupReference }
                        />
                    //, document.getElementById("root")
                    //, document.getElementsByTagName('body')[0]
                    , document.getElementById("map-markers")
                )
            */}
            <Tooltip
                ref = { markerPopupReference }
                asset = { props.asset }
                />

            <svg
                viewBox = { viewBox }
                xmlns = 'http://www.w3.org/2000/svg'
                style = { svgStyleObject }
                //onclick = { function (e) { alert('onclick') } }
                //onClick = { onClickMarker }
                onMouseEnter = { onClickMarker }
                onMouseLeave = { onMouseLeaveMarker }
                >

                {/*
                */}
                <circle
                    cx = { centerX }
                    cy = { centerY }
                    r = { outsideCircleRadius -2 }
                    stroke = '#fff'
                    strokeWidth = '2'
                    fill = 'none'
                    //style = {{ fill: '#fff' }}
                    //onclick = { function (e) { alert('onclick circle') } }
                    />

                <circle
                    cx = { centerX }
                    cy = { centerY }
                    r = { circleRadius }
                    style = { circleStyleObject }
                    />

            </svg>
        </div>
    )
};

export default MapMarker;
