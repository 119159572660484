import React, { useEffect, useState, useRef } from "react";
//import { Link } from "react-router-dom";
//import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./UserHeader.module.sass";
//import Icon from "../../Icon";
//import Theme from "../../Theme";
import { useDispatch, useSelector } from "react-redux";
//import Swal from "sweetalert2";
//import UserProfileMenu from "./UserProfileMenu";
import { axiosInstance } from "../../../utils/API";
import { numberWithCommas } from '../../../utils/formatPricingNumber';
import { PopUpAlert, } from "../../../controller/utils";
import { storeUserVerifyStatus, setProfilePicValue, setWallet, setKycVerifyStatus } from "../../../redux/counterSlice";
import { searchAssets, clearSearchAssets } from "../../../redux/assetSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotificationsButton from "../Notifications/NotificationsButton.js";
//import { UserProfileMenu } from "./UserProfileMenu";
import { NAMES_CONSTANTS } from '../../../constants';
//import Web3Modal from "web3modal";

const UserHeader = (props) => {
    console.info('UserHeader()');
    console.debug('UserHeader()', props);
    console.debug('UserHeader: props.toggleShowUserProfileMenu = ', props.toggleShowUserProfileMenu);

    //let signedIn = props.signedIn;

    //const userProfileMenuRef = useRef(null);
    //const userProfileButtonRef = useRef(null);

    const history = useHistory()
    const dispatch = useDispatch();
    //let count = useSelector(state => state.cart.countCart)
    //if (props?.assets) count = props?.assets?.length;
    const storeSearchText = useSelector(state => state.asset.searchText)

    //console.warn('UserHeader: count = ', count);
    console.warn('UserHeader: props.cartCount = ', props?.cartCount);
    if (props?.assets && props?.assets.length) console.warn('UserHeader: props.assets = ', props?.assets);

    //const [details, setDetails] = useState(localStorage.getItem('cs_details'));
    const [visible, setVisible] = useState(false);
    const [profile, setProfile] = useState('');
    const [walletDetails, setWalletDetails] = useState(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));
    const [searchText, setSearchText] = useState(storeSearchText || '');

    let walletDetailsObject;
    let tokenBal;
    let profilePicUrl;

    if (walletDetails) {
    //} else if (walletDetails) {
        console.log('UserHeader: walletDetails', walletDetails);
        walletDetailsObject = JSON.parse(walletDetails);
        console.log('UserHeader: walletDetailsObject', walletDetailsObject);
        tokenBal = walletDetailsObject.balance;
        if (tokenBal === 0) tokenBal = 75000;
        profilePicUrl = walletDetailsObject.profilePicUrl;
        console.log('UserHeader: profilePicUrl', profilePicUrl);
    }

    console.log('UserHeader: props.userDetails = ', props?.userDetails);
    console.log('UserHeader: props.userDetails.wallet = ', props?.userDetails?.wallet);
    if (props?.userDetails?.wallet) {
        console.log('UserHeader: props.userDetails.wallet = ', props.userDetails.wallet);
        console.log('UserHeader: props.userDetails.wallet.totalBalance = ', props.userDetails?.wallet?.totalBalance);
        //tokenBal = props.userDetails?.wallet?.totalBalance;
        console.log('UserHeader: props.userDetails.wallet.currentBalance = ', props.userDetails?.wallet?.currentBalance);
        tokenBal = props.userDetails?.wallet?.currentBalance;
    }

    const getConfig = () => {
        //if (!signedIn) {
        if (!props.signedIn) {
            PopUpAlert('Alert..', 'No wallet connection found', 'error');
            return;
        }
        let config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        return config
    };

    React.useEffect(() => {
        console.log('UserHeader: React.useEffect()');
        console.log('props.signedIn = ', props.signedIn);
        //if (signedIn) {
        if (props.signedIn) {
            setWalletDetails(JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS)));
        }
    }
      //, [signedIn, profile]
      , [props.signedIn, profile]
    );

    useEffect(() => {
        console.log('UserHeader: useEffect()');
        console.log('storeSearchText = ', storeSearchText);
        if (storeSearchText) {
            const timeOut = setTimeout(() => {
                dispatch(clearSearchAssets())
            }, 1000)
            return () => clearTimeout(timeOut)
        }
    }, [storeSearchText])

    /*
    const getWalletDetails = async () => {
        console.log('getWalletDetails()');
        alert('getWalletDetails()');

        const config = getConfig();
        console.log('config', config);

        await axiosInstance
            .get(`user/get-profile`, config)
            .then((res) => {
                console.log('res', res);
                const { profilePicUrl } = res.data?.result;
                console.log('profilePicUrl', profilePicUrl);
                dispatch(setWallet(res?.data?.result?.wallet))
                dispatch(storeUserVerifyStatus(res?.data?.result?.status))
                dispatch(setProfilePicValue(profilePicUrl))
                dispatch(setKycVerifyStatus(res?.data?.result?.kycVerified))
                setProfile(profilePicUrl);
                updateHeaderProfileImage(profilePicUrl);
            })
            .catch((err) => {
              if (err.response.data.message === 'Your account is blocked by Admin') {
                PopUpAlert("Alert..", err.response.data.message, "error").then(() => {
                  document.getElementById('connectWallet').click()
                })
              }
            });
    };
    */

    const updateHeaderProfileImage = (x) => {
      let profileImageElement = document.getElementById('header-profile-image');
      profileImageElement.setAttribute('src', x);
    };

    /*
    const showHideProfileMenu = () => {
      let menu = document.getElementById('profile-dropdown-menu');
      if (menu) menu.style.display = 'block';
    };
    */

    const handleClose = () => {
      // 
      // 
      setVisible(false);
      //setSubcategory({});
    };

    const toggleVisible2 = (e) => {
        console.log('toggleVisible2(e)');
        alert('toggleVisible2(e)');
        props.toggleShowUserProfileMenu();
    };

    /*
    const toggleVisible = (e) => {
        console.log('toggleVisible(e)');

        setVisible(!visible);

        //let userProfileButton = userProfileButtonRef.current;
        let userProfileMenu = props.userProfileMenuRef.current;
        if (userProfileMenu) {
            console.log('userProfileMenu = ', userProfileMenu);
            console.log('userProfileMenu.style.display = ', userProfileMenu.style.display);
            //userProfileMenu.style.display = 'none';
            if (visible) {
                userProfileMenu.style.display = 'none';
            } else {
                userProfileMenu.style.display = 'block';
            }
        }

        setTimeout(
            checkMenuPosition
            , 1
        );
    };

    const checkMenuPosition = () => {
        console.log('checkMenuPosition()');

        let buttonWidth;
        let menuWidth;

        //let userProfileButtonRef = props.userProfileButtonRef;
        console.log('userProfileButtonRef = ', userProfileButtonRef);
        console.log('userProfileButtonRef?.current = ', userProfileButtonRef?.current);
        let userProfileButton = userProfileButtonRef.current;
        if (userProfileButton) {
            console.log('userProfileButton = ', userProfileButton);
            //const { offsetWidth, offsetHeight } = userProfileButton;
            //console.log('offsetWidth = ', offsetWidth);
            //console.log('offsetHeight = ', offsetHeight);
            //userProfileButton
            buttonWidth = userProfileButton.offsetWidth;
            console.log('buttonWidth = ', buttonWidth);
        }

        let userProfileMenuRef = props.userProfileMenuRef;
        console.log('userProfileMenuRef = ', userProfileMenuRef);
        console.log('userProfileMenuRef?.current = ', userProfileMenuRef?.current);
        let userProfileMenu = userProfileMenuRef.current;
        if (userProfileMenu) {
            console.log('userProfileMenu = ', userProfileMenu);
            //const { offsetWidth, offsetHeight } = userProfileMenu;
            //console.log('offsetWidth = ', offsetWidth);
            //console.log('offsetHeight = ', offsetHeight);
            menuWidth = userProfileMenu.offsetWidth;
            console.log('menuWidth = ', menuWidth);
        }

        //let midPoint = menuWidth;
        //let buttonMidPoint = menuWidth;
        let buttonHalfWidth = parseInt(buttonWidth / 2);
        console.log('buttonHalfWidth = ', buttonHalfWidth);

        let menuHalfWidth = parseInt(menuWidth / 2);
        console.log('menuHalfWidth = ', menuHalfWidth);

        if (menuHalfWidth > 0 && menuHalfWidth > buttonHalfWidth) {
            let menuHalfWidthWiderThanButtonHalfWidthPixels = menuHalfWidth - buttonHalfWidth;
            //console.log('menuHalfWidthWiderThanButtonHalfWidthPixels = ', menuHalfWidthWiderThanButtonHalfWidthPixels);
            //userProfileMenu.style.marginLeft = -menuWiderThanButtonPixels + 'px';
            userProfileMenu.style.marginLeft = -(menuHalfWidth - buttonHalfWidth) + 'px';
        }
    };
    */

    const handleSearch = () => {
      if (searchText) {
        dispatch(searchAssets(searchText))
        history.push('/search/all')
      }
    }

    /*
    const handleOnKeySearch = (e) => {
        if (e.key === 'Enter') {
            console.error(e.key, 'key')
            handleSearch()
        }
    }
    */

    return (

        <div
            className={"notifications"}
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "spaceBetween", alignItems: "center" }}
            >

            {/*
            <div className={"search-box"}>
              <input type="text" placeholder="Search" onChange={(e) => setSearchText(e.target.value)} onKeyDown={(e) => handleOnKeySearch(e)} />
              <div className={styles.searchBtn} onClick={handleSearch}>
                <img src="/images/icons/search-icon-dark.png" style={{ verticalAlign: "middle" }} />
              </div>
            </div>
            */}

            <React.Fragment>
                <OutsideClickHandler onOutsideClick={() => handleClose()}>
                    <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>

                        

                        {/* Shopping cart icon and count badge... */}
                        {
                            //props?.assets?.length > 0
                            props?.cartCount > 0
                            &&
                                <div
                                    style = {{ position: "relative", cursor: "pointer" }}
                                    onClick={() => history.push("/cart")}>

                                    <img
                                        className = 'svg-icon'
                                        src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                        alt = 'Shopping cart icon'
                                        width = { "35px" }
                                        //height={"30px"}
                                        />

                                    <div
                                        style = {{
                                            position: "absolute"
                                            , top: 22, left: 22
                                            //width: '30px', height: '30px'
                                            , border: "1px solid red"
                                            , borderRadius: "50%"
                                            , display: "flex"
                                            , justifyContent: "center"
                                            , alignItems: "center"
                                            , background: "red"
                                            , cursor: "pointer"
                                            , minWidth: "15px"
                                            , minHeight: "15px"
                                        }}>

                                        <div
                                            style = {{
                                                fontSize: "15px"
                                                , lineHeight: "15px"
                                                , color: "#fff"
                                                //, padding: "0 10px"
                                                //, minWidth: "20px"
                                                //, minHeight: "20px"
                                                , verticalAlign: "middle"
                                                , textAlign: "center"
                                                , margin: "auto"
                                            }}
                                        >
                                        {/*
                                        { count || props?.assets?.length }
                                        { count }
                                        */}
                                        { props.cartCount }
                                        </div>
                                    </div>
                                </div>
                        }

                        <NotificationsButton
                            className = { styles.notification_button}
                            //style = {{ padding: '0px 10px' }}
                            toggleShowNotificationsPopup = { props.toggleShowNotificationsPopup }
                            handleShowNotificationsPopup = { props.handleShowNotificationsPopup }
                            notificationsButtonRef = { props.notificationsButtonRef }
                            onClickNotificationsButton = { props.onClickNotificationsButton }
                            />

                        <div
                            className={"profile-box"}
                            style={{ position: "relative", userSelect: "none" }}
                            //onclick="toggleShowHide('profile-dropdown-menu')"
                            //onClick = {() =>
                            //onClick = function() {
                            //    let menu = document.getElementById('profile-dropdown-menu');
                            //    if (menu) menu.style.display = 'block';
                            //};
                            //onClick={(e) => showHideProfileMenu()}
                            //onClick = {toggleVisible}
                            //onClick = { (e) => toggleVisible() }
                            //onClick = { (e) => toggleVisible2() }
                            onClick = { (e) => props.toggleShowUserProfileMenu() }
                            ref = { props.userProfileButtonRef }
                            //ref = { userProfileButtonRef }
                            >
                            <div
                                className = { "table button" }
                                //onClick={(e) => toggleVisible(e)}
                                //onClick = {toggleVisible}
                                style = {{ cursor: "pointer" }}
                                >
                                <div className={"row"}>
                                    <div className={"cell"} style={{ outline: "blue 0px solid" }}>
                                        {/*
                                        <img src="/images/avatar-icon-large.png" className={ "profile-image" } />
                                        */}
                                        <img
                                            //className = { "profile-image" }
                                            //className = 'svg-icon'
                                            className = { "profile-image" }
                                            id = "header-profile-image"
                                            //src = "/images/avatar-icon-large.png"
                                            //src = { profilePicUrl ? profilePicUrl : "/images/avatar-icon-large.png" }
                                            //src = { profilePicUrl ? profilePicUrl : "/images/icons/iconmonstr-user-20.svg" }
                                            src = {
                                                props?.userDetails?.profilePicUrl
                                                || "/images/icons/iconmonstr-user-20.svg"
                                            }
                                            />
                                    </div>
                                    <div className = { "cell" } style = {{ verticalAlign: "middle" }}>
                                        {/* 7.00698 <span>MATIC</span> */}
                                        <span
                                            style = {{ marginRight: 5 }}>
                                            {
                                                tokenBal
                                                ? numberWithCommas(parseFloat(tokenBal)?.toFixed(2))
                                                : 0
                                            }
                                        </span>
                                        <span
                                            //className={styles.currency}
                                            className = 'currency'
                                            >USD</span>
                                    </div>
                                </div>
                            </div>

                            {/*
                                //visible
                                //&& (
                                    <UserProfileMenu
                                        //handleDisconnectFunction = { props.handleDisconnectFunction }
                                        loginRegisterFunctions = { props.loginRegisterFunctions }
                                        handleTokenExpiredFunction = { props.handleTokenExpiredFunction }
                                        userDetails = { props.userDetails }
                                        //ref = { userProfileMenuRef }
                                        //userProfileMenuRef = { userProfileMenuRef }
                                        userProfileMenuRef = { props.userProfileMenuRef }
                                        />
                                //)
                            */}
                        </div>

                    </div>
                </OutsideClickHandler>
            </React.Fragment>

            {/*
            <Notification className={styles.notification_button} />
            <Notifications
                className = { styles.notification_button}
                //style = {{ padding: '0px 10px' }}
                />
            */}
            
        </div>
    );
};

export default UserHeader;
