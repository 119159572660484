import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./AddWalletAddressModal.scss";

const AddWalletAddressModal = ({ isOpen = false, closeModal }) => {
  const history = useHistory();

  const [walletCode, setWalletCode] = useState("");

  const handleCloseModal = () => {
    closeModal();
  };

  const handleAdd = () => {
    closeModal();
  }

  return (
    <>
      {isOpen && <div className="modal-overlay" onClick={handleCloseModal} />}
      <div className={`add-wallet-address-modal-container ${isOpen ? "show" : ""}`}>
        <span className="close" onClick={handleCloseModal} title="Close">
          <svg id="icon-close" viewBox="0 0 20 20" fill='#black' height="20px" width="20px">
            <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
          </svg>
        </span>
        <div className="modal-content">
          <div className="modal-header">Wallet Address</div>
          <div className="modal-form">
            <div className="label text-right">Please add your receiver wallet address </div>
            <div className="add-wallet-address-input">
              <input
                type="text"
                name="wallet_address"
                placeholder="ex. 0428570243752354hjofuijwr0923"
                value={walletCode || ""}
                onChange={(e) => setWalletCode(e.target.value)}
              />
            </div>
            <button className="add-wallet-address-btn btn" onClick={handleAdd}>
              Add
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddWalletAddressModal;
