import { useEffect } from "react";
//import { useLocation } from "react-router-dom";
import "./TermsAndConditions.css";
//import cn from "classnames";
import styles from './terms.module.sass';

const TermsAndConditions = () => {

    let termlyPolicyId = "73d2c83a-7bfb-4017-9cec-2a287628622e";

    useEffect(() => {
        //let termlyScriptElement = document.getElementById('termly-jssdk')
        //if (!termlyScriptElement) {
            const script = document.createElement("script");
            script.id = 'termly-jssdk';
            script.src = "https://app.termly.io/embed-policy.min.js";
            script.async = true;
            document.body.appendChild(script);
        //}
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <main className = 'edit-profile'>

            <section
                className = 'form'
                style = {{
                    backgroundColor: "var(--color-gray-100)"
                }}
                >

                <div
                    className = 'max-width'
                    >
            
                    {/*
                    <div className={styles.container} style={{ fontSize: 18 }}>
                        <div className={styles.policyContent}>
                    */}
                            <div
                                name="termly-embed"
                                data-id = { termlyPolicyId }
                                data-type="iframe"
                                style = {{
                                      backgroundColor: "white"
                                    //, maxWidth: "var(--site-width)"
                                    //, margin: "auto"
                                    , borderRadius: '10px'
                                    //, margin: '20px'
                                    , padding: '20px'
                                }}
                            ></div>
                    {/*
                        </div>
                    </div>
                    */}
                </div>
            </section>
        </main>
    );
};

export default TermsAndConditions;
