import {
    useEffect
    //, useState
} from "react";
import "./privacyPolicy.css"
import styles from './PrivacyPolicy.module.sass';

const PrivacyPolicy = () => {

    let termlyPolicyId = "c3b110a2-16d5-4f7b-9a49-f5cde884f3dc";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        //let termlyScriptElement = document.getElementById('termly-jssdk')
        //if (!termlyScriptElement) {
            const script = document.createElement("script");
            script.id = 'termly-jssdk';
            script.src = "https://app.termly.io/embed-policy.min.js";
            script.async = true;
            document.body.appendChild(script);
        //}
    }, []);

    return (
        <>
        <main className = 'edit-profile'>

            <section
                className = 'form'
                style = {{
                    backgroundColor: "var(--color-gray-100)"
                }}
                >

                <div
                    className = 'max-width'
                    >
            
                    {/*
        <div style = {{
              padding: '50px 12%'
            , fontSize: 18
            , maxWidth: "none"
            , background: "#f5f5f5"
            , height: 'calc(100% - 492px)'
            }}>
            
            <div className={styles.container} style={{ fontSize: 18 }}>
                <div className={styles.policyContent}>
                    */}
                    <div
                        name="termly-embed"
                        data-id = { termlyPolicyId }
                        data-type="iframe"
                        style = {{
                              maxWidth: "var(--site-width)"
                            , margin: "auto"
                            , backgroundColor: "white"
                            , borderRadius: '10px'
                            //, margin: '20px'
                            , padding: '20px'
                        }}
                    ></div>
        {/*
                </div>
            </div>
        </div>
        */}
                </div>
            </section>
        </main>
        </>
    );
};

export default PrivacyPolicy;
