import React, { useState, useEffect } from 'react';
import styles from './checkout.module.sass';
import AddCard from './AddCard';
import { axiosInstance } from '../../utils/API';
import { PopUpAlert } from '../../controller/utils';
import { useHistory } from 'react-router';
import Web3 from 'web3';
import * as openpgp from 'openpgp';
//import axios from 'axios';
//import { connector, Web3Provider } from '../../controller/utils';
import { useSelector } from 'react-redux';
import { decryptData } from '../../utils/decrypt';
import { NAMES_CONSTANTS } from '../../constants';

const Checkout = () => {

    let Web3Provider = new Web3(Web3.givenProvider);
    const [visible, setVisible] = useState(false);
    const [fields, setFields] = useState({});
    const [error, setError] = useState({});
    const [cardList, setCardList] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [cardInfo, setCardInfo] = useState(null);
    const euroValue = useSelector(state => state.counter.euroValue) || 0;
    //const secretKey = useSelector(state => state?.keyVal?.keyData);
    const history = useHistory();
    const handleChange = e => {
      let name = e.target.name;
      let value = e.target.value;
      if (name === 'card_number') {
        const card = cardList?.find((each, i) => each.id === parseInt(value));
        // //
        setFields({ ...fields, email: card?.userEmail, [name]: value });
        setCardInfo(card);
      } else if (name === 'cvv') {
        value = cvvValidation(value);
        setFields({ ...fields, [name]: value });
      } else {
        setFields({ ...fields, [name]: value });
      }
    };
    const cvvValidation = value => {
      if (!value) return value;
      const currentValue = value.replace(/[^\d]/g, '');
      const cvLength = currentValue.length;

      // if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 3) {
        return currentValue;
      }
      return `${currentValue.slice(0, 3)}`;
    };

    useEffect(() => {
      getListOfCard();
      const object = history?.location?.state?.productDetails;
      console.log("🚀 ~ file: index.js:59 ~ useEffect ~ productDetails:", productDetails)
      setProductDetails(object);
      setFields({ ...fields, price: '$' + object?.regularPrice?.toFixed(4) });
      //
    }, []);
    
    const getListOfCard = () => {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN),
        },
      };
      axiosInstance
        .get('/asset/order/getCardDetails', config)
        .then(res => {
          setCardList(res?.data?.result);
        })
        .catch(err => {
          PopUpAlert(
            'Alert',
            err?.message ? err?.message : err?.data?.response?.message,
            'error'
          );
        });
    };
    const validationField = () => {
      let field = fields;
      let errors = {};
      let formIsValid = true;

      //Name
      if (!field['card_number']) {
        formIsValid = false;
        errors['card_number'] = 'Please select card number';
      }
      if (!field['email']) {
        formIsValid = false;
        errors['email'] = 'Please enter your email id';
      }

      if (!field['cvv']) {
        formIsValid = false;
        errors['cvv'] = 'Please enter your cvv';
      }

      setError(errors);
      return formIsValid;
    };
    
    const submitHandle = async () => {
      if (validationField()) {
        // await provider?.enable();
        let web3 = Web3Provider;
        const account = await web3.eth.getAccounts();
        
        const getConfig = () => {
          let config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN),
            },
          };
          if (!localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)) {
            PopUpAlert('Alert..', 'No wallet connection found', 'error');
            return;
          }
          return config;
        };

        const config = getConfig();
        const result = await axiosInstance.get('/circle/public-key', config);
        const publicKeyArmored = result?.data?.result?.publicKey;
        const keyId = result?.data?.result?.keyId;

        const cardDetails = {
          cvv: fields?.cvv,
        };
        const message = await openpgp.createMessage({
          text: JSON.stringify(cardDetails),
        });
        const publicKey = await openpgp.readKey({
          armoredKey: window.atob(publicKeyArmored),
        });
        const encryptedData = await openpgp.encrypt({
          message,
          encryptionKeys: publicKey,
        });
        const toAddress = decryptData(
          '0x0af02a9f1262fca76388e25ef0840d8043458c6e'
        );
        
        let body = {
          ethPrice: productDetails?.regularPrice * euroValue,
          from: account[0],
          quantity: 1,
          salePrice: productDetails?.regularPrice,
          saleType: productDetails?.saleType,
          status: 'pending',
          to: toAddress,
          paymentType: 'circle',
          cardDetails: {
            metadata: {
              email: fields?.email,
              // "phoneNumber": "",
              sessionId: 'DE6FA86F60BB47B379307F851E238617',
              ipAddress: '244.28.239.130',
            },
            amount: {
              amount: productDetails?.regularPrice,
              currency: 'USD',
            },
            source: {
              id: cardInfo?.circleCreateCardId,
              type: 'card',
            },
            autoCapture: true,
            idempotencyKey: '',
            keyId: keyId,
            verification: 'cvv',
            description: 'Payment',
            encryptedData: window.btoa(encryptedData),
            // "channel": "ba943ff1-ca16-49b2-ba55-1057e70ca5c7"
          },
        };
        await axiosInstance
          .post(`/asset/checkout/${productDetails.id}`, body, config)
          .then(res => {
            if (res) {
              PopUpAlert('Success', 'Order Created Successfully', 'success').then(
                res => {
                  history.push('/order-details');
                }
              );
            }
          })
          .catch(err => {
            PopUpAlert(
              'Alert',
              err?.message ? err?.message : err?.data?.response?.message,
              'error'
            );
          });
      }
      // //
    };

    const modalClose = () => {
      getListOfCard();
      setVisible(false);
    };
    return (
        <>

        {visible && <AddCard visible={visible} closeModal={() => modalClose()} />}

        <main className={styles.main}>

            {/*
                loaderActive
                && (
                    <div className={styles.loaderContent}>
                        <CircularProgress />
                    </div>
                )
            */}

            {/*
                resalePopup
                && (
                    <ResellModal
                        closeModal = { closeModal }
                        resellMethod = { resellMethod }
                        item = { productDetails }
                        productInfo = { productInfo }
                        loaderShow = { setLoaderActive }
                        //getAllAssets = { getAllAssets }
                        />
                )
            */}

            <section className = { 'form' }>
                <div className = { 'max-width' }>
        {/*
        <section className='section' style={{ background: '#ffffff' }}>
            <div className='container'>
        */}
                    <div className={styles.checkout}>
                        <h2>Pay with Card</h2>
                        <button
                          type='button'
                          className={styles.addCard}
                          onClick={() => setVisible(true)}>
                          Add Card
                        </button>
                        <form>
                            <div className={styles.formGroup}>
                                <label>Amount</label>
                                <input
                                  type='text'
                                  className={styles.formControl}
                                  name='price'
                                  value={fields?.price}
                                  disabled
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label>Card Number</label>
                                <select
                                  className={styles.formControl}
                                  name='card_number'
                                  value={fields?.card_number}
                                  onChange={e => handleChange(e)}>
                                  <option>Select the card</option>
                                  {cardList?.map((each, i) => (
                                    <option value={each.id}>
                                      {each.binNo?.slice(0, 4)} XXXX XXXX {each.last4CardNo}
                                    </option>
                                  ))}
                                </select>
                                {error?.card_number && (
                                  <label className={styles.error}>{error?.card_number}</label>
                                )}
                            </div>
                            <div className={styles.formGroup}>
                                <label>CVV</label>
                                <input
                                  type='password'
                                  className={styles.formControl}
                                  name='cvv'
                                  value={fields?.cvv}
                                  onChange={e => handleChange(e)}
                                />
                                {error?.cvv && (
                                  <label className={styles.error}>{error?.cvv}</label>
                                )}
                            </div>
                            <div className={styles.formGroup}>
                                <label>Email</label>
                                <input
                                  type='email'
                                  className={styles.formControl}
                                  name='email'
                                  value={fields?.email}
                                  onChange={e => handleChange(e)}
                                />
                                {error?.email && (
                                  <label className={styles.error}>{error?.email}</label>
                                )}
                            </div>
                            <div className={styles.formGroup}>
                                <button
                                  type='button'
                                  className={styles.formBtn}
                                  onClick={e => submitHandle(e)}>
                                  Make a Payment
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
        </>
    );
};

export default Checkout;
