//import WalletConnectProvider from "@walletconnect/web3-provider";
import cn from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import Web3 from "web3";
import { toBN, toHex } from 'web3-utils';
import {
    PopUpAlert
    , checkNetwork
    , connector
    //, contract_auction_abi
    , getProvider
    , nft_contract_abi
} from '../../controller/utils';
import { axiosInstance } from '../../utils/API';
import styles from "./MyAssets.module.sass";
import { NAMES_CONSTANTS } from '../../constants';
import { CRYPTOCURRENCY_CONSTANTS } from "../../constants";

const ResellModal = ({ closeModal, resellMethod, item, getAllAssets, loaderShow, productInfo }) => {

    const [resellAmount, setResellAmount] = useState(null);
    const [resaleType, setResaleType] = useState('fixed')
    const [commissionRes, setCommissionRes] = useState(null);
    const [auction, setAuction] = useState({})
    const [errorAuction, setErrorAuction] = useState(null)
    const [errorFixed, setErrorFixed] = useState(null)
    const [confirmResaleBtn, setConfirmResaleBtn] = useState(false);
    const [shortLimit, setShortLimit] = useState(149);
    const [commissionAuctionRes, setCommissionAuctionRes] = useState(null);
    const [confirmAuctionResaleBtn, setConfirmAuctionResaleBtn] = useState(false);
    const [priceDisable, setPriceDisable] = useState(false)
    const euroValue = useSelector((state) => state?.counter.euroValue)
    //const secretKey = useSelector((state) => state?.keyVal?.keyData)
    const [error, setError] = useState('');
    //const [errorFraction, setErrorFraction] = useState('');
    const [noOfFraction, setNoOfFraction] = useState(1);
    // console.log(item,'item', productInfo)
    //const addressFrom = '0x3F34B11b382CC6Fe1E81eF81183cbba7b2fEe281';
    //let provider;
    if (connector.connected) {
        /*
        provider = new WalletConnectProvider({
            infuraId: process.env.REACT_APP_INFURA_ID
        });
        */
    }

    const handleResaleProduct = async (assetData, basePrice, salePrice, purchaseType, fractionCount) => {
        //for auction purchaseType =2, basePrice = base amount, salePrice= buy now price
        //for fixed price product resale purchaseType =1, basePrice = product price, salePrice= product price ie, basePrice = salePrice
        const web3provider = await getProvider();
        let web3 = new Web3(web3provider);
        
        const chainId = await web3.eth.getChainId();
        const networkVerify = await checkNetwork(chainId);
        console.log("networkVerify", networkVerify,);
        if (!networkVerify) return loaderShow(false);
        loaderShow(true);

        // account is an array of available accounts in connected wallet
        const account = await web3.eth.getAccounts();
        const accnt = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS))?.account;
        if (account[0] !== accnt[0]) {
            PopUpAlert('Alert', 'Please choose the wallet address that was used to purchase the asset', 'error')
            loaderShow(false);
            return;
        }


        try {
            if (purchaseType === 1) {
                const data = {
                    assetId: assetData?.id,
                    regularPrice: basePrice,
                    saleType: 1,
                    royaltyReceiver: `${account[0]}`,
                    quantity: 1,
                    royaltyPercentage: assetData.royaltyCommission
                      ? assetData.royaltyCommission * 100
                      : 0,// _royaltyPercentage(uint256)
                    seller: account[0]
                }
                if (assetData?.isFractional) {
                    data.quantity = +fractionCount
                }
                console.log('data', data);
                const config = getConfig();
                axiosInstance.post('/asset/resale', data, config).then((res) => {
                    // setResalePopup(false)
                    // setLoaderActive(false)
                    if (getAllAssets) getAllAssets(); // The getAllAssets() function is not present in the Item screen but is present in the Search screen.
                    closeModal();
                    PopUpAlert('Success', "Asset added successfully for resale", 'success')
                }).catch((err) => {
                    closeModal();
                    PopUpAlert('Alert', err?.data?.response?.message ? err?.data?.response?.message : err?.message, 'error')
                })
            }

        } catch (err) {
            loaderShow(false);
            console.log('err 1183', err, err.code);
            if (err.code === 4001) {
                PopUpAlert("Alert!", "Please confirm from your wallet", "error").then(
                    (err) => window.location.reload()
                );
            } else {
                PopUpAlert("Alert!", "Something is wrong with the resale process", "error = " + err).then((err) =>
                    window.location.reload()
                );
            }
        }
    };

    const getConfig = () => {
        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("userToken")
            }
        };
        if (!localStorage.getItem("userToken")) {
            PopUpAlert('Alert..', 'No wallet connection found', 'error')
            return
        }
        return config;
    };

    const onNext = async () => {
        if (item?.isFractional) {
            fractionalFixedAssetNext()
        } else {
            normalFixedAssetNext()
        }
    };

    const validationFraction = () => {
        let formIsValid = true;
        let error = {}
        let object = { resellAmount, noOfFraction }
        if (!object['resellAmount']) {
            formIsValid = false;
            error['resellAmount'] = "Please enter the amount";
        }
        if (item?.isFractional) {
            if (!object['noOfFraction']) {
                formIsValid = false;
                error['noOfFraction'] = 'Please enter the no of fraction';
            }
            if (object['noOfFraction'] !== '' && object['noOfFraction'] == 0) {
                formIsValid = false;
                error['noOfFraction'] = 'Please enter greater than 0';
            }
            if (object['noOfFraction'] !== '' && object['noOfFraction'] > productInfo?.fractionCount) {
                formIsValid = false;
                error['noOfFraction'] = `Please enter less than ${productInfo?.fractionCount}`;
            }
        }

        setErrorFixed(error)
        return formIsValid
    };

    const fractionalFixedAssetNext = () => {
        if (validationFraction()) {
            loaderShow(true)
            setError('')
            commissionApi()
        }
    };

    const normalFixedAssetNext = () => {
        if (resellAmount) {
            loaderShow(true)
            setErrorFixed({})
            commissionApi()
            // resellMethod(resellAmount);
        } else {
            setErrorFixed({ resellAmount: 'Please enter the resell price' });
        }
    };

    const getGasPrice = async () => {
        const web3provider = await getProvider();
        let web3 = new Web3(web3provider);
        return web3.eth.getGasPrice();
    };

    const askUserPermissionToTransferNFT = async (resellPrice) => {
        const web3provider = await getProvider();

        let web3 = new Web3(web3provider);
        try {

            const nftContractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
            const auctionContractAddress = process.env.REACT_APP_AUCTION_CONTRACT_ADDRESS;
            const escrowContractAddress = process.env.REACT_APP_ESCROW_CONTRACT_ADDRESS;
            const nftContract = await new web3.eth.Contract(nft_contract_abi, nftContractAddress);
            const account = await web3.eth.getAccounts();
            const gasPrice = await getGasPrice()
            const setApprovePermissionToSmartContractMethod = await nftContract.methods.setApprovalForAll(auctionContractAddress, true);

            const txObject = {
                from: `${account[0]}`,
                data: setApprovePermissionToSmartContractMethod.encodeABI(),
                to: nftContractAddress,
                gasPrice: toHex(toBN(Math.ceil(gasPrice * 2))),
                gas: toHex(2100000)
            };

            const txMarketplace = await web3.eth.sendTransaction({ ...txObject })
                .on('confirmation', async function (confirmationNumber, receipt) {
                    if (confirmationNumber === 24 && receipt.status) {}
                })
                .on('error', err => {
                    PopUpAlert(
                        'Alert!',
                        `You have to approve this transaction to continue the resale. Error: ${err.message}`,
                        'error'
                    );
                    throw err;
                });

            const setApprovaltoEscrowContract = await nftContract.methods.setApprovalForAll(escrowContractAddress, true);

            const txHashObject = {
              from: `${account[0]}`,
              data: setApprovaltoEscrowContract.encodeABI(),
              to: nftContractAddress,
              gasPrice: toHex(toBN(Math.ceil(gasPrice * 2))),
              gas: toHex(2100000)
            }
            const txEscrow = await web3.eth.sendTransaction({ ...txHashObject }).on('confirmation', function (confirmationNumber, receipt) {
              if (confirmationNumber === 24 && receipt.status) {
                console.log('Transfer completed', {
                  txHash: receipt.transactionHash,
                  status: receipt.status
                });

              }
            }).on('error', err => {
              PopUpAlert(
                'Alert!',
                `You have to Approve this in order to continue resale Error: ${err.message}`,
                'error'
              )
              throw err;
            })
            if (txMarketplace.status && txEscrow.status) {

              handleResaleProduct(item, resellPrice, 0, 1, noOfFraction);
            }
          }
          catch (err) {
            console.log({ err });
            PopUpAlert('Alert', 'Give permission fail', 'error')
          }
    }; //askUserPermissionToTransferNFT(resellPrice)

    const onSubmit = async () => {
        if (resaleType === 'fixed') {
            const resellPrice = item?.isFractional ? (resellAmount * noOfFraction) * euroValue : resellAmount * euroValue;
            await askUserPermissionToTransferNFT(resellPrice);
        }
    };

    const commissionApi = async () => {
        const config = getConfig();
        await axiosInstance.get(`/commission?value=${resaleType === "fixed" ? item.isFractional ? (resellAmount * parseInt(noOfFraction)) : resellAmount : auction?.price}&assetId=${item?.id}`, config).then((res) => {
            // setResalePopup(false)
            // //console.log("ere>>>",res);
            if (resaleType === 'fixed') {
                loaderShow(false)
                setCommissionRes(res?.data?.result);
                setConfirmResaleBtn(true)
                // setPriceDisable(true)
            } else {
                loaderShow(false)
                setCommissionAuctionRes(res?.data?.result);
                setConfirmAuctionResaleBtn(true)
            }
        }).catch((err) => {
            loaderShow(false)
            setCommissionRes(null)
            PopUpAlert('Alert', err?.data?.response?.message ? err?.data?.response?.message : err?.message, 'error')
        })
    };

    const auctionHandleChange = (e, type) => {
        // //console.log("ee",e);
        setConfirmAuctionResaleBtn(false)
        if (type === 'startdate') {
            setAuction({ ...auction, start: e })
            setErrorAuction({ ...errorAuction, start: null })
        } else if (type === 'enddate') {
            setAuction({ ...auction, end: e })
            setErrorAuction({ ...errorAuction, end: null })
        } else {
            setAuction({ ...auction, [e.target.name]: e.target.value })
            setErrorAuction({ ...errorAuction, [e.target.name]: null })
        }
    };

    const validationField = () => {

        let fields = auction;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["start"]) {
            formIsValid = false;
            errors["start"] = "Please select auction start";
        }
        if (!fields["end"]) {
            formIsValid = false;
            errors["end"] = "Please select auction end";
        }
        if (typeof fields["end"] !== "undefined") {
            // //console.log("moment(fields['end'].isBefore(moment(fields['start']))))",moment(fields['end']).isBefore(moment(fields['start'])));
            if (moment(fields['end']).isBefore(moment(fields['start']))) {
                formIsValid = false;
                errors["end"] = "Please select greater than auction start";
            }
        }
        if (!fields["price"]) {
            formIsValid = false;
            errors["price"] = "Please enter the price";
        }
        if (!fields["reservePrice"]) {
            formIsValid = false;
            errors["reservePrice"] = "Please enter the reserve price";
        }
        setErrorAuction(errors);
        return formIsValid;
    };

    const onAuctionNext = () => {
        if (validationField()) {
            // //console.log(moment.utc(auction?.start).format('YYYY-MM-DD h:mm:ss Z'),moment(auction.start).format(),auction?.start)
            //  deploy();
            commissionApi();
        }
    };

    const onAuctionSubmit = async () => {
        const data = {
            "orderId": item?.id,
            "saleType": 2,
            "regularPrice": auction?.reservePrice,
            "auction": {
              "startDate": moment(auction?.start).format(),
              "endingDate": moment(auction?.end).format(),
              "openingPrice": auction?.price
            }
        };

        const config = getConfig();
        await axiosInstance.post('/asset/resale', data, config).then((res) => {
            getAllAssets();
            closeModal();
            PopUpAlert('Success', "Asset added successfully to resale", 'success')
            //  //console.log("res>>>>",res);
        }).catch((err) => {
            // //console.log(":err",err);
            PopUpAlert('Alert', err?.message ? err?.message : err?.data?.response?.message, 'error')
        });
    };

    /*
    const handleChangeSelect = (e) => {
        setResaleType(e.target.value);
        setAuction({});
        setCommissionRes(null);
        setResellAmount('');
        setConfirmResaleBtn(false);
        setPriceDisable(false);
        setConfirmAuctionResaleBtn(false);
        setCommissionAuctionRes(null);
    };
    */

    const preventNonNumericalInput = (e) => {
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);

        const regex = !charStr.match(/^[0-9]+$/) && charStr !== '.' && charCode !== 8;
        //console.log("e.target.name",e.target.name,regex);
        if (regex) e.preventDefault();
    };

    const preventNonNumericalInputWithDot = (e) => {
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);

        const regex = !charStr.match(/^[0-9]+$/) && charCode !== 8;
        //console.log("e.target.name",e.target.name,regex);
        if (regex) e.preventDefault();
    };

    return (
        <div className={styles.resellPopupModal}>

            <div className={styles.resellContent}>

                <div style={{ padding: "40px" }}>

                <div className={styles.resellCloseIcon} onClick={closeModal}>&#10006;</div>
                <h3 className={styles.resaleHead}>Resell Asset</h3>

                <div className={styles.resellItem}>

                    {
                        item.mediaType === "audio"
                        && <div
                            className={`player-wrapper ${styles.resellImg}`}
                            style={{ position: "relative", paddingTop: 0 }}
                            >
                            <img
                                src={item?.audioThumbnail}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  // objectFit: "cover"
                                  objectFit: "contain"
                                }}
                                alt="thumbnail.png"
                                />
                            <audio
                                controls
                                controlsList="nodownload"
                                style={{ position: "absolute", bottom: 0, left: 0, height: 35, width: "100%" }}>
                                <source src={item?.mediaPreviewUrl} type="audio/mpeg" />
                            </audio>
                        </div>
                    }

                    {
                        item.mediaType === "video"
                        && <div
                            className={`player-wrapper ${styles.resellImg}`}
                            // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                            style={{ position: "relative", paddingTop: "56%" }}
                        >
                            <ReactPlayer
                                url={item?.mediaPreviewUrl}
                                width="100%"
                                height="100%"
                                controls={true}
                                light={false}
                                style={{ position: "absolute", top: "0", left: "0" }}
                                config={{
                                    file: {
                                        attributes: { disablePictureInPicture: true, controlsList: "nodownload" }
                                    }
                                }}
                                />
                        </div>
                    }

                    {
                        (item?.mediaType === "image" || item?.mediaType === "3D Model")
                        &&
                        <div
                            className={styles.resellImg}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                            >
                            <img
                                src={item?.thumbnailUrl || item?.mediaPreviewUrl}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain"
                                }}
                                alt="thumbnail.png"
                                />
                        </div>
                    }

                    <div className={styles.resellInfo}>
                        <h2>{item.name}</h2>
                        <p>
                            {/* {item.shortDescription} */}
                            {
                                item?.shortDescription?.length < 150 || item?.shortDescription === null
                                ? item?.shortDescription
                                : <>
                                    {item?.shortDescription?.substr(0, shortLimit)}
                                    {" "}
                                    {
                                        shortLimit < 150
                                        ? <div onClick={() => setShortLimit(1501)} className={styles.readMore}>
                                              Read more...
                                        </div>
                                        : <div onClick={() => setShortLimit(149)} className={styles.readMore}>
                                            Read less...
                                        </div>
                                    }
                                </>
                            }
                        </p>
                    </div>

                </div>

                {/* <p>Do you want to resell the Assets?</p>
                        <p>please confirm the price you intend to sell</p> */}
                {/* <label className={styles.resaleLabel}>Resale Type</label>
                            <select name="resaleType" className={styles.resaleSelect} value={resaleType} onChange={handleChangeSelect}><option value="">Select the resale type</option><option value="fixed">Fixed</option> */}
                {/* <option value="auction">Auction</option> */}
                {/* </select> */}

                {
                    resaleType === "fixed"
                    &&
                        <>
                        {/* item.isFractional &&  */}
                        {
                            item.isFractional
                            &&
                                <>
                                <label className={styles.resaleLabel}>Number of fractions</label>
                                <input
                                    type="number"
                                    className={styles.fractionNumbers}
                                    onChange={(e) => {
                                        setConfirmResaleBtn(false)
                                        setErrorFixed({ ...errorFixed, noOfFraction: null })
                                        setNoOfFraction(e.target.value)
                                    }}
                                    value = { noOfFraction ? noOfFraction : ''}
                                    placeholder = 'Enter Fractions'
                                    min = { 1 }
                                    onKeyPress = { preventNonNumericalInputWithDot }
                                    />

                                {
                                    errorFixed?.noOfFraction
                                    && <label style={{ color: '#f00', display: 'block', textAlign: 'left' }}>{errorFixed?.noOfFraction}</label>
                                }
                                </>
                        }

                        <label className={styles.resaleLabel}>Price</label>
                        <div className={styles.resellAmount}>
                            <div className={styles.dollarValue}>
                                $ <input
                                    type="number"
                                    onChange={(e) => {
                                        setConfirmResaleBtn(false)
                                        setErrorFixed({ ...errorFixed, resellAmount: null })
                                        setResellAmount(e.target.value)
                                    }}
                                    value = { resellAmount ? resellAmount : ''}
                                    placeholder='Enter amount'
                                    min={0}
                                    disabled = { priceDisable }
                                    onKeyPress = { preventNonNumericalInput }
                                    />
                            </div>
                            <div className={styles.maticValue}>{resellAmount ? (resellAmount * euroValue).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES) : '0'} Matic</div>
                        </div>

                        {errorFixed?.resellAmount && <label style={{ color: '#f00', display: 'block', textAlign: 'left' }}>{errorFixed?.resellAmount}</label>}

                        {
                            (resaleType === 'fixed' && commissionRes)
                            && <>
                                <div className={styles?.commissionReduction}>
                                    <ul>
                                        <li>
                                            <span>Seller proceeds ({100 - commissionRes?.percentage}%)</span>
                                            <span>
                                            <span className={ 'nowrap' }>${ commissionRes?.resellValue?.toFixed(2)} USD</span> <span className={ 'nowrap' }>({(commissionRes?.resellValue * euroValue).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES) } MATIC)</span>
                                            </span>
                                        </li>
                                        <li>
                                            <span>Platform Fee ({commissionRes?.percentage}%)</span>
                                            <span>${ commissionRes?.commission} USD ({(commissionRes?.commission * euroValue).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)} MATIC)</span>
                                        </li>
                                        <li>
                                            <span>Total</span>
                                            <span>${ commissionRes?.value?.toFixed(2)} USD ({(commissionRes?.value * euroValue).toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES)} MATIC)</span>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }

                        <div
                            style={{
                                marginTop: '20px'
                                //, textAlign: 'left'
                            }}>
                            {
                                !confirmResaleBtn
                                && <button type="button" className={cn("gold large stretch rounded")} onClick={onNext} >Next</button>
                            }
                            {
                                confirmResaleBtn
                                && <button type="button" className={cn("gold large rounded")} onClick = { onSubmit }>Confirm Resell</button>
                            }
                        </div>
                        </>
                    }

                    {
                        resaleType === 'auction'
                        &&
                            <>
                            <div className={styles.resaleRow}>
                                <div className={styles.resaleCol}>
                                    <label className={styles.resaleLabel}>Auction Start</label>
                                    <div className={styles.resellAmount}>
                                        {/* <input type="number" onChange={(e)=>setResellAmount(e.target.value)} value={resellAmount ? resellAmount : ''} placeholder='Enter amount' disabled={priceDisable}/> */}
                                        <Datetime
                                            value={auction?.start}
                                            placeholder="dd/MM/yyyy hh:mm a"
                                            // closeOnSelect={true}
                                            // closeOnClickOutside={false}
                                            // onChange={handleDateChange}
                                            onChange={(e) => auctionHandleChange(e, 'startdate')}
                                            />
                                    </div>

                                    {errorAuction?.start && <label style={{ color: '#f00', display: 'block' }}>{errorAuction?.start}</label>}

                                </div>
                                <div className={styles.resaleCol}>
                                    <label className={styles.resaleLabel}>Auction End</label>
                                    <div className={styles.resellAmount}>
                                        {/* <input type="number" onChange={(e)=>setResellAmount(e.target.value)} value={resellAmount ? resellAmount : ''} placeholder='Enter amount' disabled={priceDisable}/> */}
                                        <Datetime
                                          value={auction?.end}
                                          // closeOnSelect={true}
                                          // closeOnClickOutside={false}
                                          placeholder="dd/MM/yyyy hh:mm a"
                                          // onChange={handleDateChange}
                                          onChange={(e) => auctionHandleChange(e, 'enddate')}
                                        />
                                    </div>
                                    {errorAuction?.end && <label style={{ color: '#f00', display: 'block' }}>{errorAuction?.end}</label>}
                                </div>
                            </div>

                            <div className={styles.resaleRow}>
                                <div className={styles.resaleCol}>
                                    <label className={styles.resaleLabel}>Price</label>
                                    <div className={styles.resellAmount}>
                                        $ <input type="number" name="price" onChange={(e) => auctionHandleChange(e)} value={auction ? auction?.price : ''} placeholder='Enter amount' disabled={priceDisable} />
                                    </div>
                                  {errorAuction?.price && <label style={{ color: '#f00', display: 'block' }}>{errorAuction?.price}</label>}
                                </div>
                                <div className={styles.resaleCol}>
                                    <label className={styles.resaleLabel}>Reserve Price</label>
                                    <div className={styles.resellAmount}>
                                        $ <input type="number" name="reservePrice" onChange={(e) => auctionHandleChange(e)} value={auction ? auction?.reservePrice : ''} placeholder='Enter amount' disabled={priceDisable} />
                                    </div>
                                    {errorAuction?.reservePrice && <label style={{ color: '#f00', display: 'block' }}>{errorAuction?.reservePrice}</label>}
                                </div>
                            </div>

                            {
                                (resaleType === 'auction' && commissionAuctionRes)
                                &&
                                    <>
                                    <div className={styles?.commissionReduction}>
                                      <ul>
                                        <li><span>Seller proceeds ({100 - commissionAuctionRes?.percentage}%)</span><span>{commissionAuctionRes?.resellValue?.toFixed(4)}</span></li>
                                        <li><span>Platform Fee ({commissionAuctionRes?.percentage}%)</span><span>{commissionAuctionRes?.commission?.toFixed(4)}</span></li>
                                        <li><span>Total</span><span>{commissionAuctionRes?.value?.toFixed(4)}</span></li>
                                      </ul>
                                    </div>
                                    </>
                            }

                            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                {
                                    !confirmAuctionResaleBtn
                                    && <button type="button" className={cn("gold large stretch rounded", styles.resellBtn)} onClick={onAuctionNext} >NEXT</button>
                                }
                                {
                                    confirmAuctionResaleBtn
                                    && <button type="button" className={cn("button-stroke button-small", styles.resellBtn)} onClick={onAuctionSubmit} >CONFIRM RESELL</button>
                                }
                            </div>

                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ResellModal;
