import React, { useState } from "react";
import { axiosInstance } from "../../utils/API";
import Swal from 'sweetalert2'; // SweetAlert for modal dialogs
import {
      useParams
    , useHistory
} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ResetPassword = () => {
    console.log('ResetPassword()');

    const [visibility, setVisibility] = useState(false);
    const [password, setPassword] = useState(null);
    //const [cpassword, setCpassword] = useState(null);
    const [error, setError] = useState({});
    const history = useHistory();
    const { token } = useParams();
    console.log('token = ', token);

    const handleChangeVisibility = () => {
        setVisibility(!visibility);
    };

    const validationField = () => {

		//let field = {password,cpassword};
        let field = { password };
		let errors = {};
		let formIsValid = true;

		//Name
    
		if (!field["password"]) {
			formIsValid = false;
			errors["password"] = "Please enter your password";
		}

        /*
        if (!field["cpassword"]) {
			formIsValid = false;
			errors["cpassword"] = "Please enter your confirm password";
		}

        if (typeof field["cpassword"] !== "undefined") {
			if (field["cpassword"] !== field["password"]) {
				formIsValid = false;
				errors["cpassword"] = "Please enter same as password";
			}
		}
        */

        // console.log('error',errors);
		setError(errors);
		return formIsValid;
	};

    const submitHandler = (e) => {
        console.log('submitHandler(e)');

        e.preventDefault();

        if (validationField()) {
            const payload = {
                password
                , token
            };
            console.log('payload = ' + payload);
            //axiosInstance.post("/v1/admin/auth/reset-password", payload)
            axiosInstance.post("/user/reset-password", payload)
                .then((res) => {
                    Swal.fire("Great", "Password Reset Successfully", "success")
                    .then(res => history.push("/"));
                })
                .catch(err => Swal.fire("Alert", err.response.data.message, 'error'))
        }
    };

    return (
        <div className="login-wrapper">
            <form
                onSubmit = { submitHandler }
                style = {{
                    display: 'flex'
                    , flexDirection: 'column'
                    , justifyContent: 'space-between'
                }}
                >
                <div className="logoWrapperLogin" style = {{ paddingBottom: '30px', margin: 'auto', textAlign: 'center' }}>
                    <img src = '/images/logos/realsplit-logo-dark-277x69.jpg' alt="logo" className="logoImageLogin" />
                </div>

                <h2>Reset Your Password</h2>

                <div className="form-group">
                    <label
                        style = {{
                              fontSize: "12px"
                            , fontFamily: "Poppins"
                            , fontStyle: "normal"
                            , fontWeight: "700"
                            , lineHeight: "12px"
                            , textTransform: "uppercase"
                        }}
                    >New Password</label>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>

                        <input
                            type={visibility ? "text" : "password"}
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            value = { password }
                            onChange = {(e) => {
                                setPassword(e?.target?.value)
                                setError({...error, password:''})
                            }}
                            />

                        {
                            visibility
                            ? (
                                <VisibilityIcon
                                    style={{ margin: "0px 8px 0px -32px" }}
                                    onClick={() => handleChangeVisibility()}
                                    />
                            ) : (
                                <VisibilityOffIcon
                                    style={{ margin: "0px 8px 0px -32px" }}
                                    onClick={() => handleChangeVisibility()}
                                    />
                            )
                        }

                    </div>
                    {/* errorField?.password && <label className="errorMsg">{errorField?.password}</label> */}
                </div>

                <button
                    type="submit"
                    style = {{
                        marginTop: "20px"
                    }}
                    className="primary"
                    >
                    Reset Password
                </button>

                <button
                    className = 'primary-inverse medium rounded cursorPointer'
                    style = {{
                          marginTop: "30px"
                        , padding: "10px 20px"
                        , paddingLeft: '10px'
                    }}
                    onClick = { () => history.push("/") }
                    >
                    <img
                        src = '/images/icons/arrow-left.svg'
                        alt = 'Left-Arrow Icon'
                        style = {{
                            paddingRight: '10px'
                        }}
                        />
                        <div
                            style = {{
                                display: 'inline-block'
                                , lineHeight: '16px'
                                , verticalAlign: 'middle'
                                //, paddingTop: '2px'
                            }}
                            >Back to Login
                        </div>
                </button>

            </form>
        </div>
    );
};

export default ResetPassword;
