export const logLevels = ['debug', 'info', 'warn', 'error', 'none'];

//let logLevel = 'debug';
//let logLevel = 'info';
//let logLevel = 'warn';
let logLevel = logLevels[2];

const shouldLog = (level) => {
    return logLevels.indexOf(level) >= logLevels.indexOf(logLevel);
};

const windowConsole = window.console;

window.console = {
    ...window.console
    , log: (message?, ...optionalParams) => {
        shouldLog('debug') && windowConsole.log(message, ...optionalParams);
    }
    , info: (message?, ...optionalParams) => {
        shouldLog('info') && windowConsole.warn(message, ...optionalParams);
    }
    , warn: (message?, ...optionalParams) => {
        shouldLog('warn') && windowConsole.warn(message, ...optionalParams);
    }
    , error: (message?, ...optionalParams) => {
        shouldLog('error') && windowConsole.error(message, ...optionalParams);
    }
    , debug: (message?, ...optionalParams) => {
        shouldLog('debug') && windowConsole.debug(message, ...optionalParams);
    }
};