import { Fragment } from "react";
import { Link } from "react-router-dom";

const SocialIcons = (props) => {
    
    const svgvalues = {
          facebook: "/images/icons/Facebook_icon_2013.svg"
        , twitter: "/images/icons/X_logo_2023_original.svg"
        , instagram : "/images/icons/iconmonstr-instagram-11.svg"
        , linkedin:"/images/icons/LinkedIn-icon-svg.svg"
    }

    const links = {
          facebook: null
        , twitter: "https://x.com/realsplit1"
        , instagram : null
        , linkedin: null
    }
    
    return ( 
        <Fragment>
            <a
                //className = {styles.logo}
                href = { links[props.type] ? links[props.type] : '' }
                target = '_blank'
                style = {{
                    padding: "5px 0px"
                }}
                >
                <img
                    src = { svgvalues[props.type] }
                    alt = { props.type }
                    style = {{
                          height: props.height
                        , width: props.width
                    }} />
            </a>
        </Fragment>
    );
}
 
export default SocialIcons;