import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import {
    useSelector
    //, useDispatch
} from 'react-redux';
import { CircularProgress } from "@mui/material";
import moment from "moment";
//import cn from "classnames";
import styles from "./OrderDetail.module.sass";
import { axiosInstance } from "../../utils/API";
import { numberWithCommas } from '../../utils/formatPricingNumber';
import { CRYPTOCURRENCY_CONSTANTS, ITEM_ACCEPTANCE, NAMES_CONSTANTS } from "../../constants";

const OrderDetail = (props) => {

    // This was being used for "logged in" status. Now using the userAccessToken from the localStorage instead.
    const status = useSelector((state) => state.counter.value);
    const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    const { id } = useParams();
    const history = useHistory();
    //const euroValue = useSelector((state) => state.counter.euroValue) || 0;
    const [params, setParams] = useState({ limit: 11, page: 1 });
    const [loaderShow, setLoaderShow] = useState(false);
    const [orderDetail, setOrderDetail] = useState();
    const shippingFee = orderDetail?.shippingFee ? Number(orderDetail?.shippingFee || 0) : 0
    //const shippingFeeFinal = shippingFee > 50 ? shippingFee - 50 : 0

    /*
    const handlePageClick = ({ selected: selectedPage }) => {
      setParams({ ...params, page: selectedPage + 1 })
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    };
    */

    useEffect(() => {
      getOrderDetail(id);
    }, [status, params]);

    const getOrderDetail = async () => {
        setLoaderShow(true)
        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };
        try {
            let orderDetailData = await axiosInstance.get("orders/" + id, config);
            if (orderDetailData) {
                setOrderDetail(orderDetailData.data.result);
            }
            setLoaderShow(false)
        } catch (err) {
            setLoaderShow(false)
        }
    };

    const handleViewOrder = (id, assetId =  null) => {
      if (id && assetId) {
        history.push('/item-acceptance?orderId=' + id + '&assetId=' + assetId)
      } else if (id) {
        history.push('/item-acceptance?orderId=' + id)
      }
    }

    const displayStatus = (status) => {

        const thisOrderStatus = ITEM_ACCEPTANCE.ORDER_STATUS[status];

        if (thisOrderStatus) {

            let styleObject = {
                  background: '#fff'
                //, border: 'black 2px solid'
                , border: thisOrderStatus.color + ' 2px solid'
                //, borderColor: thisOrderStatus.color
                //, borderWidth: '2px'
                , color: thisOrderStatus.color
                //, textTransform: 'uppercase'
                , fontWeight: '700'
                //, lineHeight: '20px'
                , padding: '8px 18px 8px 18px'
            };
            console.warn('styleObject = ', styleObject);

            return (
                <>
                <div
                    className = { styles.orderStatus }
                    style = { styleObject }>{ thisOrderStatus.text }</div>
                </>
            );
        }

        return (
            <>
            <div className={styles.orderStatus} style={{ background: '#E61515' }}>Delivery Issuses</div>
            </>
        )
    };

    return (

        <main className = 'edit-profile'>

            <section className = { 'form' }>

                <div className = { 'max-width' }>

                    <div className='cart-checkout-page'>

                        {
                            loaderShow
                            && (
                                <div className={styles.loaderContent}>
                                    <CircularProgress />{' '}
                                </div>
                            )
                        }

                        <div className='d-flex'>
                            <button
                                className = 'primary-inverse circular medium'
                                style = {{
                                    marginLeft: '0'
                                    , paddingRight: '18px'
                                    //, paddingBottom: '4px'
                                }}
                                onClick = { () => history.push('/order-details') }
                                >
                                <img
                                    //src='/images/icons/left-arrow-icon.svg'
                                    src='/images/icons/arrow-left.svg'
                                    style = {{
                                        paddingRight: '10px'
                                        //, filter: 'invert(11%) sepia(100%) saturate(6205%) hue-rotate(244deg) brightness(83%) contrast(145%)'
                                    }}
                                    />
                                    <div
                                        style = {{
                                            display: 'inline-block'
                                            , lineHeight: '16px'
                                            , verticalAlign: 'middle'
                                            //, paddingTop: '2px'
                                        }}>
                                        Back to the Orders List
                                    </div>
                            </button>
                        </div>

                        <div
                            className = { "heading" }
                            style = {{
                                display: 'flex'
                                , flexDirection: 'row'
                                , flexWrap: 'wrap'
                                //, justifyContent: 'space-between'
                                , justifyContent: 'flex-start'
                            }}>
                            {/*
                            <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                                <h1 style = {{ display: 'inline-block' }}>
                                    <h1>Order Number {orderDetail?.id}</h1>
                                </h1>
                            </div>
                            */}

                            <div
                                className={styles.spaceBetween}
                                style = {{ flexGrow: 1 }}
                                >
                                <div
                                    style = {{
                                          display: 'flex'
                                        , justifyContent: 'space-between'
                                        //, border: '3px solid green'
                                        , width: '100%'
                                        , flexWrap: 'wrap'
                                    }}>
                                    <div style = {{ display: 'flex' }}>
                                        <h1>Order Number {orderDetail?.id}</h1>
                                    </div>
                                    <div style = {{ display: 'flex' }}>
                                        <div
                                            className={styles.alignCenter}
                                            >
                                            { displayStatus(orderDetail?.status) }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*
                            <div style = {{ paddingLeft: '30px' }}>
                                <img
                                    //src = '/images/icons/referrals-icon-28.jpg'
                                    src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                    style = {{
                                        //width: '50px'
                                        height: '60px'
                                        //, filter: 'var(--filter-theme-primary)'
                                        , filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                    }}
                                    />
                            </div>
                            */}
                        </div>

                        {/*
                        <div
                            className = 'subtitle'
                            //style = {{
                            //    paddingTop: "30px"
                            //    , paddingBottom: "0px"
                            //}}
                            >
                            {
                                assets.length > 0
                                ? ( <span>Orders you have placed.</span> )
                                : ( <span>You have not placed any orders yet. Click <Link to='/search/all'>here</Link> to explore products.</span> )
                            }
                        </div>
                        */}


                        <div
                            //className = { `${styles.orderDetailPage} container` }
                            className = { 'flex-container' }
                            style = {{ marginTop: '30px' }}
                            >

                            {/*
                            <div
                                //className = 'cart-checkout-page'
                                //style = {{
                                //    paddingBottom: '30px'
                                //}}
                                >

                                <div className='d-flex'>
                                    <div
                                        className = 'back-btn btn'
                                        //onClick = { () => history.push('/cart') }
                                        onClick = { () => history.push('/order-details') }
                                        >
                                        <img
                                            //src='/images/icons/left-arrow-icon.svg'
                                            src='/images/icons/arrow-left.svg'
                                            style = {{
                                                filter: 'invert(11%) sepia(100%) saturate(6205%) hue-rotate(244deg) brightness(83%) contrast(145%)'
                                            }}
                                            />
                                        <p>Back to Current Orders List</p>
                                    </div>
                                </div>

                            </div>

                            <div className={styles.spaceBetween}>
                                <div
                                    style = {{
                                          display: 'flex'
                                        , justifyContent: 'space-between'
                                        //, border: '3px solid green'
                                        , width: '100%'
                                        , flexWrap: 'wrap'
                                    }}>
                                    <div style = {{ display: 'flex' }}>
                                        <h1>Order Number {orderDetail?.id}</h1>
                                    </div>
                                    <div style = {{ display: 'flex' }}>
                                        <div className={styles.alignCenter}>
                                            {displayStatus(orderDetail?.status)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}

                            {
                                console.log(orderDetail)
                            }

                            {
                                userAccessToken
                                ? (
                                    <div
                                        className={styles.orderList}
                                        style = {{ flexGrow: 1 }}
                                        >
                                        <div
                                            className={styles.orderInfor}
                                            >

                                            <p>
                                                Purchase Date:
                                                &nbsp;<span style = {{ fontWeight: 'bold', color: '#23262F' }}>
                                                    {
                                                        orderDetail?.createdAt
                                                        ? moment(orderDetail?.createdAt).format('MMM DD, YYYY')
                                                        : 'N/A'
                                                    }
                                                </span>
                                            </p>

                                            <p>
                                                Order Number:
                                                &nbsp;<span style = {{ fontWeight: 'bold', color: '#23262F' }}>{ orderDetail?.id }</span>
                                            </p>
                                            
                                            {/*
                                            <p>
                                              Delivery date:{' '}
                                              {orderDetail?.deliveryDate
                                                ? moment(orderDetail?.deliveryDate).format('DD/MM/YYYY')
                                                : 'N/A'}{' '}
                                            </p>
                                            <p>
                                              Fedex Tracking ID:{' '}
                                              {orderDetail?.trackingId
                                                ? orderDetail?.trackingId
                                                : 'N/A'}{' '}
                                            </p>
                                            */}
                                        </div>

                                        {
                                            orderDetail?.assets
                                            ? (
                                                orderDetail?.assets?.map((asset, index) => (
                                                    <div
                                                        key={index}
                                                        className={styles.orderListItem}
                                                        //onClick={() => handleViewOrder(orderDetail?.id, asset?.assetId)}
                                                        onClick = { () => history.push('/item/' + asset['listedAssetId']) }
                                                        >
                                                        <div
                                                            className={styles.thumbnail}
                                                            style = {{
                                                                overflow: 'hidden'
                                                                , borderRadius: '15px'
                                                            }}
                                                            >
                                                            <img
                                                                src={
                                                                  asset["assets.mediaPreviewUrl"] ||
                                                                  asset["assets.ipfsPreviewImageUrl"]
                                                                }
                                                                alt=''
                                                                //width='64px'
                                                                //height='64px'
                                                                style = {{ height: '100%', width: 'auto' }}
                                                                />
                                                        </div>

                                                        {/*
                                                        <div className={styles.info}>
                                                          <div className={styles.name}>
                                                            {asset["assets.name"] || 'no name'}
                                                          </div>
                                                        </div>
                                                        */}
                                                        <div className={styles.info}>
                                                            <div className={styles.name}>
                                                                {/*
                                                                { asset["assets.quantity"] } X { asset["assets.name"] || 'no name'}
                                                                */}
                                                                { asset["assets.name"] || 'no name'}
                                                            </div>
                                                        </div>

                                                        <div className={styles.viewIcon}>
                                                            <svg viewBox='0 0 24 24' width='20px' height='20px'>
                                                                <path d='M9.707 18.707l6-6c0.391-0.391 0.391-1.024 0-1.414l-6-6c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z' />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                          <>
                                            <div
                                              className={styles.orderListItem}
                                              onClick={() => handleViewOrder(orderDetail?.id)}>
                                              <div className={styles.thumbnail}>
                                                <img
                                                  src={
                                                    orderDetail
                                                      ? orderDetail.asset.mediaPreviewUrl ||
                                                      orderDetail.asset.ipfsPreviewImageUrl
                                                      : ''
                                                  }
                                                  alt=''
                                                  width='64px'
                                                  height='64px'
                                                />
                                              </div>

                                              {/*
                                              */}
                                              <div className={styles.info}>
                                                <div className={styles.name}>
                                                    { orderDetail?.asset?.quantity }
                                                    X
                                                    { orderDetail ? orderDetail?.asset?.name || 'no name' : ''}
                                                </div>
                                              </div>

                                              <div className={styles.viewIcon}>
                                                <svg viewBox='0 0 24 24' width='20px' height='20px'>
                                                  <path d='M9.707 18.707l6-6c0.391-0.391 0.391-1.024 0-1.414l-6-6c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z' />
                                                </svg>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        <div className={styles.orderBillContainer}>
                                            <div
                                                //className={styles.orderBill}
                                                >

                                                {/*
                                                <b className={styles.left}>Order Price</b>
                                                <span className={styles.right}>
                                                    ${
                                                        orderDetail?.unitPrice
                                                        ? Number(orderDetail?.unitPrice).toFixed(3)
                                                        : ''}{' '}
                                                        ({
                                                            orderDetail?.ethPrice
                                                            ? Number(orderDetail?.ethPrice).toFixed(3)
                                                            : ''}{' '}
                                                        Matic)
                                                </span>
                                                */}

                                                {/*
                                                <b className={styles.left}>Delivery cost</b>
                                                <span className={styles.right}>${shippingFeeFinal.toFixed(2)}</span>
                                                */}

                                                <span
                                                    style = {{
                                                        whiteSpace: 'nowrap'
                                                        //, fontWeight: 'bold'
                                                        , color: 'var(--color-gray-700)'
                                                        , paddingRight: '20px'
                                                    }}
                                                    className = { `${styles.left} ${styles.orderTotal}` }
                                                    >
                                                    Order Total
                                                </span>

                                                <span
                                                    className={`${styles.right} ${styles.orderTotal}`}
                                                    style = {{ fontWeight: 'bold' }}
                                                    >
                                                    ${
                                                        orderDetail?.totalPrice
                                                        //? Number(orderDetail?.totalPrice).toFixed(3)
                                                        ? numberWithCommas(orderDetail?.totalPrice.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES))
                                                        : ''
                                                    } USD
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.noProductNoti}>Login to view details</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default OrderDetail
