import styles from "./mywallet.module.sass"
import cn from "classnames"
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
//import KycModal from "./KycModal";
//import WithDrawModal from "./WithDrawModal"
//import TruliooKyc from "./TurliooKyc";
import { NAMES_CONSTANTS } from '../../constants';

const MyWallet = () => {
    console.log('MyWallet()')

    //const loginStatus = useSelector((state) => state.counter.value);
    //const profilePic = useSelector(state => state.counter?.profilePicValue);
    const kycVerified = useSelector((state)=> state.counter?.kycVerifyStatus);
    const [address, setAddress] = useState("");
    const [kycPopupShow, setKycPopupShow]=useState(false);
    const [truliooPopup, setTruliooPopup]=useState(false);
    const [copiedShow, setCopiedShow] = useState(false);
    //const euroValue = useSelector((state) => state.counter.euroValue) || 0;
    //const tokenBal = useSelector((state) => state.counter?.wallet);
    const [transactionModal, setTransactionModal] = useState(false);

    useEffect(() => {
        const acc = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS))?.accounts;
        console.log('acc', acc)
        setAddress(acc)
    }, []);

    /*
    const handleClose = (val, type) => {
        if (val == "withDrawModal") {
            setTransactionModal(type)
        }
    };
    */

    /*
    const transferAmount = () => {
        if (kycVerified) {
            setTransactionModal(true)
        }else{
            setKycPopupShow(true)
        }
    };
    */

    /*
    const closeKycPopup=()=>{
        setKycPopupShow(false)
    };
    */

    /*
    const verifyNowBtn=()=>{
        setKycPopupShow(false)
        setTruliooPopup(true)
    };
    */

    /*
    const truliooPopupClose=()=>{
        setTruliooPopup(false)
    };
    */

    const copyWalletAddress = () => {
        navigator.clipboard.writeText(address);
        setCopiedShow(true);
    };

    return (

        <main
            className = 'edit-profile'
            >
            <section
                className = 'form'
                >

                <div
                    //className = { cn("container", "max-width") }
                    //className = 'flex-container max-width'
                    className = 'max-width'
                    >

                    <form
                        className = { "max-width" }
                        //onSubmit = { handleSubmit }
                        style = {{ margin: "0px", padding: "0px" }}>


                        <div
                            className = { "heading" }
                            style = {{
                                display: 'flex'
                                , flexDirection: 'row'
                                , flexWrap: 'wrap'
                                //, justifyContent: 'space-between'
                                , justifyContent: 'flex-start'
                            }}>
                            <div>
                                <h1 style = {{ display: 'inline-block' }}>
                                    My Wallet
                                </h1>
                            </div>
                            <div style = {{ paddingLeft: '40px' }}>
                                <img
                                        src = '/images/icons/noun-wallet-scratch.svg'
                                        alt = 'Wallet Icon'
                                        style = {{
                                            width: '50px'
                                            //, filter: 'var(--filter-theme-primary)'
                                        }}
                                        />
                            </div>
                        </div>

                        {/*
                        <div className = { "subtitle" }>
                            Share your <strong>referal link</strong> with your friends to earn commission on their purchases.
                            Invite your fiends to Join and <Link to="/reward-history" style = {{ cursor: "pointer" }}>earn commission</Link> on their purchases.
                        </div>
                        */}

                        {/*
                        <div
                            className = { styles.referWrapper }
                            style = {{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}
                            >
                        */}
                        <div
                            /*
                            style = {{
                                  display: "flex"
                                , flexDirection: "row"
                                , flexWrap: "wrap"
                                , width: "100%"
                                , alignItems: "center"
                                //, padding: '40px'
                            }}
                            */
                            //className = { "flex-container" }
                            className = { cn("flex-container", styles.referWrapper) }
                            >

                            <div
                                style = {{
                                      flexGrow: 1
                                    //, flexBasis: "50%"
                                    //, marginLeft: "50px"
                                }}
                                //className = { "flex-container" }
                                >

                                <label
                                    style = {{
                                          fontWeight: "700"
                                        , fontSize: "16px"
                                        , lineHeight: "20px"
                                        , textTransform: "uppercase"
                                        //, color: "#B1B5C4"
                                        , color: "var(--color-gray-600)"
                                        //, paddingBottom: "12px"
                                        , marginBottom: "12px"
                                    }}
                                    >Your Wallet Address</label>

                                {/**
                                <div
                                    className = { styles.referCopy }
                                    >
                                */}
                                <div
                                    className = 'input-box input-search'
                                    style = {{
                                        marginBottom: '20px'
                                    }}
                                    >
                                    <input
                                        type = { 'text' }
                                        name = 'copy'
                                        //value = { address?.[0]?.slice(0, 4) + "..." + address?.[0]?.slice(address?.[0]?.length - 5, address?.[0]?.length) }
                                        value = { address?.[0] }
                                        disabled
                                        //style = {{
                                        //      border: "2px solid #E6E8EC"
                                        //    , borderRadius: "12px"
                                        //}}
                                        />
                                </div>

                                <div
                                    //style={{ paddingTop: "20px" }}
                                    >
                                    <button
                                        type = { 'button' }
                                        //className = { cn("gold", "large") }
                                        className = 'primary'
                                        onClick = { () => copyWalletAddress() }
                                        >
                                        { copiedShow ? 'Copied' : 'Copy Wallet Address' }
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>

                </div>
            </section>
        </main>
    );
    
}

export default MyWallet;