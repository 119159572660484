import { useEffect } from "react";
//import { useLocation } from "react-router-dom";
import "./ShippingPolicy.css";
import cn from "classnames";
import styles from './shipping.module.sass';

const ReturnsPolicy = () => {

    let termlyPolicyId = "c8b88744-f612-40c0-a0ea-5b885a0ce1e9";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className={cn("section", styles.section)} style={{ color: "black", background: "rgb(245, 245, 245)", height: 'calc(100% - 492px)' }}>
            <div className={cn("container", styles.container)} style={{ fontSize: 18 }}>
                <div className={styles.policyContent}>
                  <div
                      name="termly-embed"
                      data-id = { termlyPolicyId }
                      data-type="iframe"
                      ></div>
                </div>
            </div>
        </div>
    );
};

export default ReturnsPolicy;
