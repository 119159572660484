import React, { useState, useEffect } from 'react';
//import { CircularProgress } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import {
    useHistory
    //, useParams
  } from 'react-router';
import {
    useSelector
    //, useDispatch
  } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';
import ReactPaginate from 'react-paginate';
import styles from './OrderDetails.module.sass';
import paginateStyles from './../../styles/Paginate.module.sass';
import { axiosInstance } from '../../utils/API';
import { numberWithCommas } from '../../utils/formatPricingNumber';
import { PAGINATE, ITEM_ACCEPTANCE, NAMES_CONSTANTS } from '../../constants';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';

const OrderDetails = (props) => {
    console.warn('OrderDetails(props)')
    console.warn('OrderDetails(props)', props)

    console.warn('props.userDetails = ', props.userDetails)
    //console.warn('props.getWindowWidth = ', props.getWindowWidth)

    // This was being used for "logged in" status. Now using the userAccessToken from the localStorage instead.
    const status = useSelector((state) => state.counter.value);
    const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);

    const rowsPerPage = 10;
    const history = useHistory();
    // const { id } = useParams();
    // const dispatch = useDispatch();
    //const euroValue = useSelector((state) => state.counter.euroValue) || 0;
    // const secretKey = useSelector((state) => state?.keyVal?.keyData);
    const [assets, setAssets] = React.useState([]);
    const [orders, setOrders] = useState([]);
    const [params, setParams] = useState({ limit: 11, page: 1 });
    const [loaderShow, setLoaderShow] = useState(false);
    const [count, setCount] = useState(0);
    const [cardView, setCardView] = useState(window.innerWidth < 1024);

    //console.warn('props.getWindowWidth() = ', props.getWindowWidth())
    //console.warn('props.windowWidth = ', props.windowWidth)
    //setCardView(props.getWindowWidth() < 1024);
    //setCardView(props.windowWidth < 1024);
    //cardView = props.windowWidth < 1024;
    //const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    React.useEffect(() => {
        //function handleResize() {
        const handleResize = () => {
            //console.warn('handleResize(): resized to: ', window.innerWidth, 'x', window.innerHeight)
            setCardView(window.innerWidth < 1024);
        }
        window.addEventListener('resize', handleResize);

        // 👇️ remove the event listener when the component unmounts
        return () => {
            //console.warn('handleResize(): window.removeEventListener(\'resize\', handleResize);')
            window.removeEventListener('resize', handleResize);
        };
    });

    const handlePageClick = ({ selected: selectedPage }) => {
        setParams({ ...params, page: selectedPage + 1 })
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    };

    //React.useEffect(() => {
    useEffect(() => {
      getAllAssets();
    }, [status, params]);

    const getAllAssets = async () => {
      setLoaderShow(true)
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN),
        },
        params
      };
      try {
        let userData = await axiosInstance.get("user/orders/", config);
        setAssets(userData?.data?.result?.orders?.rows);
        setCount(userData?.data?.result?.totalPages);
        setOrders(userData?.data?.result?.orders?.rows);
        setLoaderShow(false)
      } catch (err) {
        setLoaderShow(false)
      }
    };

    /*
    const handleView = (asset) => {
      history.push({
        pathname: '/checkoutReceipt',
        state: {
          details: asset,
          name: asset?.asset?.name,
          paymentType: asset.paymentType,
          orderTx: asset?.asset?.orderTx
        }
      })
    };
    */

    const handleViewOrder = (id) => {
      if (id) {
        history.push('/order-details/' + id)
      }
    };

    /*
    const displayStatus = (status) => {
      const thisOrderStatus = ITEM_ACCEPTANCE.ORDER_STATUS[status]
      if (thisOrderStatus) return (
        <>
          <div className={styles.orderStatus} style={{ background: thisOrderStatus.color }}>{thisOrderStatus.text}</div>
        </>
      )
      return (
        <>
          <div className={styles.orderStatus} style={{ background: '#E61515' }}>Delivery Issuses</div>
        </>
      )
    };
    */

    const clickListView = (event) => {
        console.log('clickListView(event)', event);

        setCardView(false);
    };

    const clickCardView = (event) => {
        console.log('clickCardView(event)', event);
        setCardView(true);
    };

    return (

        <main className = 'edit-profile'>
            <section
                className = 'form'
                style = {{
                    backgroundColor: "var(--color-gray-100)"
                }}
                >

                {
                    loaderShow
                    &&
                        <div
                            //className={styles.loaderContent}
                            ><CircularProgress /> </div>
                }

                <div
                    //className = {`${styles.orderDetailsPage} container max-width`}
                    className = 'max-width'
                    >

                    {/*
                    <div
                      style={{
                        paddingBottom: "30px"
                      }}>
                      <button
                        type="button"
                        className={cn("rounded-clear", "light")}
                        onClick={() => history.push("/my-assets")}
                        style={{
                          padding: "10px 15px"
                          , border: "2px solid #E6E8EC"
                        }}>
                        <div className={"table"}>
                          <div className={"cell"}><img src="images/icons/left-arrow-icon.png" style={{ verticalAlign: "middle" }} /></div>
                          <div className={"cell"} style={{ verticalAlign: "middle", paddingLeft: "15px" }}>Back to Profile</div>
                        </div>
                      </button>
                    </div>
                    */}

                    <div
                        className = { "heading" }
                        style = {{
                            display: 'flex'
                            , flexDirection: 'row'
                            , flexWrap: 'wrap'
                            //, justifyContent: 'space-between'
                            , justifyContent: 'flex-start'
                        }}>
                        <div style = {{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <h1 style = {{ display: 'inline-block' }}>
                                Your Orders
                            </h1>
                        </div>
                        {/*
                        <div style = {{ paddingLeft: '30px' }}>
                            <img
                                //src = '/images/icons/referrals-icon-28.jpg'
                                src = '/images/icons/shopping-cart-free-4-svgrepo-com-scratch.svg'
                                style = {{
                                    //width: '50px'
                                    height: '60px'
                                    //, filter: 'var(--filter-theme-primary)'
                                    , filter: 'invert(31%) sepia(37%) saturate(2487%) hue-rotate(192deg) brightness(92%) contrast(108%)'
                                }}
                                />
                        </div>
                        */}
                    </div>

                    <div
                        className = 'subtitle'
                        //style = {{
                        //    paddingTop: "30px"
                        //    , paddingBottom: "0px"
                        //}}
                        >
                        {
                            assets.length > 0
                            ? ( <span>Orders you have placed.</span> )
                            : ( <span>You have not placed any orders yet. Click <Link to='/search/all'>here</Link> to explore products.</span> )
                        }
                    </div>

                    {/*
                    {status ?
                      <div>
                        <>
                          {
                            assets?.map((asset, index) => {
                              const item = asset?.asset;
                              return (
                                <div className={styles.orderitem} style={{ padding: "10px" }} key={index}>
                                  {item.mediaType === "audio" &&
                                    <div
                                      className={styles.playerwrapper}
                                      style={{ position: "relative", paddingTop: 0 }}
                                    >
                                      <img
                                        src={item?.audioThumbnail}
                                        className={styles.thumbnailImage}
                                        alt="thumbnail.png"
                                      />
                                      <audio controls controlsList="nodownload"
                                        style={{ position: "absolute", bottom: 0, left: 0, height: 25, width: "100%" }}
                                      >
                                        <source src={item?.mediaPreviewUrl} type="audio/mpeg" />
                                      </audio>
                                    </div>
                                  }
                                  {
                                    item.mediaType === "video" &&
                                    <div
                                      className={styles.playerwrapper}

                                      style={{ position: "relative", paddingTop: 0 }}
                                    >
                                      <ReactPlayer
                                        url={item?.mediaPreviewUrl}
                                        width="100%"
                                        height="100%"
                                        controls={true}
                                        light={false}
                                        style={{ position: "absolute", top: "0", left: "0" }}
                                        config={{
                                          file: {
                                            attributes: { disablePictureInPicture: true, controlsList: "nodownload" }
                                          }
                                        }}
                                      />
                                    </div>
                                  }
                                  {(item?.mediaType === "image" || item?.mediaType === "3D Model") &&
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      className={styles.playerwrapper}
                                    >
                                      <img
                                        src={item?.thumbnailUrl || item?.mediaPreviewUrl}
                                        className={styles.thumbnailImage}
                                        alt="thumbnail.png"
                                      />
                                    </div>
                                  }
                                  <div className={styles.itemdetails}>
                                    <div className={styles.itemdetailsrow}>
                                      <h4 style={{ flexBasis: "70%" }}>
                                        {item?.name}
                                      </h4>
                                      {asset?.orderTx &&
                                        <a href={url + asset?.orderTx} target='_blank' rel="noreferrer"
                                          className={cn("button")} style={{ margin: 0 }}>
                                          View on Polygon Scan
                                        </a>
                                      }
                                    </div>
                                    <div className={styles.itemdetailsrow}>
                                      <div>
                                        <p className={styles.bold}>Order Id :</p>
                                        {asset?.id ? asset?.id : '-'}
                                      </div>
                                      <div>
                                        <p className={styles.bold}>Date :</p>
                                        {asset?.createdAt ? moment(asset?.createdAt).format('DD/MM/YYYY hh:mm A') : '-'}
                                      </div>
                                      <div>
                                        <p className={styles.bold}>Price :</p>
                                        &#36;{(asset?.totalPrice)?.toFixed() || "0" + " "} ({(asset?.totalPrice * euroValue)?.toFixed()} MATIC)
                                      </div>
                                      <div>
                                        <p className={styles.bold}>Status :</p>
                                        {asset?.status}
                                      </div>
                                      <div className={styles.viewBtnGroup}>
                                        <button className={styles.viewBtn} onClick={() => handleView(asset)}>
                                          View
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </>
                        {
                          assets?.length === 0
                            ? <div style={{ textAlign: 'center' }}>
                              No orders yet. Click on <Link to='/search/all' >categories</Link> to explore our products.
                            </div>
                            : <ReactPaginate
                              previousLabel={"← Previous"}
                              nextLabel={"Next →"}
                              pageCount={count}
                              onPageChange={handlePageClick}
                              containerClassName={styles.pagination}
                              pageClassName={styles.pagination_page}
                              previousLinkClassName={styles.pagination__link}
                              nextLinkClassName={styles.pagination__link}
                              disabledClassName={styles.pagination__link__disabled}
                              activeClassName={styles.pagination__link__active}
                            />
                        }
                      </div>
                      : <div
                        style={{
                          width: "90%"
                          , padding: "30px 5%"
                          , margin: "auto"
                          , textAlign: "center"
                          , fontSize: 15
                        }}>
                        Login to view details
                      </div>
                    }
                    */}

                    {
                        //status
                        userAccessToken
                        ? (
                            <div
                                //className={styles.orderList}
                                className = "flex-container"
                                style = {{
                                    marginTop: '30px'
                                    , gap: '0px'
                                    , justifyContent: 'end'
                                    , flexDirection: 'column'
                                }}
                                >

                                <div
                                    style = {{
                                        display: 'inline-block'
                                        , textAlign: 'right'
                                        , marginLeft: 'auto'
                                    }}
                                    >

                                    <div
                                        style = {{
                                            display: 'flex'
                                            , gap: '10px'
                                            , verticalAlign: 'bottom'
                                        }}
                                        >

                                        <div
                                            className = 'icon-button'
                                            style = {{
                                                verticalAlign: 'middle'
                                                , margin: '0px'
                                                //, backgroundColor: 'var(--color-gray-50)'
                                                , backgroundColor: '#f4f5f6'
                                                , borderRadius: '30px'
                                                , padding: '6px'
                                                , borderBottom: '1px solid var(--color-gray-400)'
                                            }}
                                            >
                                            {/*
                                            */}
                                            <ViewListIcon
                                                style = {{
                                                    width: '1em'
                                                    , height: '1em'
                                                    , fontSize: '40px'
                                                    , verticalAlign: 'middle'
                                                    , margin: '0px'
                                                    , fill: 'var(--color-theme-primary)'
                                                }}
                                                //onClick = { clickListView }
                                                onClick = { (event) => clickListView(event) }
                                                />
                                        </div>

                                        <div
                                            className = 'icon-button'
                                            style = {{
                                                verticalAlign: 'middle'
                                                , margin: '0px'
                                                //, backgroundColor: 'var(--color-gray-50)'
                                                , backgroundColor: '#f4f5f6'
                                                , borderRadius: '30px'
                                                , padding: '6px'
                                                , borderBottom: '1px solid var(--color-gray-400)'
                                            }}
                                            >
                                            <GridViewIcon
                                                //style = {{ width: '1em', height: '1em', fontSize: '2.1875rem' }}
                                                style = {{
                                                    width: '1em'
                                                    , height: '1em'
                                                    , fontSize: '40px'
                                                    , verticalAlign: 'bottom'
                                                    , margin: '0px'
                                                    , fill: 'var(--color-theme-primary)'
                                                    //, filter: 'invert(23%) sepia(85%) saturate(1915%) hue-rotate(203deg) brightness(96%) contrast(100%)'
                                                    //, fill: '#0A59C5'
                                                }}
                                                //onClick = { clickCardView }
                                                onClick = { (event) => clickCardView(event) }
                                                />
                                        </div>

                                    </div>
                                </div>

                                {
                                    orders?.length !== 0
                                    &&
                                        cardView
                                        ?
                                            <div
                                                className = 'card-container'
                                                style = {{
                                                    display: 'flex'
                                                    //background: 'var(--color-gray-150)'
                                                    //, margin: '8px'
                                                    , flex: '1'
                                                    , flexDirection: 'row'
                                                    , flexWrap: 'wrap'
                                                    , marginTop: '20px'
                                                }}
                                                >
                                            {
                                                orders?.map((order, index) => (
                                                    <div
                                                        //style = {{
                                                        //    background: 'var(--color-gray-150)'
                                                        //    , margin: '8px'
                                                        //    , borderRadius: '8px'
                                                        //}}
                                                        style = {{
                                                              //backgroundImage: 'url('+item['assets.mediaPreviewUrl']+')'
                                                              //backgroundImage: { 'url(' + { order?.assetsList?[0]?['assets.mediaPreviewUrl'] } + ')' }
                                                              backgroundImage: 'url('+order.assetsList[0]['assets.mediaPreviewUrl']+')'
                                                            , backgroundRepeat: 'no-repeat'
                                                            , backgroundPosition: 'center'
                                                            , backgroundSize: 'cover'
                                                            //, width: '64px'
                                                            //, minWidth: '64px'
                                                            //, height: '64px'
                                                            , cursor: 'pointer'
                                                            //, flexBasis: '50%'
                                                        }}
                                                        onClick = { () => handleViewOrder(order.id) }
                                                        >
                                                        <div
                                                          className = 'table'
                                                          //style = {{
                                                          //    cursor: 'pointer'
                                                          //}}
                                                          >

                                                            <div className = 'row'>
                                                                <div className = 'cell'>
                                                                    {/*
                                                                    <div className = 'label'>
                                                                        Order Number
                                                                    </div>
                                                                    */}
                                                                </div>
                                                                <div className = 'cell'>
                                                                    <div className = 'value label'>
                                                                        Order #{ order?.id }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className = 'row'>
                                                                <div className = 'cell'>
                                                                    {/*
                                                                    <div className = 'label'>
                                                                        Purchased on
                                                                    </div>
                                                                    */}
                                                                </div>
                                                                <div className = 'cell'>
                                                                    <div className = 'value label'>
                                                                        {
                                                                              //moment(order?.createdAt).format('DD/MM/YYYY')
                                                                              moment(order?.createdAt).format('ddd, MMMM DD, yyyy')
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className = 'row'>
                                                                <div className = 'cell'>
                                                                    {/*
                                                                    <div className = 'label'>
                                                                        Items
                                                                    </div>
                                                                    */}
                                                                </div>
                                                                <div
                                                                    className = 'cell'
                                                                    style = {{ verticalAlign: 'middle' }}
                                                                    >

                                                                    <div className = 'value label'>
                                                                        <div
                                                                            //style = {{ verticalAlign: 'middle' }}
                                                                            >
                                                                            {
                                                                                order?.assetsList ? order?.assetsList?.map((asset, index) => (
                                                                                    <div
                                                                                        //className = { styles.orderAsset }
                                                                                        key = { index }
                                                                                        >
                                                                                        { asset.quantity } x { asset["assets.name"] || 'No name' }
                                                                                    </div>
                                                                                )) : (
                                                                                    <div
                                                                                        //className={styles.orderAsset}
                                                                                        >
                                                                                        { order.quantity } x { order["asset.name"] || 'No name' }
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className = 'row'>
                                                                <div className = 'cell'>
                                                                    {/*
                                                                    <div className = 'label'>
                                                                        Order Total
                                                                    </div>
                                                                    */}
                                                                </div>
                                                                <div className = 'cell'>
                                                                    <div className = 'value label'>
                                                                        ${ numberWithCommas(order?.totalPrice) }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        :
                                            <>
                                            <div
                                                //className = { cn('table-container', styles.ordersTable) }
                                                className = { cn('table-container') }
                                                id="tableContainer"
                                                style = {{ overflow: 'auto' }}
                                                >
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            {/*
                                                            <th scope="col" className={styles.flexLeft}>Order Number</th>
                                                            <th scope="col" className = 'end'></th>
                                                            */}
                                                            <th scope="col" className={ cn(styles.flexLeft, "start") }>Order Number</th>
                                                            <th scope="col">Purchased on</th>
                                                            <th scope="col" className={styles.flexLeft}>Items</th>
                                                            <th scope="col" className={styles.flexRight}>Order Total</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody style = {{ fontSize: '1.2em' }}>
                                                        {
                                                            orders?.map((order, index) => (
                                                                <tr
                                                                    onClick = { () => handleViewOrder(order.id) }
                                                                    key = { index }
                                                                    style = {{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    >
                                                                    <td
                                                                        style = {{ textAlign: 'center' }}
                                                                        >
                                                                        <div
                                                                            //className={`${styles.flexLeft} ${styles.orderId}`}
                                                                            style = {{ textAlign: 'center' }}
                                                                            >
                                                                            #{order?.id}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div
                                                                            //className={styles.flexCenter}
                                                                            >
                                                                            { moment(order?.createdAt).format('ddd, MMM DD, yyyy') }
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div 
                                                                            //className={styles.flexLeft}
                                                                            >
                                                                            <div
                                                                                //className={styles.orderAssets}
                                                                                >
                                                                                {
                                                                                    order?.assetsList ? order?.assetsList?.map((asset, index) => (
                                                                                        <div
                                                                                            //className={styles.orderAsset}
                                                                                            key={index}>
                                                                                            { asset.quantity } X {asset["assets.name"] || 'No name'}
                                                                                        </div>
                                                                                    )) : (
                                                                                        <div
                                                                                            //className={styles.orderAsset}
                                                                                            >
                                                                                            { order.quantity } X {order["asset.name"] || 'No name'}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div
                                                                            //className={styles.flexRight}
                                                                            >
                                                                            ${ numberWithCommas(order?.totalPrice) }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            //className={styles.flexCenter}
                                                                            >
                                                                            <svg
                                                                                viewBox = "0 0 24 24"
                                                                                width="30px" height="30px"
                                                                                fill="#777E90"
                                                                                >
                                                                                <path d="M9.707 18.707l6-6c0.391-0.391 0.391-1.024 0-1.414l-6-6c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z" />
                                                                            </svg>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            {
                                                orders?.length === 0
                                                ? <div style={{ textAlign: 'center' }}>
                                                    No orders yet.
                                                    <br/><Link style = {{ color: 'var(--color-theme-primary)' }}to='/search/all' >Explore Deals</Link>
                                                  </div>
                                                : orders?.length > rowsPerPage
                                                    && (
                                                        <ReactPaginate
                                                            previousLabel = "← Previous"
                                                            nextLabel = "Next →"
                                                            pageCount = { count }
                                                            onPageChange = { handlePageClick }
                                                            containerClassName = { paginateStyles.pagination }
                                                            pageClassName = { paginateStyles.pagination_page }
                                                            previousLinkClassName = { paginateStyles.pagination__link__previous }
                                                            nextLinkClassName = { paginateStyles.pagination__link__next }
                                                            disabledClassName = { paginateStyles.pagination__link__disabled }
                                                            activeClassName = { paginateStyles.pagination__link__active }
                                                            pageRangeDisplayed = { PAGINATE.PAGE_RANGE_DISPLAYED }
                                                            marginPagesDisplayed = { PAGINATE.MARGIN_PAGES_DISPLAYED }
                                                            />
                                                    )
                                            }
                                            </>
                                }
                            </div>
                        )
                        : (
                            <div className={styles.noProductNoti}>
                                Login to view details
                            </div>
                        )
                    }
                </div>
            </section>
        </main>
    )
}

export default OrderDetails
