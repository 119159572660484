/** @format */

import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: []
  },
  reducers: {
    setNotifications: (state, data) => {
      state.notifications =  [...state.notifications, data.payload];
    },
  }
});

// Action creators are generated for each case reducer function
export const { setNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
