import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Icon from "../../../components/Icon";
import API from "../../../utils/API";
import styles from "./Auction.module.sass";
const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props} >{children}</button>
);

const dateOptions = ["Today", "Morning", "Dinner", "Evening"];
const directionOptions = ["Sellers", "Buyers"];
const RecentSold = (props) => {

  const [data, setData] = useState([]);
  //const [date, setDate] = useState(dateOptions[0]);
  //const [direction, setDirection] = useState(directionOptions[0]);
  //const euroValue = useSelector((state) => state.counter.euroValue) || 0;
  //const [isUpcomingBid, setIsUpcomingBid] = useState(false)
  const history = useHistory();

  useEffect(() => {

    let params = {
      // limit: 6,
      // page: 1,
    };

    API.get("/asset/active/auctions", {
      params,
    }).then((res) => {

      if (res?.data?.result?.assets) {
        let result = res?.data?.result?.assets
        let arr = []
        result.forEach(el => {

          /*
          let obj = {
            ...el,
            ...el?.asset
          }
          */

          arr.push(el)
        })
        setData(arr)
      }
    })
  }, []);

  /*
  const sliderSettings = {
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrownext" viewbox="0 0 13 22" size="13" fill="#0E69FF" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrowprev" viewbox="0 0 13 22" size="13" fill="#0E69FF" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  */

  const checkForUpcomingBid = (bidStartDate) => {
    let time = new Date(bidStartDate);
    var now = new Date();
    return time < now ? false : true;
  }

  if (data.length === 0) {
    return (null);
  } else {

    return (

      <section
      /*
      className = { "dark" }
      className={styles.section}
      style={{
          width: "100%"
        , backgroundImage: props.backgroundImage
      }}
      */
      >
        {/*
            <div className={cn("container", styles.container)}>
            */}
        <div className={"max-width"}>

          {/*}
                {data.length ?
                */}

          <>
            <h2>Current Auctions</h2>

            {/*
                <div className = { cn("container", "card-container") } style = {{ gap: "10px", justifyContent: "spaceBetween" }}>
                */}
            <div
              className={cn("container", "auctions-container")}
              style={{
                //  gap: "10px"
                //, justifyContent: "spaceBetween"
                padding: 0
              }}
            >

              {data.length ?
                <>
                  <div>
                    <ol style={{ listStyle: "none" }}>
                      {/*
                            {
                                <Slider className="popular-slider" {...sliderSettings}>
                            */}
                      {data.map((x, index) => {
                        if (!x?.asset) return
                        //const upcomingAsset = x?.auction ? checkForUpcomingBid(x?.auction?.startDate) : false;
                        return (
                          <li key={index}>

                            <div
                              className={"table"}
                              style={{ cursor: "pointer" }}
                              onClick={() => history.push("/item/" + x?.id)}
                            >
                              <div className={"cell"} style={{ verticalAlign: "middle", textAlign: "center", width: "50px" }}>
                                1
                              </div>
                              <div className={"cell"}>
                                {/*
                                <img src="images/Auction-Item-1.png" style={{ verticalAlign: "bottom" }} />
                                */}
                                <img src={x?.asset?.mediaPreviewUrl} style = {{ maxWidth: "80px", maxHeight: "80px" }} />
                              </div>
                              <div className={"cell"} style={{ verticalAlign: "middle" }}>
                                <span style={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>{x?.asset?.name}</span>
                                {/*
                                <br />Highest Bid: <strong>US${x?.asset ? (x?.asset?.regularPrice).toFixed(4) : ''}</strong>
                                */}
                                <br />Highest Bid: <strong>US${x?.asset ? (x?.auction?.currentBid).toFixed(4) : ''}</strong>
                              </div>
                            </div>

                            {/*
                                                <div
                                                    //className={styles.item}
                                                    className = { cn("item", styles.item) }
                                                    onClick={() => history.push("/item/" + x?.id)}
                                                    >

                                                    <img src="images/products/watch-black.png" style = {{ margin: "auto", padding: "0px 18px" }} />
                                                    <div className = { "watch-info-box" }>
                                                        <div>
                                                            <div className = { "watch-title" }>{(x?.asset?.name)}</div>
                                                        </div>
                                                        <div>
                                                            <div className = { "watch-subtitle" }>Altiplano</div>
                                                        </div>
                                                    </div>
                                                    <div className = { "price-box" }>
                                                        <div>
                                                            <div>Price USD</div>
                                                            <div>Price Crypto</div>
                                                        </div>
                                                        <div>
                                                            <div className = { "price price-dollars" }>US${x?.asset ? (x?.asset?.regularPrice).toFixed(4) : ''}</div>
                                                            <div className = { "price price-crypto" }>{(x?.asset?.regularPrice * euroValue).toFixed(4)} MATIC</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                */}
                          </li>
                        )
                      })}
                      {/*
                                </Slider>
                            }
                            */}
                    </ol>

                    <div className={"button-container"}>
                      <div className={"button-wrapper"}>
                        <button
                            className={"gold"}
                            onClick={() => history.push("/search/all")}
                            >See All</button>
                      </div>
                    </div>

                  </div>
                </>
                : <div className={styles.nodata}>
                  No Active Auctions.
                </div>
              }
            </div>
          </>

          {/*}
                : <div></div>
                }
                */}

        </div>
      </section>
    );
  } // if (data.length > 0) {
};

export default RecentSold;
