import React, { useState } from "react";
//import cn from "classnames";
import styles from "./EditProfile.module.sass";
//import Swal from "sweetalert2";
import { axiosInstance } from "../../utils/API";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
//import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setProvider, storeUserVerifyStatus } from '../../redux/counterSlice';
import { PopUpAlert } from "../../controller/utils";
//import { CircularProgress } from "@material-ui/core";
//import { CircularProgress } from '@mui/material';
//import { useEffect } from "react";
//import { useForm } from "react-hook-form";
//import HtmlComment from "../../components/HtmlComment";
import { base_url } from './../../controller/utils';
import { NAMES_CONSTANTS } from '../../constants';

const TEXTURE_API_BASE_URL = process.env.REACT_APP_TEXTURE_API_BASE_URL;
const TEXTURE_API_USERNAME = process.env.REACT_APP_TEXTURE_API_USERNAME;
const TEXTURE_API_PASSWORD = process.env.REACT_APP_TEXTURE_API_PASSWORD;

const EditProfile = ({
      className
    , value
}) => {
    console.log('EditProfile()');

    //const { register, handleSubmit, reset } = useForm();
    //const { handleSubmit, reset } = useForm();
    //const { reset } = useForm();

    //const history = useHistory()
    //const [productDetails, setProductDetails] = React.useState();
    const [error, setError] = useState(null)
    const [userDetails, setUserDetails] = React.useState({});
    const [textureCustomerObject, setTextureCustomerObject] = React.useState({});
    //const [address, setAddress] = React.useState("");
    const [walletAddress, setWalletAddress] = React.useState("");
    const [profilePic, setProfilePic] = React.useState(null);
    //const [confirmedTerm, setConfirmTerm] = React.useState(false);
    const [loaderShow, setLoaderShow] = useState(false)
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    let status = useSelector((state) => state.counter.value);
    //console.log('status = ' + status);
    //status = 1;

    const getConfig = () => {
        console.log('getConfig()');

        console.log('localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN) = ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN));

        let config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
        };

        /* Temporarily removed for development/testing
        if (!localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)) {
            PopUpAlert('Alert..', 'No wallet connection found', 'error')
            return
        }
        */

        return config;
    };

    const getProfileDetails = async () => {
        console.log('getProfileDetails()');

        /*
        if (!status) {
            // PopUpAlert('Disconnected', 'Connect to view your account userDetails', 'error');
            history.push('/search/all');
            return;
        }
        */

        const config = getConfig();
        await axiosInstance
            .get(`user/get-profile`, config)
            .then((res) => {

                console.log('res:', res);
                console.log('res.data:', res.data);
                console.log('res.data.result:', res.data.result);

                // Previous implementation...
                //const { firstName, lastName, email, mobileNumber, userName, profilePicUrl, status } = res.data?.result;
                //console.log('setUserDetails({ '+firstName+', '+lastName+', '+email+', '+mobileNumber+', '+userName+' });');
                //setUserDetails({ firstName, lastName, email, mobileNumber, userName });

                const {
                    firstName
                    , lastName
                    , email
                    , mobileNumber
                    , userName
                    , profilePicUrl
                    , status
                    , addresses
                    , textureCapitalCustomerId
                } = res.data?.result;

                console.log('firstName = ' + firstName);
                console.log('addresses = ' + addresses);
                setUserDetails(res.data?.result);
                console.log('userDetails:');
                console.log(userDetails);
                console.log('textureCapitalCustomerId = ' + textureCapitalCustomerId);

                /* Debugging user addresses...
                let addresses = userDetails?.addresses;
                console.log('addresses = ' + addresses)
                //let address = userDetails?.addresses?.[0];
                let address = addresses?.[0];
                console.log('address = ' + address);
                */

                dispatch(storeUserVerifyStatus(status));
                console.log('profilePicUrl = ' + profilePicUrl);
                if (profilePicUrl) {
                    setProfilePic(profilePicUrl);
                    updateHeaderProfileImage(profilePicUrl);
                }

                console.log('localStorage.getItem(\'textureCapitalCustomerId\') ' + localStorage.getItem('textureCapitalCustomerId'));
                //let textureCapitalCustomerId = "5e5859bc-c551-43fc-bb97-f9f93bc1347a";
                //localStorage.setItem('textureCapitalCustomerId', textureCapitalCustomerId);
                //let textureCapitalCustomerId = localStorage.getItem('textureCapitalCustomerId');
                if (!textureCapitalCustomerId) textureCapitalCustomerId = localStorage.getItem('textureCapitalCustomerId');
                console.log('textureCapitalCustomerId = ', textureCapitalCustomerId);
                fetchGetTextureCustomer(textureCapitalCustomerId);
            })
            .catch((err) => {

                /*
                if (err.response.data.message === 'Your account is blocked by Admin') {
                    PopUpAlert("Alert..", err.response.data.message, "error").then(() => {
                        document.getElementById('connectWallet').click()
                    });
                } else {
                  PopUpAlert("Alert..", err.response.data.message, "error")
                }
                */

                //history.push('/search/all');
                //setProfileDetails();
            });
    };

    /*
     * Temporary for development/testing
     */
    const setProfileDetails = async () => {

        /*
        const firstName = "First Name"
            , lastName = "Last Name"
            , email = "Email"
            , mobileNumber = "Mobile Number"
            , userName = "User Name"
            , profilePicUrl = "profilePicUrl"
            , status = "1"
            ;
        setUserDetails({ firstName, lastName, email, mobileNumber, userName });
        //*/

        /*
        dispatch(storeUserVerifyStatus(status))
        if (profilePicUrl) {
            setProfilePic(profilePicUrl);
            updateHeaderProfileImage(profilePicUrl);
        }
        */
    };

    const getProfilePicUrl = async () => {
        const config = getConfig();
        await axiosInstance
            .get(`user/get-profile`, config).then((res) => {
              const { profilePicUrl, status } = res.data?.result;
              dispatch(storeUserVerifyStatus(status))
              if (profilePicUrl) {
                setProfilePic(profilePicUrl);
              }
            });
    };

    React.useEffect(() => {
        getProfileDetails(id);
        //const walletAddress = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))?.accounts;
        const walletAddress = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.REALSPLIT_USER_WALLET_DETAILS))?.accounts;
        //setAddress(walletAddress);
        setWalletAddress(walletAddress);
    }, [status]);

    // effect runs when user state is updated
    //React.useEffect(() => {
        // reset form with user data
        //reset(userDetails);
    //}, [userDetails]);

    const handleChange = (e) => {
        console.log('handleChange(e)');
        console.log(e.target.name + ' = ' + e.target.value);
        console.log('e.target = ', e.target);
        console.log('e.target.type = ', e.target.type);
        
        let fieldName = e.target.name;
        let fieldValue = e.target.value;
        if (e.target.type == 'checkbox') {
            console.log('e.target.checked = ', e.target.checked);
            fieldValue = e.target.checked;
        }
        if (fieldValue) {
            setUserDetails({ ...userDetails, [fieldName]: fieldValue });
            setError({ ...error, [fieldName]: '' });
            console.log('userDetails = ', userDetails);
        } else {
            setUserDetails({ ...userDetails, [fieldName]: '' }); // unset the value in the userDetails object
            setError({ ...error, [fieldName]: 'This field is required' });
            console.log('error[\''+fieldName+'\'] = ' + error[fieldName]);
        }
    };

    //const handleImageUpload = (e) => {
    //    document.getElementById('my_file').click();
    //};

    /*
    const uploadFile = (e) => {
        console.log('files', e);
        setLoaderShow(true)
        const config = getConfig();
        const pic = document.getElementById('profile_pic');
        let files = e.target.files[0];

        if (files?.type === 'image/jpeg' || files?.type === 'image/jpg' || files?.type === 'image/png') {
            let formData = new FormData();
            formData.append("image", files);
            //console.log(formData);
            axiosInstance
                .post(`/user/update-profile-pic`, formData, config)
                .then(res => {
                    getProfilePicUrl()
                    setLoaderShow(false)
                    PopUpAlert("Great!", "Profile image successfully uploaded", "success").then(res => {
                      // window.location.reload();                        
                      setError({ ...error, profile: '' })
                    })
                })
                .catch(err => {
                    setLoaderShow(false)
                    PopUpAlert("Alert!", err?.response?.data?.message ? err?.response?.data?.message : 'Invalid media file, for IMAGE, only JPEG, PNG, is allowed!', "error");
                    e.target.value = null;
                })
        } else {
            setLoaderShow(false)
            PopUpAlert('Alert!', 'Please upload image in jpeg / jpg / png format', 'error').then((res) => window.location.reload())
        }
        // console.log("::files>>>>",files.type);
    };
    */

    const validation = () => {
        console.log('validation()');

        console.log('userDetails = ' + userDetails);
        console.log(userDetails);
        let valid = true
        let field = userDetails;
        let errors = {}

        if (!field['firstName'] || field['firstName']?.trim() == "") {
            errors['firstName'] = 'Please enter your first name'
            console.log('errors[\'firstName\'] = ' + errors['firstName']);
            valid = false
        }

        if (!field['lastName'] || field['lastName']?.trim() == "") {
            errors['lastName'] = 'Please enter your last name'
            console.log('errors[\'lastName\'] = ' + errors['lastName']);
            valid = false
        }

        if (!field["email"] || field['email']?.trim() == "") {
            errors["email"] = "Please enter your email";
            console.log('errors[\'email\'] = ' + errors['email']);
            valid = false;
        }

        /*
        if (profilePic === null) {
            errors["profile"] = "Please upload your profile image";
            console.log('errors[\'profile\'] = ' + errors['profile']);
            valid = false;
        }
        */

        // console.log('typeof field["email"]',typeof field["email"] ,field["email"]);
        if (field["email"] !== null) {

            console.log('field["email"] = ' + field["email"]);
            if (
                !field["email"].match(
                    /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
                )
            ) {
                errors["email"] = "Please enter valid email id";
                console.log('errors[\'email\'] = ' + errors['email']);
                valid = false;
            }
        }

        /*
        if (!field['userName'] || field['userName']?.trim() == "") {
            errors['userName'] = 'Please enter the user name'
            console.log('errors[\'userName\'] = ' + errors['userName']);
            valid = false
        }
        */

        let fieldNames = [
            'domicile'
            , 'occupation'
            , 'telephone_number'
            , 'finra_affiliation'
            , 'employer'
            , 'income' // commas may not be used, use underscore if a delimiter is desired
            , 'net_worth'
            , 'invest_objective'
            , 'liquidity_need'
            , 'tax_id'
            , 'dob'
            //, 'terms_of_service'
        ];
        
        /*
        if (!field["domicile"] || field['domicile']?.trim() == "") {
            errors["domicile"] = "This field is required";
            console.log('errors[\'domicile\'] = ' + errors['domicile']);
            valid = false;
        }
        */

        fieldNames.forEach((fieldName) => {
            console.log('requiredField('+ fieldName +')');
            console.log('userDetails['+fieldName+'] = '+ userDetails[fieldName]);
            if (!userDetails[fieldName] || userDetails[fieldName]?.trim() == "") {
                errors[fieldName] = "This field is required";
                console.log('errors[\''+fieldName+'\'] = ' + errors[fieldName]);
                valid = false;
                //return false;
            }
        });
        //fieldNames.forEach(requiredField);
        //fieldNames.forEach(function(fieldName) {
        //    let isValid = requiredField(fieldName);
        //})

        if (!field["terms_of_service"]) {
            errors["terms_of_service"] = "This field is required";
            console.log('errors[\'terms_of_service\'] = ' + errors['terms_of_service']);
            valid = false;
        }

        setError(errors);

        return valid
    };

    /*
    const requiredField = (fieldName) => {
        console.log('requiredField('+ fieldName +')');
        if (!userDetails[fieldName] || userDetails[fieldName]?.trim() == "") {
            errors[fieldName] = "This field is required";
            console.log('errors[\''+fieldName+'\'] = ' + errors[fieldName]);
            return false;
        }
    }
    */

    /* Example user JSON
    {
        "firstName": "First",
        "lastName": "Name",
        "email": "name@email.com",
        "userName": "username",
        "addresses": [
            {
                "addressLine1": "addressLine1",
                "addressLine2": "addressLine2",
                "townCity": "townCity",
                "countyStateProvince": "countyStateProvince",
                "postalCode": "12345",
                "countryCode": "US",
                "isMainAddress": true,
                "isDeleted": false,
                "id": 11
            }
        ]
    }
    */

    const handleSubmit = (e) => {
        console.log('handleSubmit(e)')

        e.preventDefault();

        if (validation()) {

            console.log('userDetails = ', userDetails);
            console.log('userDetails.email = ' + userDetails.email);
            userDetails.userName = userDetails.email;
            console.log('userDetails.userName = ' + userDetails.userName);

            let addressObject = {
                  "addressLine1": userDetails.addressLine1
                , "addressLine2": userDetails.addressLine2
                , "townCity": userDetails.townCity
                , "countyStateProvince": userDetails.countyStateProvince
                , "postalCode": userDetails.postalCode
                , "countryCode": userDetails.countryCode
                , "isMainAddress": true
                , "isDeleted": false
                //, "id": 11
            };
            console.log(addressObject);

            let addresses = [
                addressObject
            ];

            userDetails.addresses = addresses;
            console.log(userDetails.addresses);

            let textureCustomerObject = createTextureCustomerObject(userDetails);

            fetchUpdateProfile(userDetails, textureCustomerObject);
        }
    };

    /*
    "customer": {
        "first_name": "Joseph",
        "last_name": "Schumpeter",
        "email": "joey@schumps.com",
        "domicile": "USA",
        "state": "GA",
        "telephone_number": "212-555-1212",
        "tax_id": "333-22-4444",
        "dob": "1950-01-08", # use the example format
        "address_line1": "123 West Vienna Street",
        "address_line2": "Apt. 25",
        "city": "Triesch",
        "zip": "12345",
        "occupation": "Professor",
        "employer": "University of Vienna",
        "finra_affiliation": "false", # indicates whether or not the customer is affiliated with a B/D, exchange or similar
        "income": "100_000", # commas may not be used, use underscore if a delimiter is desired
        "net_worth": "3_000_000",
        "invest_objective": "Speculation",
        "liquidity_need": "Does not matter",
        "terms_of_service": "true"
    }
    */
    let createTextureCustomerObject = (userDetails) => {
        console.log('createTextureCustomerObject(userDetails:'+typeof userDetails+')');

        let textureCustomerObject = {
              "first_name": userDetails.firstName
            , "last_name": userDetails.lastName
            , "email": userDetails.email
            , "domicile": userDetails.domicile
            , "state": userDetails.state
            , "state": userDetails?.addresses?.[0]?.countyStateProvince
            /*
            , "telephone_number": userDetails.phone
            , "tax_id": "333-22-4444"
            , "dob": "1950-01-08" // use the example format
            , "address_line1": "123 West Vienna Street"
            , "address_line2": "Apt. 25"
            , "city": "Triesch"
            , "zip": "12345"
            , "occupation": userDetails.occupation
            , "employer": userDetails.employer
            , "finra_affiliation": "false" // indicates whether or not the customer is affiliated with a B/D, exchange or similar
            , "income": "100_000" // commas may not be used, use underscore if a delimiter is desired
            , "net_worth": "3_000_000"
            , "invest_objective": userDetails.investmentObjective
            , "liquidity_need": userDetails.liquidityNeed
            , "terms_of_service": "true"
            */
        }
        console.log('textureCustomerObject = ', textureCustomerObject);

        return textureCustomerObject;
    };

    let fetchUpdateProfile = (bodyObject, textureCustomerObject) => {
        console.log('fetchUpdateProfile(bodyObject:'+typeof bodyObject+', textureCustomerObject)');

        console.log('bodyObject = ', bodyObject);
        console.log('textureCustomerObject = ', textureCustomerObject);
        console.log('Authorization: Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN));

        let fetchConfig = {
            method: 'PATCH'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: JSON.stringify(bodyObject)
        };

        console.log('base_url = ', base_url);
        console.log(`base_url = ${base_url}`);
        let fetchPath = `${base_url}user/update`;
        console.log('fetchPath = ', fetchPath);

        //*
        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                console.log('fetchPromise.then(response)');
                console.log(response);
                console.log('response.ok = ' + response.ok);
                console.log('response.status = ' + response.status);
                console.log('response.statusText = ' + response.statusText);
                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //Swal.close();
                    return response.json();
                }
            })
            .then(function(responseJson) {
                console.log('fetch("'+fetchPath+'").then(responseJson)');
                console.log(responseJson);
                //getProfileDetails();
                //fetchTextureToken();
                //fetchTextureToken(handleTextureTokenResponse, textureCustomerObject);
                fetchTextureToken(textureCustomerObject);
            });
        //*/
    }; // fetchUpdateProfile()

    /*
    let handleTextureTokenResponse = function(responseJson) {
        console.log('handleTextureTokenResponse(responseJson)');
        console.log(responseJson);
        //getProfileDetails();
        let textureToken = responseJson.token;
        console.log('textureToken = ', textureToken);
        localStorage.setItem('textureToken', textureToken)
        console.log('localStorage.getItem(\'textureToken\') ' + localStorage.getItem('textureToken'));

        fetchCreateTextureCustomer();
    };
    */

    /*
    {
        "token": "eyJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lcl9pZCI6IjMwMjIxY2NlLTkxNjgtNGZiYy04MzY3LTMwNzE0NmU5Y2ZjNSIsImV4cCI6MTY4NzE2NzkyM30.7evVnV3ynUUyNRjNy0fmz4FwafdgyMTmacReML6aHiA",
        "exp": "06-19-2023 09:45",
        "customer_id": "30221cce-9168-4fbc-8367-307146e9cfc5"
    }
    */
    //let fetchTextureToken = (fetchTokenCallbackFunction, textureCustomerObject) => {
    let fetchTextureToken = (textureCustomerObject) => {
        //console.log('fetchTextureToken(fetchTokenCallbackFunction:'+typeof fetchTokenCallbackFunction+', textureCustomerObject:'+typeof textureCustomerObject+')');
        console.log('fetchTextureToken(textureCustomerObject:'+typeof textureCustomerObject+')');

        let fetchConfig = {
            method: 'POST'
            , headers: {
                  'Accept': 'application/json'
                  //'Accept': '*/*'
                //, 'Content-Type': 'application/json'
                //, Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: null
        };

        console.log('TEXTURE_API_BASE_URL = ', TEXTURE_API_BASE_URL);
        console.log(`TEXTURE_API_BASE_URL = ${TEXTURE_API_BASE_URL}`);
        //{{BASE_URL}}/api/v1/auth/login?email={{username}}&password={{password}}
        let fetchPath = `${TEXTURE_API_BASE_URL}/api/v1/auth/login?email=${TEXTURE_API_USERNAME}&password=${TEXTURE_API_PASSWORD}`;
        console.log('fetchPath = ', fetchPath);

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                console.log('fetchPromise.then(response)');
                console.log(response);
                console.log('response.ok = ' + response.ok);
                console.log('response.status = ' + response.status);
                console.log('response.statusText = ' + response.statusText);
                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    return response.json();
                }
            })

            /*
                {
                    "token": "eyJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lcl9pZCI6IjMwMjIxY2NlLTkxNjgtNGZiYy04MzY3LTMwNzE0NmU5Y2ZjNSIsImV4cCI6MTY4NzUyOTM1NH0.dM2xxStZ5G0dV7Jlrn-yE5BD5uUsX-zjx7OSyzkPm00",
                    "exp": "06-23-2023 14:09",
                    "customer_id": "30221cce-9168-4fbc-8367-307146e9cfc5"
                }
            */
            //*
            .then(
                /*
                function(responseJson) {
                    console.log('fetch("'+fetchPath+'").then(responseJson)');
                    console.log(responseJson);
                    //getProfileDetails();
                    let textureToken = responseJson.token;
                    console.log('textureToken = ', textureToken);
                    localStorage.setItem('textureToken', textureToken)
                    console.log('localStorage.getItem(\'textureToken\') ' + localStorage.getItem('textureToken'));
                }
                */
                function(responseJson) {
                    console.log('function(responseJson)');
                    console.log(responseJson);
                    //getProfileDetails();
                    let textureToken = responseJson.token;
                    console.log('textureToken = ', textureToken);
                    localStorage.setItem('textureToken', textureToken)
                    console.log('localStorage.getItem(\'textureToken\') ' + localStorage.getItem('textureToken'));

                    fetchCreateTextureCustomer(textureCustomerObject);
                }
            );
            //*/
            //.then(fetchTokenCallbackFunction);

    }; // fetchTextureToken(textureCustomerObject)

    let fetchCreateTextureCustomer = (textureCustomerObject) => {
        console.log('fetchCreateTextureCustomer(textureCustomerObject:'+typeof textureCustomerObject+')');

        console.log('Authorization: Bearer ' + localStorage.getItem('textureToken'));

        let fetchConfig = {
            method: 'POST'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem('textureToken')
            }
            , body: JSON.stringify(textureCustomerObject)
        };

        console.log(`TEXTURE_API_BASE_URL = ${TEXTURE_API_BASE_URL}`);
        let fetchPath = `${TEXTURE_API_BASE_URL}/api/v1/customers/`;
        console.log('fetchPath = ', fetchPath);

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                console.log('fetch('+fetchPath+').then(response = ', response);
                console.log('response.ok = ' + response.ok);
                console.log('response.status = ' + response.status);
                console.log('response.statusText = ' + response.statusText);
                if (response.ok) { // status of 200 signifies sucessful HTTP call, 'response.ok' is shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //Swal.close();
                    return response.json();
                }
            })
            .then(function(responseJson) {
                console.log('fetch("'+fetchPath+'").then(responseJson)');
                console.log(responseJson);
                //getProfileDetails();

                /*
                    {
                        "id": "5e5859bc-c551-43fc-bb97-f9f93bc1347a",
                        "customer_type": "User",
                        "full_name": "dermot doherty",
                        "approved_to_trade": false,
                        "email": "service.account@wearefuturetech.com",
                        "telephone_number": null,
                        "address_line1": null,
                        "address_line2": null,
                        "city": null,
                        "state": "Gto.",
                        "zip": null,
                        "domicile": "MEX"
                    }
                */
                let textureCapitalCustomerId = responseJson.id;
                console.log('textureCapitalCustomerId = ', textureCapitalCustomerId);
                localStorage.setItem('textureCapitalCustomerId', textureCapitalCustomerId);
                console.log('localStorage.getItem(\'textureCapitalCustomerId\') ' + localStorage.getItem('textureCapitalCustomerId'));

                getProfileDetails();
                fetchGetTextureCustomer(textureCapitalCustomerId);
            });

    }; // fetchCreateTextureCustomer()

    /*
        {
            "id": "5e5859bc-c551-43fc-bb97-f9f93bc1347a",
            "customer_type": "User",
            "full_name": "dermot doherty",
            "approved_to_trade": false,
            "email": "service.account@wearefuturetech.com",
            "telephone_number": null,
            "address_line1": null,
            "address_line2": null,
            "city": null,
            "state": "Gto.",
            "zip": null,
            "domicile": "MEX"
        }
    */
    let fetchGetTextureCustomer = (textureCapitalCustomerId) => {
        console.log('fetchGetTextureCustomer(textureCustomerObject:'+typeof textureCapitalCustomerId+')');

        console.log('Authorization: Bearer ' + localStorage.getItem('textureToken'));

        let fetchConfig = {
            method: 'GET'
            , headers: {
                 'Accept': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem('textureToken')
            }
        };

        console.log(`TEXTURE_API_BASE_URL = ${TEXTURE_API_BASE_URL}`);
        let fetchPath = `${TEXTURE_API_BASE_URL}/api/v1/customers/${textureCapitalCustomerId}`;
        console.log('fetchPath = ', fetchPath);

        fetch(fetchPath, fetchConfig)
            .then(function(response) {
                console.log('fetch('+fetchPath+').then(response = ', response);
                console.log('response.ok = ' + response.ok);
                console.log('response.status = ' + response.status);
                console.log('response.statusText = ' + response.statusText);
                
                if (response.ok) { // status of 200 signifies sucessful HTTP call, 'response.ok' is shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.
                    //return response.json();
                    response.json().then(function(responseJson) {
                        console.log(responseJson);
                        setTextureCustomerObject(responseJson);
                    });
                } else {
                    response.json().then(function(responseJson) {
                        console.log(responseJson);

                        let responseError = responseJson.errors;
                        console.log("responseError = ", responseError);

                        // Signature has expired
                        if (responseError == "Signature has expired")
                            fetchTextureToken(textureCapitalCustomerId);
                    });
                }
            /*
            })
            .then(function(responseJson) {
                console.log('fetch("'+fetchPath+'").then(responseJson)');
                console.log(responseJson);
            */
            });

    }; // fetchGetTextureCustomer()

    /*
    const handleCancel = () => {
        setUserDetails({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          userName: ""
        })
        setError(null)
        getProfileDetails();
        // setConfirmTerm(false);
    };
    */

    /*
    const goTo = (x) => {
        console.log("EditProfile(): goTo("+x+")");
        alert("EditProfile(): goTo("+x+")");
    };
    */

    const updateHeaderProfileImage = (x) => {
        let profileImageElement = document.getElementById('header-profile-image');
        profileImageElement.setAttribute('src', x);

        //const walletAddress = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))?.account;
        //const profileImage = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))?.profileImage;
        //alert("profileImage = " + profileImage);
        const walletUserDetails = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))
        console.log('EditProfile(): walletUserDetails = ' + walletUserDetails)
        console.log(walletUserDetails)
        console.log('EditProfile(): walletUserDetails.profilePicUrl = ' + walletUserDetails.profilePicUrl)
        //const profileImage = JSON.parse(localStorage.getItem('REALSPLIT_USER_WALLET_DETAILS'))?.profileImage;
        //let profilePicObject = {
        //    profilePicUrl: x
        //};
        //localStorage.setItem('REALSPLIT_USER_WALLET_DETAILS', JSON.stringify({...walletUserDetails, x }))
        //localStorage.setItem('REALSPLIT_USER_WALLET_DETAILS', JSON.stringify({...walletUserDetails, profilePicObject }))
        //localStorage.setItem('REALSPLIT_USER_WALLET_DETAILS', JSON.stringify({...walletUserDetails, { profilePicUrl: x } }))
        localStorage.setItem('REALSPLIT_USER_WALLET_DETAILS', JSON.stringify({...walletUserDetails, profilePicUrl: x }))
    };

    //let developmentMode = true;
    //const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    //let fieldName;

    return (

        <main
            className = { "edit-profile" }
            //style = {{ paddingTop: "230px" }}
            >

            <section
                className = { "form" }
                //style = {{ padding: "0px 50px" }}
                //style = {{
                //    paddingTop: "40px"
                //}}
                >

                <div className = { "max-width" }>

                    <form
                        className = { "max-width" }
                        onSubmit = { handleSubmit }
                        style = {{ margin: "0px", padding: "0px" }}>

                        <div
                            className = { "button-container" }
                            //style = {{ paddingBottom: "40px" }}
                            >
                            <h1>Profile</h1>
                        </div>

                        {/*
                        <p style = {{ maxWidth: "736px" }}>
                            Thank you for joining RealSplit, we’d like to know more about you, please enter your name, email address and a profile photo below. you can also add a Shipping address for when you purchase a product.
                        </p>
                        */}

                        {/*                        
                        <div className = { 'form-section' }>
                        */}

                            <div
                                className = { "flex-container" }
                                //style = {{ flexBasis: "50%" }}
                                //style = {{
                                //    gap: "32px"
                                //    , alignItems: "flex-start"
                                //}}
                                >

                                <div
                                    /*
                                    className = { "form-heading" }
                                    */
                                    style = {{
                                          display: "flex"
                                        //, padding: "10px"
                                        //, background: "#EBEBEB"
                                        //, borderRadius: "6px"
                                        //, fontFamily: "'Poppins'"
                                        //, fontStyle: "normal"
                                        //, fontWeight: "700"
                                        //, fontSize: "14px"
                                        //, lineHeight: "12px"
                                        //, textTransform: "uppercase"
                                        //, color: "#000000"
                                        , width: "100%"
                                        , justifyContent: 'space-between'
                                    }}
                                    >
                                    <div className = { "form-heading" }>
                                        Personal Information
                                    </div>
                                    <div>
                                        <button
                                            className = { "theme-success" }
                                            onClick = { () => history.push('/user-profile') }
                                            >
                                            <div
                                                style = {{
                                                    display: 'table'
                                                }}
                                                >
                                                <div className = { "row" }>
                                                    <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                        <img src="images/icons/edit-icon-white.svg" alt = 'Edit Icon' />
                                                    </div>
                                                    <div className = { "cell text" }>
                                                        Cancel
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div
                                    className = { "profile-photo" }
                                    style = {{ flexBasis: "100%" }}
                                    >

                                    <label>Profile Image</label>
                                    <div className = { "table" }>
                                        <div className = { "cell" }  style = {{ paddingRight: "20px" }}>
                                            <img
                                                style = {{ width: '86px', height: '86px' }}
                                                alt = 'Profile Pic'
                                                src="images/avatar-icon-large.png" />
                                        </div>
                                        <div className = { "cell" }  style = {{ verticalAlign: "top", marginTop: "0" }}>

                                            <div className = { "profile-photo-box" }>
                                                {/*
                                                <div>
                                                    <div className = { "title" }>Profile photo</div>
                                                </div>
                                                */}
                                                <div>
                                                    <div className = { "subtitle" }>We recommend an image of at least 400x400. Gifs work too</div>
                                                </div>
                                                <div  style = {{ marginTop: "0px" }}>
                                                    <button type="button" className = { "rounded-clear light" }  style = {{ width: "auto", padding: "8px 12px", marginTop: "0px" }}>Upload</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div>

                                    <label>First name</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name = "firstName"
                                            placeholder="Enter First name"
                                            //value = { userDetails?.firstName }
                                            value = { userDetails?.firstName ? userDetails?.firstName : '' }
                                            onChange = { handleChange }
                                            />

                                        <img src="images/icons/user-icon-dark.png"  alt = 'User Icon Dark' />
                                    </div>
                                    {
                                        error?.firstName
                                        && <div className = { styles.errorMsg }>{ error?.firstName }</div>
                                    }

                                </div>
                                <div>

                                    <label>Last Name</label>
                                    <div className = { "input-box" }>
                                        <input
                                            //className={styles.input}
                                            type="text"
                                            name="lastName"
                                            placeholder="Enter Last name"
                                            value = { userDetails?.lastName }
                                            onChange = { handleChange }
                                            //disabled = { !confirmedTerm }
                                            />
                                    </div>
                                    {
                                        error?.lastName
                                        && <div className = { styles.errorMsg }>{ error?.lastName }</div>
                                    }
                                </div>

                                <div>
                                    <label>Email</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="email"
                                            //placeholder="Enter Your Email"
                                            placeholder="name@email"
                                            onChange = { handleChange }
                                            value = { userDetails?.email }
                                            onChange = { handleChange }
                                            //disabled = { !confirmedTerm }
                                            />
                                        <input type="hidden" name="userName" />
                                    </div>
                                    {
                                        error?.email
                                        && <div className = { styles.errorMsg }>{ error?.email }</div>
                                    }
                                </div>

                                <div>
                                    <label>Telephone Number</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="telephone_number"
                                            id="telephone_number"
                                            placeholder="000-000-0000"
                                            onChange = { handleChange }
                                            value = { textureCustomerObject?.telephone_number }
                                            />
                                    </div>
                                    {
                                        error?.telephone_number
                                        && <div className = { styles.errorMsg }>{ error?.telephone_number }</div>
                                    }
                                </div>

                                {/*
                                <div>
                                    <label>Domicile</label>
                                    <div className = { "input-box" }>
                                        <select
                                            //className={styles.input}
                                            type="text"
                                            name="domicile"
                                            placeholder="Domicile"
                                            value = { textureCustomerObject?.domicile }
                                            onChange = { handleChange }
                                            //disabled = { !confirmedTerm }
                                            >
                                            <option value="">Select</option>
                                            <option value="USA">United States</option>
                                            <option value="MEX">Mexico</option>
                                        </select>
                                    </div>
                                    {
                                        error?.domicile
                                        && <div className = { styles.errorMsg }>{ error?.domicile }</div>
                                    }
                                </div>

                                <div>
                                    <label>State</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="state"
                                            id="state"
                                            //placeholder="State"
                                            onChange = { handleChange }
                                            //value = { address?.countyStateProvince }
                                            value = { textureCustomerObject?.state }
                                            />
                                    </div>
                                    {
                                        error?.domicile
                                        && <div className = { styles.errorMsg }>{ error?.domicile }</div>
                                    }
                                </div>

                                <div>
                                    <label>Date of Birth</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="dob"
                                            id="dob"
                                            placeholder="E.g. 1970-01-31"
                                            onChange = { handleChange }
                                            value = { textureCustomerObject?.dob }
                                            />
                                    </div>
                                    {
                                        error?.dob
                                        && <div className = { styles.errorMsg }>{ error?.dob }</div>
                                    }
                                </div>

                                <div>
                                    <label>Tax ID (SSN)</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="tax_id"
                                            id="tax_id"
                                            placeholder="xxx-xx-xxxx"
                                            onChange = { handleChange }
                                            value = { textureCustomerObject?.tax_id }
                                            />
                                    </div>
                                    {
                                        error?.tax_id
                                        && <div className = { styles.errorMsg }>{ error?.tax_id }</div>
                                    }
                                </div>
                                */}

                            </div>

                            <div
                                className = { "flex-container" }
                                >

                                <div
                                    //className = { "form-heading" }
                                    style = {{
                                          display: "flex"
                                        , width: "100%"
                                        , justifyContent: 'space-between'
                                        , flexBasis: "100%"
                                    }}
                                    >
                                    <div className = { "form-heading" }>
                                        Location
                                    </div>
                                    <div>
                                        <button
                                            className = { "theme-success" }
                                            onClick = { () => history.push('/user-profile') }
                                            >
                                            <div
                                                style = {{
                                                    display: 'table'
                                                }}
                                                >
                                                <div className = { "row" }>
                                                    <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                        <img
                                                            //src="images/icons/edit-icon-white.svg"
                                                            src="images/icons/svgrepo/edit-svgrepo-com.svg"
                                                            alt = 'Edit Icon'
                                                            style = {{ width: '20px' }}
                                                            />
                                                    </div>
                                                    <div className = { "cell text" }>
                                                        Cancel
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                {
                                      userDetails?.addresses?.length
                                    ? userDetails?.addresses?.map((address, index) =>
                                        <>
                                            <div>
                                                <label>Address line 1</label>
                                                <div className = { "input-box" }>
                                                    <input
                                                        type="text"
                                                        name = "addressLine1"
                                                        id = "addressLine1"
                                                        //placeholder = "Address line 1"
                                                        value = { address?.addressLine1 }
                                                        onChange = { handleChange }
                                                        //disabled = { !confirmedTerm }
                                                        />
                                                </div>
                                            </div>
                                            <div>
                                                <label>Address line 2</label>
                                                <div className = { "input-box" }>
                                                    <input
                                                        type="text"
                                                        name="addressLine2"
                                                        id="addressLine2"
                                                        //placeholder="Address line 2"
                                                        value = { address?.addressLine2 }
                                                        onChange = { handleChange }
                                                        //disabled = { !confirmedTerm }
                                                        />
                                                </div>
                                            </div>
                                            <div>
                                                <label>Town/City</label>
                                                <div className = { "input-box" }>
                                                    <input
                                                        type="text"
                                                        name="townCity"
                                                        id="townCity"
                                                        //placeholder="Town / City"
                                                        value = { address?.townCity }
                                                        onChange = { handleChange }
                                                        />
                                                </div>
                                            </div>
                                            <div>
                                                <label>State</label>
                                                <div className = { "input-box" }>
                                                    <input
                                                        type="text"
                                                        name="countyStateProvince"
                                                        id="countyStateProvince"
                                                        //placeholder="State"
                                                        value = { address?.countyStateProvince }
                                                        onChange = { handleChange }
                                                        />
                                                </div>
                                            </div>
                                            <div>
                                                <label>ZIP/Postal Code</label>
                                                <div className = { "input-box" }>
                                                    <input
                                                        type = "text"
                                                        name = "postalCode"
                                                        id = "postalCode"
                                                        //placeholder = "ZIP/Postal Code"
                                                        value = { address?.postalCode }
                                                        onChange = { handleChange }
                                                        />
                                                </div>
                                            </div>
                                            <input type="hidden" name="countryCode" id="countryCode" value="US" />
                                        {/*</div>*/}
                                        </>
                                    ) : 
                                        <>
                                        
                                        <div>
                                            <label>Address line 1</label>
                                            <div className = { "input-box" }>
                                                <input
                                                    type="text"
                                                    name = "addressLine1"
                                                    id = "addressLine1"
                                                    //placeholder = "Address line 1"
                                                    //value = { userDetails?.addresses?[0]?.addressLine1? }
                                                    //value = { address?.addressLine1 }
                                                    //value = { userDetails?.addresses?.length }
                                                    onChange = { handleChange }
                                                    //disabled = { !confirmedTerm }
                                                    />
                                            </div>
                                        </div>
                                        <div>
                                            <label>Address line 2</label>
                                            <div className = { "input-box" }>
                                                <input
                                                    type="text"
                                                    name="addressLine2"
                                                    id="addressLine2"
                                                    //placeholder="Address line 2"
                                                    onChange = { handleChange }
                                                    //value = { address?.addressLine2 }
                                                    //disabled = { !confirmedTerm }
                                                    />
                                            </div>
                                        </div>
                                        <div>
                                            <label>City</label>
                                            <div className = { "input-box" }>
                                                <input
                                                    type="text"
                                                    name="townCity"
                                                    id="townCity"
                                                    //placeholder="Town / City"
                                                    onChange = { handleChange }
                                                    //value = { address?.townCity }
                                                    />
                                            </div>
                                        </div>
                                        <div>
                                            <label>Zip Code</label>
                                            <div className = { "input-box" }>
                                                <input
                                                    type = "text"
                                                    name = "postalCode"
                                                    id = "postalCode"
                                                    //placeholder = "ZIP/Postal Code"
                                                    onChange = { handleChange }
                                                    //value = { address?.postalCode }
                                                    />
                                            </div>
                                        </div>
                                        </>
                                }

                            </div>
                            <div
                                className = { "flex-container" }
                                >                                

                                <div
                                    //className = { "form-heading" }
                                    style = {{
                                          display: "flex"
                                        , width: "100%"
                                        , justifyContent: 'space-between'
                                        , flexBasis: "100%"
                                    }}
                                    >
                                    <div className = { "form-heading" }>
                                        Employment Information
                                    </div>
                                    <div>
                                        <button
                                            className = { "theme-success" }
                                            onClick = { () => history.push('/user-profile') }
                                            >
                                            <div
                                                style = {{
                                                    //padding: '4px 8px'
                                                    display: 'table'
                                                }}
                                                >
                                                <div className = { "row" }>
                                                    <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                        <img src="images/icons/edit-icon-white.svg" alt = 'Edit Icon' />
                                                    </div>
                                                    <div className = { "cell text" }>
                                                        Cancel
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <label>Occupation</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name = "occupation"
                                            id = "occupation"
                                            //placeholder = "occupation"
                                            onChange = { handleChange }
                                            />
                                    </div>
                                    {
                                        error?.occupation
                                        && <div className = { styles.errorMsg }>{ error?.occupation }</div>
                                    }
                                </div>

                                <div>
                                    <label>FINRA Affiliation</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="finra_affiliation"
                                            id="finra_affiliation"
                                            //placeholder = "FINRA Affiliation"
                                            onChange = { handleChange }
                                            />
                                    </div>
                                    {
                                        error?.finra_affiliation
                                        && <div className = { styles.errorMsg }>{ error?.finra_affiliation }</div>
                                    }
                                </div>

                                <div>
                                    <label>Employer</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name = "employer"
                                            id = "employer"
                                            //placeholder = "employer"
                                            onChange = { handleChange }
                                            />
                                    </div>
                                    {
                                        error?.employer
                                        && <div className = { styles.errorMsg }>{ error?.employer }</div>
                                    }
                                </div>

                                {/*
                                <div>
                                    <HtmlComment text="Empty div for responsiveness" />
                                </div>
                                */}

                            </div>

                            <div
                                className = { "flex-container" }
                                >

                                <div
                                    className = { "form-heading" }
                                    style = {{
                                          display: "flex"
                                        , width: "100%"
                                        , justifyContent: 'space-between'
                                        , flexBasis: "100%"
                                    }}
                                    >
                                    <div className = { "form-heading" }>
                                        Financial Information
                                    </div>
                                    <div>
                                        <button
                                            className = { "theme-success" }
                                            onClick = { () => history.push('/user-profile') }
                                            >
                                            <div
                                                style = {{
                                                    display: 'table'
                                                }}
                                                >
                                                <div className = { "row" }>
                                                    <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                        <img src="images/icons/edit-icon-white.svg" alt = 'Edit Icon' />
                                                    </div>
                                                    <div className = { "cell text" }>
                                                        Cancel
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <label>Income</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name = "income"
                                            id = "income"
                                            placeholder = "$0,000.00"
                                            onChange = { handleChange }
                                            />
                                    </div>
                                    {
                                        error?.income
                                        && <div className = { styles.errorMsg }>{ error?.income }</div>
                                    }
                                </div>

                                <div>
                                    <label>Net Worth</label>
                                    <div className = { "input-box" }>
                                        <input
                                            type="text"
                                            name="net_worth"
                                            id="net_worth"
                                            placeholder = "$0,000.00"
                                            onChange = { handleChange }
                                            />
                                    </div>
                                    {
                                        error?.net_worth
                                        && <div className = { styles.errorMsg }>{ error?.net_worth }</div>
                                    }
                                </div>
                            </div>

                            <div
                                className = { "flex-container" }
                                >

                                <div
                                    className = { "form-heading" }
                                    style = {{
                                          display: "flex"
                                        , width: "100%"
                                        , justifyContent: 'space-between'
                                        , flexBasis: "100%"
                                    }}
                                    >
                                    <div className = { "form-heading" }>
                                        Investment Preferences
                                    </div>
                                    <div>
                                        <button
                                            className = { "theme-success" }
                                            onClick = { () => history.push('/user-profile') }
                                            >
                                            <div
                                                style = {{
                                                    display: 'table'
                                                }}
                                                >
                                                <div className = { "row" }>
                                                    <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                                        <img src="images/icons/edit-icon-white.svg" alt = 'Edit Icon' />
                                                    </div>
                                                    <div className = { "cell text" }>
                                                        Cancel
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <label>Investment Objective</label>
                                    <div className = { "input-box" }>
                                        {/*
                                        <input
                                            type="text"
                                            name = "investmentObjective"
                                            id = "investmentObjective"
                                            placeholder = "Investment Objective"
                                            onChange = { handleChange }
                                            />
                                        }
                                        */}
                                        <select
                                            name = "invest_objective"
                                            id = "invest_objective"
                                            onChange = { handleChange }
                                            >
                                            <option value="">Select...</option>
                                            <option value="Income">Income</option>
                                            <option value="Balanced">Balanced</option>
                                            <option value="Growth & Income">Growth & Income</option>
                                            <option value="Long Term Growth with Safety">Long Term Growth with Safety</option>
                                            <option value="Long Term Growth with Greater Risk">Long Term Growth with Greater Risk</option>
                                            <option value="Speculation">Speculation</option>
                                        </select>
                                    </div>
                                    {
                                        error?.invest_objective
                                        && <div className = { styles.errorMsg }>{ error?.invest_objective }</div>
                                    }
                                </div>

                                <div>
                                    <label>Liquidity Need</label>
                                    <div className = { "input-box" }>
                                        <select
                                            name="liquidity_need"
                                            id="liquidity_need"
                                            onChange = { handleChange }
                                            >
                                            <option value="">Select...</option>
                                            <option value="Very Important">Very Important</option>
                                            <option value="Important">Important</option>
                                            <option value="Somewhat important">Somewhat important</option>
                                            <option value="Does not matter">Does not matter</option>
                                        </select>
                                    </div>
                                    {
                                        error?.liquidity_need
                                        && <div className = { styles.errorMsg }>{ error?.liquidity_need }</div>
                                    }
                                </div>

                            </div>
                            {/*
                            <HtmlComment text = "flex-container" />
                            */}
                            
                            <div style = {{ flexBasis: "100%" }}>
                                <div className = { "table" } style = {{ maxWidth: "600px", margin: "auto" }}>
                                    <div className = { "row" }>
                                        <div className = { "cell" }>
                                            <input
                                                type = "checkbox"
                                                id = "terms_of_service"
                                                name = "terms_of_service"
                                                //value = "Create Profile"
                                                onChange = { handleChange }
                                                style = {{
                                                    width: "20px"
                                                    , height: "20px"
                                                    , verticalAlign: "middle"
                                                    , border: "2px solid #E6E8EC"
                                                }}
                                                />
                                        </div>
                                        <div className = { "cell" }>
                                            <label
                                                for="terms_of_service"
                                                style = {{
                                                    paddingLeft: "10px"
                                                    , userSelect: "none"
                                                    , paddingBottom: "0px"
                                                }}
                                                >
                                                By clicking here you understand our <Link to="/terms">Terms of Service</Link> and to our <Link to="/terms">Privacy Policy</Link>.
                                                Please read these carefully before using the Site. 
                                            </label>
                                            {
                                                error?.terms_of_service
                                                && <div className = { styles.errorMsg }>{ error?.terms_of_service }</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*
                            <div style = {{ flexBasis: "100%" }}>
                                Already have an account ? &nbsp;<a href="" className = { "gold" }>Login</a>
                            </div>
                            */}

                            <div style = {{ flexBasis: "100%" }}>
                                <div
                                    className = { "button-container" }
                                    style = {{
                                        display: "flex"
                                        , justifyContent: "center"
                                        , padding: "26px 0px"
                                    }}
                                    >
                                    <div className = { "button-wrapper" }>
                                        <button
                                            type = "submit"
                                            value = "Save"
                                            style = {{ color: "#fff" }}
                                            //className = { "gold-background" }
                                            className = { "theme-success" }
                                            >
                                            Update Profile
                                        </button>
                                    </div>

                                    {/*
                                    <div className = { "button-wrapper" }>
                                        <button
                                            className = { "gilded large curved" }
                                            onClick = { () => history.push("/user-profile") }
                                            >
                                            Cancel
                                        </button>
                                    </div>
                                    */}

                                </div>
                            </div>

                        {/*
                        </div>
                        */}

                    </form>
                </div>
            </section>
        </main>
    );
};

export default EditProfile;
