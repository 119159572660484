import "./Faqs.css"
//import { Link } from 'react-router-dom';
//import { useEffect } from 'react';
//import { useHistory } from "react-router";

const Faqs = () => {

  //let history = useHistory();
  // useEffect(()=> {
  //     window.location.reload();
  // }, [history])

  return (
    <div id="container123" className="justify1" style={{ color: "black", background: "rgb(245, 245, 245)" }}>
      <h4 className="faqText">FAQ</h4>
      <b>
        What are NFTs?<br />
      </b>

      NFTs are Non-Fungible Tokens, and in layman terms, representing digital art or collection, video clips of best moments in the sports and entertainment field, gaming skins and collectibles, stored in a distributed ledger powered by blockchain technology. These are unique items and are not interchangeable with another NFT.

      <br /><br />
      <b>
        What makes NFTs valuable?<br />
      </b>

      Generally, things are valuable when they are scarce. There is only one Mona Lisa. There are only 59 Le Bron James dunking NBA Top Shots (one of which sold for $US387,000).

      <br /><br />
      <b>
        What is the connection between NFTs and Cryptocurrency?<br />
      </b>

      The primary difference between the two is that unlike cryptocurrency and digital currency, NFTs cannot be traded for each other as they are unique. representations of real-world assets. Cryptocurrencies and digital currencies can be traded for each other as there will be no loss to their value.

      <br /><br />
      <b>
        How do NFTs work?<br />
      </b>

      NFTs are digital tokens used to represent ownership of unique items of value. They let you tokenize items like art, collectibles, real estate, photographs and memorabilia. NFTs can also be used to tokenize new digital assets you may create... and to further your own business and brand.

      These tokens are like certificates of ownership or  “digital land deeds” for both virtual and physical assets. People can buy NFTs and invest in them... and they can be sold on secondary markets when there is a desire to liquidate them.  They can be used to make purchases in virtual environments as well.
      <br />
      <img src="./nftwork.png" />
      <br /><br />
      <b>
        Why NFTs work?<br />
      </b>

      NFTs are made possible through blockchain technology. In essence, blockchain is a system of recording data that is completely decentralized. This makes it almost impossible for fraud to occur.

      When you create an NFT... you can assign or claim ownership of any piece of digital data or content being put into that NFT. This can include physical assets as well. Proof of ownership can be tracked and secured by using blockchain as a public ledger.  No one can modify the record or copy a new NFT into existence.

      Web3 is giving individuals more control over their finances... and opening a new world of decentralized commerce.  Companies who understand this paradigm shift will have a huge leg up on their competition.

      <br /><br />
      <b>
        What is DeFi?<br />
      </b>

      There’s another piece to our RealSplit  Platform... which we consider part of our secret sauce.  It gives Record Labels a way to finance and fund music production through NFTs.

      It’s called “Decentralized Finance” or DeFi for short. DeFi is an emerging financial technology based on secure distributed ledgers... similar to those used by cryptocurrencies.

      In essence, DeFi is a shift from traditional, centralized financial systems to a decentralized peer-to-peer financial system.  Like NFTs, DeFi is built on the blockchain.

      <br /><br />
      <b>
        Who is RealSplit ?<br />
      </b>

      RealSplit  is a Web3 NFT Platform that lets businesses and brands launch and grow their own NFT projects, marketplaces and communities... turn-key. We’ve done the heavy lifting for you.

      <br /><br />
      <b>
        What are the RealSplit  Solutions?<br />
      </b>

      Send payments globally in just minutes... without the hassles of fiat.
      Payment rails that were built for NFTs... and have done billions in
      NFT transactions for NBA Top Shots.
      Ability to raise debt for future royalty payments on creative works.
      Cut out the middleman and save precious time and money.<br />
      <img src="nftEconomy.png" />
      <br /><br />
      <b>
        What is the RealSplit  partnership program?<br />
      </b>

      RealSplit  partners with major brands in two primary ways:

      1.	We can launch your NFT projects on our platform through our Launch Partnership Program.  This gives you access to our team, our expertise and to our revolutionary RealSplit  Launch Technology.
      2.	We can build for you and manage a branded platform using our
      RealSplit  Technology Suite and team.  This is our full-service Platinum Partnership Program.

      We’ve developed a vertically integrated process which takes your NFTs from concept to close. This includes developing, marketing and promoting your NFT projects and collections.

      Through either of our partnership programs... we offer a solution for projects of any size to launch, mint, and market NFTs.

      <br /><br />
      <b>
        What are RealSplit ’s top features?<br />
      </b>

      •	Fiat & Crypto Payments: RealSplit  give your mintees the ability to purchase with credit card, ACH, wire transfer, and crypto. We use the most sophisticated and secure payment rails in the industry... which have done billions in transactions.
      •	Multiple Smart Contracts:  You can choose between seven different blockchains to mint your NFTs. This gives you the flexibility to choose the blockchain architecture best suited for your project strategy and pricing model.
      •	Affiliates & Royalty: You can offer affiliates and influencers the ability to automate
      and track their royalty payouts. No other platform offers this. Our smart contract technology ensures your people will be paid... quickly.
      •	Auctions & Secondaries: Mint generative artworks, conduct primary offerings, dynamic auctions, and host your secondary sales... securely on the RealSplit
      platform... without having to build any additional tech.
      •	Chip Technology With NFC: We give you the ability to create NFTs that will tether real world items like cars, houses and other real estate you may own. This is done by using chip technology with Near Field Communication.
      •	Fractional Ownership: You can legally create NFTs that offer fractional ownership of things like art, real estate, music catalogs, movie clips and collectibles, and more.

      <br /><br />
      <b>
        Can RealSplit  handle our tech and data needs?<br />
      </b>

      RealSplit  is a Web3 Platform and marketplace that offers
      best-in-class technology to our NFT Launch partners.

      We are also a full-service solution that provides tech, marketing, and management to our NFT Platinum partners.Our technology suite lets you build, mint and market an NFT... from one place.  It uses fans, influencers and affiliates as a driving force.

      Advanced technology drives community and secondary trading. Our platform provides the highest level of security, transparency and compliance in the industry.

      Our proprietary pixel technology captures 500 Million points of data for marketing to your exact niche.  It helps promote your NFT offering to those more likely to invest.

      <br /><br />
      <b>
        How can RealSplit  help my business or brand?<br />
      </b>

      •	Proprietary technology prevents wash trading and counterfeit NFTs.
      •	Integration with U.S. stock exchange to ensure compliance with all SEC and FINRA regulations.
      •	Anti-theft technology to guarantee against theft of NFTs from platform wallet.
      •	Lloyd's of London partnership that guarantees each platform wallet up to $10 million.
      •	Deep analytics module for clients to have in-depth reporting of initial mint and secondary trading performance.
      •	Ability to offer fractionalized ownership of real estate, art, and much more.
      •	Ability to provide customers with a fiat bank account with a U.S. bank tied to the platform that is FDIC insured.
      •	Gamification / rewards through an internal Token.
      •	Chip technology with Near Field Communication (NFC) that enables creation of NFTs that will tether Real World Items to NFTs.
      •	Payment rails that were built for NFTs and have done billions in NFTs transactions for NBA Top Shots.
      •	Ability to raise debt for future royalty payments on creative works.

      <br /><br />
      What are the RealSplit  platform categories?<br />


      <br /><br />
      <b>
        What are the NFT services?<br />
      </b>
      <br />
      <img src="./faqService.png" />
      <br /><br />
      <b>
        What coins can RealSplit  use to mint NFTs?<br />
      </b>

      Answer here.

      <br /><br />
      <b>
        What is a NFT drop?<br />
      </b>

      An NFT drop is simply the release or launch of an NFT collection. When a collection “drops”, it’s generally the time and date the NFT minting happens and becomes available to the public. Since scarcity equals value, collectors often watch for upcoming drops in order to be the first to purchase. To stay tuned on upcoming RealSplit  drops, join our Discord channel and follow us on Twitter and Instagram.

      <br /><br />
      <b>
        What is an auction?<br />
      </b>

      An auction means only one digital collectible is available for sale and is sold to the highest bidder.

      <br /><br />
      <b>
        What is an RealSplit  challenge?<br />
      </b>

      A challenge means you collect all the digital collectibles from a brand’s collection offering within a given timeframe for the chance to earn exclusive and rare rewards.

      <br /><br />
      <b>
        How can I buy an NFT on the RealSplit  platform?<br />
      </b>

      You may use a credit card, debit card, or crypto to purchase a collectible.

      <br /><br />
      <b>
        Can I sell my NFT?<br />
      </b>

      Yes! You can sell your NFTs right on the RealSplit  platform or alternative secondary markets such as OpenSea or Rarible.

      <br /><br />
      <b>
        Do I need a crypto wallet to use RealSplit ?<br />
      </b>

      No, you don’t need a crypto wallet to purchase Curio digital collectibles, but if you have one you may use it.

      <br /><br />
      <b>
        Can I connect my wallet to RealSplit ?<br />
      </b>

      Yes. Our platform supports any browser based plugin wallet (i.e. Metamask, Bitski, etc.). Make sure you follow the instructions your wallet provider has issued for proper installation and use of their plugin. Please note, if your wallet does not have a browser based plugin it will not work with RealSplit .

      <br /><br />
      <b>
        How do I delete my wallet from the RealSplit  platform?<br />
      </b>

      A wallet, once connected to your RealSplit  account, it cannot be deleted or unassociated with the account. Please be very sure you are connecting the specific wallet you intend to use with RealSplit .

      <br /><br />
      <b>
        What can I do with my wallet?<br />
      </b>

      Once connected, you can use your wallet to fund your Curio account. You can also withdraw your funds and tokens to your wallet. Please note that when you do you will pay the current gas fee for the transaction(s).

      <br /><br />
      <b>
        What blockchain does RealSplit  use?<br />
      </b>

      RealSplit  utilizes the Ethereum blockchain for our ERC-721 (a smart contract standard) NFTs. We believe it is important for our users to have full interoperability for the digital collectibles they buy, share, and sell on RealSplit . RealSplit  also used BNB, Matic,…

      <br /><br />
      <b>
        How much does it cost to list my NFTs on the RealSplit  platform?<br />
      </b>

      Answer here.

      <br /><br />
      <b>
        Do I pay additional gas fees?<br />
      </b>

      Answer here.

      <br /><br />
      <b>
        Can I sell non-RealSplit  NFTs on the RealSplit  platform?<br />
      </b>

      Answer here.

      <br /><br />
      <b>
        What do I need to sign up with RealSplit ?<br />
      </b>

      To sign up to RealSplit , all you need is a valid email address and mobile phone number. To purchase or bid on NFTs on the RealSplit  Marketplace, you must verify your email and provide a payment method such as a credit card or crypto. When bidding with crypto, you’ll need to verify your identity prior to funding your account.

      <br /><br />
      <b>
        How do I create an RealSplit  account?<br />
      </b>

      Creating an account on RealSplit  is free and takes less than two minutes! Start by clicking "Sign Up" on the homepage and follow these five easy steps:

      •	Enter your mobile number and we’ll send you an SMS with a 6-digit code.
      •	Enter the 6-digit code when prompted on the screen.
      •	Choose a username and password and enter your email address.
      •	Check your inbox for a welcome email and verify your account by clicking on the link.
      •	You are now successfully signed up!

      <br /><br />
      <b>
        What are the RealSplit  tiers?<br />
      </b>

      Increasing in rarity and value, the RealSplit  tiers are Green, Gold, Platinum, Diamond and the RealSplit  VIP tier. The VIP tier is the single-edition auctioned item and the collection's most exclusive NFT. The VIP tier often includes once-in-a-lifetime experiences such as VIP concert tickets, meet and greets, access to unreleased tracks, private events, and more.

      <br /><br />
      <b>
        Is RealSplit  secure?<br />
      </b>

      Answer here.

      <br /><br />
      <b>
        Is RealSplit  insured?<br />
      </b>

      Answer here.

      <br /><br />
      <b>
        Why was RealSplit  created?<br />
      </b>

      RealSplit  was founded with a very simple mission - to make NFTs accessible to everyone.

      <br /><br />
      <b>
        What can I do if my account is compromised?<br />
      </b>

      Quickly Move Your Items to a Clean Wallet
      ⚠️ If you believe your wallet is compromised, the best action is to immediately move your items in your wallet to a non-compromised wallet.  Once done, you can mark your wallet as compromised directly within RealSplit  by navigating to Account Support in your profile settings.

      Mark Your RealSplit  Account as Compromised
      If you are unable to quickly move your items to a new wallet, we’ve rolled out a feature on RealSplit  to help users who believe their wallets have been compromised.  We’ll walk you step-by-step on how to use the feature below.

      This action will mark your account (which is associated with your wallet address) as compromised and disable NFTs in your wallet from being bought, sold, or transferred using RealSplit . Marking your account as compromised (even if you’ve safely moved your items to a new wallet) will also help notify other RealSplit  users that the account has been marked compromised, letting them know they may not want to transact with the account.

      However, it’s important to remember that RealSplit  does not have custody of your NFTs at any point in time (the NFTs live on the blockchain, not in RealSplit ’s systems).  This means that marking your account as compromised will also not prevent your NFTs from being moved using other platforms or tools outside of RealSplit .

      <br /><br />
      <b>
        How do I stay safe on Discord?<br />
      </b>

      We're constantly taking steps to improve safety measures on our platform and ensure users feel secure on all of our community channels.

      Here are some tips for staying safe on Discord.

      1. Staying safe on Discord
      There have been reports of phishing scams occurring on Discord. These are highly coordinated operations that involve bad actors impersonating staff from RealSplit  or other notable NFT project Discord servers (like Bored Ape Yacht Club).

      2. These bad actors will often change their username to impersonate an RealSplit  staff member and reach out to users via DMs. After introducing themselves, they then invite the unsuspecting user to a fake Discord server that is imitating RealSplit  or another NFT project (see below).

      In response to these incidents, RealSplit  has implemented several safety measures on our Discord server. RealSplit  no longer offers customer support over Discord DMs. If you have any questions or concerns, please reach out to us on Zendesk.

      3. How to avoid DMs
      RealSplit  staff will not send DMs to you first and will never ask for your wallet seed phrase, show your wallet QR Code or invite you to a different server. RealSplit  will NEVER ask users for any cryptocurrencies, to click a link, or scan a QR code for verification, blue checkmark, technical support, or similar reasons. We recommend that you block DMs for RealSplit 's Discord server. To do so:

      - right-click on the server logo

      <br /><br />
      <b>
        How can I report fraudulent content on RealSplit ?<br />
      </b>

      RealSplit  is primarily a self-serve, peer-to-peer marketplace. That means anyone can create and sell an NFT on RealSplit !

      Our user safety team actively monitors the platform and removes fraudulent content as soon as it is discovered or reported by users. This includes content that may infringe on the rights of others, or otherwise violates RealSplit 's Terms of Service.

      <br /><br />
      <b>
        What is the stolen item policy?<br />
      </b>

      The sale of stolen items is against our terms of service and not allowed on RealSplit .

      We take allegations of theft seriously, and when we are notified of potentially stolen items, we disable the ability to buy, sell, or transfer the items via RealSplit  to make sure we are complying with legal requirements and protecting users.

      <br /><br />
      <b>
        Why does RealSplit  have this policy?<br />
      </b>

      This policy helps keep RealSplit  a safe and trusted marketplace.

      <br /><br />
      <b>
        What happens if my items are stolen?<br />
      </b>

      If you believe your wallet is compromised, the best action is to immediately move your items in your wallet to a non-compromised wallet.  Some of our users do this directly by using tools and apps like Etherscan and wallets like MetaMask.

      You can also mark your RealSplit  account as Compromised, this disables your account from further buying, selling, and transferring via RealSplit . For instructions on how to use this tool, please read this guide.

      Once affected items have been identified, our Trust & Safety team will assist with disabling the ability to buy, sell, or transfer the items via RealSplit . If someone contests whether the item is stolen, we will then ask you, the original owner, to file a police report if you have not done so already.

      <br /><br />
      <b>
        I purchased an item and now it is reported for suspicious activity. Is it stolen?<br />
      </b>

      If you purchased an item and it displays a red banner (as seen below), please contact RealSplit so our Trust & Safety team can assist.

      <br /><br />
      <b>
        What is an inactive listing?<br />
      </b>

      This article will explore our new Listings tab on your RealSplit  profile. This feature allows you to review Active and Inactive listings on your NFT items.

      A listing is created when you take action to make one of your NFTs available for sale. Only the individual who controls a wallet can make and cancel listings for NFTs in that wallet. RealSplit  cannot make or cancel listings on a user's behalf.

      Note: Different types of NFT listings

      Before we dive in, let's explain what kind of NFT listings are available on RealSplit .

      •	Active listing: An active listing is the current fixed-price listing of your NFT that is yet to be fulfilled on RealSplit . You can view active listings by navigating to the specific NFT you have listed for sale.

      •	Expired listing: An expired listing is a fixed-price listing that has expired without being fulfilled on RealSplit . The maximum duration for a fixed-price listing on RealSplit  is six months.

      •	Inactive listing: An inactive listing is a listing of your NFT that was never canceled and is still fulfillable should that item be returned to your wallet. Inactive listings will persist unless they are canceled or expire. Canceling an inactive listing on Ethereum will require a gas fee. Inactive listings may exist if:

      You previously lowered the price of a fixed-price listing and did not cancel all listings. You transferred a listed NFT to another wallet without canceling all listings (active & inactive). Transferring NFTs to another wallet does not automatically cancel listings, so you should cancel your active listings before initiating a transfer if you expect to have the item return to your wallet in the future.

      Below are some recommendations on when you should cancel an active listing.

      If you expect to transfer (or repurchase) an item back to the wallet you originally purchased it with, please review and cancel any inactive listings associated with that item.
      If you don’t plan on transferring an item back to your wallet because you’ve sold or transferred it to another person, there is no need to cancel your inactive listings associated with that item.


      Transferring items with active and inactive listings

      You will receive an alert before transferring an item that has active and inactive listings. On the right side of the alert is an option, Review listings.

      <br /><br />
      <b>
        How do I find my funds on Polygon?<br />
      </b>

      Polygon is a blockchain that provides scalable, secure and instant transactions with Ethereum currencies like MATIC, USDC and DAI. Currencies must first be "bridged" from Ethereum's mainnet to Polygon. Think of Polygon as a sibling to Ethereum, the currencies are similar, but the two blockchains have slight differences.



    </div>
  )
}

export default Faqs