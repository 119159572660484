
  const hasAuctionEnded = (auctionEndDate) =>{
    let endingTime = new Date(auctionEndDate)
    let now = new Date()

    if(now >= endingTime){
      return true
    }
    return false
  }

  export default hasAuctionEnded