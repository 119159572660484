import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
//import styles from "./Main.module.sass";
//import axios from 'axios';
import { useDispatch } from "react-redux";
//import { setEuroValue } from "../../redux/counterSlice";
//import { axiosInstance } from "../../utils/API";
//import { apiCall } from '../../controller/utils'
import Hero from "../../screens/Home/Hero";
import Popular from "../../screens/Home/Popular";
import PastDeals from "../../screens/Home/PastDeals";
import LatestArrivals from '../../screens/Home/LatestArrivals/index';
//import RecentSold from '../../screens/Home/RecentSold/index';
import RecentSold from '../../screens/Home/RecentSold';
import AuctionAssets from "../../screens/Home/AuctionAssets";
import Header from "../Header";
import StartInvestingToday from "../../screens/Home/StartInvestingToday";
import WhatIsRealSplit from "../../screens/Home/WhatIsRealSplit";
import Team from "../../screens/Home/Team";
import Video from "../../screens/Home/Video";

const Main = (props) => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        clearAllBodyScrollLocks();
    }, [pathname]);

    //const dispatch = useDispatch();

    /*
    const getpricefeed = async () => {
        const url = process.env.REACT_APP_ADMIN_URL
        await axios
            .get(url + '/admin/exchange/rate')
            .then(res => dispatch(setEuroValue(res.data?.result?.exchangeRate)))
    }

    useEffect(() => {
        getpricefeed();
        dataEncrypt();
        const myInterval = setInterval(() => {
            getpricefeed();
        }, 30000)
        return () => {
            clearInterval(myInterval);
        };
    });

    const dataEncrypt = async () => {
        axiosInstance.get('/user/getClientVariables').then((res) => {
            dispatch(keyDetails(res?.data?.result));
            apiCall()
        });
    };
    */

    return (
        <>
        <main className = { "home" }>

            {/*
                Header and Hero components in the same section to achieve the effect of having the hero section background image in the header.
            */}
            <section className = { "top-section" }>
                <Header
                    className = "dark"
                    homePage = "true"
                    connectWalletFunction       = { props.connectWalletFunction }
                    clickConnectWalletFunction  = { props.clickConnectWalletFunction }
                    chooseWalletType            = { props.chooseWalletType }
                    showRegisterDialog          = { props.showRegisterDialog }
                    showLoginDialog             = { props.showLoginDialog }
                    isHomePage                  = { props.isHomePage }
                    userDetails                 = { props.userDetails }
                    loginRegisterFunctions      = { props.loginRegisterFunctions }
                    handleShowMobileNav         = { props.handleShowMobileNav }
                    handleShowMobileUserHeader  = { props.handleShowMobileUserHeader }
                    userProfileMenuRef          = { props.userProfileMenuRef }
                    userProfileButtonRef        = { props.userProfileButtonRef }
                    toggleShowUserProfileMenu   = { props.toggleShowUserProfileMenu }
                    toggleShowNotificationsPopup = { props.toggleShowNotificationsPopup }
                    handleShowNotificationsPopup = { props.handleShowNotificationsPopup }
                    notificationsButtonRef       = { props.notificationsButtonRef }
                    onClickNotificationsButton = { props.onClickNotificationsButton }
                    onOutsideClickUserProfileMenu = { props.onOutsideClickUserProfileMenu }

                    cartCount = { props.cartCount }

                    />
                <Hero />
            </section>

            <WhatIsRealSplit />

            <LatestArrivals
                textColor="#000"
                backgroundImage="linear-gradient(180deg, rgba(212, 222, 236, 0.3) 0%, rgba(217, 217, 217, 0) 100%)"
                />

            <StartInvestingToday />

            {/*
            <RecentSold
                color="#fff"
                textColor="#000"
                />
            */}

            <Video
                //heading = 'HumanPal.io Project #2'
                heading = 'Why Fractional Commercial Real Estate?'
                //heading = 'Why\r\nFractional Commercial\r\nReal Estate?'
                //headingElement = '<div >Why\nFractional Commercial\nReal Estate?'
                //fileName = 'Project_3204_768f0e31-f4e8-4d4c-92e1-2437b855bf48.mp4'
                //filePath = 'https://realsplit.s3.amazonaws.com/properties/Project_3204_768f0e31-f4e8-4d4c-92e1-2437b855bf48.mp4'
                filePath = 'https://realsplit-s3-bucket-us-east-1.s3.amazonaws.com/Project_3204_768f0e31-f4e8-4d4c-92e1-2437b855bf48.mp4'
                styleObject = {{ whiteSpace: 'pre-wrap', color: '#fff' }}
                />

            <Team />

            <Video
                //heading = 'HumanPal.io Project #3'
                heading = 'Why RealSplit?'
                //filePath = 'https://realsplit.s3.amazonaws.com/properties/Project_3013_1b6d3535-8329-4c9d-b5d3-0265f45882cc.mp4'
                filePath = 'https://realsplit-s3-bucket-us-east-1.s3.amazonaws.com/Project_3013_1b6d3535-8329-4c9d-b5d3-0265f45882cc.mp4'
                styleObject = {{ whiteSpace: 'pre-wrap', color: '#fff' }}
                />


            {/*
            <Video
                heading = 'HumanPal.io Project #1'
                filePath = 'https://realsplit.s3.amazonaws.com/properties/Project_3203_12d02456-070e-451b-87a5-2aa32760f1f1.mp4'
                />
            */}

            {/*
            <Popular
                textColor="#fff"
                backgroundImage="linear-gradient(180deg, #000000 0%, #2C466B 100%)"
                />
            */}

            {/*
            */}
            <AuctionAssets
                textColor="#000"
                backgroundImage="linear-gradient(180deg, rgba(212, 222, 236, 0.3) 0%, rgba(217, 217, 217, 0) 100%)"
                />

            <PastDeals
                textColor="#fff"
                backgroundImage="linear-gradient(180deg, #000000 0%, #2C466B 100%)"
                />

        </main>
        </>
    );
};

export default withRouter(Main);
