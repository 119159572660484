import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import './style.scss';
//import { axiosInstance } from '../../../utils/API';
//import { searchAssets, clearSearchAssets } from "../../../redux/assetSlice";
import UserProfileMenu from '../UserHeader/UserProfileMenu';
import { NAMES_CONSTANTS } from '../../../constants';

//const MobileUserHeader = ({ isOpen = false, closeModal, handleDisconnectFunction, handleTokenExpiredFunction}) => {
{/*
const MobileUserHeader = ({
      isOpen = false
    , closeModal
    , handleDisconnectFunction
    , handleTokenExpiredFunction
    , walletDetails
    , userDetails
}) => {
*/}
const MobileUserHeader = (props) => {
    console.info('MobileUserHeader()');
    console.debug('MobileUserHeader()', props);
    console.debug('MobileUserHeader: props.userDetails = ', props.userDetails)

    const history = useHistory()
    //const dispatch = useDispatch();
    const storeSearchText = useSelector(state => state.asset.searchText);
    const [selectedDropdown, setSelectedDropdown] = useState([])
    //const [searchText, setSearchText] = useState(storeSearchText || '');
    //const userAccessToken = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
    //let walletImageSrc = '/images/icons/wallet-icon.svg';
    const [address, setAddress] = useState("");

    useEffect(() => {
        //const acc = JSON.parse(localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS))?.accounts;
        //const acc = JSON.parse(props.walletDetails)?.accounts;
        const acc = JSON.parse(props.walletDetails)?.accounts;
        console.log('acc', acc)
        setAddress(acc)
    }, []);

    const handleCloseModal = () => {
        console.warn('handleCloseModal()');
        props.closeModal();
    };

    useEffect(() => {
        console.debug('MobileUserHeader: useEffect(() => {');
        console.debug('    props.closeModal();');
        props.closeModal();
    }, [history.location.pathname])

    /*
    const toggleSelectedDropdown = (value) => {
        if (value === selectedDropdown) {
          setSelectedDropdown(null)
        } else {
          setSelectedDropdown(value)
        }
    }
    */

    return (
        <>

        { props.isOpen && <div className="modal-overlay" onClick = { handleCloseModal } /> }

        <div className = { `mobile-user-header-container ${props.isOpen ? "show" : "" }`}>

            {/*
            */}
            <div
                //className="mobile-nav-content"
                /*
                style = {{
                    //  height: '110px'
                    //, verticalAlign: "middle"
                    //, margin: "auto"
                    padding: '8px'
                    //, marginRight: "0px"
                    //, marginLeft: "auto"
                    , textAlign: "right"
                }}
                */
                >


                {/*
                <div className = 'table' style = {{ width: '100%' }}>
                    <div className = 'row'>
                */}
                <div
                    style = {{
                          display: 'flex'
                        , justifyContent: 'space-between'
                        //, justifyContent: 'flex-end'
                        , alignItems: 'center'
                    }}
                    >
                        <div
                            //className = 'cell'
                            style = {{
                                verticalAlign: 'middle'
                                , textAlign: 'center'
                                //, alignSelf: 'flex-end'
                                //, justifySelf: 'right'
                              }}
                            >

                            {/*
                            <div>
                                <button
                                    //type = 'button'
                                    //className = { 'connect-wallet' }
                                    className = { 'primary connect-wallet' }
                                    //onClick={() => props.chooseWalletType()}
                                    onClick = { chooseWalletType }
                                    >
                                    Investor Login
                                </button>
                            </div>
                            */}

                            {/*
                            <div
                                className = { 'table' }
                                style = {{ paddingLeft: '20px'}}
                                >
                                <div className = { 'row' }>
                                    <div
                                        className = { 'cell' }
                                        style = {{ verticalAlign: 'top', marginTop: '0' }}>

                                        <div
                                            //className = { 'profile-photo-box' }
                                            className = { 'title' }
                                            style = {{ paddingTop: '10px' }}
                                            >
                                            { props.userDetails?.firstName } { props.userDetails?.lastName }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>

                        <div
                            //className = 'cell'
                            >
                            <div
                                className="close"
                                onClick={handleCloseModal}
                                title="Close"
                                style = {{
                                      padding: '30px'
                                    //, paddingBottom: '0px'
                                    //, verticalAlign: "middle"
                                    //, margin: "auto"
                                    , display: "inline-block"
                                    , paddingRight: '30px'
                                    , paddingLeft: '15px'
                                    , paddingBottom: '0px'
                                }}
                                >
                                {/*
                                <svg id="icon-close" viewBox="0 0 20 20" fill='#black' height="20px" width="20px">
                                    <path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
                                </svg>
                                */}
                                <img
                                    src = '/images/icons/close-icon-svg-primary.svg'
                                    style = {{ width: '32px', height: '32px' }}
                                    />
                            </div>
                        </div>
                {/*
                    </div>
                */}
                </div>

            </div>

            <div
                className="mobile-user-header-content"
                //style = {{
                //      marginTop: "-40px"
                    //, display: 'none'
                //}}
                >
                    <UserProfileMenu
                        loginRegisterFunctions = { props.loginRegisterFunctions }
                        //handleDisconnectFunction = { props.handleDisconnectFunction }
                        handleTokenExpiredFunction = { props.handleTokenExpiredFunction }
                        userDetails = { props.userDetails }
                        onOutsideClickUserProfileMenu = { props.onOutsideClickUserProfileMenu }
                        />
                </div>
            </div>
        </>
    );
};

export default MobileUserHeader;
