import Web3 from "web3";

// create a web3 instance using a browser wallet or any another provider
const web3 = new Web3(window.ethereum);

export const getNetworkId = async () => {
  const currentChainId = await web3.eth.net.getId();
  return currentChainId;
};

export const switchNetWork = async (chainId) => {
  const currentChainId = await getNetworkId();

  if (currentChainId !== chainId) {
    try {
      await web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        alert("add this chain id");
      }
    }
  }
};
