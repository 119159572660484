import React, { useState, useEffect } from "react";
//import cn from 'classnames';
import styles from './Auctions.module.sass';
import { axiosInstance } from "../../utils/API";
import cn from "classnames";
import {
    useSelector
    //, useDispatch
} from 'react-redux';
//import Web3 from "web3";
import ReactPlayer from "react-player";
//import Swal from 'sweetalert2';
import {
    PopUpAlert
    //, current_datetime
} from "../../controller/utils";
import ReactPaginate from "react-paginate";
import moment from "moment";
//import { CircularProgress } from "@material-ui/core";
import { CircularProgress } from '@mui/material';

const BidCards = (props) => {

    const [details, setDetails] = useState([]);
    const [params, setParams] = useState({ limit: 10, page: 1, type: "active" });
    const [count, setCount] = useState(0);
    //const datetime = new Date();
    const { index } = props;
    const value = useSelector((state) => state.counter.euroValue);
    const [loaderShow,setLoaderShow] = useState(false);

    const getAllAssets = async (index) => {
        setLoaderShow(true);
        let parameters = params;
        switch (index) {
            case 0:
                parameters = { ...params, type: "active" };
                break;
            case 1:
                parameters = { ...params, type: "won" };
                break;
            case 2:
                parameters = { ...params, type: "lost" };
                break;
            default:
                break;
        }

        let config = {
          headers: {
            Authorization: "Bearer "+localStorage.getItem("userToken"),
          },
          params: parameters
        };

        try {
            let userData = await axiosInstance.get("asset/auction/bidder-details", config);
            setDetails(userData?.data?.result?.Data?.rows);
            setCount(userData?.data?.result?.totalPages);
            setLoaderShow(false)
        } catch (err) {
            setDetails([]);
            PopUpAlert('Alert', err?.response?.data?.message, "warning")
            setLoaderShow(false)
        }
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        //console.log(selectedPage, "hello");
        setParams({ ...params, page: selectedPage + 1 })
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    };

    useEffect(() => {
        getAllAssets(index);
    }, [props, params]);

    const [activeIndex, setActiveIndex] = useState(0);

    let navLinks = [
          "Active Bids"
        , "Auctions Won"
        , "Auctions Lost"
    ];

    return (

        <div
            style = {{
                //padding: "10px 0px"
                //, margin: "auto"
                //, minHeight:"32vh"
                flexGrow: 1
            }}
            >

            {loaderShow && <div className={styles.loaderContent}><CircularProgress /></div>}

            <div style = {{ display: 'inline-block' }}>
                <div className={styles.wrapper}>
                    <div
                        //className={styles.nav}
                        style = {{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}
                        >
                        {
                            navLinks.map((x, index) => (
                                <div>
                                <button
                                    className={cn(
                                    //    { [styles.active]: index === activeIndex },
                                        { ['active']: index === activeIndex }
                                        //, styles.link
                                        , 'primary-inverse'
                                        //, 'rounded'
                                        , 'circular'
                                        , 'medium'
                                    )}
                                    onClick = { () => setActiveIndex(index) }
                                    key = { index }
                                    >
                                    { x }
                                </button>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
               
            {
                details?.length === 0
                &&  <>
                    {
                      index === 2
                      &&
                          <div
                              className={styles.info}
                              style = {{ textAlign: 'left', fontSize: 'unset' }}
                              >
                              You are not participating in any auctions yet.
                          </div>
                    }

                {
                    index === 1
                    &&
                        <div
                            className={styles.info}
                            style = {{ textAlign: 'left', fontSize: 'unset' }}
                            >
                            You are not participating in any auctions yet.
                        </div>
                    }

                {
                    index === 0
                    && <div
                        className={styles.info}
                        style = {{ textAlign: 'left', fontSize: 'unset' }}
                        >
                        You are not participating in any auctions yet.
                    </div>
                }
                </>
            }

            {
                details?.map((asset, idx) => {
                    const item = asset?.asset;
                    return (
                        <div className={styles.orderitem} style={{ padding: "10px" }} key={idx}>
                            {
                                item.mediaType === "audio"
                                &&
                                    <div
                                        className="player-wrapper"
                                        style={{ position: "relative", paddingTop: 0 }}
                                        >
                                        <div style={{width:100, height:90,position: "relative"}}>
                                            <img
                                              src={item?.audioThumbnail}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover"
                                              }}
                                              alt="thumbnail.png"
                                            />
                                            <audio controls controlsList="nodownload"
                                              style={{ position: "absolute", bottom: 0, left: 0, height: 15, width: "100%" }}>
                                              <source src={item?.mediaPreviewUrl} type="audio/mpeg" />
                                            </audio>
                                        </div>
                                    </div>
                            }

                            {
                                item.mediaType === "video"
                                &&
                                    <div
                                        className="player-wrapper"
                                        // style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                                        style={{ position: "relative", paddingTop: 0,minWidth:'98px' }}
                                        >
                                        <ReactPlayer
                                            url={item?.mediaPreviewUrl}
                                            width="100%"
                                            height="100%"
                                            controls={true}
                                            light={false}
                                            style={{ position: "absolute", top: "0", left: "0" }}
                                            config={{
                                              file: {
                                                attributes: { disablePictureInPicture: true, controlsList: "nodownload" }
                                              }
                                            }}
                                            />
                                    </div>
                            }

                            {
                                (item?.mediaType === "image" || item?.mediaType === "3D Model")
                                &&
                                    <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        className={styles.playerwrapper}
                                        >
                                        <img
                                            src={item?.thumbnailUrl || item?.mediaPreviewUrl}
                                            style={{
                                              width: "100%",
                                              objectFit: "cover"
                                            }}
                                            alt="thumbnail.png"
                                            />
                                    </div>
                            }

                            <div className={styles.itemdetails}>
                                <div className={styles.itemdetailsrow}>
                                    <h4>
                                      {item?.name}
                                    </h4>
                                    {/* <h4>
                                      {"Order Id: " + asset.id}
                                    </h4> */}
                                    {/* {index === 2 &&
                                      <button className={cn("button-stroke", styles.button)}
                                        onClick={()=>handleWithdraw(item.id)}
                                      >
                                        Withdraw
                                      </button> }*/}

                                </div>
                                <div className={styles.itemdetailsrow}>
                                    <div>
                                      <p>End Date</p>
                                      {/* {current_datetime(asset?.endingDate) || "-"} */}
                                      {asset?.endingDate ? moment(asset?.endingDate).format('DD/MM/YYYY hh:mm A') : "-"}
                                    </div>
                                    <div>
                                      <p>Current Bid</p>
                                      &#36; {(asset?.currentBid).toFixed(4)}
                                      ( {(asset?.currentBid * value).toFixed(4)} MATIC)
                                    </div>
                                    <div>
                                      <p>Status</p>
                                      {asset?.status}
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    )
                })
            }

            {
                details?.length > 0
                && (
                    <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        pageCount={count}
                        onPageChange={handlePageClick}
                        containerClassName={styles.pagination}
                        previousLinkClassName={styles.pagination__link}
                        nextLinkClassName={styles.pagination__link}
                        disabledClassName={styles.pagination__link__disabled}
                        activeClassName={styles.pagination__link__active}
                        />)
            }
        </div>
    )
}

export default BidCards;