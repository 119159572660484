import
    React
    , {
        useState
        //, useCallback
        //, useEffect
    } from 'react';
import { useSelector } from 'react-redux';
import cn from "classnames";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import styles from './ContactUs.module.sass'
import { PopUpAlert } from '../../controller/utils';
import { axiosInstance } from '../../utils/API';
// import { decryptData } from '../../utils/decrypt';

//const ContactForm = () => {
const ContactForm = (props) => {
    console.log('ContactForm(props)');

    // const secretKey = useSelector((state) => state?.keyVal?.keyData);

    const [field, setField] = useState({})
    const [error, setError] = useState({})
    const [enteredData, setEnteredData] = useState({})
    const [disabled, setDisabled] = useState(false)

    const onHandleChange = (e) => {
        console.log('onHandleChange(e)');

        const value = e.target.value.replace(/\s\s+/g, ' ');
        console.log('value = ', value);

        if (value?.charAt(0) !== ' ') {
            setField({
                ...field,
                [e.target.name]: value
            });
        }

        enteredData[e.target.name] = value;

        setError({
            ...error,
            [e.target.name]: ''
        });
    };

    const validationField = () => {
        console.log('validationField()');

        let fields = field;
        let errors = {};
        let formIsValid = true;

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Please enter your name";
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter your email";
        }
        if (typeof fields["email"] !== "undefined") {
            if (!fields["email"].match(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
            )) {
                formIsValid = false;
                errors["email"] = "Please enter valid email";
            }
        }
        /*
        if (!fields["telephone"]) {
            formIsValid = false;
            errors["telephone"] = "Please enter your telephone";
        }
        */
        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "Please enter your message";
        }
        setError(errors);
        return formIsValid;
    };

    const contactUsMethod = async () => {
        setDisabled(true);
        if (validationField()) {
            let data = {
                  "name": field?.name
                , "email": field?.email
                , "message": field?.message
                , "telephone_number": field?.telephone
            };
            await axiosInstance
                .post(`user/sendQuery`, data)
                .then((res) => {
                  if (res?.data?.success) {
                    setField({})
                    //PopUpAlert('Thank you for contacting us', 'You are very important to us, We will contact you as soon as we review your message. ', 'success')
                    PopUpAlert(
                        'Thank you for contacting us.'
                        , 'We will contact you as soon as we review your message.'
                        //, 'success'
                        , 'confirmation'
                    );
                    setDisabled(false)
                  } else {
                    PopUpAlert('Alert', res?.data?.message, 'error')
                    setDisabled(false)
                  }
                })
                .catch((error) => {
                  PopUpAlert('Alert', error?.data?.response?.error ? error?.data?.response?.error : error?.message, 'error')
                  setDisabled(false)
                });
        } else {
            setDisabled(false)
        }
    };

    const { executeRecaptcha } = useGoogleReCaptcha();

    /*
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return null;
      }

      const token = await executeRecaptcha();
      return token
      // Do whatever you want with the token
    }
    */

    // You can use useEffect to trigger the verification as soon as the component being loaded
    // useEffect(() => {
    //   handleReCaptchaVerify();
    // }, [handleReCaptchaVerify]);
    // const key = decryptData(secretKey?.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY)

    return (

        <form
            className = { "max-width" }
            //onSubmit = { handleSubmit }
            style = {{ margin: "0px", padding: "0px" }}>

            <div
                className = { "button-container" }
                //style = {{ paddingBottom: "40px" }}
                >
                <h1>Contact Us</h1>
            </div>

            {/*
            */}
            <p style = {{ maxWidth: "736px" }}>
                Please use the form below to inform us of any issue with your order.
            </p>

            {/*                        
            <div className = { 'form-section' }>
            */}

            <div
                className = { "flex-container" }
                //style = {{ flexBasis: "50%" }}
                //style = {{
                //    gap: "32px"
                //    , alignItems: "flex-start"
                //}}
                >

                {/*
                <div
                    className = { "form-heading" }
                    style = {{
                          display: "flex"
                        //, padding: "10px"
                        //, background: "#EBEBEB"
                        //, borderRadius: "6px"
                        //, fontFamily: "'Poppins'"
                        //, fontStyle: "normal"
                        //, fontWeight: "700"
                        //, fontSize: "14px"
                        //, lineHeight: "12px"
                        //, textTransform: "uppercase"
                        //, color: "#000000"
                        , width: "100%"
                        , justifyContent: 'space-between'
                    }}
                    >

                    <div className = { "form-heading" }>
                        Personal Information
                    </div>

                    <div>
                        <button
                            className = { "theme-success" }
                            //onClick = { () => history.push('/user-profile') }
                            >
                            <div
                                style = {{
                                    display: 'table'
                                }}
                                >
                                <div className = { "row" }>
                                    <div className = { "cell" } style = {{ paddingRight: '8px', verticalAlign: 'middle' }}>
                                        <img src="images/icons/edit-icon-white.svg" alt = 'Edit Icon' />
                                    </div>
                                    <div className = { "cell text" }>
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                */}

                {/*
                <div
                    className = { "profile-photo" }
                    style = {{ flexBasis: "100%" }}
                    >

                    <label>Profile Image</label>
                    <div className = { "table" }>
                        <div className = { "cell" }  style = {{ paddingRight: "20px" }}>
                            <img
                                style = {{ width: '86px', height: '86px' }}
                                alt = 'Profile Pic'
                                src="images/avatar-icon-large.png" />
                        </div>
                        <div className = { "cell" }  style = {{ verticalAlign: "top", marginTop: "0" }}>

                            <div className = { "profile-photo-box" }>
                                <div>
                                    <div className = { "title" }>Profile photo</div>
                                </div>
                                <div>
                                    <div className = { "subtitle" }>We recommend an image of at least 400x400. Gifs work too</div>
                                </div>
                                <div  style = {{ marginTop: "0px" }}>
                                    <button type="button" className = { "rounded-clear light" }  style = {{ width: "auto", padding: "8px 12px", marginTop: "0px" }}>Upload</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                */}

                <div>
                    <label htmlFor = 'name'>Name <span className = 'required'>*</span></label>
                    <div className = { "input-box" }>
                        <input
                            type = "text"
                            name = "name"
                            //className={styles.formInput}
                            placeholder="Enter First name"
                            //value = { userDetails?.firstName }
                            //value = { userDetails?.firstName ? userDetails?.firstName : '' }
                            //value = { field?.name || '' }
                            //value = {
                            //    props.userDetails?.firstName + ' ' + props.userDetails?.lastName
                            //    || ''
                            //}
                            value = {
                                enteredData?.name
                                ? enteredData?.name
                                : props.userDetails && enteredData?.firstName && enteredData?.lastName
                                    ? props.userDetails?.firstName + ' ' + props.userDetails?.lastName
                                    : ''
                            }
                            //onChange = { handleChange }
                            onChange={(e) => onHandleChange(e)}
                            style = {{ textTransform: 'capitalize' }}
                            />

                        <img src="images/icons/user-icon-dark.png"  alt = 'User Icon Dark' />
                    </div>
                    {
                        error?.name
                        && <div className = { styles.errorMsg }>{ error?.name }</div>
                    }

                </div>

                <div>
                    <label htmlFor = 'email'>Email Address <span className = 'required'>*</span></label>
                    <div className = { "input-box" }>
                        <input
                            //className={styles.input}
                            type = "email"
                            name="email"
                            //className={styles.formInput}
                            placeholder="name@email.com"
                            //value={field?.email || ''}
                            value = {
                                enteredData?.email
                                ? enteredData?.email
                                //: props.userDetails
                                : props.userDetails && enteredData?.email != ''
                                  ? props.userDetails?.email
                                  : ''
                            }

                            //onChange = { handleChange }
                            onChange={(e) => onHandleChange(e)}
                            //disabled = { !confirmedTerm }
                            />
                    </div>
                    {
                        error?.email
                        && <div className = { styles.errorMsg }>{ error?.email }</div>
                    }
                </div>

                <div>
                    <label htmlFor = 'telephone'>Telephone Number</label>
                    <div className = { "input-box" }>
                        <input
                            type="text"
                            name="telephone"
                            id="telephone"
                            className={styles.formInput}
                            placeholder="000-000-0000"
                            value={field?.telephone || ''}
                            //onChange = { handleChange }
                            onChange={(e) => onHandleChange(e)}
                            />
                    </div>
                    {/*
                        error?.telephone
                        && <div className = { styles.errorMsg }>{ error?.telephone }</div>
                    */}
                </div>

                <div>
                    <label htmlFor = 'message'>How can we help you? <span className = 'required'>*</span></label>
                    <div className = { "input-box" }>
                        <textarea
                            name="message"
                            className={styles.formInput} placeholder=''
                            value={field?.message || ''}
                            onChange={(e) => onHandleChange(e)}
                            rows={10}
                            style = {{
                                  height: 'auto'
                                , width: '100%'
                                , resize: 'vertical'
                                , padding: '0px'
                                , margin: '0px'
                                , background: 'transparent'
                                , border: '0'
                                , outline: 'none'
                            }}
                            />
                    </div>
                    {
                        error?.message
                        && <div className = { styles.errorMsg }>{ error?.message }</div>
                    }
                </div>

                {/* <button onClick={handleReCaptchaVerify}>Verify recaptcha</button> */}
                <div
                    className={styles.center}
                    style = {{ textAlign: 'center', paddingTop: '30px' }}
                    >
                    <button
                        type="button"
                        disabled = { disabled }
                        //className={`button ${styles.submitBtn}`}
                        className = 'primary'
                        style = {{ paddingLeft: '32px', paddingRight: '32px' }}
                        onClick={() => contactUsMethod()}
                        >
                        Send
                    </button>
                </div>
            </div>
        </form>
    );
}

export default ContactForm;
