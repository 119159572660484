import React,{useState} from 'react';
//import TeamMemberDetail from './TeamMemberDetail';
//import styles from './Teams.module.sass'

const Team = () => {

    const teamMembers = [
        {
              //image:'./images/people/Brian-Massie3.png'
              image:'./images/people/Brian-Massie-Headshot_614x604.png'
            , name: 'Brian Massie'
            , position: 'Co-Founder | CEO'
        }
        , {
              //image:'./images/people/Daniel-Sloan.jpg'
              image:'./images/people/Daniel-Sloan-Headshot_316x316.png'
            , name: 'Daniel Sloan'
            , position:'Co-Founder | President'
        }
        , {
              //image:'./images/people/Dermot-Doherty0F.png'
              image:'./images/people/Dermot-Doherty-Headshot_593x595.png'
            , name: 'Dermot Doherty'
            , position: 'CTO | Software Architect'
        }
    ];

    const advisors = [
        {
              image:'./images/people/Mateo-Stroul.jpg'
            , name: 'Mateo Stroul'
            , position: 'Technology Advisor | Solutions Architect'
        }
        , {
              image:'./images/people/Sanjeev-Lalchandani.jpg'
            , name: 'Sanjeev Lalchandani'
            , position: 'Technology Advisor | Solutions Architect'
        }
        , {
             name: 'Brian Baker'
            , image:'./images/people/Brian-Baker.jpg'
            , position: 'Advisor'
        }
        , {
              name: 'Ryan Granito'
            , image:'./images/people/Ryan-Granito.jpg'
            , position: 'Advisor'
        }
        , {
              name: 'Dylan Marer'
            , image:'./images/people/Dylan-Marer.jpg'
            , position: 'Advisor'
        }
        , {
              name: 'Joshua Whittemore'
            , image:'./images/people/Joshua-Whittemore.jpg'
            , position: 'Advisor'
        }
        /*
        , {
              image:'./images/people/team-member6.jpg'
            , name: 'Phil Appleseed'
            , position:'Position in the company'
        }
        */
    ];

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const onTeamMemberClick = (each) => {
        console.log('onTeamMemberClick(): each = ', each);
        setOpen(true)
        setData(each)
    }

    return (

        <section
            //className={styles.teamsection}
            //id="teams"
            //className = 'max-width'
            >

            <div
                id = 'team-wrapper'
                className = 'max-width'
                >

                {/*
                <TeamMemberDetail open={open} setOpen={setOpen} teamMemberData={data} />
                */}

                <h2
                    //className = { styles.sectionTitle }
                    style = {{
                        padding: '50px 0px 40px 0px'
                        , margin: '0'
                        //, fontSize: 'unset'
                    }}
                    >Management Team</h2>

                <p
                    style = {{
                          maxWidth: '700px'
                        , fontFamily: 'Source Sans 3'
                        //, fontSize: '20px'
                        , fontStyle: 'normal'
                        , fontWeight: '400'
                        //, lineHeight: '150%'
                        , color: 'var(--color-gray-600, #343A40)'
                        , textAlign: 'center'
                        , margin: 'auto'
                        , paddingBottom: '40px'
                    }}
                    >Meet our dedicated team: a blend of seasoned real estate professionals and innovative thinkers, all passionate about redefining property investment. With decades of combined experience, we're committed to delivering exceptional value, ensuring our clients' goals align with the best opportunities. Trust in our expertise to guide your investment journey.</p>

                <div
                    className = 'container team-container max-width'
                    style = {{
                          display: 'flex'
                        , flexWrap: 'wrap'
                        //, flexGrow: '1'
                        , gap: '24px'
                        //, justifyContent: 'space-around'
                        , justifyContent: 'space-evenly'
                        //, width: 'calc(100% / 3)'
                    }}>

                    {
                        teamMembers.map((dataItem, teamMembersIndex) =>
                            (
                                <>

                                {
                                    teamMembersIndex % 2 == 0
                                    &&
                                        <div
                                            key = { 'Team_Empty_' + teamMembersIndex }
                                            style = {{ flexBasis: '100%', height: '0' }}
                                            />
                                }

                                <div
                                    key = { 'Team_Member_' + teamMembersIndex }
                                    //className = 'team-item'
                                    style = {{
                                        //margin: 'auto'
                                        textAlign: 'center'
                                        //, border: 'blue 2px solid'
                                    }}>
                                    <div
                                        className = 'team-item'
                                        style = {{
                                            //width: 'calc(100% / 3)'
                                            margin: 'auto'
                                            //, backgroundColor: '#fff'
                                            , maxWidth: '325px'
                                            //, width: '33%'
                                            //, flexGrow: '1'
                                            //, flexGrow: 'unset'
                                            //, flexBasis: '33%'
                                            //, flexBasis: 'calc(33% - 24px)'
                                            //, padding: '0px 12px'
                                            //, flexGrow: '1'
                                        }}
                                        onClick = { () => onTeamMemberClick(dataItem) }
                                        >

                                        <div
                                            style = {{
                                                  textAlign: 'center'
                                                , maxWidth: '325px'
                                                , maxHeight: '325px'
                                                , overflow: 'hidden'
                                                //, backgroundColor: '#000'
                                                , marginLeft: 'auto'
                                                , marginRight: 'auto'
                                            }}>

                                            <img src = { dataItem.image }
                                                style = {{
                                                    //width: 'calc(100% / 3)'
                                                      width: '100%'
                                                      //width: 'auto'
                                                    , maxWidth: '325px'
                                                    //, minHeight: '325px'
                                                    //, maxHeight: '400px'
                                                    //, maxHeight: '325px'
                                                }} />
                                        </div>

                                        <div className = "title">

                                        <h3
                                            style = {{
                                                /*
                                                , fontFamily: 'Source Sans 3'
                                                , padding: '20px 0px'
                                                , fontSize: '30px'
                                                , fontStyle: 'normal'
                                                , fontWeight: '700'
                                                , lineHeight: '100%'
                                                , color: 'var(--color-gray-900, #212529)'
                                                */
                                                  textAlign: 'center'
                                                , whiteSpace: 'nowrap'
                                                , textOverflow: 'ellipsis'
                                                , overflow: 'hidden'
                                                //, fontSize: '26px'
                                            }}
                                            >
                                            { dataItem.name }
                                        </h3>

                                        <p style = {{
                                              fontFamily: 'Source Sans 3'
                                            , fontSize: '15px'
                                            , fontStyle: 'normal'
                                            , fontWeight: '400'
                                            , lineHeight: '150%'
                                            , textAlign: 'center'
                                            , color: 'color: var(--color-gray-900, #212529)'
                                        }}>{ dataItem.position }</p>

                                        </div>

                                    </div>
                                </div>
                                
                                </>
                            )
                        )
                    }

                </div>

                <h2
                    //className = { styles.sectionTitle }
                    style = {{
                        padding: '50px 0px 40px 0px'
                        , margin: '0'
                        //, fontSize: 'unset'
                    }}
                    >Advisors</h2>

                <div
                    className = 'container team-container max-width'
                    style = {{
                          display: 'flex'
                        , flexWrap: 'wrap'
                        //, flexGrow: '1'
                        , gap: '24px'
                        //, justifyContent: 'space-around'
                        , justifyContent: 'space-evenly'
                        //, width: 'calc(100% / 3)'
                    }}>

                    {
                        advisors.map((advisor, advisorsIndex) =>
                            (
                                <>

                                {
                                    advisorsIndex % 2 == 0
                                    &&
                                        <div
                                            key = { 'Empty_' + advisorsIndex }
                                            style = {{ flexBasis: '100%', height: '0' }}
                                            />
                                }

                                <div
                                    key = { 'Advisor_' + advisorsIndex }
                                    //className = 'team-item'
                                    style = {{
                                        //margin: 'auto'
                                        textAlign: 'center'
                                        //, border: 'blue 2px solid'
                                    }}>
                                    <div
                                        //key = { 'test_' + index2 }
                                        className = 'team-item'
                                        style = {{
                                            //width: 'calc(100% / 3)'
                                            margin: 'auto'
                                            //, backgroundColor: '#fff'
                                            , maxWidth: '325px'
                                            //, width: '33%'
                                            //, flexGrow: '1'
                                            //, flexGrow: 'unset'
                                            //, flexBasis: '33%'
                                            //, flexBasis: 'calc(33% - 24px)'
                                            //, padding: '0px 12px'
                                            //, flexGrow: '1'
                                        }}
                                        onClick = { () => onTeamMemberClick(advisor) }
                                        >

                                        <div
                                            style = {{
                                                  textAlign: 'center'
                                                //, maxWidth: '325px'
                                                //, maxHeight: '325px'
                                                , overflow: 'hidden'
                                                //, backgroundColor: '#000'
                                                , marginLeft: 'auto'
                                                , marginRight: 'auto'
                                                , width: '200px'
                                                , height: '200px'
                                                , borderRadius: '50%'
                                            }}>

                                            <img src = { advisor.image }
                                                style = {{
                                                    //width: 'calc(100% / 3)'
                                                      width: '100%'
                                                      //width: 'auto'
                                                    //, maxWidth: '325px'
                                                    //, minHeight: '325px'
                                                    //, maxHeight: '400px'
                                                    //, maxHeight: '325px'
                                                }} />
                                        </div>

                                        <div className = "title">

                                        <h3
                                            style = {{
                                                /*
                                                , fontFamily: 'Source Sans 3'
                                                , padding: '20px 0px'
                                                , fontSize: '30px'
                                                , fontStyle: 'normal'
                                                , fontWeight: '700'
                                                , lineHeight: '100%'
                                                , color: 'var(--color-gray-900, #212529)'
                                                */

                                                textAlign: 'center'
                                                , fontSize: '26px'
                                                //, whiteSpace: 'nowrap'
                                                //, textOverflow: 'ellipsis'
                                                //, textAlign: 'left'
                                                //, overflow: 'hidden'
                                            }}
                                            >
                                            { advisor.name }
                                        </h3>

                                        <p style = {{
                                              fontFamily: 'Source Sans 3'
                                            , fontSize: '15px'
                                            , fontStyle: 'normal'
                                            , fontWeight: '400'
                                            , lineHeight: '150%'
                                            //, textAlign: 'left'
                                            , textAlign: 'center'
                                            , color: 'color: var(--color-gray-900, #212529)'
                                        }}>{ advisor.position }</p>

                                        </div>

                                    </div>

                                </div>

                                </>
                            )
                        )
                    }

                </div>

            </div>
        </section>
    );
}

export default Team;
