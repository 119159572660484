//import { useState } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";
import App from "./App";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./utils/react-query";
import {
      BrowserRouter as Router
    , Switch
    //, Route
    //, useHistory
    //, useLocation
    //, useParams
    //, withRouter
    //, Link
} from 'react-router-dom';

/*
 * Reason why AppWrapper exists:
 * https://stackoverflow.com/questions/60329421/usedispatch-error-could-not-find-react-redux-context-value-please-ensure-the
 */

export const persistor = persistStore(store);

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                    <Router>
                        <App />
                    </Router>
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    );
};

export default AppWrapper;
